import configuration from '../configuration';

const CONTACT_SUPPORT_MESSAGE = `Intente de nuevo y si el problema continúa contacte a soporte.  ${configuration.supportPhoneNumber}`;
const RESOURCE_WAS_NOT_CREATED = `Debido a un error desconocido, el registro no puedo ser creado. ${CONTACT_SUPPORT_MESSAGE}`;
const RESOURCE_WAS_NOT_UPDATED = `Debido a un error desconocido, el registro no puedo ser actualizado. ${CONTACT_SUPPORT_MESSAGE}`;
const RESOURCE_WAS_NOT_FOUND = 'No encontramos el registro solicitado';
const RESOURCE_WAS_NOT_DELETED = `Debido a un error desconocido, el registro no pudo ser eliminado. ${CONTACT_SUPPORT_MESSAGE}`;

const errorsDictionary = {
  RESOURCE_WAS_NOT_CREATED,
  RESOURCE_WAS_NOT_UPDATED,
  RESOURCE_WAS_NOT_FOUND,
  RESOURCE_WAS_NOT_DELETED,
  INSPECTIONS_WERE_NOT_FOUND: `No se encontraron inspecciones. ${CONTACT_SUPPORT_MESSAGE}`,
  RFC_ALREADY_EXIST: `El RFC ya existe en nuestros registros ${CONTACT_SUPPORT_MESSAGE}`,
  SHORT_NAME_ALREADY_EXIST: `El nombre corto ya existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  EMAIL_ALREADY_EXIST: `El email ya existe en nuestros registros ${CONTACT_SUPPORT_MESSAGE}`,
  PETITION_NUMBER_ALREADY_EXIST: `El número de pedimento ya existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_STATUS_CHANGE: `Debido al estatus actual del registro, la acción no pudo completarse. ${CONTACT_SUPPORT_MESSAGE}`,
  ENTITY_CANNOT_BE_UPDATED: `Debido al estatus actual del registro, no ser actualizado. ${CONTACT_SUPPORT_MESSAGE}`,
  FILE_DOES_NOT_EXIST: `El archivo no existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_CONTRACT_DATE: `La fecha de la firma del contrato no puede ser posterior al día de hoy. ${CONTACT_SUPPORT_MESSAGE}`,
  ADDRESS_WAS_NOT_FOUND: `La dirección no existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_FASE_FOR_NORM_051: `Para las normas 051, 173, 187 y 235 solo se aceptan las fases 2 ó 3. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_ALREADY_HAVE_A_TASK_SCHEDULED: `Los productos elegidos ya fueron asignados en otra tarea. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_WAS_NOT_FOUND: `El documento no existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  FILE_WAS_NOT_SENT: `Faltó incluir el o los archivos. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_ALREADY_EXIST: `El documento que intentas enviar ya existe con ese nombre. ${CONTACT_SUPPORT_MESSAGE}`,
  COLLABORATOR_WAS_NOT_FOUND: `El colaborador no existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_WERE_NOT_FOUND: `Los productos no existen en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  MISSING_INVALIDATION_REASON: `Se señaló un producto invaldiado y no se especificó la razón, revise sus datos. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_DOES_NOT_ACCEPT_MORE_PRODUCTS: `Por su estado actual, ya no se pueden añadir más productos. ${CONTACT_SUPPORT_MESSAGE}`,
  GROUPED_PRODUCTS_CANNOT_BE_DELETED_BY_ID: `Los productos agrupados no pueden eliminarse individualmente. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_CANNOT_BE_UPDATED: `Debido a sus estatus, los productos no pueden ser actualizados. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_DOES_NOT_ACCEPT_DOCUMENT_MODIFICATIONS: `Ya no es posible subir documentos. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_ONLY_ACCEPTS_ONE_OF_THIS_TYPE_OF_DOCUMENT: `Solo puedes subir este tipo de documento una vez. ${CONTACT_SUPPORT_MESSAGE}`,
  COULD_NOT_READ_FILE: `No se pudo leer el archivo. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_DOES_NOT_BELONG_TO_SERVICE: `El archivo no corresponde a este pedimento. ${CONTACT_SUPPORT_MESSAGE}`,
  INVOICES_WERE_NOT_FOUND_IN_THE_DOCUMENT: `No encontramos folios en el pedimento pagado, asegúrate de subir el archivo correcto. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_DOCUMENT_TYPE: `El tipo de documento no es válido. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_DOES_NOT_BELONG_TO_USER: `Esta inspección no está asignada a ti, por lo tanto no puedes realizar cambios en ella. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_HAS_BEEN_CONCLUDED: `La inspección ya ha terminado, por lo tanto no es posible completar la acción. ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_WAS_NOT_FOUND: `La ronda no existe en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  CLIENTS_WERE_NOT_FOUND: `Los clientes no existen en nuestros registros. ${CONTACT_SUPPORT_MESSAGE}`,
  INVOICES_DOES_NOT_BELONG_TO_THE_SAME_CLIENT: `Los folios seleccionados no pertenecen al mismo cliente. ${CONTACT_SUPPORT_MESSAGE}`,
  VISIT_ADDRESS_WAS_NOT_SENT: `Debes elegir la direccion de visita para la inspecciones remotas. ${CONTACT_SUPPORT_MESSAGE}`,
  TIME_WAS_NOT_SENT: `Se debe elegir la hora de visita para la inspecciones remotas. ${CONTACT_SUPPORT_MESSAGE}`,
  MASTER_USER_CANNOT_BE_DELETED: `Este usuario no puede ser eliminado. ${CONTACT_SUPPORT_MESSAGE}`,
  UNAUTHORIZED: `No tienes permiso para realizar esta acción. ${CONTACT_SUPPORT_MESSAGE}`,
  UNEXPECTED_ERROR: `Error inesperado. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_CREDENTIALS: `Correo o contraseña incorrectos. ${CONTACT_SUPPORT_MESSAGE}`,
  VALIDATION_ERROR: `Datos ingresados no válidos. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_FILE_TYPE: `Tipo de archivo inválido. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_DOES_NOT_BELONG_TO_THE_SERVICE: `El archivo seleccionado no corresponde a este servicio, verifica que los números de pedimento coincidan. ${CONTACT_SUPPORT_MESSAGE}`,
  NO_IMAGES_FOUND: `No se encontraron las imágenes para generar el documento. ${CONTACT_SUPPORT_MESSAGE}`,
  COULD_NOT_GENERATE_DOCUMENT: `Debido a un error inesperado, no se pudo generar el documento(s). ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_IS_NOT_READY_FOR_A_VISIT: `El servicio aún no está listo para solicitar la visita. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_IS_ALREADY_WAITING_FOR_A_VISIT: `Ya se ha solicitado una visita para este servicio. ${CONTACT_SUPPORT_MESSAGE}`,
  NO_PRODUCTS_ARE_ABLE_TO_BE_INSPECTED: `El servicio no tiene productos para inspeccionar. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_NOT_CURRENTLY_IN_REVIEW: `La inspección no se encuentra en aprobación. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTOR_CANNOT_APPROVE_HIMSELF: `No puedes aprobar tu misma inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  APPROVER_DOES_NOT_HAS_THE_REQUIRED_NORMS: `No tienes las normas necesarias para aprobar la inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  RESPONSIBLE_DOES_NOT_HAS_THE_REQUIRED_NORMS: `El responsable no maneja la norma necesaria para realizar la inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_NOT_COMPLETE: `La inspección no ha sido completada, por lo tanto no puede enviarse a aprobar, por favor sube evidencia de la inspección ó su respectivo "Formato de Inspección" de la ronda. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ONSITE: `La tarea no es en sitio, por lo tanto no requiere de firmas de testigos ni hora de llegada. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_DOES_NOT_BELONG_TO_USER: `La tarea no te pertenece, así que no puedes realizar cambios en ella. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_HAS_NO_VISIT_RECORDS: `Aún no se ha agendado una visita para la tarea, por lo que no se pueden subir firmas de testigos aún. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_WAS_NOT_FOUND: `No se encontró la inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ON_SITE: `$La tarea no es en sitio, por lo tanto no se requieren de declaraciones del cliente o inspector. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_CONCLUDED: `La tarea ya finalizó, por consecuencia no se pueden hacer cambios en ella. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ABLE_TO_CLOSE: `La tarea no está en revisión ó en progreso, por lo tanto no puede ser cerrada. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_IS_NOT_IN_REVIEW: `La tarea no puede cerrarse ya que todas sus inspecciones deben estar en revisión ó asignadas a una nueva tarea. ${CONTACT_SUPPORT_MESSAGE}`,
  OUT_OF_CUSTOM_DATE_CANNOT_BE_BEFORE_SERVICE_CREATION_DATE: `La fecha de desaduanamiento no puede ser antes de la fecha de creación del servicio. ${CONTACT_SUPPORT_MESSAGE}`,
  DICTUM_SERVICE_CANNOT_BE_UPDATED: `El servicio no puede ser actualizado debido a su estatus actual. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_DOCUMENT_IS_REQUIRED_FIRST: `Para subir el pedimento rectificado es necesario subir primero el pedimento pagado. ${CONTACT_SUPPORT_MESSAGE}`,
  ORIGINAL_PETITION_NUMBER_WAS_NOT_FOUND_IN_THE_DOCUMENT: `El documento no contiene una referencia al número de pedimento original. ${CONTACT_SUPPORT_MESSAGE}`,
  SOME_INVOICES_WERE_NOT_FOUND_IN_THE_DOCUMENT: `Algunos folios no fueron encontrados en el documento, es necesario que todos los folios relacionados a este pedimento aparezcan en el documento. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_IS_NOT_ABLE_TO_UPDATE_BECAUSE_YOUR_INSPECTION_IS_IN_PROGRESS: `El producto no se puede actualizar porque su inspección ya esta en progreso. ${CONTACT_SUPPORT_MESSAGE}`,
  OUT_OF_CUSTOM_DATE_CANNOT_BE_MORE_THAN_TODAY: `La fecha de desaduanamiento no puede ser mayor a hoy. ${CONTACT_SUPPORT_MESSAGE}`,
  THE_CHECK_IN_TIME_IS_NOT_WITHIN_WORKING_HOURS: `La hora de inicio no es dentro del horario laboral, de 7am a 10pm. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_HAS_NOT_HAVE_PLANIFICATION_SIGNATURE: `La tarea no tiene registrada la firma del cliente de la "Planificacion de la Inspección". ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_HAS_NOT_HAVE_VISITOR_STATEMENTS: `Aún no se han registrado declaraciones del inspector. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTOR_IS_NOT_APPROVER: `No puedes aprobar la inspección ya que no eres aprobador. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_DONT_ABLE_TO_UPLOAD_DOCUMENTS: `El producto ya está siendo inspeccionado por lo tanto ya no es posible subir documentos. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_NOT_IN_PROGRESS: `La inspección no esta en progreso, por lo tanto no puede ser enviada a revisión. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ABLE_TO_CLOSE_BECAUSE_INSPECTIONS_STATUS: `No se puede cerrar la tarea, todas sus inspecciones deben estar asignadas a otra tarea o deben estar concluidas o en revisión. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_IS_NOT_COCNLUDED: `No se puede cerrar la tarea, todas sus inspecciones deben estar concluidas. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_WERE_NOT_FOUND_TO_LAYOUT_2: `No se encontraron productos para enviar en el Layout 2. ${CONTACT_SUPPORT_MESSAGE}`,
  NO_INVOICE_IS_CONCLUDED: `No se encontraron productos concluidos para generar dictámenes. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_ARE_NOT_UNATACCHED: `Todas las inspecciones de la tarea aún no estan terminadas. ${CONTACT_SUPPORT_MESSAGE}`,
  ALREADY_SET_PRODUCT_NOT_PRESENTED: `Ya se ha declarado el producto como no presentado. ${CONTACT_SUPPORT_MESSAGE}`,
  THE_PRODUCT_CANNOT_BE_UPDATED_DUE_TO_THE_STATUS_OF_ITS_INSPECTION: `El producto no puede ser actualizado por el estatus de su inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_LIST_ALREADY_SAVE: `La lista de inspección ya se ha guardado, no se puede volver a guardar. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_ALREADY_DICTAMINATE: `La inspección ya fué dictaminada. ${CONTACT_SUPPORT_MESSAGE}`,
  EVIDENCE_FILE_LIMIT_EXCEEDED: `Límite de archivos de evidencia excedido (máximo 50 documentos). ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_FEEDBACK_FILE_HAS_ALREADY_UPLOADED: `El formato de inspección ya fué subido con anterioridad, no es posible volver a subirlo. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_FORM_IS_NOT_IN_PDF_FORMAT: `El archivo no tiene el formato correcto, debe ser un archivo PDF. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_NOT_IN_VALID_STATUS: `Todas las inspecciones de la tarea deben estar en aprobación, concluidas o en una ronda difrente para subir las firmas de testigos. ${CONTACT_SUPPORT_MESSAGE}`,
  WITNESSES_SIGNS_ARE_NOT_UPLOADED_YET: `Se deben subir firma(s) de testigos para poder subir las declaraciones. ${CONTACT_SUPPORT_MESSAGE}`,
  TOO_MANY_REQUESTS: `Demasiadas solicitudes. ${CONTACT_SUPPORT_MESSAGE}`,
  STEP_ALREADY_DONE: `Acción no permitida, debido al estado actual del producto e inspección, no se puede volver a efectuar este paso en la realización de la inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_DOES_NOT_ACCEPT_DOCUMENTS: `Debido al estatus de la inspección, ya no se aceptan documentos. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_FORMAT_IS_REQUIRED: `Es necesario subir el "Formato de Inspección" para poder mandar a aprobar la inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_STEPS_HAVE_NOT_BEEN_DONE_YET: `Los pasos para realizar la inspección (actualizar producto, realizar lista de inspección, dictaminar y subir evidencia) deben ser completados para mandarla a aprobación. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_LIST_NOT_COMPLETED: `Se debe realizar la lista de inspección para poder dictaminar. ${CONTACT_SUPPORT_MESSAGE}`,
  QUESTIONS_NOT_UPLOADED_YET: `Las preguntas aún no se han subido, por lo tanto no se puede generar aún la Lista de Inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  QUESTIONS_ARE_MANDATORY: `Las preguntas son obligatorias para poder guardar la Lista de Inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_HAS_NOT_UPLOADED_EVIDENCE: `La ronda aún no tiene archivos subidos. ${CONTACT_SUPPORT_MESSAGE}`,
  SAMPLING_REJECTED_IS_ZERO: `Se ha contestado al menos un reactivo de la lista de inspeccion como 'No cumple' por lo tanto la cantidad de rechazados no puede ser 0 (cero). ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_CONSTANCY_FROM_EXPIRED_INVOICE: `No se puede generar una Constancia de un producto expirado. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_ARE_EXPIRED_ONLY_CAN_BE_APPROVED: `La inspección expiró, por lo tanto sólo se puede aprobar. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_EMPTY: `No hay folios disponibles para generar documentos, el archivo está vacío. ${CONTACT_SUPPORT_MESSAGE}`,
  UPLOADED_DOCUMENT_EMPTY: `El archivo que estás intentando subir está vacío. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_COMPLY_INSPECTION_LIST_WHIT_NOT_REJECTED_SAMPLING: `Se marcó como no cumple al menos un inciso de la lista de inspección, por lo tanto se debe marcar al menos 1 muestra rechazada en el muestreo. ${CONTACT_SUPPORT_MESSAGE}`,
  COMPLY_INSPECTION_LIST_WHIT_REJECTED_SAMPLING: `Se marcaron muestras rechazadas en el muestreo, por lo tanto debe de marcarse al menos un inciso de la lista deinspección como "No cumple". ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_WITH_NOT_COMPLY_INSPECTION_LIST_DOESNT_COMPLY: `Se marcó al menos un inciso de la lista de inspección como "No cumple", por lo tanto la inspección no puede ser dictaminada como "Cumple". ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_CONSTANCY_WHITOUT_INSPECTION_LIST: `No se puede generar la Constancia ya que no se ha realizado la lista de inspección del folio. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_DICTUM_WHITOUT_INSPECTION_LIST: `No se puede generar el Dictamen ya que no se ha realizado la lista de inspección del folio. ${CONTACT_SUPPORT_MESSAGE}`,
  EXPIRED_PRODUCT_CANNOT_BE_DELETED: `No se puede eliminar un folio expirado. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_NEW_ROUND_FROM_COMPLIES_ROUND: `No se puede mandar a generar nueva ronda a partir de una ronda que se marcó como 'Cumple'. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_WITH_NOT_COMPLY_INSPECTION_LIST_DOESNT_NOT_COMPLY: `No se marcó ningún inciso de la 'Lista de Inspección' como 'No cumple', por lo tanto no se puede dictaminar como 'No cumple'. ${CONTACT_SUPPORT_MESSAGE}`,
  RECTIFIED_PETITION_NUMBER_ITS_SAME_AS_PETITION_NUMBER: `El numero de pedimento rectificado es el mismo que el número de pedimento. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_DELETE_DOCUMENT_FROM_ANOTHER_ROUND: `No se pueden eliminar documentos de rondas pasadas. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_ABLE_TO_DELETE_DOCUMENTS: `No se pueden eliminar documentos de la inspección por su estatus actual. ${CONTACT_SUPPORT_MESSAGE}`,
  COULD_NOT_GENERATE_SERVICE_REQUEST: `No se pueden descargar la 'Solicitud de servicio' por el estado de la solicitud, debe completarse para poder descargar el documento. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_DELETE_SERVICE: `No se puede eliminar el servicio por su estatus actual. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_DELETE_INVOICE: `No se puede eliminar el folio por su estatus actual. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_CLOSED: `La tarea no esta cerrada aún, el documento no se puede descargar. ${CONTACT_SUPPORT_MESSAGE}`,
  CURRENTLY_THE_ROUND_DOES_NOT_ACCEPT_UPLOADING_EVIDENCE: `Actualmente la ronda no acepta subir archivos de ronda. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_ABLE_TO_GENERATE_DICTUMS: `No se pueden generar los documentos, ya que no existe pedimento pagado. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_NOT_PRESENTED_IN_ROUND: `No se puede guardar la lista de inspección de una ronda donde el producto no se presentó. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_SCHEDULED_FOR_TODAY: `No puedes iniciar la tarea porque no esta agendada para hoy. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_GROUPED_PRODUCTS_MUST_BE_INCLUDED: `No se puede generar el dictamen, ya que todos los productos agrupados deben estar concluidos. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_CANNOT_BE_DELETED_BECAUSE_STATUS: `El producto no se puede eliminar, ya que está en acciones ante S.E. ${CONTACT_SUPPORT_MESSAGE}`,
  DESIGN_FILE_HAS_ALREADY_UPLOADED: `El archivo de diseño ya se ha subido con anterioridad. ${CONTACT_SUPPORT_MESSAGE}`,
  DESIGN_INSPECTOR_CANNOT_BE_SAME_AT_LATER_INSPECTORS: `No se puede asignar ese responsable de la tarea, ya que no puede ser el mismo que el inspector de la primera ronda de diseño de etiquetas de una inspeccion seleccionada.  ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_ALREADY_INVOICED: `El servicio ya fue marcado como facturado. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_ABLE_EDIT_TASK: `No se puede editar la tarea por su estatus actual. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_ALREADY_COMPLETED: `El servicio ya fue completado, por lo tanto no se pueden hacer modificaciones. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_UPDATE_PRODUCT: `Por el estado actual del producto no se pueden hacer modificaciones en él. ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_DOCUMENTS_ALREADY_UPLOADED: `Los documentos de la ronda ya se han subido, recarga la página para ver la informacion actualizada. ${CONTACT_SUPPORT_MESSAGE}`,
  USER_CANNOT_BE_APPROVER_WHITOUT_BE_TECHNICAL_MANAGER: `El usuario no puede ser marcado como aprobador sin ser "Gerente Técnico" ó "Gerente Técnico Sustituto". ${CONTACT_SUPPORT_MESSAGE}`,
  USER_CANNOT_BE_TECHNICAL_MANAGER_WHITOUT_BE_APPROVER: `El usuario no puede ser marcado como "Gerente Técnico" ó "Gerente Técnico Sustituto" sin ser aprobador. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_NOT_READY_FOR_VISIT: `La(s) inspeción seleccionada aún no está lista para realizar la visita. ${CONTACT_SUPPORT_MESSAGE}`,
  OUT_OF_CUSTOMS_DATE_ALREADY_SET: `La fecha de desaduanamiento ya fue definida anteriormente. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_CREATE_USER_SYS_ADMIN: `No es posible crear un usuario con ese rol. ${CONTACT_SUPPORT_MESSAGE}`,
  DATE_ARE_AFTER_THAN_TODAY: `La fecha no puede ser mayor a hoy. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_NEEDS_UPLOADED_BEFORE: `Para ingresar datos de facturación se debe subir primero el pedimento pagado. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_UPLOADED_EVIDENCE: `La evidencia aún no se ha subido, se debe subir antes de dictaminar la ronda. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_INSPECTION_LIST_UPLOADED_YET: `Se debe realizar la 'Lista de Inspección' antes de subir evidencia de la ronda. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_CONSTANCY: `Actualmente no se puede generar la constancia, debido a que su inspección aún no a concluido. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_DICTUM: `Actualmente no se puede generar el dictamen, debido a que su inspección aún no a concluido. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_UPDATE_INDICATORS_PRODUCT: `Datos ingresados no válidos, no se pueden actualizar las propiedades especificadas del producto. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_UPDATE_SERVICE_BY_INVOICED: `El servicio no puede ser modificado porque ya fue facturado. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_MODIFY_PRODUCT_BY_INVOICED: `El producto no puede ser modificado porque su servicio ya fue facturado. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_CREATE_PRODUCT_BY_INVOICED: `El producto no puede ser creado porque su servicio ya fue facturado. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_INVOICED_SERVICE_WHITOUT_COMPLETE_REQUEST: `El servicio no puede ser facturado aún ya que no ha sido completada su solicitud ${CONTACT_SUPPORT_MESSAGE}`,
  413: `El archivo es demasiado grande, intenta comprimir el archivo e intentalo de nuevo. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTIONS_TO_REPORT_NOT_FOUND: `No se encontraron inspecciones para generar el reporte. ${CONTACT_SUPPORT_MESSAGE}`,
  TASKS_TO_REPORT_NOT_FOUND: `No se encontraron tareas para generar el reporte. ${CONTACT_SUPPORT_MESSAGE}`,
  COMMENTS_TO_REPORT_NOT_FOUND: `No se encontraron comentarios para generar el reporte. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_DOCUMENT_NOT_SIGNED_CONTRACT: `No se puede descargar el documento ya que el cleinte no tiene contrato firmado. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_TYPE_NOT_ACCEPTED: `El tipo de documento no es aceptado. ${CONTACT_SUPPORT_MESSAGE}`,
  REMOTE_INSPECTIONS_NOT_ABLE_TO_ASSIGN: `Una o varias de las inspecciones seleccionadas aún no están listas para asignarse a una tarea. ${CONTACT_SUPPORT_MESSAGE}`,
  SOME_DONT_COMPLY_QUESTION_DONT_HAVE_EVIDENCE: `Algun reactivo marcado como no cumple no tiene evidencia subida, revisa la lista de inspección. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_INSPECTION_FORMAT: `No es posible generar el "Formato de Inspección". ${CONTACT_SUPPORT_MESSAGE}`,
  EVIDENCE_SHOULD_BE_IMAGE: `La evidencia debe ser una imagen. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_ALREADY_SUPERVISED: `La inspección ya ha sido supervisada. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_SUPERVISED_OWN_JOB: `No puedes supervisar tu propio trabajo. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_SUPERVISED_INSPECTION_BY_STATUS: `No puedes supervisar la inspección por el estado actual del cierre mensual. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_FINISH_MONTHLY_CLOSING_BY_STATUS: `No puedes cerrar el cierre mensual por su estatus actual. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_NOT_AVAILABLE: `Documento no disponible. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_TO_REPORT_NOT_FOUND: `No se encontraron productos para generar el reporte. ${CONTACT_SUPPORT_MESSAGE}`,
  RESPONSIBLE_DOES_NOT_HAS_THE_REQUIRED_CERTIFICATE_NORMS: `El inspector no se puede asignar a esta tarea ya que cuenta con la norma pero no esta certificado en ella, para rondas que no son de "diseño" en las inspecciones seleccionadas, el inspector debe estar certificado. ${CONTACT_SUPPORT_MESSAGE}`,
  ACTION_IN_NOT_BELONG_THE_USER: `La acción no te pertenece, por lo tanto no puedes realizar cambios en ésta. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_APPROVE_OWN_ACTION: `No puedes aprobar una acción creada por tí. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_MODIFY_ACTION: `La acción ya se aprobó, no se pueden realizar modificaciones en ésta. ${CONTACT_SUPPORT_MESSAGE}`,
  ACTION_IS_NOT_APPROVED: `Por el estatus actual del registro, no se puede generar el documento. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICES_TO_REPORT_NOT_FOUND: `No se encontraron servicios para el reporte. ${CONTACT_SUPPORT_MESSAGE}`,
  INVOICES_NOT_FOUND: `No se encontraron folios. ${CONTACT_SUPPORT_MESSAGE}`,
  AUDIT_DOCUMENTS_NOT_FOUND: `No se encontraron documentos para el expediente. ${CONTACT_SUPPORT_MESSAGE}`,
  GENERATE_PRE_SIGNED_URL_ERROR: `Ocurrió un problema al subir el o los archivos, espere unos momentos antes de volver a intenatarlo y si el problema continúa contacte a soporte.  ${configuration.supportPhoneNumber}`,
  SELECTED_NORM_REQUIRE_MEASUREMENT_EQUIPMENT: `La norma a la que pertenece la lista de inspección requiere especificar equipo de medición pero éste no se proporcionó.  ${configuration.supportPhoneNumber}`,
  COLLABORATOR_EMAIL_ALREADY_EXIST: `El correo electrónico del colaborador ya existe en los registros del cliente, por favor usa otro.  ${configuration.supportPhoneNumber}`,
  CANNOT_UPDATE_TRAININ_RECORD: `No se puede actualizar el registro, todos los participantes ya han firmado la capacitación. ${configuration.supportPhoneNumber}`,
  THE_RECORD_HAS_NOT_BEEN_SIGNED_IN_FULL: `Aún no se ha firmado por completo el registro, no se puede generar el documento. ${configuration.supportPhoneNumber}`
};

export default errorsDictionary;
