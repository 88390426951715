import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  Modal,
  Select,
  Spinner,
  Switch,
  Table,
  Title
} from '../../../components';

import './styles.scss';
import { useResource } from '../../../hooks';
import { AppContext } from '../../../context/AppContext';
import {
  Operation,
  SelectOption
} from '../../../interfaces';
import { utils } from '../../../helpers';

const UsersList = () => {
  const { t } = useTranslation();

  dayjs.extend(weekOfYear);

  const {
    fetchResource: getOperations
  } = useResource<Operation[]>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [operations, setOperations] = useState<Operation[]>([]);
  const [operationsPeriod, setOperationsPeriod] = useState<'weekly' | 'monthly'>('weekly');
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());

  const getOperationsReport = () => {
    getOperations(
      `admin/notifications/operations-report?operationsPeriod=${operationsPeriod}`,
      (data) => {
        setOperations(data);
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const throttledHandleRequest = utils.throttle(getOperationsReport, 1000);

  const getYearsFromDates = (): SelectOption[] => {
    const years = new Set<number>();
    const data: SelectOption[] = [];

    operations.forEach(operation => {
      years.add(operation.year);
    });

    const yearsArray = Array.from(years);

    for (let index = 0; index < yearsArray.length; index += 1) {
      data.push({
        value: yearsArray[index],
        text: `${yearsArray[index]}`
      });
    }

    return data;
  };

  useEffect(() => {
    throttledHandleRequest();
  }, [operationsPeriod]);

  return (
    <div className="reports">
      <Header
        title={t('menu.management') || ''}
        showBackbutton={true}
      />
      <div className="reports__main">
        <div className="reports__main__container">
          <Title title='Reporte general de operaciones' />
          {
            operations.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {
                  getYearsFromDates().length > 1 && (
                    <Select
                      options={getYearsFromDates()}
                      onChange={(_value: string, _id: string) => {
                        setSelectedYear(Number(_value));
                      }}
                      value={selectedYear}
                      title={t('global.year') || ''}
                    />
                  )
                }
                <Switch
                  leftLabel={{
                    text: 'Semanal',
                    value: 'weekly'
                  }}
                  rigthLabel={{
                    text: 'Mensual',
                    value: 'monthly'
                  }}
                  onChange={(value: string | number) => {
                    setOperationsPeriod(`${value}` as 'weekly' | 'monthly');
                  }}
                />
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: operationsPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'week',
                        format: 'none'
                      },
                      {
                        label: 'Ingresos totales de modelos',
                        value: 'models',
                        format: 'none'
                      },
                      {
                        label: 'Inspecciones concluidas',
                        value: 'concludedInspections',
                        format: 'none'
                      },
                      {
                        label: 'Cantidad de desaprobaciones',
                        value: 'disaprobals',
                        format: 'none'
                      },
                      {
                        label: '% de incidencias',
                        value: 'incidents',
                        format: 'none'
                      },
                      {
                        label: 'Minutos disponibles',
                        value: 'minutesAvailable',
                        format: 'none',
                        helperText: 'Minutos calculados de la cantidad de inspectores por 1920min',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Minutos asignados',
                        value: 'minutesAssigned',
                        format: 'none',
                        helperText: 'Minutos sumados por norma: 004, 015, 020, 024 y 050 por 10min, 141, 142, 189 y 003 por 15min, 051, 187, 235 y 173 por 20min.',
                        helperTextTooltip: true
                      },
                      {
                        label: '% Ocupación',
                        value: 'percentageOfOccupation',
                        format: 'none'
                      },
                      {
                        label: 'Cantidad de tareas',
                        value: 'tasksQuantity',
                        format: 'none'
                      },
                      {
                        label: 'Cerradas en tiempo',
                        value: 'tasksClosedInTime',
                        format: 'none',
                        helperText: 'Tareas agendadas para el periodo que se cerraron en la fecha o menos',
                        helperTextTooltip: true
                      },
                      {
                        label: '% de cumplimiento',
                        value: 'percentageOfCompliance',
                        format: 'none'
                      }
                    ]}
                    items={operations.filter(item => item.year === selectedYear)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default UsersList;
