import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import {
  CheckBox,
  Input,
  ModalView,
  Select,
  Title
} from '../../../../components';

import { useResource } from '../../../../hooks';
import {
  Client
} from '../../../../interfaces';

import './styles.scss';
import { emptyClient } from '../../../../emptyObjects';
import { validate } from '../../../../helpers';

interface Props {
  visible: boolean
  onClose: () => void
  onSuccess: (_data: Client) => void
  onError: (_error: string) => void
  title: string
  client?: Client
}

const ClientForm: React.FC<Props> = (props) => {
  const {
    visible = false,
    onClose = () => { },
    onSuccess = (_data: Client) => { },
    onError = (_error: string) => { },
    client = emptyClient
  } = props;

  const { t } = useTranslation();

  const {
    createResource,
    updateResource
  } = useResource<Client>();

  const [formClient, setFormClient] = useState<Client>(client);
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const onChangeInput = (value: string | number, id: string) => {
    const setValue: { [name: string]: string } = {
      rfc: `${value}`.toUpperCase(),
      contractDate: `${dayjs(value).toDate()}`
    };

    setFormClient({
      ...formClient,
      [id]: setValue[id] || value
    });
  };

  const validData = (): boolean => {
    if (validate.text(formClient.rfc, 1) !== 'success') {
      setErrors({
        rfc: t('clients.errors.rfc')
      });

      return false;
    }

    if (validate.text(formClient.shortName, 1) !== 'success') {
      setErrors({
        shortName: t('clients.errors.shortName')
      });

      return false;
    }

    if (formClient.personType.length === 4) {
      setErrors({
        personType: t('clients.errors.personType')
      });

      return false;
    }

    if (formClient.tax_system === 'none' || formClient.tax_system === undefined || formClient.tax_system.length === 0) {
      setErrors({
        tax_system: t('clients.errors.taxSystem')
      });

      return false;
    }

    if (formClient.creditDays === undefined || formClient.creditDays === 0) {
      setErrors({
        creditDays: t('clients.errors.creditDays')
      });

      return false;
    }

    if (validate.text(formClient.businessName, 1) !== 'success') {
      setErrors({
        businessName: t('clients.errors.businessName')
      });

      return false;
    }

    if (validate.text(String(formClient.contractDate), 1) !== 'success') {
      setErrors({
        contractDate: t('clients.errors.contractDate')
      });

      return false;
    }

    if (
      Date.parse(dayjs(formClient.contractDate).format('YYYY-MM-DD'))
      > Date.parse(dayjs(new Date()).format('YYYY-MM-DD'))
    ) {
      setErrors({
        contractDate: t('clients.errors.contractDateGreaterThanToday')
      });

      return false;
    }

    if (validate.text(formClient.legalRepresentative, 1) !== 'success') {
      setErrors({
        legalRepresentative: t('clients.errors.legalRepresentative')
      });

      return false;
    }

    if (formClient.nationalityType.length === 4) {
      setErrors({
        nationalityType: t('clients.errors.nationalityType')
      });

      return false;
    }

    if (validate.text(formClient.contact.email, 1) !== 'success') {
      setErrors({
        contactEmail: t('clients.errors.contactEmail')
      });

      return false;
    }

    if (validate.email(formClient.contact.email) !== 'success') {
      setErrors({
        contactEmail: validate.email(formClient.contact.email)
      });

      return false;
    }

    if (validate.text(formClient.contact.phone, 1, 20) !== 'success') {
      setErrors({
        contactPhone: validate.text(formClient.contact.phone, 1, 20)
      });

      return false;
    }

    if (validate.text(formClient.contact.name, 1) !== 'success') {
      setErrors({
        contactName: t('clients.errors.contactName')
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.street, 1) !== 'success') {
      setErrors({
        fiscalAddressStreet: t('clients.errors.fiscalAddressStreet')
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.number, 1) !== 'success') {
      setErrors({
        fiscalAddressNumber: t('clients.errors.fiscalAddressNumber')
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.neighborhood, 1) !== 'success') {
      setErrors({
        fiscalAddressNeighborhood: t('clients.errors.fiscalAddressNeighborhood')
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.postalCode, 1, 5) !== 'success') {
      setErrors({
        fiscalAddressPostalCode: validate.text(formClient.fiscalAddress.postalCode, 1, 5)
      });

      return false;
    }

    if (formClient.fiscalAddress.postalCode.length !== 5) {
      setErrors({
        fiscalAddressPostalCode: t('clients.errors.fiscalAddressPostalCode')
      });

      return false;
    }

    if (validate.number(formClient.fiscalAddress.postalCode) !== 'success') {
      setErrors({
        fiscalAddressPostalCode: validate.number(formClient.fiscalAddress.postalCode)
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.city, 1) !== 'success') {
      setErrors({
        fiscalAddressCity: t('clients.errors.fiscalAddressCity')
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.state, 1) !== 'success') {
      setErrors({
        fiscalAddressState: t('clients.errors.fiscalAddressState')
      });

      return false;
    }

    if (validate.text(formClient.fiscalAddress.country, 1) !== 'success') {
      setErrors({
        fiscalAddressCountry: t('clients.errors.fiscalAddressCountry')
      });

      return false;
    }

    return true;
  };

  const handleCreateClient = () => {
    if (validData()) {
      createResource(
        'admin/clients',
        {
          rfc: formClient.rfc,
          shortName: formClient.shortName,
          businessName: formClient.businessName,
          contractDate: dayjs(formClient.contractDate).format('YYYY-MM-DD'),
          legalRepresentative: formClient.legalRepresentative,
          personType: formClient.personType,
          nationalityType: formClient.nationalityType,
          signedContract: formClient.signedContract,
          contact: {
            name: formClient.contact.name,
            email: formClient.contact.email,
            phone: formClient.contact.phone
          },
          fiscalAddress: {
            street: formClient.fiscalAddress.street,
            number: formClient.fiscalAddress.number,
            int: formClient.fiscalAddress.int || '',
            neighborhood: formClient.fiscalAddress.neighborhood,
            city: formClient.fiscalAddress.city,
            postalCode: formClient.fiscalAddress.postalCode,
            state: formClient.fiscalAddress.state,
            country: formClient.fiscalAddress.country
          },
          tax_system: formClient.tax_system,
          creditDays: Number(formClient.creditDays)
        },
        (data: Client) => {
          setFormClient(client);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  const handleEditClient = () => {
    if (validData()) {
      updateResource(
        `admin/clients/${formClient.id}`,
        {
          rfc: formClient.rfc,
          shortName: formClient.shortName,
          businessName: formClient.businessName,
          contractDate: dayjs(formClient.contractDate).format('YYYY-MM-DD'),
          legalRepresentative: formClient.legalRepresentative,
          personType: formClient.personType,
          nationalityType: formClient.nationalityType,
          signedContract: formClient.signedContract,
          contact: {
            name: formClient.contact.name,
            email: formClient.contact.email,
            phone: formClient.contact.phone
          },
          fiscalAddress: {
            street: formClient.fiscalAddress.street,
            number: formClient.fiscalAddress.number,
            int: formClient.fiscalAddress.int || '',
            neighborhood: formClient.fiscalAddress.neighborhood,
            city: formClient.fiscalAddress.city,
            postalCode: formClient.fiscalAddress.postalCode,
            state: formClient.fiscalAddress.state,
            country: formClient.fiscalAddress.country
          },
          tax_system: formClient.tax_system,
          creditDays: Number(formClient.creditDays)
        },
        (data: Client) => {
          setFormClient(client);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (client) {
      setFormClient(client);
    }
  }, [client]);

  useEffect(() => {
    setErrors({});
    if (!visible) {
      setFormClient(client);
    }
  }, [visible]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={client.id ? () => handleEditClient() : () => handleCreateClient()}
      customComponent={
        <div className="client-form">
          <Title title={client.id ? t('clients.formEdit') : t('clients.formTitle')} />
          <div className="client-form__title">
            <span>{t('services.generalInformation')}</span>
            <br />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title="RFC"
              type="text"
              value={formClient.rfc}
              id='rfc'
              onChange={onChangeInput}
              placeholder={t('clients.exampleRFC') || ''}
              disabled={!!client.id}
              hasError={!!errors.rfc}
              helperText={errors.rfc}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.shortName') || ''}
              type="text"
              value={formClient.shortName}
              id='shortName'
              onChange={onChangeInput}
              placeholder={t('clients.shortName') || ''}
              hasError={!!errors.shortName}
              helperText={errors.shortName}
            />
          </div>
          <div className="client-form--one-thirds">
            <Select
              title={t('clients.personType') || ''}
              options={[
                {
                  text: 'Moral',
                  value: 'moral'
                },
                {
                  text: t('clients.physcial'),
                  value: 'physcial'
                }
              ]}
              value={formClient.personType}
              id='personType'
              onChange={onChangeInput}
              toolTipText={t('clients.personTypeExample') || ''}
              toolTipPosition="left"
              hasError={!!errors.personType}
              helperText={errors.personType}
            />
          </div>
          <div className="client-form--one-thirds">
            <Select
              title={t('clients.taxSystem') || ''}
              options={[
                {
                  text: '601 - General de Ley Personas Morales',
                  value: '601'
                },
                {
                  text: '603 - Personas Morales con Fines no Lucrativos',
                  value: '603'
                },
                {
                  text: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios',
                  value: '605'
                },
                {
                  text: '606 - Arrendamiento',
                  value: '606'
                },
                {
                  text: '608 - Demás ingresos',
                  value: '608'
                },
                {
                  text: '609 - Consolidación',
                  value: '609'
                },
                {
                  text: '610 - Residentes en el Extranjero sin Establecimiento Permanente en México',
                  value: '610'
                },
                {
                  text: '611 - Ingresos por Dividendos (socios y accionistas)',
                  value: '611'
                },
                {
                  text: '612 - Personas Físicas con Actividades Empresariales y Profesionales',
                  value: '612'
                },
                {
                  text: '614 - Ingresos por intereses',
                  value: '614'
                },
                {
                  text: '616 - Sin obligaciones fiscales',
                  value: '616'
                },
                {
                  text: '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
                  value: '620'
                },
                {
                  text: '621 - Incorporación Fiscal',
                  value: '621'
                },
                {
                  text: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
                  value: '622'
                },
                {
                  text: '623 - Opcional para Grupos de Sociedades',
                  value: '623'
                },
                {
                  text: '624 - Coordinados',
                  value: '624'
                },
                {
                  text: '628 - Hidrocarburos',
                  value: '628'
                },
                {
                  text: '607 - Régimen de Enajenación o Adquisición de Bienes',
                  value: '607'
                },
                {
                  text: '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
                  value: '629'
                },
                {
                  text: '630 - Enajenación de acciones en bolsa de valores',
                  value: '630'
                },
                {
                  text: '615 - Régimen de los ingresos por obtención de premios',
                  value: '615'
                },
                {
                  text: '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
                  value: '625'
                },
                {
                  text: '626 - Régimen Simplificado de Confianza',
                  value: '626'
                }
              ]}
              value={formClient.tax_system}
              id='tax_system'
              onChange={onChangeInput}
              hasError={!!errors.tax_system}
              helperText={errors.tax_system}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.creditDays') || ''}
              type="number"
              value={formClient.creditDays}
              id='creditDays'
              onChange={onChangeInput}
              placeholder={t('clients.creditDays') || ''}
              hasError={!!errors.creditDays}
              helperText={errors.creditDays}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.businessName') || ''}
              type="text"
              value={formClient.businessName}
              id='businessName'
              onChange={onChangeInput}
              placeholder={t('clients.businessNameExample') || ''}
              toolTipText={t('clients.businessNameExplication') || ''}
              toolTipPosition="left"
              hasError={!!errors.businessName}
              helperText={errors.businessName}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.contractDate') || ''}
              type="date"
              value={dayjs(formClient.contractDate).format('YYYY-MM-DD')}
              id='contractDate'
              onChange={onChangeInput}
              hasError={!!errors.contractDate}
              helperText={errors.contractDate}
            />
          </div>
          <div className="client-form--two-thirds">
            <Input
              title={t('clients.legalRepresentative') || ''}
              type="text"
              value={formClient.legalRepresentative}
              id='legalRepresentative'
              onChange={onChangeInput}
              placeholder={t('clients.legalRepresentativeExample') || ''}
              hasError={!!errors.legalRepresentative}
              helperText={errors.legalRepresentative}
            />
          </div>
          <div className="client-form--one-thirds">
            <Select
              title={t('clients.clientType') || ''}
              options={[
                {
                  text: 'Nacional',
                  value: 'national'
                },
                {
                  text: 'International',
                  value: 'international'
                }
              ]}
              value={String(formClient.nationalityType)}
              id='nationalityType'
              onChange={onChangeInput}
              toolTipText={t('clients.clientTypeExplication') || ''}
              toolTipPosition="left"
              hasError={!!errors.nationalityType}
              helperText={errors.nationalityType}
            />
            <CheckBox
              label={t('global.signedContract')}
              checked={formClient.signedContract}
              onChange={() => {
                setFormClient({
                  ...formClient,
                  signedContract: !formClient.signedContract
                });
              }}
            />
          </div>
          <div className="client-form__title">
            <br />
            <span>{t('clients.contact')}</span>
            <br />
          </div>
          <div className="client-form--two-thirds">
            <Input
              title="E-mail"
              type="email"
              value={formClient.contact.email}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  contact: {
                    ...formClient.contact,
                    email: String(value)
                  }
                });
              }}
              placeholder={t('clients.emailExample') || ''}
              hasError={!!errors.contactEmail}
              helperText={errors.contactEmail}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.phone') || ''}
              type="number"
              value={formClient.contact.phone}
              onChange={(value: string | number) => {
                if (String(value).length <= 10) {
                  setFormClient({
                    ...formClient,
                    contact: {
                      ...formClient.contact,
                      phone: String(value)
                    }
                  });
                }
              }}
              placeholder={`${t('global.example')}: 3487891010`}
              hasError={!!errors.contactPhone}
              helperText={errors.contactPhone}
            />
          </div>
          <div className="client-form--two-thirds">
            <Input
              title={t('clients.fullName') || ''}
              type="text"
              value={formClient.contact.name}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  contact: {
                    ...formClient.contact,
                    name: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: Carlos Leon Hernandez`}
              hasError={!!errors.contactName}
              helperText={errors.contactName}
            />
          </div>
          <div className="client-form__title">
            <br />
            <span>{t('clients.fiscalAddress')}</span>
            <br />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.street') || ''}
              type="text"
              value={formClient.fiscalAddress.street}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    street: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: Matamoros`}
              hasError={!!errors.fiscalAddressStreet}
              helperText={errors.fiscalAddressStreet}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.extNumber') || ''}
              type="text"
              value={formClient.fiscalAddress.number}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    number: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: 34 B`}
              hasError={!!errors.fiscalAddressNumber}
              helperText={errors.fiscalAddressNumber}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.intNumber') || ''}
              type="text"
              value={formClient.fiscalAddress.int}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    int: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: 351`}
              hasError={!!errors.fiscalAddressNumber}
              helperText={errors.fiscalAddressNumber}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.cologne') || ''}
              type="text"
              value={formClient.fiscalAddress.neighborhood}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    neighborhood: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: Las Lomas`}
              hasError={!!errors.fiscalAddressNeighborhood}
              helperText={errors.fiscalAddressNeighborhood}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.postalCode') || ''}
              type="number"
              value={formClient.fiscalAddress.postalCode}
              onChange={(value: string | number) => {
                if (String(value).length <= 5) {
                  setFormClient({
                    ...formClient,
                    fiscalAddress: {
                      ...formClient.fiscalAddress,
                      postalCode: String(value)
                    }
                  });
                }
              }}
              placeholder={`${t('global.example')}: 47180`}
              hasError={!!errors.fiscalAddressPostalCode}
              helperText={errors.fiscalAddressPostalCode}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.city') || ''}
              type="text"
              value={formClient.fiscalAddress.city}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    city: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: Guadalajara`}
              hasError={!!errors.fiscalAddressCity}
              helperText={errors.fiscalAddressCity}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.state') || ''}
              type="text"
              value={formClient.fiscalAddress.state}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    state: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: Jalisco`}
              hasError={!!errors.fiscalAddressState}
              helperText={errors.fiscalAddressState}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.country') || ''}
              type="text"
              value={formClient.fiscalAddress.country}
              onChange={(value: string | number) => {
                setFormClient({
                  ...formClient,
                  fiscalAddress: {
                    ...formClient.fiscalAddress,
                    country: String(value)
                  }
                });
              }}
              placeholder={`${t('global.example')}: Mexico`}
              hasError={!!errors.fiscalAddressCountry}
              helperText={errors.fiscalAddressCountry}
            />
          </div>
        </div>
      }
    />
  );
};

export default ClientForm;
