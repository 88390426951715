import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CheckBox,
  Input,
  Select,
  Title
} from '../..';

import { validate } from '../../../helpers';

import { emptyRevisionProduct } from '../../../emptyObjects';
import { RevisionProduct, RevisionProductInput, NormCodes } from '../../../interfaces';
import { useKeyPress } from '../../../hooks';

import './styles.scss';
import { AppContext } from '../../../context/AppContext';

interface Props {
  title?: string
  preloadedProduct?: RevisionProduct
  onCreateProduct?: (_product: RevisionProduct) => void
  onUpdateProduct: (_prodct: RevisionProduct, _productHasChanged: boolean) => void
  disableInputs?: RevisionProductInput[]
  triggerSaveProduct?: boolean
  cleanStates?: boolean
}

export const RevisionProductForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    title = t('services.productFormTitle') || '',
    preloadedProduct = emptyRevisionProduct,
    onCreateProduct = () => { },
    onUpdateProduct = () => { },
    disableInputs = [],
    triggerSaveProduct = false,
    cleanStates = false
  } = props;

  const {
    openModal
  } = useContext(AppContext);

  const [productHasChanged, setProductHasChanged] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [product, setProduct] = useState<RevisionProduct>(preloadedProduct);
  const isANewProduct = preloadedProduct.id === '';

  const setOptionsFase = () => {
    if (['051', '173', '187', '235'].includes(preloadedProduct.normCode) || ['051', '173', '187', '235'].includes(product.normCode)) {
      return [
        {
          text: 'II',
          value: 'II'
        },
        {
          text: 'III',
          value: 'III'
        }
      ];
    }

    return [
      {
        text: 'II',
        value: 'II'
      },
      {
        text: 'III',
        value: 'III'
      },
      {
        text: 'N/A',
        value: 'N/A'
      }
    ];
  };

  const validProductData = () => {
    if (validate.text(product.normCode, 1) !== 'success' || product.normCode === 'none') {
      setErrors({
        normCode: t('services.errors.norm')
      });

      return false;
    }

    if (validate.text(product.fase, 1) !== 'success' || product.fase === 'none') {
      setErrors({
        fase: t('services.errors.fase')
      });

      return false;
    }

    if (validate.text(product.brand, 1) !== 'success') {
      setErrors({
        brand: t('services.errors.brand')
      });

      return false;
    }

    if (validate.text(product.presentation, 1) !== 'success') {
      setErrors({
        presentation: t('services.errors.presentation')
      });

      return false;
    }

    if (validate.text(product.description, 1) !== 'success') {
      setErrors({
        description: t('services.errors.description')
      });

      return false;
    }

    if (validate.text(product.code, 1) !== 'success') {
      setErrors({
        code: t('services.errors.code')
      });

      return false;
    }

    if (['0003', '050', '051', '141', '142', '173', '187', '189'].includes(product.normCode)) {
      if (product.indicators.package === 'none'
        || product.indicators.package === '') {
        setErrors({
          package: t('services.errors.emptypackage')
        });

        return false;
      }

      if (product.indicators.package === 'cylindrical') {
        // alto y radio
        if (product.indicators.radio.length === 0
          && product.indicators.high.length === 0) {
          setErrors({});
          setErrors({
            high: t('services.errors.emptyHigh'),
            radio: t('services.errors.emptyRadio')
          });
          return false;
        }
        if (product.indicators.high.length === 0) {
          setErrors({});
          setErrors({
            high: t('services.errors.emptyHigh')
          });
          return false;
        }
        if (product.indicators.radio.length === 0) {
          setErrors({});
          setErrors({
            radio: t('services.errors.emptyRadio')
          });
          return false;
        }
      }

      if (product.indicators.package === 'circular') {
        // radio
        if (product.indicators.radio.length === 0) {
          setErrors({});
          setErrors({
            radio: t('services.errors.emptyRadio')
          });
          return false;
        }
      }

      if (product.indicators.package === 'rectangular') {
        // largo y alto
        if (product.indicators.long.length === 0
          && product.indicators.high.length === 0) {
          setErrors({});
          setErrors({
            high: t('services.errors.emptyHigh'),
            long: t('services.errors.emptyLong')
          });
          return false;
        }
        if (product.indicators.high.length === 0) {
          setErrors({});
          setErrors({
            high: t('services.errors.emptyHigh')
          });
          return false;
        }
        if (product.indicators.long.length === 0) {
          setErrors({});
          setErrors({
            long: t('services.errors.emptyLong')
          });
          return false;
        }
      }
    }

    setErrors({});

    return true;
  };

  const onChangeInput = (value: string | number, id: string) => {
    setProductHasChanged(true);
    if (id === 'normCode') {
      if (!['051', '173', '187', '235'].includes(`${value}`)) {
        setProduct({
          ...product,
          normCode: value as NormCodes,
          fase: 'N/A'
        });
      } else {
        setProduct({
          ...product,
          normCode: value as NormCodes,
          fase: 'II'
        });
      }
    } else {
      setProduct({
        ...product,
        [id]: value
      });
    }
  };

  const handleSaveProduct = () => {
    if (validProductData()) {
      setProductHasChanged(false);
      if (product.id === '') {
        onCreateProduct(product);
      } else {
        onUpdateProduct(
          product,
          productHasChanged
        );
      }
    }
  };

  useEffect(() => {
    setProduct(preloadedProduct);
  }, [preloadedProduct]);

  useEffect(() => {
    if (cleanStates && !preloadedProduct.id) {
      setProduct(emptyRevisionProduct);
    }
  }, [cleanStates, preloadedProduct]);

  useEffect(() => {
    if (triggerSaveProduct) {
      handleSaveProduct();
    }
  }, [triggerSaveProduct]);

  useEffect(() => {
    if (disableInputs.length > 0) {
      setProductHasChanged(true);
    }
  }, [disableInputs]);

  useKeyPress(openModal ? () => { } : () => handleSaveProduct(), [product, openModal]);

  return (
    <div className='manual-form'>
      <Title title={title} type='secondary' />
      <p>{t('services.required')}</p>
      <br />
      <div className='manual-form__container'>
        <Select
          options={[
            {
              text: '003',
              value: '003'
            },
            {
              text: '004',
              value: '004'
            },
            {
              text: '015',
              value: '015'
            },
            {
              text: '020',
              value: '020'
            },
            {
              text: '024',
              value: '024'
            },
            {
              text: '050',
              value: '050'
            },
            {
              text: '051',
              value: '051'
            },
            {
              text: '141',
              value: '141'
            },
            {
              text: '142',
              value: '142'
            },
            {
              text: '173',
              value: '173'
            },
            {
              text: '187',
              value: '187'
            },
            {
              text: '189',
              value: '189'
            },
            {
              text: '235',
              value: '235'
            }
          ]}
          id='normCode'
          onChange={onChangeInput}
          title={t('global.norm') || ''}
          value={product.normCode}
          hasError={!!errors.normCode}
          helperText={errors.normCode}
          disabled={disableInputs.includes('normCode')}
        />
        <Select
          disabled={!['051', '173', '187', '235'].includes(product.normCode) || disableInputs.includes('normCode')}
          options={setOptionsFase()}
          id='fase'
          onChange={onChangeInput}
          title='Fase'
          value={product.fase}
          hasError={!!errors.fase}
          helperText={errors.fase}
        />
        <Input
          type='text'
          id='brand'
          value={product.brand}
          onChange={onChangeInput}
          title={t('services.productBrand') || ''}
          placeholder={t('services.productBrandExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.productBrandExplication') || ''}
          hasError={!!errors.brand}
          helperText={errors.brand}
          disabled={disableInputs.includes('brand')}
        />
        <Input
          type='text'
          value={product.presentation}
          id='presentation'
          onChange={onChangeInput}
          title={t('services.presentation') || ''}
          placeholder={t('services.presentationExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.presentationExplication') || ''}
          hasError={!!errors.presentation}
          helperText={errors.presentation}
          disabled={disableInputs.includes('presentation')}
          upperCase={false}
        />
        <Input
          type='text'
          value={product.description}
          id='description'
          onChange={onChangeInput}
          title={t('services.description') || ''}
          placeholder={t('services.descriptionExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.descriptionExplication') || ''}
          hasError={!!errors.description}
          helperText={errors.description}
          disabled={disableInputs.includes('description')}
        />
      </div>
      <div className='manual-form__codes-container'>
        <div className='manual-form__container'>
          <Input
            type='text'
            value={product.code}
            id='code'
            onChange={onChangeInput}
            title={t('services.codeInput') || ''}
            placeholder={t('services.codeInputExample') || ''}
            ableAutocomplete={false}
            hasError={!!errors.code}
            helperText={errors.code}
            disabled={disableInputs.includes('code')}
          />
        </div>
        {
          ['003', '050', '051', '141', '142', '173', '187', '189'].includes(product.normCode)
          && !disableInputs.includes('package')
          && !disableInputs.includes('long')
          && !disableInputs.includes('high')
          && !disableInputs.includes('radio')
          && (
            <>
              <div className='manual-form__container'>
                <Select
                  disabled={disableInputs.includes('package')}
                  options={[
                    {
                      text: 'Cilindro',
                      value: 'cylindrical'
                    },
                    {
                      text: 'Circular',
                      value: 'circular'
                    },
                    {
                      text: 'Rectángulo',
                      value: 'rectangular'
                    }
                  ]}
                  id='package'
                  onChange={(_value: string) => {
                    if (_value === 'none') {
                      setProductHasChanged(true);
                      setProduct({
                        ...product,
                        indicators: {
                          ...product.indicators,
                          package: 'cylindrical',
                          high: '',
                          long: '',
                          radio: ''
                        }
                      });
                    } else {
                      setProductHasChanged(true);
                      setProduct({
                        ...product,
                        indicators: {
                          ...product.indicators,
                          package: _value,
                          high: '',
                          long: '',
                          radio: ''
                        }
                      });
                    }
                  }}
                  title={t('global.package') || ''}
                  value={product.indicators.package}
                  hasError={!!errors.package}
                  helperText={errors.package}
                />
              </div>
              <div className='manual-form__container'>
                <Input
                  title={t('global.long') || ''}
                  id="width"
                  value={product.indicators.long}
                  disabled={product.indicators.package !== 'rectangular' || disableInputs.includes('long')}
                  type="decimal"
                  hasError={!!errors.long}
                  helperText={errors.long}
                  onChange={(_value: string | number) => {
                    setProductHasChanged(true);
                    setProduct({
                      ...product,
                      indicators: {
                        ...product.indicators,
                        long: `${_value}`
                      }
                    });
                  }}
                />
                <Input
                  title={t('global.high') || ''}
                  id="height"
                  value={product.indicators.high}
                  disabled={product.indicators.package === 'circular' || disableInputs.includes('high')}
                  type="decimal"
                  hasError={!!errors.high}
                  helperText={errors.high}
                  onChange={(_value: string | number) => {
                    setProductHasChanged(true);
                    setProduct({
                      ...product,
                      indicators: {
                        ...product.indicators,
                        high: `${_value}`
                      }
                    });
                  }}
                />
                <Input
                  title={t('global.radius') || ''}
                  id="radio"
                  value={product.indicators.radio}
                  disabled={product.indicators.package === 'rectangular' || disableInputs.includes('radio')}
                  type="decimal"
                  hasError={!!errors.radio}
                  helperText={errors.radio}
                  onChange={(_value: string | number) => {
                    setProductHasChanged(true);
                    setProduct({
                      ...product,
                      indicators: {
                        ...product.indicators,
                        radio: `${_value}`
                      }
                    });
                  }}
                />
              </div>
              <div className='manual-form__container__package-img-container'>
                <img src={`/images/${product.indicators.package}.jpeg`} alt="Package" className='manual-form__container__package-img-container__package-img' />
              </div>
            </>
          )
        }
        {
          !disableInputs.includes('code') && (
            <CheckBox
              label={t('services.design')}
              checked={product.indicators.design}
              onChange={() => {
                setProductHasChanged(true);
                setProduct({
                  ...product,
                  indicators: {
                    ...product.indicators,
                    design: !product.indicators.design
                  }
                });
              }}
              disabled={disableInputs.includes('code')}
            />
          )
        }
        {
          !disableInputs.includes('urgent') && (
            <CheckBox
              label={t('global.urgent')}
              checked={product.indicators.urgent}
              onChange={() => {
                setProductHasChanged(true);
                setProduct({
                  ...product,
                  indicators: {
                    ...product.indicators,
                    urgent: !product.indicators.urgent
                  }
                });
              }}
              disabled={disableInputs.includes('urgent')}
            />
          )
        }
      </div>
      <div className='manual-form__bottom-buttons'>
        <Button
          onClick={handleSaveProduct}
          type='secondary'
          label={t('services.saveProduct') || ''}
          submit={true}
          disabled={!productHasChanged}
        />
        {
          isANewProduct && (
            <Button
              onClick={() => {
                setProduct(emptyRevisionProduct);
              }}
              type='secondary-outlined'
              transparent={true}
              border='secondary-outlined'
              label={t('services.cleanForm') || ''}
            />
          )
        }
      </div>
    </div>
  );
};

export default RevisionProductForm;
