import i18next from 'i18next';

import {
  NormCodes,
  NormFases
} from '../../../../../../../../interfaces';

export interface ProductRow {
  normCode: string
  fase: number
  brand: string
  presentation: string
  description: string
  code: string
  design: string
  package: string
  long: string
  high: string
  radius: string
  urgent: string
}

interface RowValidation { isValid: boolean, errors: { [name: string]: string } }
interface CellValidation { isValid: boolean, errors: { [name: string]: string }, value: string }
type ValitadionsColumns = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'H' | 'I' | 'J' | 'K'

const isNormValid = (normCode: string): boolean => {
  if (normCode.length === 0) {
    return false;
  }

  switch (normCode) {
    case '004':
    case '04':
    // case '003':
    // case '03':
    // case '3':
    // eslint-disable-next-line no-fallthrough
    case '050':
    case '051':
    case '4':
    case '50':
    case '51':
    case '141':
    case '142':
    case '189':
    case '015':
    case '024':
    case '15':
    case '24':
    case '020':
    case '20':
    case '173':
    case '187':
    case '235':
      return true;
    default:
      return false;
  }
};

const validNumber = (value: string): boolean => {
  return !Number.isNaN(parseFloat(value));
};

export default {
  validateProductRow: (product: ProductRow, rowNumber: number): RowValidation => {
    let isValid = true;
    const errors: { [name: string]: string } = {};

    if (!isNormValid(product.normCode)) {
      isValid = false;
      errors[`A${rowNumber}`] = `${i18next.t('services.errors.invalidNorm')} '${product.normCode}'`;
    }

    if (['051', '51', '173', '235', '187'].includes(product.normCode) && !product.fase) {
      isValid = false;
      errors[`B${rowNumber}`] = i18next.t('services.errors.emptyFase');
    }

    if (['051', '51', '173', '235', '187'].includes(product.normCode) && (product.fase < 2 || product.fase > 3)) {
      isValid = false;
      errors[`B${rowNumber}`] = i18next.t('services.errors.invalidFase');
    }

    if (product.brand.length === 0) {
      isValid = false;
      errors[`C${rowNumber}`] = i18next.t('services.errors.emptyBrand');
    }

    if (product.presentation.length === 0) {
      isValid = false;
      errors[`D${rowNumber}`] = i18next.t('services.errors.emptyPresentation');
    }

    if (product.description.length === 0) {
      isValid = false;
      errors[`E${rowNumber}`] = i18next.t('services.errors.emptyDescription');
    }

    if (product.code.length === 0) {
      isValid = false;
      errors[`F${rowNumber}`] = i18next.t('services.errors.emptyCode');
    }

    if (product.package.length === 0) {
      isValid = false;
      errors[`H${rowNumber}`] = i18next.t('services.errors.emptypackage');
    }

    if (['024', '050', '051', '141', '142', '173', '187', '189', '24', '50', '51'].includes(product.normCode)) {
      if (product.package.length > 0) {
        const type = product.package.toLocaleUpperCase();

        if (!['RECTANGULAR', 'CILINDRO', 'CIRCULAR'].includes(type.toLocaleUpperCase())) {
          isValid = false;
          errors[`H${rowNumber}`] = i18next.t('services.errors.enumsPackage');
        }

        if (type === 'RECTANGULAR') {
          // largo y alto
          if (product.long.length === 0) {
            isValid = false;
            errors[`I${rowNumber}`] = i18next.t('services.errors.emptyLong');
          }
          if (product.high.length === 0) {
            isValid = false;
            errors[`J${rowNumber}`] = i18next.t('services.errors.emptyHigh');
          }
        }

        if (type === 'CILINDRO') {
          // alto y radio
          if (product.radius.length === 0) {
            isValid = false;
            errors[`K${rowNumber}`] = i18next.t('services.errors.emptyRadio');
          }
          if (product.high.length === 0) {
            isValid = false;
            errors[`J${rowNumber}`] = i18next.t('services.errors.emptyHigh');
          }
        }

        if (type === 'CIRCULAR') {
          // radio
          if (product.radius.length === 0) {
            isValid = false;
            errors[`K${rowNumber}`] = i18next.t('services.errors.emptyRadio');
          }
        }
      }
    }

    return {
      isValid,
      errors
    };
  },
  validateProductCell: (id: string): CellValidation => {
    let isValid = true;
    const errors: { [name: string]: string } = {};
    const type: ValitadionsColumns = id.substring(0, 1) as ValitadionsColumns;
    const number: string = id.substring(1);

    // eslint-disable-next-line no-undef
    const element: HTMLInputElement | null = document.querySelector(`#${id}`);

    let value: string = '';
    let valueNormCode: string = '';

    if (element) {
      value = element.value;
      element.classList.remove('excel-table__input--error');
    }

    if (['B', 'H', 'I', 'J', 'K'].includes(type)) {
      // eslint-disable-next-line no-undef
      const elementNormCode: HTMLInputElement | null = document.querySelector(`#A${id.substring(1)}`);

      if (elementNormCode) {
        valueNormCode = elementNormCode.value;
      }
    }

    const validations = {
      A: () => {
        if (!isNormValid(value)) {
          isValid = false;
          errors[id] = `${i18next.t('services.errors.invalidNorm')} '${value}'`;
        }
      },
      B: () => {
        if (['051', '51', '173', '235', '187'].includes(valueNormCode) && value === 'N/A') {
          isValid = false;
          errors[id] = i18next.t('services.errors.emptyFase');
        }
      },
      C: () => {
        if (value.length <= 0) {
          isValid = false;
          errors[id] = i18next.t('services.errors.emptyBrand');
        }
      },
      D: () => {
        if (value.length <= 0) {
          isValid = false;
          errors[id] = i18next.t('services.errors.emptyPresentation');
        }
      },
      E: () => {
        if (value.length <= 0) {
          isValid = false;
          errors[id] = i18next.t('services.errors.emptyDescription');
        }
      },
      F: () => {
        if (value.length <= 0) {
          isValid = false;
          errors[id] = i18next.t('services.errors.emptyCode');
        }
      },
      H: () => {
        if ((value.length <= 0
          && ['024', '050', '051', '141', '142', '173', '187', '189', '24', '50', '51'].includes(valueNormCode))) {
          isValid = false;
          errors[id] = i18next.t('services.errors.emptypackage');
        }

        if (!['RECTANGULAR', 'CILINDRO', 'CIRCULAR'].includes(value.toLocaleUpperCase())) {
          isValid = false;
          errors[id] = i18next.t('services.errors.enumsPackage');
        }
      },
      I: () => {
        if (['024', '050', '051', '141', '142', '173', '187', '189', '24', '50', '51'].includes(valueNormCode)) {
          // eslint-disable-next-line no-undef
          const packageElement: HTMLInputElement | null = document.querySelector(`#H${number}`);

          const packageElementValue = packageElement?.value.toLocaleUpperCase();

          if (packageElementValue === 'RECTANGULAR') {
            if (value.length <= 0) {
              isValid = false;
              errors[id] = i18next.t('services.errors.emptyLong');
            }
            if (validNumber(value) === false
              && value.length > 0) {
              isValid = false;
              errors[id] = i18next.t('services.errors.notNumberLong');
            }
          }
        }
      },
      J: () => {
        if (['024', '050', '051', '141', '142', '173', '187', '189', '24', '50', '51'].includes(valueNormCode)) {
          // eslint-disable-next-line no-undef
          const packageElement: HTMLInputElement | null = document.querySelector(`#H${number}`);

          const packageElementValue = packageElement?.value.toLocaleUpperCase();

          if (packageElementValue === 'RECTANGULAR'
            || packageElementValue === 'CILINDRO') {
            if (value.length <= 0) {
              isValid = false;
              errors[id] = i18next.t('services.errors.emptyHigh');
            }
            if (validNumber(value) === false
              && value.length > 0) {
              isValid = false;
              errors[id] = i18next.t('services.errors.notNumberHigh');
            }
          }
        }
      },
      K: () => {
        if (['024', '050', '051', '141', '142', '173', '187', '189', '24', '50', '51'].includes(valueNormCode)) {
          // eslint-disable-next-line no-undef
          const packageElement: HTMLInputElement | null = document.querySelector(`#H${number}`);

          const packageElementValue = packageElement?.value.toLocaleUpperCase();

          if (packageElementValue === 'CIRCULAR'
            || packageElementValue === 'CILINDRO') {
            if (value.length <= 0) {
              isValid = false;
              errors[id] = i18next.t('services.errors.emptyRadio');
            }
            if (validNumber(value) === false
              && value.length > 0) {
              isValid = false;
              errors[id] = i18next.t('services.errors.notNumberRadio');
            }
          }
        }
      }
    };

    validations[type]();

    return {
      isValid,
      errors,
      value
    };
  },
  isAnEmptyRow: (product: ProductRow): boolean => {
    if (
      product.normCode === ''
      && product.brand === ''
      && product.fase <= -1
    ) {
      return true;
    }

    return false;
  },
  parseNorm051Fase: (norm: NormCodes, fase: number): NormFases => {
    if (!['051', '51', '173', '235', '187'].includes(norm)) {
      return 'N/A';
    }

    switch (fase) {
      case 2:
        return 'II';
      case 3:
        return 'III';
      default:
        return 'N/A';
    }
  },
  parseDesign: (design: string): string => {
    if (design === ''
      || design.toLocaleLowerCase() === 'no'
      || design.toLocaleLowerCase() === 'false') {
      return 'NO';
    }

    return 'SI';
  },
  parseNormCode: (normCode: string): NormCodes => {
    switch (normCode) {
      case '3':
      case '03':
      case '003':
        return '003';
      case '4':
      case '004':
        return '004';
      case '50':
      case '050':
        return '050';
      case '51':
      case '051':
        return '051';
      case '141':
        return '141';
      case '142':
        return '142';
      case '189':
        return '189';
      case '15':
      case '015':
        return '015';
      case '24':
      case '024':
        return '024';
      case '20':
      case '020':
        return '020';
      case '173':
        return '173';
      case '187':
        return '187';
      case '235':
        return '235';
      default:
        return '051';
    }
  },
  formatFase: (value: string): number => {
    const auxValue = value.toLocaleUpperCase();

    const traductionsFase: { [name: string]: number } = {
      I: 1,
      II: 2,
      III: 3,
      1: 1,
      2: 2,
      3: 3
    };

    return traductionsFase[auxValue] || -1;
  }
};
