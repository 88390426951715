/* eslint-disable no-console */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { driver } from 'driver.js';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { useResource, useNavigate } from '../../hooks';

import {
  Accordeon,
  Button,
  Header,
  LateralMenu,
  Modal,
  ActionButtons,
  ModalViewDictumProductForm
} from '../../components';

import {
  ActionButton,
  Collaborator,
  DictumProduct,
  DictumService,
  Inspection,
  LateralMenuOption,
  TableOrderDirection,
  User
} from '../../interfaces';

import './styles.scss';

import {
  GeneralInfo,
  Documents,
  Tracing,
  History,
  Products,
  AssignOutOfCustomsDate,
  UploadPaidPetition,
  UploadRectifiedPetition,
  ModalBillingInfo
} from './components';

import { emptyDictumService } from '../../emptyObjects';
import { AppContext } from '../../context/AppContext';
import storage from '../../storage';
import configuration from '../../configuration';
import { utils } from '../../helpers';

type DictumServiceDetailContentType = 'info' | 'documents' | 'history' | 'tracing' | 'products';

const DictumServiceDetail = () => {
  const params = useParams();
  const {
    dictumServiceID,
    lookInTheTrash
  } = params;

  const {
    fetchResource,
    eraseResource,
    updateResource
  } = useResource<DictumService>();

  const {
    fetchResources
  } = useResource<DictumProduct>();

  const {
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getDictumProductDocument
  } = useResource<any>();

  const {
    updateResource: updateUser
  } = useResource<User>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const navigate = useNavigate();

  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects,
    userID,
    setUserToursDone,
    userToursDone
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [dictum, setDictum] = useState<DictumService>(emptyDictumService);
  const [products, setProducts] = useState<DictumProduct[]>([]);
  const [productsOrderBy, setProductsOrderBy] = useState<string>('invoice');
  const [productsOrderDirection, setProductsOrderDirection] = useState<TableOrderDirection>('ASC');
  const [productsTotalItems, setProductsTotalItems] = useState<number>(0);
  const [changePagination, setChangePagination] = useState<boolean>(false);
  const [productsCurrentPage, setProductsCurrentPage] = useState<number>(1);
  const [refetchDictum, setRefetchDictum] = useState<boolean>(false);
  const [lateralMenuValue, setLateralMenuValue] = useState<DictumServiceDetailContentType>('info');
  const [showUploadPaidPetitionModal, setShowUploadPaidPetitionModal] = useState<boolean>(false);
  const [hasReadyToCancelProducts, setHasReadyToCancelProducts] = useState<boolean>(false);
  const [hasCancelProducts, setHasCancelProducts] = useState<boolean>(false);
  const [
    haveAtLastOneInspectionAssigned,
    setHaveAtLastOneInspectionAssigned
  ] = useState<boolean>(false);
  const [
    thereAreProductToSEProcedures,
    setThereAreProductToSEProcedures
  ] = useState<boolean>(false);
  const [
    showUploadRectifiedPetitionModal,
    setShowUploadRectifiedPetitionModal
  ] = useState<boolean>(false);
  const [showAssignOutOfCustomsDate, setShowAssignOutOfCustomsDate] = useState<boolean>(false);
  const [
    showModalViewDictumProductForm,
    setShowModalViewDictumProductForm
  ] = useState<boolean>(false);
  const [rerenderContent, setRerenderContent] = useState<boolean>(false);
  const [showModalBillingInfo, setShowModalBillingInfo] = useState<boolean>(false);

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    },
    {
      label: t('services.documents'),
      value: 'documents'
    },
    {
      label: t('services.history'),
      value: 'history'
    },
    {
      label: t('services.tracing'),
      value: 'tracing'
    },
    {
      label: t('global.products'),
      value: 'products'
    }
  ];

  const handleDeleteService = (haveReadyToCancellateInvoices: boolean) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.deleteService'),
      () => {
        eraseResource(
          `/${apiType}/dictum-services/${dictumServiceID}`,
          {},
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('services.serviceDeleted'),
              setOpenModal,
              () => navigate('/binnacle'),
              soundEffects
            );
          },
          (error) => Modal.fireError(`${error}, ${haveReadyToCancellateInvoices ? t('services.errors.alertReadyTocancellateInvoices') : ''}`, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const handleRequestVisit = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.requestInspection'),
      () => {
        updateResource(
          `/${apiType}/dictum-services/${dictumServiceID}/ready-for-visit`,
          {},
          (data) => {
            Modal.fireSuccess(
              t('global.correct'),
              t('services.requestedInspection'),
              setOpenModal,
              () => setDictum(data),
              soundEffects
            );
          },
          (error) => Modal.fireError(error as string, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const ableOutOfCustomsDateButton = () => {
    if (apiType === 'admin'
      && userRole !== 'inspector') {
      return true;
    }

    if (apiType === 'public' && dictum.outOfCustomsDate === null) {
      return true;
    }

    return false;
  };

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    true: 'true',
    false: 'false'
  };

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo dictum={dictum} />),
      documents: (
        <Documents
          dictum={dictum}
          dictumServiceID={dictum.id}
          petitionNumber={dictum.petitionNumber}
          files={dictum.documents}
          // eslint-disable-next-line max-len
          onDownloadFileError={(error: string) => Modal.fireError(error, setOpenModal, soundEffects)}
          clientShortName={dictum.client.shortName}
        />
      ),
      history: (<History history={dictum.tracking} />),
      tracing: (<Tracing status={dictum.status} />),
      products: (<Products
        lookInTheTrash={lookInTheTrash === 'true'}
        products={products}
        totalItems={productsTotalItems}
        orderBy={productsOrderBy}
        orderDirection={productsOrderDirection}
        setOrderBy={setProductsOrderBy}
        setOrderDirection={setProductsOrderDirection}
        currentPage={productsCurrentPage}
        setCurrentPage={setProductsCurrentPage}
        changePagination={changePagination}
        setChangePagination={setChangePagination}
      />
      )
    };

    return contents[lateralMenuValue];
  }, [
    lateralMenuValue,
    dictum,
    rerenderContent,
    lookInTheTrash,
    products,
    productsCurrentPage,
    productsTotalItems,
    productsOrderBy,
    productsOrderDirection
  ]);

  const throttledHandleRequest = utils.throttle(
    () => {
      fetchResource(
        `/${apiType}/dictum-services/${dictumServiceID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash || '']}`,
        (data: DictumService) => {
          setDictum(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledHandleRequest();
  }, [dictumServiceID, apiType, refetchDictum]);

  useEffect(() => {
    if (dictum.id) {
      fetchResources(
        {
          resourcePath: `/${apiType}/dictum-products`,
          filters: {
            s_serviceID: dictum.id,
            b_lookInTheTrash: lookInTheTrashValues[lookInTheTrash || ''],
            b_distinctInvoices: dictum.status === 'validating-invoices' ? 'true' : '',
            b_attachedToTheService: true
          },
          pagination: {
            n_currentPage: productsCurrentPage,
            n_perPage: 10,
            n_totalItems: productsTotalItems
          },
          ordering: {
            s_orderBy: productsOrderBy,
            s_orderDirection: productsOrderDirection
          }
        },
        (data) => {
          setProducts(data.items);
          setProductsCurrentPage(data.pagination.currentPage);
          setProductsTotalItems(data.pagination.totalItems);
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  }, [
    dictum,
    rerenderContent,
    productsOrderBy,
    productsOrderDirection,
    changePagination
  ]);

  useEffect(() => {
    for (let index = 0; index < products.length; index += 1) {
      const { inspection } = products[index];

      if (inspection !== null && lookInTheTrash !== 'true') {
        const { id } = inspection;
        getInspection(
          `/${apiType}/inspections/${id}`,
          (data) => {
            if (data.task !== null || data.inspector !== null) {
              setHaveAtLastOneInspectionAssigned(true);
            }
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  }, [products]);

  useEffect(() => {
    if (dictum.id) {
      const filtersReadyToCancelProducts: { [filter: string]: string | boolean } = {
        s_status: 'validated',
        b_attachedToTheService: false,
        b_distinctInvoices: true,
        s_serviceID: dictum.id
      };

      fetchResources(
        {
          resourcePath: '/admin/dictum-products',
          filters: filtersReadyToCancelProducts
        },
        (data) => {
          if (data.items.length > 0) {
            setHasReadyToCancelProducts(true);
            storage.set('lateralMenuSEProceduresValue', 'cancellation-layout');
          }
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );

      fetchResources(
        {
          resourcePath: '/admin/dictum-products',
          filters: {
            s_status: 'cancellation-in-progress',
            s_serviceID: dictum.id
          }
        },
        (data) => {
          if (data.items.length > 0) {
            setHasCancelProducts(true);
          }
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );

      const filtersAnyProductToSEprocedures: { [filter: string]: string | boolean } = {
        b_distinctInvoices: true,
        s_serviceID: dictum.id
      };

      fetchResources(
        {
          resourcePath: '/admin/dictum-products',
          filters: filtersAnyProductToSEprocedures
        },
        (data) => {
          let result = false;

          if (dictum.status === 'validating-invoices') {
            if (data.items.find(element => ['validation-in-progress', 'invoice-assigned'].includes(element.status)) !== undefined) {
              result = true;
            }
          } else if (data.items.find(element => ['cancellation-in-progress'].includes(element.status)) !== undefined) {
            result = true;
          }

          if (result) {
            setThereAreProductToSEProcedures(true);
          }
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );
    }
  }, [
    dictum,
    rerenderContent
  ]);

  const setACtionSEProcedures = (havevalidationInProgressProducts: boolean): string => {
    if (havevalidationInProgressProducts) {
      return 'validate';
    }

    if (hasCancelProducts) {
      return 'cancelate';
    }
    return 'none';
  };

  const downloadAllCertificates = () => {
    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters: {
          s_status: 'concluded',
          s_serviceID: dictum.id
        }
      },
      async (data) => {
        let documentsDownloaded: number = 0;

        Modal.fireLoading();

        // eslint-disable-next-line no-restricted-syntax
        for (const product of data.items) {
          // eslint-disable-next-line no-await-in-loop, no-loop-func
          await new Promise<void>((resolve, reject) => {
            getDictumProductDocument(
              `/${apiType}/dictum-products/${product.id}/dictum-of-inspection`,
              (data2) => {
                const bytes = new Uint8Array(data2.file.data);
                const blob = new Blob([bytes], { type: 'application/pdf' });

                // eslint-disable-next-line no-undef
                const link = document.createElement('a');
                // eslint-disable-next-line no-undef
                link.href = window.URL.createObjectURL(blob);
                link.download = `${product.invoice}-${dictum.petitionNumber}-Dictamen.pdf`;
                link.click();
                documentsDownloaded += 1;

                // Actualizar el porcentaje y continuar con el siguiente documento
                const porcentaje = (documentsDownloaded / data.items.length) * 100;
                Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));

                resolve();
              },
              (error) => {
                Modal.fireError(error as string, undefined, soundEffects);
                reject(error);
              }
            );
          });
        }

        Modal.close();
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );
  };

  useEffect(() => {
    let whitoutExpiredInvoices: boolean = true;
    let havevalidationInProgressProducts: boolean = false;
    let sectionSEProcedures: string = 'none';

    for (let index = 0; index < products.length; index += 1) {
      if (products[index].indicators.waitingToExpired
        && products[index].status !== 'concluded') {
        sectionSEProcedures = 'cancellation';
        whitoutExpiredInvoices = false;
      }

      if (products[index].status === 'validation-in-progress') {
        havevalidationInProgressProducts = true;
      }
    }

    if (!whitoutExpiredInvoices) {
      sectionSEProcedures = 'cancellation';
    } else if (hasReadyToCancelProducts) {
      sectionSEProcedures = 'cancellation';
    }

    const requestIsCompleted = dictum.indicators ? dictum.indicators.requestIsCompleted : false;

    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              navigate(`/dictum-request/step_1/${dictum.id}`);
            }}
            type='primary-outlined'
            label={requestIsCompleted === true ? t('services.editService') || '' : t('services.completeService') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='editService'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('edit-service'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && dictum.actionsEnabled.includes('edit-service')
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              setShowAssignOutOfCustomsDate(true);
            }}
            type='primary-outlined'
            label={dictum.outOfCustomsDate !== null ? t('services.reAssignOutOfCustomsDate') || '' : t('services.assignOutOfCustomsDate') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='calendar'
            alignContent='left'
          />
        ),
        available: ableOutOfCustomsDateButton()
          && dictum.actionsEnabled.includes('assign-out-of-customs-date'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'collaborator'
          && userRole !== 'finance'
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={handleRequestVisit}
            type='primary-outlined'
            label={t('services.requestInspectionButton') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='onSiteVisit'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('request-inspection'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'collaborator'
          && userRole !== 'finance'
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => handleDeleteService(sectionSEProcedures === 'cancellation' || setACtionSEProcedures(havevalidationInProgressProducts) === 'cancelate')}
            type='primary-outlined'
            label={t('services.deleteServiceButton') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='paperShredder'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('delete-service'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && dictum.actionsEnabled.includes('delete-service')
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              setShowModalViewDictumProductForm(true);
            }}
            type='primary-outlined'
            label={t('services.addProduct') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='add'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('add-product'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && dictum.actionsEnabled.includes('add-product')
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              navigate(`/dictum-request/step_2/${dictum.id}/${dictum.petitionNumber}/${dictum.client.id}/${dictum.collaborator.id}`);
            }}
            type='primary-outlined'
            label={t('services.editProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='edit'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('edit-products'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && dictum.actionsEnabled.includes('edit-products')
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => setShowUploadPaidPetitionModal(true)}
            type='primary-outlined'
            label={t('services.uploadPaidPetition') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='paidPetitionFile'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('upload-paid-petition'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'collaborator'
          && userRole !== 'finance'
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => setShowUploadRectifiedPetitionModal(true)}
            type='primary-outlined'
            label={t('services.uploadRectifiedPetition') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='rectifiedPetitionFile'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('upload-rectified-petition'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'collaborator'
          && userRole !== 'finance'
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              navigate(`/se-procedures/none/${setACtionSEProcedures(havevalidationInProgressProducts)}/${sectionSEProcedures}`);
            }}
            type='primary-outlined'
            label={t('services.validateProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='SELogoDark'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('validate-products'),
        specialValidation: apiType === 'admin'
          && userRole !== 'finance'
          && ['master', 'operator'].includes(userRole)
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/none/${dictum.id}/DC`)}
            type='primary-outlined'
            label={t('services.assignProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspector'
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('assign-products'),
        specialValidation: ['master', 'operator'].includes(userRole) && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => setShowModalBillingInfo(true)}
            type='primary-outlined'
            label={t('global.registerInvoiced') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon={'creditCard'}
            alignContent='left'
          />
        ),
        available: dictum.actionsEnabled.includes('register-invoiced'),
        specialValidation: ['finance', 'master'].includes(userRole)
          && dictum.actionsEnabled.includes('register-invoiced')
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => downloadAllCertificates()}
            type='primary-outlined'
            label={'Descargar todos los certificados 1-1'}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon={'download'}
            alignContent='left'
            disabled={userRole !== 'sys-admin'}
          />
        ),
        available: userRole === 'sys-admin',
        specialValidation: userRole === 'sys-admin' && dictum.status === 'concluded'
      }
    ]);
  }, [
    dictum,
    products,
    i18n.language,
    apiType,
    hasReadyToCancelProducts,
    userRole,
    thereAreProductToSEProcedures,
    rerenderContent,
    haveAtLastOneInspectionAssigned,
    lookInTheTrash
  ]);

  const handleSelectLateralMenu = (value: string) => {
    setLateralMenuValue((value as DictumServiceDetailContentType));
  };

  const driverObj = driver({
    showProgress: true,
    allowClose: false,
    smoothScroll: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#header', popover: { title: t('tours.serviceTitle') || '', description: t('tours.serviceTitleDescription') || '' } },
      { element: '#share-button', popover: { title: t('tours.share') || '', description: t('tours.shareDescription') || '' } },
      { element: '#menu-section', popover: { title: t('tours.sections') || '', description: t('tours.sectionsDescription') || '' } },
      { element: '#data-section', popover: { title: t('tours.data') || '', description: t('tours.dataDescription') || '' } }
    ],
    onDestroyed: () => {
      if (apiType === 'admin') {
        updateUser(
          `admin/users/${userID}/tour-done`,
          {
            tourDone: 'service-detail'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }

      if (apiType === 'public') {
        updateCollaborator(
          'public/collaborators/tour-done',
          {
            tourDone: 'service-detail'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }
    }
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('service-detail') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <div className="dictum-service-detail">
      <UploadPaidPetition
        visible={showUploadPaidPetitionModal}
        dictumServiceID={dictumServiceID || ''}
        onClose={() => {
          setShowUploadPaidPetitionModal(false);

          setRefetchDictum(!refetchDictum);
          setRerenderContent(!rerenderContent);
        }}
        setRefetchDictum={setRefetchDictum}
        refetchDictum={refetchDictum}
      />
      <UploadRectifiedPetition
        visible={showUploadRectifiedPetitionModal}
        dictumServiceID={dictumServiceID || ''}
        onClose={() => {
          setShowUploadRectifiedPetitionModal(false);

          setRefetchDictum(!refetchDictum);
          setRerenderContent(!rerenderContent);
        }}
        setRefetchDictum={setRefetchDictum}
        refetchDictum={refetchDictum}
      />
      <Header
        title={t('services.dictumRequest')}
        subTitle={[
          {
            label: dictum.petitionNumber,
            title: t('services.petitionTitle') || ''
          }
        ]}
        showBackbutton={true}
      />
      <div className="dictum-service-detail__main">
        <div className="dictum-service-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                id: 'menu-section',
                element: (
                  <div >
                    <LateralMenu
                      value={lateralMenuValue}
                      onSelect={handleSelectLateralMenu}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="dictum-service-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/dictum-services.detail.${dictum.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/dictum-services.detail.${dictum.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
            id='share-button'
          />
          {getContent()}
        </div>
      </div>
      <AssignOutOfCustomsDate
        visible={showAssignOutOfCustomsDate}
        dictumServiceID={dictum.id}
        onOutOfCustomsDateAssigned={(_dictum: DictumService) => {
          let outOfCustomsDateAlert = '';

          if (dayjs(new Date()).diff(dayjs(_dictum.outOfCustomsDate), 'day') >= 30) {
            outOfCustomsDateAlert = t('services.outOfCustomsDateAlert');
          }

          console.log(_dictum);
          Modal.fireSuccess(
            t('global.correct'),
            `${t('services.outOfCustomsDateAssigned')} ${outOfCustomsDateAlert}`,
            setOpenModal,
            () => setDictum(_dictum),
            soundEffects
          );
        }}
        onClose={() => {
          setShowAssignOutOfCustomsDate(false);
        }}
        serviceCreatedAt={dictum.createdAt}
      />
      <ModalViewDictumProductForm
        visible={showModalViewDictumProductForm}
        title={t('services.addProduct') || ''}
        clientID={dictum.client.id}
        collaboratorID={dictum.collaborator.id}
        serviceID={dictum.id}
        onCreateProduct={() => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.createdProduct'),
            setOpenModal,
            () => {
              setShowModalViewDictumProductForm(false);
              setRerenderContent(!rerenderContent);
            },
            soundEffects
          );
        }}
        onClose={() => {
          setShowModalViewDictumProductForm(false);
        }}
        onError={(error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }}
      />
      <ModalBillingInfo
        visible={showModalBillingInfo}
        onClose={() => {
          setShowModalBillingInfo(false);
        }}
        serviceID={dictum.id}
        setDictum={setDictum}
      />
    </div>
  );
};

export default DictumServiceDetail;
