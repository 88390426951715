import _050 from './v1/050';
import _050_V2 from './v2/050';
import _050_V3 from './v3/050';
import _141 from './v1/141';
import _141_V2 from './v2/141';
import _189 from './v1/189';
import _189_V2 from './v2/189';
import _024 from './v1/024';
import _024_V2 from './v2/024';
import _015 from './v1/015';
import _015_V2 from './v2/015';
import _142 from './v1/142';
import _142_V2 from './v2/142';
import _004 from './v1/004';
import _003 from './v1/003';
import _003_V3 from './v3/003';
import _004_V2 from './v2/004';
import _004_V3 from './v3/004';
import _051 from './v1/051';
import _051_V2 from './v2/051';
import _051_V3 from './v3/051';
import _020 from './v1/020';
import _020_V2 from './v2/020';
import _173 from './v1/173';
import _173_V2 from './v2/173';
import _173_V3 from './v3/173';
import _187 from './v1/187';
import _187_V2 from './v2/187';
import _187_V3 from './v3/187';
import _235 from './v1/235';
import _235_V3 from './v3/235';

const versions: { [norm: string]: any } = {
  1: {
    '050': _050,
    141: _141,
    142: _142,
    '004': _004,
    '003': _003,
    189: _189,
    '015': _015,
    '024': _024,
    '051': _051,
    '020': _020,
    173: _173,
    187: _187,
    235: _235
  },
  2: {
    '050': _050_V2,
    141: _141_V2,
    142: _142_V2,
    '004': _004_V2,
    '003': _003,
    189: _189_V2,
    '015': _015_V2,
    '024': _024_V2,
    '051': _051_V2,
    '020': _020_V2,
    173: _173_V2,
    187: _187_V2,
    235: _235
  },
  3: {
    '050': _050_V3,
    141: _141_V2,
    142: _142_V2,
    '004': _004_V3,
    '003': _003_V3,
    189: _189_V2,
    '015': _015_V2,
    '024': _024_V2,
    '051': _051_V3,
    '020': _020_V2,
    173: _173_V3,
    187: _187_V3,
    235: _235_V3
  }
};

export default versions;
