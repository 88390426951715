import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { useResource, useNavigate } from '../../hooks';

import {
  Accordeon,
  Button,
  Header,
  LateralMenu,
  Modal,
  ActionButtons,
  ModalViewRevisionProductForm
} from '../../components';

import {
  ActionButton,
  RevisionProduct,
  RevisionService,
  Inspection,
  LateralMenuOption,
  TableOrderDirection,
  Collaborator,
  User
} from '../../interfaces';

import './styles.scss';

import {
  GeneralInfo,
  Tracing,
  History,
  Products,
  Documents,
  ModalBillingInfo
} from './components';
import { emptyRevisionService } from '../../emptyObjects';
import { AppContext } from '../../context/AppContext';
import configuration from '../../configuration';
import { UploadFiles } from '../RevisionProductDetail/components';
import storage from '../../storage';

type RevisionServiceDetailContentType = 'info' | 'history' | 'tracing' | 'products';

interface ProductFiles {
  visible: boolean
  productId: string
  roundID: number
}

const RevisionServiceDetail = () => {
  const params = useParams();

  const {
    revisionServiceID,
    lookInTheTrash
  } = params;

  const {
    fetchResource,
    eraseResource
  } = useResource<RevisionService>();

  const {
    fetchResources
  } = useResource<RevisionProduct>();

  const {
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    updateResource: updateUser
  } = useResource<User>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects,
    userID,
    setUserToursDone,
    userToursDone
  } = useContext(AppContext);

  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [products, setProducts] = useState<RevisionProduct[]>([]);
  const [productsTotalItems, setProductsTotalItems] = useState<number>(0);
  const [productsOrderBy, setProductsOrderBy] = useState<string>('invoice');
  const [productsOrderDirection, setProductsOrderDirection] = useState<TableOrderDirection>('ASC');
  const [productsCurrentPage, setProductsCurrentPage] = useState<number>(1);
  const [revision, setRevision] = useState<RevisionService>(emptyRevisionService);
  const [lateralMenuValue, setLateralMenuValue] = useState<RevisionServiceDetailContentType>('info');
  const [showModalBillingInfo, setShowModalBillingInfo] = useState<boolean>(false);
  const [visibleProductFiles, setVisibleProductFiles] = useState<ProductFiles>({
    visible: false,
    productId: '',
    roundID: 1
  });
  const [
    showModalViewRevisionProductForm,
    setShowModalViewRevisionProductForm
  ] = useState<boolean>(false);
  const [rerenderContent, setRerenderContent] = useState<boolean>(false);

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    true: 'true',
    false: 'false'
  };

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    },
    {
      label: t('services.documents'),
      value: 'documents'
    },
    {
      label: t('services.history'),
      value: 'history'
    },
    {
      label: t('services.tracing'),
      value: 'tracing'
    },
    {
      label: t('global.products'),
      value: 'products'
    }
  ];

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo revision={revision} />),
      documents: (<Documents
        revision={revision}
        revisionServiceID={revision.id}
        serviceNumber={revision.requestNumber}
        clientShortName={revision.client.shortName}
      />),
      history: (<History history={revision.tracking} />),
      tracing: (<Tracing status={revision.status} />),
      products: (<Products
        lookInTheTrash={lookInTheTrash === 'true'}
        products={products}
        totalItems={productsTotalItems}
        orderBy={productsOrderBy}
        orderDirection={productsOrderDirection}
        setOrderBy={setProductsOrderBy}
        setOrderDirection={setProductsOrderDirection}
        currentPage={productsCurrentPage}
        setCurrentPage={setProductsCurrentPage}
      />)
    };

    return contents[lateralMenuValue];
  }, [
    lateralMenuValue,
    revision,
    rerenderContent,
    lookInTheTrash,
    products,
    productsCurrentPage,
    productsTotalItems,
    productsOrderBy,
    productsOrderDirection
  ]);

  useEffect(() => {
    fetchResource(
      `/${apiType}/revision-services/${revisionServiceID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash || ''] || ''}`,
      (data) => {
        setRevision(data);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, [revisionServiceID, apiType, rerenderContent]);

  useEffect(() => {
    if (revision.id) {
      fetchResources(
        {
          resourcePath: `/${apiType}/revision-products`,
          filters: {
            s_serviceID: revision.id,
            b_lookInTheTrash: lookInTheTrashValues[lookInTheTrash || ''],
            b_attachedToTheService: true
          },
          pagination: {
            n_currentPage: productsCurrentPage,
            n_perPage: 10,
            n_totalItems: productsTotalItems
          },
          ordering: {
            s_orderBy: productsOrderBy,
            s_orderDirection: productsOrderDirection
          }
        },
        (data) => {
          setProducts(data.items);
          setProductsCurrentPage(data.pagination.currentPage);
          setProductsTotalItems(data.pagination.totalItems);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [revision, productsOrderBy, productsOrderDirection, productsCurrentPage]);

  const handleSelectLateralMenu = (value: string) => {
    setLateralMenuValue((value as RevisionServiceDetailContentType));
  };

  const handleDeleteService = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.deleteService'),
      () => {
        eraseResource(
          `/${apiType}/revision-services/${revisionServiceID}`,
          {},
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('services.serviceDeleted'),
              setOpenModal,
              () => navigate('/binnacle'),
              soundEffects
            );
          },
          (error) => Modal.fireError(error as string, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const ableButtonsIfDoesntComplete = () => {
    if (userRole === 'collaborator'
      && revision.indicators.requestIsCompleted) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              if (apiType === 'admin') {
                navigate(`/revision-request/step_1/${revision.id}`);
              } else {
                navigate(`/revision-request/step_2/${revision.id}/${revision.requestNumber}/${revision.client.id}/${revision.collaborator.id}`);
              }
            }}
            type='primary-outlined'
            label={revision.indicators.requestIsCompleted === true ? t('services.editService') || '' : t('services.completeService') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='editService'
            alignContent='left'
          />
        ),
        available: revision.actionsEnabled.includes('edit-service'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && ableButtonsIfDoesntComplete()
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              setShowModalViewRevisionProductForm(true);
            }}
            type='primary-outlined'
            label={t('services.addProduct') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='add'
            alignContent='left'
          />
        ),
        available: revision.actionsEnabled.includes('add-product'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && ableButtonsIfDoesntComplete()
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              navigate(`/revision-request/step_2/${revision.id}/${revision.requestNumber}/${revision.client.id}/${revision.collaborator.id}`);
            }}
            type='primary-outlined'
            label={t('services.editProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='edit'
            alignContent='left'
          />
        ),
        available: revision.actionsEnabled.includes('edit-products'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && ableButtonsIfDoesntComplete()
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={handleDeleteService}
            type='primary-outlined'
            label={t('services.deleteServiceButton') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='paperShredder'
            alignContent='left'
          />
        ),
        available: revision.actionsEnabled.includes('delete-service'),
        specialValidation: ['master', 'operator', 'collaborator'].includes(userRole) && ableButtonsIfDoesntComplete() && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/none/${revision.id}/REV`)}
            type='primary-outlined'
            label={t('services.assignProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspector'
            alignContent='left'
          />
        ),
        available: revision.actionsEnabled.includes('assign-products'),
        specialValidation: apiType === 'admin' && ['master', 'operator'].includes(userRole) && ableButtonsIfDoesntComplete() && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => setShowModalBillingInfo(true)}
            type='primary-outlined'
            label={t('global.registerInvoiced') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon={'creditCard'}
            alignContent='left'
          />
        ),
        available: revision.actionsEnabled.includes('register-invoiced'),
        specialValidation: revision.actionsEnabled.includes('register-invoiced') && lookInTheTrash !== 'true'
      }
    ]);
  }, [revision, apiType, i18n.language, products, lookInTheTrash]);

  const driverObj = driver({
    showProgress: true,
    allowClose: false,
    smoothScroll: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#header', popover: { title: t('tours.serviceTitle') || '', description: t('tours.serviceTitleDescription') || '' } },
      { element: '#share-button', popover: { title: t('tours.share') || '', description: t('tours.shareDescription') || '' } },
      { element: '#menu-section', popover: { title: t('tours.sections') || '', description: t('tours.sectionsDescription') || '' } },
      { element: '#data-section', popover: { title: t('tours.data') || '', description: t('tours.dataDescription') || '' } }
    ],
    onDestroyed: () => {
      if (apiType === 'admin') {
        updateUser(
          `admin/users/${userID}/tour-done`,
          {
            tourDone: 'service-detail'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }

      if (apiType === 'public') {
        updateCollaborator(
          'public/collaborators/tour-done',
          {
            tourDone: 'service-detail'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }
    }
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('service-detail') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <div className="revision-service-detail">
      <Header
        title={t('services.revisionRequest')}
        subTitle={[
          {
            label: revision?.requestNumber,
            title: t('services.serviceNumber') || ''
          }
        ]}
        showBackbutton={true}
      />
      <div className="revision-service-detail__main">
        <div className="revision-service-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                id: 'menu-section',
                element: (
                  <div >
                    <LateralMenu
                      value="info"
                      onSelect={handleSelectLateralMenu}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="revision-service-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/revision-services.detail.${revision.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/revision-services.detail.${revision.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
            id='share-button'
          />
          {getContent()}
        </div>
      </div>
      <ModalViewRevisionProductForm
        visible={showModalViewRevisionProductForm}
        title={t('services.addProduct') || ''}
        clientID={revision.client.id}
        collaboratorID={revision.collaborator.id}
        serviceID={revision.id}
        onCreateProduct={(data) => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.createdProduct'),
            setOpenModal,
            () => {
              setRerenderContent(!rerenderContent);

              getInspection(
                `/admin/inspections/${data.inspection?.id}`,
                (inspection) => {
                  setVisibleProductFiles({
                    visible: true,
                    productId: data.id,
                    roundID: inspection.rounds.length
                  });
                },
                (error: string) => Modal.fireError(error, undefined, soundEffects)
              );
            },
            soundEffects
          );
        }}
        onClose={() => {
          setShowModalViewRevisionProductForm(false);
        }}
        onError={(error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }}
      />
      <ModalBillingInfo
        visible={showModalBillingInfo}
        onClose={() => {
          setShowModalBillingInfo(false);
        }}
        serviceID={revision.id}
        setRevision={setRevision}
      />
      <UploadFiles
        visible={visibleProductFiles.visible}
        require={true}
        onClose={() => setVisibleProductFiles({ ...visibleProductFiles, visible: false })}
        onChangeProduct={(_product: RevisionProduct) => {
          setVisibleProductFiles({ ...visibleProductFiles, visible: false });

          Modal.fireSuccess(
            t('global.correct'),
            t('services.uploadedFiles'),
            setOpenModal,
            () => { },
            soundEffects
          );
        }}
        productID={visibleProductFiles.productId}
        roundID={visibleProductFiles.roundID}
        productFiles={[]}
      />
    </div>
  );
};

export default RevisionServiceDetail;
