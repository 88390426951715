import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  Modal,
  ModalView,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { useResource } from '../../../../hooks';
import { DictumService } from '../../../../interfaces';

import './styles.scss';

interface Props {
  serviceID: string
  setDictum: (_value: DictumService) => void
  onClose: () => void
  visible: boolean
}

const ModalBillingInfo: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    serviceID,
    setDictum,
    onClose,
    visible
  } = props;

  const {
    updateResource
  } = useResource<DictumService>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [UUID, setUUID] = useState<string>('');
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const validData = () => {
    if (invoiceNumber === '') {
      setErrors({
        invoiceNumber: t('global.dataError')
      });
      return false;
    }
    if (date === '') {
      setErrors({
        date: t('global.dataError')
      });
      return false;
    }
    if (UUID === '') {
      setErrors({
        UUID: t('global.dataError')
      });
      return false;
    }

    const formatoRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    if (!formatoRegex.test(UUID)) {
      setErrors({
        UUID: t('global.UUIDinvalid')
      });
      return false;
    }

    return true;
  };

  const markInvoiced = () => {
    if (validData()) {
      updateResource(
        `/admin/dictum-services/${serviceID}/invoiced`,
        {
          invoiceNumber,
          date,
          UUID
        },
        (data) => {
          Modal.fireSuccess(
            t('global.correct'),
            t('global.updated'),
            setOpenModal,
            () => {
              setDictum(data);
              onClose();
            },
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={markInvoiced}
      customComponent={
        <div className='form-billing-container'>
          <Title title={t('global.billingInformation')} />
          <Input
            title={t('global.invoiceNumber') || ''}
            type={'text'}
            value={invoiceNumber}
            onChange={(_value: string | number, _id: string) => {
              setInvoiceNumber(`${_value}`);
            }}
            hasError={!!errors.invoiceNumber}
            helperText={errors.invoiceNumber || ''}
          />
          <Input
            title={t('global.date') || ''}
            type={'date'}
            value={date}
            onChange={(_value: string | number, _id: string) => {
              setDate(`${_value}`);
            }}
            hasError={!!errors.date}
            helperText={errors.date || ''}
            maxDate={dayjs(new Date()).format('YYYY-MM-DD')}
          />
          <Input
            title='UUID'
            type={'text'}
            value={UUID}
            onChange={(_value: string | number, _id: string) => {
              setUUID(`${_value}`);
            }}
            hasError={!!errors.UUID}
            helperText={errors.UUID || ''}
            placeholder='3fa85f64-5717-4562-b3fc-2c963f66afa6'
          />
        </div>
      }
    />
  );
};

export default ModalBillingInfo;
