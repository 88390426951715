import axios from 'axios';
import { Modal } from './components';
import configuration from './configuration';
import Storage from './storage';

const sessionErrorCodes = ['JWT_EXPIRED', 'BAD_JWT_TOKEN'];

class Api {
  instance: any;
  UNEXPECTED_ERROR: string

  constructor() {
    this.instance = axios.create({
      baseURL: configuration.apiBaseUrl,
      headers: { 'Content-Type': 'application/json' }
    });

    this.instance.interceptors.request.use((requestConfig: any) => {
      Modal.fireLoading();

      const sessionToken = Storage.get('sessionToken');

      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.common.Authorization = `Bearer ${sessionToken}`;

      return requestConfig;
    });

    this.instance.interceptors.response.use((res: any) => {
      Modal.close();

      return Promise.resolve(res);
    }, (error: any) => {
      const { response = {} } = error || {};
      const { data = {} } = response;
      const { code } = data;

      Modal.close();
      if (sessionErrorCodes.includes(code)) {
        Storage.clear();
        // eslint-disable-next-line no-undef
        window.location.replace(`${configuration.webAppBaseUrl}/signin/no-route`);
      }

      return Promise.reject(error);
    });

    this.UNEXPECTED_ERROR = 'Error inesperado, intente de nuevo más tarde o contacte a un asesor 3323847097.';
  }

  _get(endpoint: string, params: any) {
    return this.instance.get(endpoint, { params });
  }

  _post(endpoint: string, payload: any, extraData?: any) {
    return this.instance.post(endpoint, payload, extraData || null);
  }

  _put(endpoint: string, payload: any) {
    return this.instance.put(endpoint, payload);
  }

  _delete(endpoint: string, params: any) {
    return this.instance.delete(endpoint, { params });
  }
}

export default new Api();
