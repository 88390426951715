/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SubTitle } from '../../../../components';
import { ConstancyProduct, DictumProduct, RevisionProduct } from '../../../../interfaces';

import './styles.scss';

interface Item {
  label: string
  data: string
}

interface Props {
  dictumProduct: DictumProduct
  constancyProduct: ConstancyProduct
  revisionProduct: RevisionProduct
}

const ProductInformation: React.FC<Props> = (props) => {
  const {
    dictumProduct,
    constancyProduct,
    revisionProduct
  } = props;

  const { t } = useTranslation();

  const dictumProductData: Item[] = [
    {
      label: t('global.invoice'),
      data: dictumProduct.invoice
    },
    {
      label: t('global.norm'),
      data: dictumProduct.normCode
    },
    {
      label: 'Fase',
      data: dictumProduct.fase
    },
    {
      label: t('global.tariffFraction'),
      data: dictumProduct.tariffFraction
    },
    {
      label: 'UMC',
      data: dictumProduct.umc
    },
    {
      label: t('services.umcQuantity'),
      data: String(dictumProduct.umcQuantity)
    },
    {
      label: t('services.labelsToPut'),
      data: String(dictumProduct.labelsToPut)
    },
    {
      label: t('services.labelingMode'),
      data: dictumProduct.labelingMode
    },
    {
      label: t('global.brand'),
      data: dictumProduct.brand
    },
    {
      label: t('global.presentation'),
      data: dictumProduct.presentation
    },
    {
      label: t('global.description'),
      data: dictumProduct.description
    },
    {
      label: t('global.code'),
      data: dictumProduct.code
    },
    {
      label: t('global.model2'),
      data: dictumProduct.code2
    }
  ];
  const constancyProductData: Item[] = [
    {
      label: t('global.invoice'),
      data: constancyProduct.invoice
    },
    {
      label: t('global.norm'),
      data: constancyProduct.normCode
    },
    {
      label: 'Fase',
      data: constancyProduct.fase
    },
    {
      label: t('global.brand'),
      data: constancyProduct.brand
    },
    {
      label: t('global.presentation'),
      data: constancyProduct.presentation
    },
    {
      label: t('global.description'),
      data: constancyProduct.description
    },
    {
      label: t('global.code'),
      data: constancyProduct.code
    }
  ];
  const revisionProductData: Item[] = [
    {
      label: t('global.invoice'),
      data: revisionProduct.invoice
    },
    {
      label: t('global.norm'),
      data: revisionProduct.normCode
    },
    {
      label: 'Fase',
      data: revisionProduct.fase
    },
    {
      label: t('global.brand'),
      data: revisionProduct.brand
    },
    {
      label: t('global.presentation'),
      data: revisionProduct.presentation
    },
    {
      label: t('global.description'),
      data: revisionProduct.description
    },
    {
      label: t('global.code'),
      data: revisionProduct.code
    }
  ];

  return (
    <div className='inspection-product-information__global-container' >
      <div className='inspection-product-information inspection-product-information--open'>
        <SubTitle
          subTitle={t('services.productInformation')}
        />
        <div className='inspection-product-information__container'>
          {
            dictumProduct.id !== '' && (
              dictumProductData.map((item: Item, index: number) => (
                <div className='inspection-product-information__item' key={index}>
                  <p className='inspection-product-information__item__title'>{item.label}</p>
                  <p className='inspection-product-information__item__data' id={item.label}>{item.data}</p>
                </div>
              ))
            )
          }
          {
            constancyProduct.id !== '' && (
              constancyProductData.map((item: Item, index: number) => (
                <div className='inspection-product-information__item' key={index}>
                  <p className='inspection-product-information__item__title'>{item.label}</p>
                  <p className='inspection-product-information__item__data' id={item.label}>{item.data}</p>
                </div>
              ))
            )
          }
          {
            revisionProduct.id !== '' && (
              revisionProductData.map((item: Item, index: number) => (
                <div className='inspection-product-information__item' key={index}>
                  <p className='inspection-product-information__item__title'>{item.label}</p>
                  <p className='inspection-product-information__item__data' id={item.label}>{item.data}</p>
                </div>
              ))
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;
