import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';

import {
  Accordeon,
  Button,
  Header,
  Modal,
  ActionButtons,
  ModalView,
  CheckBox
} from '../../components';
import { emptyCollaborator, emptyUser } from '../../emptyObjects';
import { useResource } from '../../hooks';
import {
  ActionButton,
  Collaborator,
  User,
  UserRoles
} from '../../interfaces';
import {
  ChangePasswordForm,
  GeneralInfo,
  UploadProfilePicture
} from './components';

import './styles.scss';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';
import storage from '../../storage';

export const Profile = () => {
  const {
    fetchResource: getUser
  } = useResource<User>();

  const {
    fetchResource: getCollaborator
  } = useResource<Collaborator>();

  const {
    createResource
  } = useResource<boolean>();

  const {
    updateResource: updateUser
  } = useResource<User>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const {
    apiType,
    userRole,
    setOpenModal,
    mandatoryInspectionList,
    setMandatoryInspectionList,
    soundEffects,
    setUserToursDone,
    userToursDone
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const ableRolesToEditProfile: UserRoles[] = ['master', 'inspector', 'operator', 'sys-admin'];
  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [user, setUser] = useState<User>(emptyUser);
  const [collaborator, setCollaborator] = useState<Collaborator>(emptyCollaborator);
  const [
    showUploadProfilePictureModal,
    setShowUploadProfilePictureModal
  ] = useState<boolean>(false);
  const [
    showModalMandatoryInspectionList,
    setShowModalMandatoryInspectionList
  ] = useState<boolean>(false);
  const [
    checkboxMandatoryInspectionList,
    setCheckboxMandatoryInspectionList
  ] = useState<boolean>(false);
  const [
    showChangePasswordModal,
    setShowChangePasswordModal
  ] = useState<boolean>(false);

  const changeMandatoryInspectionList = () => {
    if (mandatoryInspectionList !== checkboxMandatoryInspectionList) {
      Modal.fire(
        'warning',
        t('global.alert'),
        t('global.askChangemandatoryInspectionList', { value: checkboxMandatoryInspectionList ? t('global.mandatory') : t('global.notMandatory') }),
        () => {
          createResource(
            '/admin/inspections/mandatory-inspection-list',
            {
              mandatory: checkboxMandatoryInspectionList
            },
            () => {
              setMandatoryInspectionList(!mandatoryInspectionList);
              setCheckboxMandatoryInspectionList(!mandatoryInspectionList);
              Modal.fireSuccess(
                t('global.correct'),
                t('global.mandatorySuccess'),
                setOpenModal,
                () => {
                  setShowModalMandatoryInspectionList(false);
                  Modal.close(setOpenModal);
                },
                soundEffects
              );
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        },
        undefined,
        undefined,
        undefined,
        setOpenModal
      );
    } else {
      setShowModalMandatoryInspectionList(false);
    }
  };

  useEffect(() => {
    setCheckboxMandatoryInspectionList(mandatoryInspectionList);
  }, [mandatoryInspectionList]);

  const throttledGetUser = utils.throttle(
    () => {
      getUser(
        `/${apiType}/users/me/data`,
        (data: User) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  const throttledGetCollaborator = utils.throttle(
    () => {
      getCollaborator(
        `/${apiType}/collaborators/me`,
        (data) => {
          setCollaborator(data);
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );
    },
    1000
  );

  useEffect(() => {
    if (apiType === 'public') {
      throttledGetCollaborator();
    } else if (apiType === 'admin') {
      throttledGetUser();
    }
  }, [apiType]);

  useEffect(() => {
    setCheckboxMandatoryInspectionList(mandatoryInspectionList);
  }, [showModalMandatoryInspectionList]);

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => setShowChangePasswordModal(true)}
            type='primary-outlined'
            label={t('profile.changePassword') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='changePassword'
            alignContent='left'
          />
        ),
        available: true
      },
      {
        button: (
          <Button
            onClick={() => setShowUploadProfilePictureModal(true)}
            type='primary-outlined'
            label={user.picture ? t('profile.changeAvatar') || '' : t('profile.uploadAvatar') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='picture'
            alignContent='left'
          />
        ),
        available: apiType === 'admin' && ableRolesToEditProfile.includes(userRole),
        specialValidation: apiType === 'admin' && ableRolesToEditProfile.includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => setShowModalMandatoryInspectionList(true)}
            type='primary-outlined'
            label={t('global.changeMandatoryInspectionList') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspectionList'
            alignContent='left'
          />
        ),
        available: apiType === 'admin' && userRole === 'master',
        specialValidation: apiType === 'admin' && userRole === 'master' && false
      }
    ]);
  }, [apiType, i18n.language, user.picture]);

  const setSubTitle = useCallback(() => {
    let result = {
      label: '',
      title: ''
    };

    if (user.substituteTechnicalManager) {
      result = {
        title: user.name,
        label: t('users.substituteTechnicalManager') || ''
      };
    } else if (user.technicalManager) {
      result = {
        title: user.name,
        label: t('users.technicalManager') || ''
      };
    }

    return result;
  }, [user]);

  const driverObj = driver({
    showProgress: true,
    allowClose: false,
    smoothScroll: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#main-menu', popover: { title: t('tours.mainMenu') || '', description: t('tours.mainMenuDescription') || '' } },
      { element: '#main-menu-footer', popover: { title: t('tours.mainMenuFooter') || '', description: t('tours.mainMenuFooterDescription') || '' } }
    ],
    onDestroyed: () => {
      if (apiType === 'admin') {
        updateUser(
          `admin/users/${user.id}/tour-done`,
          {
            tourDone: 'main-menu'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }

      if (apiType === 'public') {
        updateCollaborator(
          'public/collaborators/tour-done',
          {
            tourDone: 'main-menu'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }
    }
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('main-menu') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <div className="profile">
      <Header
        title={t('profile.title')}
        subTitle={[setSubTitle()]}
        showBackbutton={true}
      />
      <ChangePasswordForm
        visible={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
        onSuccess={(data: Collaborator | User) => {
          if (apiType === 'admin') {
            setUser(data as User);
          } else if (apiType === 'public') {
            setCollaborator(data as Collaborator);
          }

          setShowChangePasswordModal(false);
          Modal.fireSuccess(t('global.correct'), t('profile.updatedPassword'), setOpenModal, undefined, soundEffects);
        }}
        onError={(error: string) => {
          setShowChangePasswordModal(false);
          Modal.fireError(error, setOpenModal, soundEffects);
        }}
      />
      <UploadProfilePicture
        userID={user.id}
        visible={showUploadProfilePictureModal}
        onClose={() => setShowUploadProfilePictureModal(false)}
        onSuccess={() => {
          Modal.fireSuccess(
            t('global.correct'),
            t('profile.updatedAvatar'),
            setOpenModal,
            // eslint-disable-next-line no-undef
            () => document.location.reload(),
            soundEffects
          );
        }}
      />
      <ModalView
        visible={showModalMandatoryInspectionList}
        onClose={() => setShowModalMandatoryInspectionList(false)}
        confirmationButonLabel={'Ok'}
        fullSzie={false}
        onConfirm={changeMandatoryInspectionList}
        customComponent={
          <div className='mandatory-inspection-list-modal'>
            <p className='mandatory-inspection-list-modal__title'>{t('global.mandatoryState')} {mandatoryInspectionList ? t('global.mandatory') : t('global.notMandatory')}</p>
            <p>{t('global.mandatoryExplication')}</p>
            <CheckBox
              label={t('global.mandatoryTitle')}
              checked={checkboxMandatoryInspectionList}
              onChange={() => {
                setCheckboxMandatoryInspectionList(!checkboxMandatoryInspectionList);
              }}
            />
          </div>
        }
      />
      <div className="profile__main">
        <div className="profile__left-container">
          <Accordeon
            items={[
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="profile__right-container">
          <GeneralInfo
            user={user}
            collaborator={collaborator}
            showPicture={ableRolesToEditProfile.includes(userRole)}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
