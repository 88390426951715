/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Client,
  ConstancyProductIndicators,
  DictumProduct,
  Inspection,
  RevisionProductIndicators,
  Round,
  TabItem,
  User
} from '../../../../interfaces';

import Button from '../../../Button';
import { useNavigate, useResource } from '../../../../hooks';
import Modal from '../../../Modal';
import { AppContext } from '../../../../context/AppContext';

import './styles.scss';
import { ListForm } from '../InspectionLists';
import { emptyRound, emptyUser } from '../../../../emptyObjects';
import Icon from '../../../Icon';
import Tabs from '../../../Tabs/Tabs';

interface Props {
  updateInspectionState: (_value: Inspection) => void
  dictumProduct: DictumProduct
  productIndicators?: ConstancyProductIndicators | RevisionProductIndicators
  user: User
  client: Client
}

const InspectionList: React.FC<Props> = (props) => {
  const {
    updateInspectionState,
    dictumProduct,
    productIndicators,
    client
  } = props;

  const navigate = useNavigate();

  const {
    fetchResource: getInspectionList
  } = useResource<any>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const { t } = useTranslation();

  const {
    apiType,
    setOpenModal,
    inspection,
    soundEffects
  } = useContext(AppContext);

  const [user, setUser] = useState<User>(emptyUser);
  const [
    selectedRound,
    setSelectedRound
  ] = useState<Round>(inspection.rounds.find(round => round.id === 1) || emptyRound);
  const [itemsTabs, setItemsTabs] = useState<TabItem[]>([]);

  const documentCodes: { [name: string]: { [type: string]: string } } = {
    '003': {
      DC: 'F-',
      CC: 'F-',
      DN: 'F-',
      CN: 'F-',
      REV: 'F-'
    },
    '004': {
      DC: 'F7-43',
      CC: 'F7-41',
      DN: 'F7-43',
      CN: 'F7-41',
      REV: 'F7-41'
    },
    '015': {
      DC: 'F7-45',
      CC: 'F7-44',
      DN: 'F7-45',
      CN: 'F7-44',
      REV: 'F7-44'
    },
    '024': {
      DC: 'F7-47',
      CC: 'F7-46',
      DN: 'F7-47',
      CN: 'F7-46',
      REV: 'F7-46'
    },
    '050': {
      DC: 'F7-35',
      CC: 'F7-34',
      DN: 'F7-35',
      CN: 'F7-34',
      REV: 'F7-34'
    },
    '051': {
      DC: 'F7-31',
      CC: 'F7-31',
      DN: 'F7-31',
      CN: 'F7-31',
      REV: 'F7-31'
    },
    141: {
      DC: 'F7-38',
      CC: 'F7-38',
      DN: 'F7-38',
      CNC: 'F7-38',
      REV: 'F7-38'
    },
    142: {
      DC: 'F7-40',
      CC: 'F7-40',
      DN: 'F7-40',
      CN: 'F7-40',
      REV: 'F7-40'
    },
    189: {
      DC: 'F7-39',
      CC: 'F7-39',
      DN: 'F7-39',
      CN: 'F7-39',
      REV: 'F7-39'
    },
    '020': {
      DC: 'F7-49',
      CC: 'F7-49',
      DN: 'F7-49',
      CN: 'F7-49',
      REV: 'F7-49'
    },
    173: {
      DC: 'F7-51',
      CC: 'F7-51',
      DN: 'F7-51',
      CN: 'F7-51',
      REV: 'F7-51'
    },
    187: {
      DC: 'F7-36',
      CC: 'F7-36',
      DN: 'F7-36',
      CN: 'F7-36',
      REV: 'F7-36'
    },
    235: {
      DC: 'F7-',
      CC: 'F7-',
      DN: 'F7-',
      CN: 'F7-',
      REV: 'F7-'
    }
  };

  const generateInspectionList = () => {
    getInspectionList(
      `/${apiType}/documents/inspection-list/${inspection.id}/${selectedRound.id}`,
      (_data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(_data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `${documentCodes[inspection.norm][inspection.service.code]} Lista de Inspección NOM ${inspection.norm}-${inspection.product.invoice}.pdf`;
        link.click();

        Modal.close(setOpenModal);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    const auxItemsTabs: TabItem[] = [];

    inspection.rounds.sort((a, b) => {
      return a.id - b.id;
    });

    for (let index = 0; index < inspection.rounds.length; index += 1) {
      auxItemsTabs.push({
        id: inspection.rounds[index].id,
        label: `${t('services.roundInspectionList')} #${inspection.rounds[index].id}`
      });
    }

    setItemsTabs(auxItemsTabs);
  }, [inspection]);

  useEffect(() => {
    if (apiType === 'admin') {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [inspection, dictumProduct, apiType]);

  const showMakeListButton = (): boolean => {
    if (user.id) {
      let result: boolean = false;
      let userIsOwner: boolean = false;

      if (['CC', 'CN', 'REV'].includes(inspection.service.code)) {
        if (inspection.inspector?.id === user.id) {
          userIsOwner = true;
        }
      } else if (inspection.rounds.find((round) => round.id === selectedRound.id)?.inspector?.id === user.id) {
        userIsOwner = true;
      }

      if (!selectedRound.steps.includes('list-completed') && userIsOwner) {
        result = true;
      }

      return result;
    }

    return false;
  };

  return (
    <div className='inspection-list-view__main'>
      <Tabs
        items={itemsTabs}
        onSelectTab={(item: TabItem) => {
          setSelectedRound(inspection.rounds.find(element => element.id === item.id) || emptyRound);
        }}
      />
      {
        selectedRound.list === null ? (
          <div className='full-table__empty-state'>
            <p>{selectedRound.productNotPresented ? t('services.inspectionListDontMade') : t('services.inspectionListDonFound')}</p>
            <Icon type='emptyState' className='full-table__empty-state__icon' />
          </div>
        ) : (
          <>
            {
              selectedRound.steps.includes('list-completed') && (
                <Button
                  type='secondary-outlined'
                  label={t('services.downloadInspectionList') || ''}
                  icon='downloadWhite'
                  iconPosition='left'
                  size='big'
                  onClick={generateInspectionList}
                />
              )
            }
            {
              showMakeListButton() && (
                <Button
                  type='secondary-outlined'
                  label={t('services.makeList', { extraLabel: `${t('inspections.round')} #${selectedRound.id}` }) || ''}
                  icon='inspectionList'
                  iconPosition='left'
                  size='big'
                  onClick={() => {
                    navigate(`/inspection/inspection-list/${inspection.id}/${selectedRound.id}`);
                  }}
                />
              )
            }
            <ListForm
              disabled={true}
              roundID={selectedRound.id}
              onFetchInspection={updateInspectionState}
              labelsToPut={['DC', 'DN'].includes(inspection.service.code) ? dictumProduct.labelsToPut : 0}
              isGrouped={dictumProduct.indicators.isGrouped}
              productIndicators={productIndicators}
              user={user}
              client={client}
            />
          </>
        )
      }
    </div>
  );
};

export default InspectionList;
