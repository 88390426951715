import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import {
  Button,
  CheckBox,
  Header,
  Icon,
  Input,
  Modal,
  SubTitle,
  Switch,
  Table,
  Title
} from '../../components';
import {
  SignedFile,
  TrainingRecord,
  TrainingRecordPersonReference,
  User,
  UserRoles
} from '../../interfaces';

import './styles.scss';
import { useNavigate, useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import { emptyTrainingRecord, emptyUser } from '../../emptyObjects';
import { validate } from '../../helpers';
import { IconTypes } from '../../components/Icon/iconList';

interface CustomItem extends User {
  checkBox: ReactElement
}

interface CustomItemSigns extends TrainingRecordPersonReference {
  checkSign: ReactElement
  role: UserRoles
}

const CorrectiveActionForm = () => {
  const params = useParams();
  const { trainingRecordID } = params;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    fetchResources
  } = useResource<User>();

  const {
    fetchResource
  } = useResource<TrainingRecord>();

  const {
    updateResource,
    createResource
  } = useResource<TrainingRecord>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    fetchResource: getActionDocument
  } = useResource<any>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const {
    soundEffects,
    setOpenModal,
    userRole,
    manager
  } = useContext(AppContext);

  // eslint-disable-next-line max-len
  const [trainingRecord, setTrainingRecord] = useState<TrainingRecord>(emptyTrainingRecord);
  const [user, setUser] = useState<User>(emptyUser);
  const [checkBoxValues, setCheckBoxValues] = useState<{ [id: string]: boolean }>({});
  const [users, setUsers] = useState<User[]>([]);
  const [customUsers, setCustomUsers] = useState<CustomItem[]>([]);
  const [customUsersSigns, setCustomUsersSigns] = useState<CustomItemSigns[]>([]);
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const sigCanvas = useRef<any>();

  const dataURLtoBlob = (dataURL: string) => {
    const array = [];
    const binary = atob(dataURL.split(',')[1]);

    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }

    return new File([new Uint8Array(array)], 'png');
  };

  const validData = (edit: boolean = false): boolean => {
    if (validate.text(trainingRecord.theme, 1) !== 'success') {
      setErrors({
        theme: t('global.dataError')
      });

      return false;
    }

    if (trainingRecord.date === null) {
      setErrors({
        date: t('global.dataError')
      });

      return false;
    }

    if (validate.text(trainingRecord.duration, 1) !== 'success') {
      setErrors({
        duration: t('global.dataError')
      });

      return false;
    }

    if (validate.text(trainingRecord.instructor, 1) !== 'success') {
      setErrors({
        instructor: t('global.dataError')
      });

      return false;
    }

    if (validate.text(trainingRecord.reviewedTopics, 1) !== 'success') {
      setErrors({
        reviewedTopics: t('global.dataError')
      });

      return false;
    }

    if (edit === false) {
      if (Object.keys(checkBoxValues).length === 0
        || Object.values(checkBoxValues).every(value => value === false)) {
        Modal.fireError('Se debe selecciona al menos un usuario involucrado');

        return false;
      }
    }

    return true;
  };

  const handleUploadFile = () => {
    if (sigCanvas.current.isEmpty()) {
      Modal.fireError(t('global.signTrainingRecord'), undefined, soundEffects);

      return;
    }

    registerUploadedFiles(
      `/admin/training-records/${trainingRecord.id}/sign`,
      {
        filePaths: [{
          path: `${user.name}__signature.png`,
          type: 'image/png'
        }]
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map(async (item) => {
            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            formData.append('file', dataURLtoBlob(sigCanvas.current.toDataURL('image/png')));

            return axios.post(
              item.signedURL.url,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                  const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                  Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                }
              }
            ).then(() => {
              Modal.close();
              uploadedPaths.push(item.url);
            });
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/admin/training-records/${trainingRecord.id}/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (dataTrainingRecord) => {
              setTrainingRecord(dataTrainingRecord);
              sigCanvas.current.clear();
              Modal.fireSuccess('Correcto', 'Firma registrada');
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleEditCorrectiveAction = () => {
    if (validData(true)) {
      updateResource(
        `admin/training-records/${trainingRecord.id}`,
        trainingRecord,
        (data) => {
          setTrainingRecord(data);
          Modal.fireSuccess(t('global.correct'), t('global.updatedTrainingRecord'), setOpenModal, undefined, soundEffects);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  const handleCreateCorrectiveAction = () => {
    if (validData()) {
      createResource(
        'admin/training-records',
        {
          ...trainingRecord,
          involved: Object.entries(checkBoxValues)
            // eslint-disable-next-line no-unused-vars
            .filter(([key, value]) => value === true)
            .map(([key]) => key)
        },
        (data) => {
          setTrainingRecord(data);
          Modal.fireSuccess(
            t('global.correct'),
            t('global.createdTrainingRecord'),
            setOpenModal,
            () => navigate('/training-records'),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  const handleGenerateActionDocument = async () => {
    getActionDocument(
      `/admin/training-records/${trainingRecordID}/document`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = 'F6-17 LISTA DE ASISTENCIA.pdf';
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  useEffect(() => {
    if (trainingRecordID && trainingRecordID !== 'none') {
      fetchResource(
        `/admin/training-records/${trainingRecordID}`,
        (data) => {
          setTrainingRecord(data);
        },
        (error) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [trainingRecordID]);

  useEffect(() => {
    getUser(
      '/admin/users/me/data',
      (userData: User) => {
        setUser(userData);
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, []);

  useEffect(() => {
    fetchResources(
      {
        resourcePath: 'admin/users'
      },
      (data) => {
        setUsers(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, []);

  const handleCheckItem = (userID: string) => {
    checkBoxValues[userID] = !checkBoxValues[userID];

    setCheckBoxValues({ ...checkBoxValues });
  };

  useEffect(() => {
    const _customItems: CustomItem[] = [];

    users.forEach((userItem) => {
      _customItems.push({
        ...userItem,
        checkBox: (
          <div className='procedures-se__checkbox-container'>
            <CheckBox
              label=''
              checked={checkBoxValues[userItem.id]}
              onChange={() => { handleCheckItem(userItem.id); }}
            />
          </div>
        )
      });
    });

    setCustomUsers(_customItems);
  }, [checkBoxValues, users]);

  const signIcon = (
    sign: string
  ): IconTypes => {
    if (sign === '') {
      return 'circleError';
    }

    if (sign !== '') {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  useEffect(() => {
    if (trainingRecord.id !== '') {
      const _customItems: CustomItemSigns[] = [];

      trainingRecord.involved.forEach((userItem) => {
        _customItems.push({
          ...userItem,
          role: users.find(userDB => userDB.id === userItem.id)?.role || 'master',
          checkSign: (
            <Icon type={signIcon(userItem.sign)} className='task-detail__table-icon' />
          )
        });
      });

      setCustomUsersSigns(_customItems);
    }
  }, [trainingRecord, users]);

  useEffect(() => {
    if (trainingRecord.id !== '' && trainingRecord.involved.some(item => item.id === user.id)) {
      // eslint-disable-next-line no-undef
      const signatureElement = document.getElementById('signature');

      if (signatureElement) {
        signatureElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [trainingRecord]);

  return (
    <div className='corrective-actions-form__main'>
      <Header
        title={t('global.trainingRecords') || ''}
        showBackbutton={true}
      />
      <div className="corrective-actions-form__container">
        {
          trainingRecord.involved.every(item => item.sign !== '') && trainingRecord.involved.length > 0 && (
            <div className="corrective-actions-form__container__button-container">
              <Button
                type={'primary'}
                onClick={() => handleGenerateActionDocument()}
                label={t('global.downloadDocument') || ''}
                icon='downloadWhite'
                size='big'
              />
            </div>
          )
        }
        <SubTitle subTitle={'Tipo de capacitación'} />
        <Switch
          leftLabel={{
            text: 'Presencial',
            value: 'in-person'
          }}
          rigthLabel={{
            text: 'Remota',
            value: 'remote'
          }}
          disabled={trainingRecord.id !== '' ? trainingRecord.createdBy.id !== user.id || trainingRecord.involved.every(item => item.sign !== '') : false}
          onChange={(value: string | number) => {
            setTrainingRecord({
              ...trainingRecord,
              type: `${value}` as 'in-person' | 'remote'
            });
          }}
        />
        <br />
        <Input
          type={'text'}
          value={trainingRecord.theme}
          title='Tema'
          placeholder='Tema de capacitación'
          maxLength={1000}
          disabled={trainingRecord.id !== '' ? trainingRecord.createdBy.id !== user.id || trainingRecord.involved.every(item => item.sign !== '') : false}
          onChange={(value: string | number) => {
            setTrainingRecord({
              ...trainingRecord,
              theme: `${value}`
            });
          }}
          hasError={!!errors.theme}
          helperText={errors.theme}
        />
        <br />
        <Input
          type={'date'}
          value={trainingRecord.date ? dayjs(trainingRecord.date).add(1, 'day').format('YYYY-MM-DD') : ''}
          title='Fecha'
          disabled={trainingRecord.id !== '' ? trainingRecord.createdBy.id !== user.id || trainingRecord.involved.every(item => item.sign !== '') : false}
          onChange={(value: string | number) => {
            setTrainingRecord({
              ...trainingRecord,
              date: new Date(value)
            });
          }}
          hasError={!!errors.date}
          helperText={errors.date}
        />
        <br />
        <Input
          type={'decimal'}
          value={trainingRecord.duration}
          title='Duración (horas)'
          disabled={trainingRecord.id !== '' ? trainingRecord.createdBy.id !== user.id || trainingRecord.involved.every(item => item.sign !== '') : false}
          onChange={(value: string | number) => {
            setTrainingRecord({
              ...trainingRecord,
              duration: `${value}`
            });
          }}
          hasError={!!errors.duration}
          helperText={errors.duration}
        />
        <br />
        <Input
          type={'text'}
          value={trainingRecord.instructor}
          title='Instructor'
          disabled={trainingRecord.id !== '' ? trainingRecord.createdBy.id !== user.id || trainingRecord.involved.every(item => item.sign !== '') : false}
          onChange={(value: string | number) => {
            setTrainingRecord({
              ...trainingRecord,
              instructor: `${value}`
            });
          }}
          hasError={!!errors.instructor}
          helperText={errors.instructor}
        />
        <br />
        <Input
          type={'text'}
          value={trainingRecord.reviewedTopics}
          title='Temas revisados'
          disabled={trainingRecord.id !== '' ? trainingRecord.createdBy.id !== user.id || trainingRecord.involved.every(item => item.sign !== '') : false}
          onChange={(value: string | number) => {
            setTrainingRecord({
              ...trainingRecord,
              reviewedTopics: `${value}`
            });
          }}
          hasError={!!errors.reviewedTopics}
          helperText={errors.reviewedTopics}
        />
        {
          trainingRecord.id === '' ? (
            <>
              <br />
              <br />
              <Title title={'Involucrados'} />
              <Table
                floatingHeader={true}
                headers={[
                  {
                    label: 'Nombre',
                    value: 'name',
                    format: 'none'
                  },
                  {
                    label: 'Puesto',
                    value: 'role',
                    format: 'role'
                  },
                  {
                    label: 'Seleccionar',
                    value: 'checkBox',
                    format: 'custom-component'
                  }
                ]}
                items={customUsers}
              />
              <br />
              {
                (['inspector', 'quality'].includes(userRole)
                  || (userRole === 'inspector' ? manager : false)) && (
                  <div className="corrective-actions-form__container__approve-buttons">
                    <br />
                    <Button
                      type={'primary'}
                      onClick={() => {
                        handleCreateCorrectiveAction();
                      }}
                      label={t('global.save') || ''}
                      icon='addWhite'
                      size='big'
                    />
                  </div>
                )
              }
            </>
          ) : (
            <>
              <br />
              <br />
              <Title title={'Involucrados'} />
              <Table
                floatingHeader={true}
                headers={[
                  {
                    label: 'Nombre',
                    value: 'name',
                    format: 'none'
                  },
                  {
                    label: 'Puesto',
                    value: 'role',
                    format: 'role'
                  },
                  {
                    label: 'Firma subida',
                    value: 'checkSign',
                    format: 'custom-component'
                  }
                ]}
                items={customUsersSigns}
              />
              <br />
            </>
          )
        }
        {
          trainingRecord.id !== '' && trainingRecord.involved.some(item => item.id === user.id && item.sign === '') && (
            <div id='signature'>
              <br />
              <br />
              <Title title={t('tasks.sign')} />
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ className: 'witnesses__content__canvas-draw' }}
              />
              <div className='witnesses__content__action-buttons'>
                <Button type='secondary-outlined' onClick={() => sigCanvas.current.clear()} label={t('services.clean') || ''} />
                <Button type='secondary-outlined' onClick={handleUploadFile} label={t('global.save') || ''} />
              </div>
            </div>
          )
        }
        {
          trainingRecord.id !== '' && trainingRecord.createdBy.id === user.id && trainingRecord.involved.some(item => item.sign === '') && (
            <div className="corrective-actions-form__container__approve-buttons">
              <br />
              <Button
                type={'primary'}
                onClick={() => {
                  handleEditCorrectiveAction();
                }}
                label={t('tasks.form.save') || ''}
                icon='editService'
                size='big'
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CorrectiveActionForm;
