import React, { useContext, useEffect, useState } from 'react';
import { driver } from 'driver.js';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FullTable,
  Modal,
  StepsIndicator,
  Title
} from '../../../../components';
import { emptyDictumProduct } from '../../../../emptyObjects';
import {
  DictumProduct,
  Pagination,
  TableHeader
} from '../../../../interfaces';
import ProductForm from './components';
import { utils } from '../../../../helpers';

import './styles.scss';
import { useKeyPress, useResource, useNavigate } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';

export const Step2 = () => {
  const {
    eraseResources,
    fetchResources,
    updateResource
  } = useResource<DictumProduct>();

  const navigate = useNavigate();
  const params = useParams();

  const {
    apiType,
    setOpenModal,
    openModal,
    soundEffects,
    userToursDone,
    userRole,
    showDictumRequestTour
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [selectedDictumProduct, setSelectedDictumProduct] = useState<DictumProduct>(
    emptyDictumProduct
  );
  const [dictumProducts, setDictumProducts] = useState<DictumProduct[]>([]);
  const [currentComponent, setCurrentComponent] = useState<'product-list' | 'product-form'>('product-list');
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    perPage: 10,
    totalItems: 0
  });

  const headers: TableHeader[] = [
    {
      label: t('tasks.filters.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('global.status.title'),
      value: 'status',
      format: 'status'
    },
    {
      label: t('global.norm'),
      value: 'normCode',
      format: 'none'
    },
    {
      label: 'Fase',
      value: 'fase',
      format: 'none'
    },
    {
      label: t('services.tariffFraction'),
      value: 'tariffFraction',
      format: 'none'
    },
    {
      label: 'UMC',
      value: 'umc',
      format: 'none'
    },
    {
      label: t('services.umcQuantity'),
      value: 'umcQuantity',
      format: 'none'
    },
    {
      label: t('services.labelsToPut'),
      value: 'labelsToPut',
      format: 'none'
    },
    {
      label: t('services.labelingMode'),
      value: 'labelingMode',
      format: 'none'
    },
    {
      label: t('global.brand'),
      value: 'brand',
      format: 'none'
    },
    {
      label: t('global.description'),
      value: 'description',
      format: 'none'
    },
    {
      label: t('global.modelCode'),
      value: 'code',
      format: 'none'
    },
    {
      label: t('global.modelCode2'),
      value: 'code2',
      format: 'none'
    },
    {
      label: t('global.grouped'),
      value: 'indicators.isGrouped',
      format: 'boolean'
    }
  ];

  const handleContinue = () => {
    if (dictumProducts.length <= 0) {
      Modal.fireError(t('services.errors.atLeastOneProduct'), setOpenModal, soundEffects);
      return false;
    }

    if (apiType === 'admin') {
      updateResource(
        `/${apiType}/dictum-services/${params.dictumServiceID}`,
        {
          requestIsCompleted: true
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.requestSaved'),
            setOpenModal,
            () => navigate(`/dictum-services/detail/${params.dictumServiceID}/none`),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
      return true;
    }

    navigate(`/dictum-request/step_3/${params.dictumServiceID}/${params.petitionNumber}/${params.clientID}/${params.collaboratorID}`);
    return true;
  };

  const handleChangePage = (page: number) => {
    setPagination({
      ...pagination,
      currentPage: page
    });
  };

  const deleteDictumProduct = (product: DictumProduct) => {
    Modal.fireWarning(
      product.indicators.isGrouped ? t('services.deleteGroupedProduct') : t('services.deleteProduct'),
      () => {
        let endpoint = `/${apiType}/dictum-products`;

        if (product.indicators.isGrouped) {
          endpoint = `/${apiType}/dictum-products/grouped/${product.invoice}`;
        }

        eraseResources(
          endpoint,
          {
            s_serviceID: params.dictumServiceID,
            s_productID: product.id
          },
          () => {
            let searchCriteria: 'id' | 'invoice' = 'id';

            if (product.indicators.isGrouped) {
              searchCriteria = 'invoice';
            }

            const remainingProducts = dictumProducts.filter(
              (dictumProduct) => product[searchCriteria] !== dictumProduct[searchCriteria]
            );

            setDictumProducts(remainingProducts);
            Modal.fireSuccess(t('global.correct'), t('services.deletedProduct'), setOpenModal, undefined, soundEffects);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      setOpenModal
    );
  };

  const deleteAllproducts = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.deleteProducts'),
      () => {
        eraseResources(
          `/${apiType}/dictum-products`,
          {
            b_deleteAll: true,
            s_serviceID: params.dictumServiceID
          },
          () => {
            setPagination({
              currentPage: 1,
              perPage: 10,
              totalItems: 0
            });
            setDictumProducts([]);
            Modal.fireSuccess(t('global.correct'), t('services.deletedProducts'), setOpenModal, undefined, soundEffects);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    if (currentComponent === 'product-list') {
      fetchResources(
        {
          resourcePath: `/${apiType}/dictum-products`,
          pagination: {
            n_perPage: pagination.perPage,
            n_currentPage: pagination.currentPage,
            n_totalItems: pagination.totalItems
          },
          filters: {
            s_serviceID: params.dictumServiceID || '',
            b_attachedToTheService: true
          }
        },
        (data) => {
          setDictumProducts(data.items);

          if (utils.paginationHasChange(pagination, data.pagination)) {
            setPagination(data.pagination);
          }
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [currentComponent, pagination.currentPage, apiType]);

  useKeyPress(
    openModal ? () => { } : () => handleContinue(),
    [dictumProducts, currentComponent, openModal, selectedDictumProduct],
    currentComponent === 'product-list'
  );

  const driverObj = driver({
    showProgress: false,
    allowClose: false,
    smoothScroll: true,
    disableActiveInteraction: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#create-products', popover: { title: t('tours.createProducts') || '', description: t('tours.createProductsDescription') || '' } }
    ]
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('dictum-request') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }

    if (showDictumRequestTour) {
      driverObj.drive();
    }
  }, [userToursDone, showDictumRequestTour]);

  const component = {
    'product-list': (
      <div className='step-2__full-table-container'>
        <div className='step-2__action-buttons-container'>
          <Button
            onClick={() => {
              setSelectedDictumProduct(emptyDictumProduct);
              setCurrentComponent('product-form');
            }}
            type='secondary-outlined'
            label={t('services.addProducts') || ''}
            icon='plusRounded'
            iconPosition='top'
            transparent={true}
            id='create-products'
          />
          <Button
            onClick={() => deleteAllproducts()}
            type='secondary-outlined'
            label={t('services.deleteAllProducts') || ''}
            icon='minusRounded'
            iconPosition='top'
            transparent={true}
            disabled={pagination.totalItems === 0}
          />
        </div>
        <div className='step-2__solid-background-container'>
          <FullTable
            headers={headers}
            items={dictumProducts}
            currentPage={pagination.currentPage}
            perPage={pagination.perPage}
            totalItems={pagination.totalItems}
            onChangeCurrentPage={handleChangePage}
            onDeleteItem={(item) => { deleteDictumProduct(item); }}
            onEditItem={(item: any) => {
              setSelectedDictumProduct(item);
              setCurrentComponent('product-form');
            }}
            type='dictumproducts'
          />
        </div>
        <div className='step-2__button-container'>
          <Button
            onClick={() => navigate(`/dictum-request/step_1/${params.dictumServiceID}`)}
            type='primary'
            label={t('global.goBack') || ''}
            icon='leftArrow'
            iconPosition='left'
          />
          <Button
            onClick={handleContinue}
            type='primary'
            label={apiType === 'admin' ? t('global.finish') || '' : t('global.continue') || ''}
            icon='rightArrow'
            iconPosition='right'
          />
        </div>
      </div>
    ),
    'product-form': (
      <ProductForm
        onChangeCurrentComponent={setCurrentComponent}
        dictumServiceID={params.dictumServiceID || ''}
        clientID={params.clientID || ''}
        collaboratorID={params.collaboratorID || ''}
        preloadedProduct={selectedDictumProduct}
      />
    )
  };

  return (
    <>
      <div className='step-2__steps'>
        {
          apiType === 'admin' ? (
            <StepsIndicator
              stepsQuantity={2}
              currentStep={2}
            />
          ) : (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={2}
            />
          )
        }
      </div>
      <div className='step-2__content'>
        <div className='step-2'>
          <Title title={t('services.step2Products')} />
          {component[currentComponent]}
        </div>
      </div>
    </>
  );
};

export default Step2;
