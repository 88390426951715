/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-undef */
import customParseFormat from 'dayjs/plugin/customParseFormat';

import i18next from 'i18next';
import statusDictionaryEn from '../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../dictionaries/statusDictionary-es';
import taxSystemDictionaryEn from '../dictionaries/taxSystemDictionary-en';
import taxSystemDictionaryEs from '../dictionaries/taxSystemDictionary-es';
import roleDictionaryEn from '../dictionaries/roleDictionary-en';
import roleDictionaryEs from '../dictionaries/roleDictionary-es';
import rejectReasonsEn from '../dictionaries/rejectReasons-en';
import rejectReasonsEs from '../dictionaries/rejectReasons-es';
import monthsEs from '../dictionaries/months-es';
import monthsEn from '../dictionaries/months-en';
import {
  Pagination,
  ValueFormat,
  Round,
  Product,
  NutritionalTable,
  BinnacleTableHeader
} from '../interfaces';
import { emptyRound } from '../emptyObjects';

interface Measures {
  long: number
  broad: number
}

const dayjs = require('dayjs');

dayjs.extend(customParseFormat);

const convertToNumber = (value: string | number): number => {
  return parseFloat(`${value}`.replace(/[-,]/g, ''));
};

const itemsWithLimitLength = ['description', 'inspectionAddress', 'visit.address', 'brand'];

const formatData = (
  item: any,
  headerValue: string,
  headerFormat: ValueFormat,
  index: number
) => {
  return itemsWithLimitLength.includes(headerValue) ? (
    functions.limitValueLength(
      functions.formatValue(
        headerFormat,
        functions.getNestedValue(item, headerValue),
        '',
        index + 1
      ),
      25
    )
  ) : (
    functions.formatValue(
      headerFormat,
      functions.getNestedValue(item, headerValue),
      '',
      index + 1
    )
  );
};

const getLabelMeasuresBySPE = (spe: number) => {
  if (spe <= 5) {
    return '. Al menos 15% de la SPE. 1 Sello con el numero de sellos y en cualquier parte de la Superficie Principal de exhibición';
  }
  if (spe > 5 && spe <= 30) {
    return '. 1 Sello con el numero de sellos y en cualquier parte de la Superficie Principal de exhibición';
  }
  if (spe > 30 && spe <= 40) {
    return '. 1 Sello con el numero de sellos y en cualquier parte de la Superficie Principal de exhibición';
  }
  if (spe > 40 && spe <= 60) {
    return '. Los sellos van en cualquier parte de la Superficie Principal de Exhibición.';
  }
  if (spe > 60 && spe <= 100) {
    return '. Los sellos van en la parte superior derecha de la superficie principal de exhibición.';
  }
  if (spe > 100 && spe <= 200) {
    return '. Los sellos van en la parte superior derecha de la superficie principal de exhibición.';
  }
  if (spe > 200 && spe <= 300) {
    return '. Los sellos van en la parte superior derecha de la superficie principal de exhibición.';
  }
  if (spe > 300) {
    return '. Los sellos van en la parte superior derecha de la superficie principal de exhibición.';
  }

  return '';
};

const setMeasuresEdulcorante = (spe: number): Measures => {
  if (spe >= 5 && spe <= 30) {
    return {
      long: 19.8,
      broad: 2.2
    };
  }
  if (spe > 30 && spe <= 40) {
    return {
      long: 29.8,
      broad: 3.2
    };
  }
  if (spe > 40 && spe <= 60) {
    return {
      long: 29.8,
      broad: 3.2
    };
  }
  if (spe > 60 && spe <= 100) {
    return {
      long: 39.7,
      broad: 4.3
    };
  }
  if (spe > 100 && spe <= 200) {
    return {
      long: 49.6,
      broad: 5.4
    };
  }
  if (spe > 200 && spe <= 300) {
    return {
      long: 59.5,
      broad: 6.5
    };
  }
  if (spe > 300) {
    return {
      long: 69.5,
      broad: 7.5
    };
  }

  return {
    long: 0,
    broad: 0
  };
};

const setMeasuresCafeina = (spe: number): Measures => {
  if (spe >= 5 && spe <= 30) {
    return {
      long: 11.25,
      broad: 2.16
    };
  }
  if (spe > 30 && spe <= 40) {
    return {
      long: 16.8,
      broad: 3.23
    };
  }
  if (spe > 40 && spe <= 60) {
    return {
      long: 16.8,
      broad: 3.23
    };
  }
  if (spe > 60 && spe <= 100) {
    return {
      long: 22.5,
      broad: 4.32
    };
  }
  if (spe > 100 && spe <= 200) {
    return {
      long: 28.1,
      broad: 5.39
    };
  }
  if (spe > 200 && spe <= 300) {
    return {
      long: 33.7,
      broad: 6.46
    };
  }
  if (spe > 300) {
    return {
      long: 39.3,
      broad: 7.54
    };
  }

  return {
    long: 0,
    broad: 0
  };
};

const getMeasures = (SPE: number) => {
  const measures = {
    height: 0,
    width: 0
  };

  if (SPE <= 0) {
    measures.height = 0;
    measures.width = 0;
  } else if (SPE <= 5) {
    measures.height = 0;
    measures.width = 0;
  } else if (SPE <= 30) {
    measures.height = 1.11;
    measures.width = 1;
  } else if (SPE <= 40) {
    measures.height = 1.66;
    measures.width = 1.5;
  } else if (SPE <= 60) {
    measures.height = 1.66;
    measures.width = 1.5;
  } else if (SPE <= 100) {
    measures.height = 2.22;
    measures.width = 2;
  } else if (SPE <= 200) {
    measures.height = 2.77;
    measures.width = 2.5;
  } else if (SPE <= 300) {
    measures.height = 3.32;
    measures.width = 3;
  } else {
    measures.height = 3.88;
    measures.width = 3.5;
  }

  return measures;
};

const formatNumbers = (value: number) => {
  return `${value.toLocaleString('en-US')}`.replace(/,/g, ' ');
};

const functions = {
  paginationHasChange: (currentPagination: Pagination, newPagination: Pagination): boolean => {
    if (newPagination.currentPage !== currentPagination.currentPage) {
      return true;
    }

    if (newPagination.perPage !== currentPagination.perPage) {
      return true;
    }

    if (newPagination.totalItems !== currentPagination.totalItems) {
      return true;
    }

    return false;
  },
  getNestedValue: (item: any = {}, path: string): string => {
    const nestLevels = path.split('.');
    let level: any = item;

    nestLevels.forEach((currentKey: string) => {
      if (!level) {
        return;
      }

      level = level[`${currentKey}`];
    });

    if (typeof level === 'number') {
      return `${level}`;
    }

    return level;
  },
  formatValue: (format: ValueFormat, value: any, auxiliarValue?: any, index?: number): string => {
    if (format === 'rectifiedPetitionNumber') {
      return value === null ? 'N/A' : `${value}`;
    }
    if (value === ''
      || value === null
      || value === undefined
    ) {
      if (auxiliarValue) {
        return auxiliarValue;
      }

      return i18next.t('global.undefined');
    }
    if (format === 'none' && !value) {
      return i18next.t('global.undefined');
    }
    if (format === 'complies') {
      if (i18next.language === 'es') {
        return value ? 'Cumple' : 'No cumple';
      }
      return value ? 'Complies' : 'Not complies';
    }
    if (format === 'boolean') {
      if (i18next.language === 'es') {
        return value ? 'Si' : 'No';
      }
      return value ? 'Yes' : 'No';
    }
    if (format === 'index' && index) {
      return index.toString();
    }
    if (format === 'rejectReason') {
      return i18next.language === 'es' ? (rejectReasonsEs as { [name: string]: string })[value] : (rejectReasonsEn as { [name: string]: string })[value] || 'No asignado(a)';
    }
    if (format === 'status') {
      return i18next.language === 'es' ? (statusDictionaryEs as any)[value] : (statusDictionaryEn as any)[value] || 'Desconocido';
    }
    if (format === 'taxSystem') {
      return i18next.language === 'es' ? (taxSystemDictionaryEs as any)[value] : (taxSystemDictionaryEn as any)[value] || 'Desconocido';
    }
    if (format === 'corrective-actions-type') {
      const typesEs = {
        corrective: 'Correctiva',
        preventive: 'Preventiva'
      };
      const typesEn = {
        corrective: 'Corrective',
        preventive: 'Preventive'
      };

      return i18next.language === 'es' ? (typesEs as any)[value] : (typesEn as any)[value] || 'Desconocido';
    }
    if (format === 'training-records-type') {
      const typesEs = {
        'in-person': 'Presencial',
        remote: 'Remota'
      };
      const typesEn = {
        'in-person': 'In person',
        remote: 'Remote'
      };

      return i18next.language === 'es' ? (typesEs as any)[value] : (typesEn as any)[value] || 'Desconocido';
    }
    if (format === 'role') {
      return i18next.language === 'es' ? (roleDictionaryEs as any)[value] : (roleDictionaryEn as any)[value] || 'Desconocido';
    }
    if (format === 'minutes') {
      return `${value} min`;
    }
    if (format === 'incidents') {
      return `-${value}`;
    }
    if (format === 'month') {
      return i18next.language === 'es' ? (monthsEs as any)[dayjs(value).format('MMMM').toUpperCase()] : (monthsEn as any)[dayjs(value).format('MMMM').toUpperCase()];
    }
    if (format === 'list') {
      let result: string = '';

      for (let i = 0; i < value.length; i += 1) {
        if (value.length - 1 === i) {
          result += `${value[i]}`;
        } else {
          result += `${value[i]}, `;
        }
      }

      return result || i18next.t('global.noItems');
    }
    if (format === 'list-user-norms') {
      let result: string = '';

      for (let i = 0; i < value.length; i += 1) {
        if (value.length - 1 === i) {
          result += `${value[i].norm}${value[i].training === true ? ` ${i18next.language === 'es' ? 'en entrenamiento' : 'in training'}` : ''}`;
        } else {
          result += `${value[i].norm}${value[i].training === true ? ` ${i18next.language === 'es' ? 'en entrenamiento' : 'in training'}` : ''}, `;
        }
      }

      return result || i18next.t('global.noItems');
    }
    if (format === 'date') {
      const separatedValue = String(value).substring(0, 10);
      const dateValue = dayjs(separatedValue).format('DD/MM/YYYY');
      return dateValue === 'Invalid Date' ? i18next.t('global.noAssignedDate') : dateValue;
    }
    if (format === 'time') {
      const dateValue = dayjs(value, 'HH:mm').format('hh:mm a');
      return dateValue === 'Invalid Date' ? i18next.t('global.noAssignedTime') : dateValue;
    }
    if (format === 'date-time') {
      const dateValue = dayjs(value).format('DD/MM/YYYY hh:mm a');
      return dateValue === 'Invalid Date' ? i18next.t('global.noAssignedDate') : dateValue;
    }
    if (format === 'quantity') {
      return value.length;
    }
    if (format === 'address') {
      return `${value.street}, ${value.number}, ${value.neighborhood}, ${value.city}, ${value.state}, ${value.country}, ${value.postalCode}`;
    }
    if (format === 'client-status') {
      return value === 'active' ? i18next.t('global.active') : i18next.t('global.inactive');
    }
    if (format === 'nationality-type') {
      return value === 'national' ? i18next.t('global.national') : i18next.t('global.international');
    }
    if (format === 'person-type') {
      return value === 'moral' ? 'Moral' : i18next.t('global.physical');
    }
    if (format === 'person-name') {
      const splitedName = value.split(' ');

      if (splitedName.length === 1) {
        return `${splitedName[0]}`;
      }

      if (splitedName.length === 2) {
        return `${splitedName[0]} ${splitedName[1]}`;
      }

      if (splitedName.length === 3) {
        return `${splitedName[0]} ${splitedName[1]}`;
      }

      if (splitedName.length === 4) {
        return `${splitedName[0]} ${splitedName[2]}`;
      }

      if (splitedName.length === 5) {
        return `${splitedName[0]} ${splitedName[2]}`;
      }

      return value;
    }
    if (format === 'dictum-expired-date') {
      if (auxiliarValue) {
        return dayjs(auxiliarValue.substring(0, 10)).add(30, 'day').format('DD/MM/YYYY hh:mm a');
      }

      return dayjs(value).add(30, 'day').format('DD/MM/YYYY hh:mm a');
    }
    if (format === 'constancy-expired-date') {
      return dayjs(value).add(90, 'day').format('DD/MM/YYYY hh:mm a');
    }
    if (format === 'onSiteTask') {
      if (value !== undefined && value.length > 0) {
        return value[value.length - 1].task ? value[value.length - 1].task.number : i18next.t('global.undefined');
      }

      return i18next.t('global.undefined');
    }
    if (format === 'applicationValidity') {
      return `${dayjs(value).add(30, 'day').format('DD/MM/YYYY hh:mm a')}`;
    }
    if (format === 'actionsEnabledDictumService') {
      if (value.includes('register-invoiced') && value.length === 1) {
        return i18next.t('global.registerInvoiced');
      }
      if (value.includes('assign-products')) {
        return i18next.t('services.assignProducts');
      }
      if (value.includes('request-inspection')) {
        return i18next.t('services.requestInspectionButton');
      }
      if (value.includes('assign-out-of-customs-date')) {
        return i18next.t('services.assignOutOfCustomsDate');
      }
      if (value.includes('upload-paid-petition')) {
        return i18next.t('services.uploadPaidPetition');
      }
      if (value.includes('validate-products')) {
        return i18next.t('services.validateProducts');
      }
      if (value.includes('edit-service')) {
        return i18next.t('services.editService');
      }

      return 'N/A';
    }
    if (format === 'actionsEnabledDictumProduct') {
      if (value.includes('have-procedure-se')) {
        return i18next.t('services.proceduresSE');
      }
      if (value.includes('assign-products')) {
        return i18next.t('services.assignProducts');
      }
      if (value.includes('edit-products')) {
        return i18next.t('global.edit');
      }
      // if (value.includes('delete-invoice')) {
      //   return i18next.t('services.deleteInvoice');
      // }

      return 'N/A';
    }
    if (format === 'actionsEnabledConstancyService') {
      if (value.includes('register-invoiced') && value.length === 1) {
        return i18next.t('global.registerInvoiced');
      }
      if (value.includes('assign-products')) {
        return i18next.t('services.assignProducts');
      }
      if (value.includes('edit-service')) {
        return i18next.t('services.editService');
      }

      return 'N/A';
    }
    if (format === 'actionsEnabledRevisionService') {
      if (value.includes('register-invoiced') && value.length === 1) {
        return i18next.t('global.registerInvoiced');
      }
      if (value.includes('assign-products')) {
        return i18next.t('services.assignProducts');
      }
      if (value.includes('edit-service')) {
        return i18next.t('services.editService');
      }

      return 'N/A';
    }
    if (format === 'actionsEnabledConstancyProduct') {
      if (value.includes('upload-files')) {
        return i18next.t('services.uploadFiles');
      }
      if (value.includes('assign-products')) {
        return i18next.t('services.assignProducts');
      }
      if (value.includes('edit-products')) {
        return i18next.t('global.edit');
      }
      return 'N/A';
    }
    if (format === 'actionsEnabledRevisionProduct') {
      if (value.includes('upload-files')) {
        return i18next.t('services.uploadFiles');
      }
      if (value.includes('assign-products')) {
        return i18next.t('services.assignProducts');
      }
      if (value.includes('edit-products')) {
        return i18next.t('global.edit');
      }
      return 'N/A';
    }
    if (auxiliarValue) {
      return auxiliarValue;
    }

    return value;
  },
  createCSVDataString(headers: BinnacleTableHeader[], data: any[]): string {
    let file: string = '';

    const columns: string[] = [];

    headers.forEach(header => {
      columns.push(header.label);
    });

    file += `${columns.join(',')}\n`;

    data.forEach((item) => {
      const items: string[] = [];

      headers.forEach((header, index) => {
        items.push(`${formatData(item, header.value, header.format, index)}`);
      });

      file += '"';
      file += items.join('","');
      file += '"';
      file += '\n';
    });

    return file;
  },
  toCapitalCase: (setence: string): string => {
    const words = setence.split(' ');

    for (let i = 0; i < words.length; i += 1) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(' ');
  },
  limitValueLength: (value: string, limit: number) => {
    if (value.length <= limit) {
      return `${value}`;
    }

    return `${value.substring(0, limit - 3)}...`;
  },
  getCurrentRound: (rounds: Round[]) => {
    if (rounds) {
      if (rounds.length === 0) {
        return emptyRound;
      }

      return rounds.at(-1)
        || rounds[0];
    }

    return emptyRound;
  },
  deleteDotsOnFileName: (fileName: string) => {
    const splited = fileName.split('.');
    let result: string = '';

    for (let index = 0; index < splited.length - 1; index += 1) {
      result += splited[index];
    }

    return `${result}.${splited.at(-1)}`;
  },
  throttle: <T extends (...args: any[]) => any>(func: T, limit: number): ((...args: Parameters<T>) => void) => {
    let inThrottle: boolean;

    return (...args: Parameters<T>): void => {
      if (!inThrottle) {
        func(...args);
        inThrottle = true;
        // eslint-disable-next-line no-return-assign
        setTimeout(() => inThrottle = false, limit);
      }
    };
  },
  removeAccents(str: string) {
    const accents: { [name: string]: string } = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      à: 'a',
      è: 'e',
      ì: 'i',
      ò: 'o',
      ù: 'u',
      À: 'A',
      È: 'E',
      Ì: 'I',
      Ò: 'O',
      Ù: 'U',
      ä: 'a',
      ë: 'e',
      ï: 'i',
      ö: 'o',
      ü: 'u',
      Ä: 'A',
      Ë: 'E',
      Ï: 'I',
      Ö: 'O',
      Ü: 'U',
      â: 'a',
      ê: 'e',
      î: 'i',
      ô: 'o',
      û: 'u',
      Â: 'A',
      Ê: 'E',
      Î: 'I',
      Ô: 'O',
      Û: 'U',
      ã: 'a',
      õ: 'o',
      Ã: 'A',
      Õ: 'O',
      å: 'a',
      Å: 'A',
      ā: 'a',
      ē: 'e',
      ī: 'i',
      ō: 'o',
      ū: 'u',
      Ā: 'A',
      Ē: 'E',
      Ī: 'I',
      Ō: 'O',
      Ū: 'U',
      ą: 'a',
      ę: 'e',
      į: 'i',
      ų: 'u',
      Ą: 'A',
      Ę: 'E',
      Į: 'I',
      Ų: 'U',
      č: 'c',
      ď: 'd',
      ě: 'e',
      ľ: 'l',
      ň: 'n',
      ř: 'r',
      š: 's',
      ť: 't',
      ž: 'z',
      Č: 'C',
      Ď: 'D',
      Ě: 'E',
      Ľ: 'L',
      Ň: 'N',
      Ř: 'R',
      Š: 'S',
      Ť: 'T',
      Ž: 'Z',
      ç: 'c',
      Ç: 'C',
      ñ: 'n',
      Ñ: 'N',
      ḱ: 'k',
      Ḱ: 'K',
      ł: 'l',
      Ł: 'L',
      ß: '',
      ÿ: 'y',
      Ÿ: 'Y',
      ḿ: 'm',
      ẃ: 'w',
      ŕ: 'r',
      ý: 'y',
      ṕ: 'p',
      ś: 's',
      ǵ: 'g',
      ĺ: 'l',
      ź: 'z',
      ć: 'c',
      ǘ: 'u',
      ń: 'n',
      '´': '',
      '¨': '',
      '¨¨': '',
      '|': '',
      '°': ''
    };

    return str.split('').map(letter => {
      return accents[letter] === '' ? '' : accents[letter] || letter;
    }).join('');
  },
  calculateNOM51: (
    SPEValue?: string,
    requirePreparationValue?: 'SI' | 'NO' | '',
    contentCommonListInputValue?: string,
    contentValue?: string,
    productTypeValue?: 'solido g' | 'liquido ml',
    faseValue?: 'II' | 'III',
    portionValue?: string,
    proteinsValue?: string,
    totalFatValue?: string,
    transFatValue?: string,
    carbohydratesValue?: string,
    fiberValue?: string,
    saturatedFatValue?: string,
    sodiumValue?: string,
    sugarsValue?: string,
    sugarsAddedValue?: string,
    currentPortionValue?: string,
    azucaresSelectValue?: 'SI' | 'NO' | '',
    grasasSelectValue?: 'SI' | 'NO' | '',
    sodioSelectValue?: 'SI' | 'NO' | '',
    edulcorantesSelectValue?: 'SI' | 'NO' | '',
    cafeinaSelectValue?: 'SI' | 'NO' | '',
    EXCESOCALORIASValue?: string,
    EXCESOAZUCARESValue?: string,
    EXCESOGRASASSATURADASValue?: string,
    EXCESOGRASASTRANSValue?: string,
    EXCESOSODIOValue?: string,
    EDULCORANTESValue?: string,
    CAFEINAValue?: string,
    PorLoTantoValue?: string,
    sellosImagenesValue?: string,
    sellosImagenes2Value?: string,
    sellosImagenes3Value?: string,
    strips1Value?: string,
    strips2Value?: string,
    measures1Value?: string,
    measures2Value?: string,
    leyend216Value?: string,
    leyend216SelectValue?: string,
    strips1Question141Value?: string,
    strips2Question142Value?: string,
    measures1Question141Value?: string,
    measures2Question142Value?: string,
    sellosMedidasValue?: string,
    sellosMedidas2Value?: string,
    sellosMedidas3Value?: string,
    result2Value?: string,
    result3Value?: string,
    result4Value?: string,
    result5Value?: string,
    result6Value?: string,
    result7Value?: string,
    result8Value?: string,
    result9Value?: string,
    result10Value?: string,
    result1Table2Value?: string,
    result2Table2Value?: string,
    result3Table2Value?: string,
    result4Table2Value?: string,
    result5Table2Value?: string,
    result6Table2Value?: string,
    result7Table2Value?: string,
    result8Table2Value?: string,
    result9Table2Value?: string,
    result10Table2Value?: string,
    result11Table2Value?: string,
    declarationValue?: string,
    question145SelectValue?: string,
    resultQuestion145Value?: string,
    selectLeyendaQ144Value?: string,
    selectSuperficieLeyendaQ144Value?: string,
    inputMedidaLeyendaQ144Value?: string,
    resultQ144Value?: string,
    select1Question135Value?: string,
    select2Question135Value?: string,
    select3Question135Value?: string,
    select4Question135Value?: string,
    select5Question135Value?: string,
    select6Question135Value?: string,
    select7Question135Value?: string,
    select8Question135Value?: string,
    select9Question135Value?: string,
    select10Question135Value?: string,
    select11Question135Value?: string,
    select12Question135Value?: string,
    result1Question135Value?: string,
    result2Question135Value?: string,
    result3Question135Value?: string,
    result4Question135Value?: string,
    result5Question135Value?: string,
    result6Question135Value?: string,
    question98Alert1Value?: string,
    question98Alert2Value?: string,
    question98Alert3Value?: string,
    question98Alert4Value?: string,
    question98Alert5Value?: string,
    buttonAlertValue?: string,
    selectAddedSugarsValue?: string
  ) => {
    const product: Product = {
      content: '', // █Contenido Neto del Envase (g o ml)█
      fiber: '', // █Fibra dietética g█
      phase: 'II', // █Fase█
      portion: '', // █Tamaño de la porción (g./ml.)█
      productType: 'solid', // █Tipo de empaque█
      proteins: '', // █Proteinas g█
      saturatedFat: '', // █Grasas Saturadas g█
      sodium: '', // █Sodio mg█
      sugars: '', // █Azucares g█
      sugarsAdded: '', // █Azucares Añadidos g█
      totalFat: '', // █Grasas Totales g█
      transFat: '', // █Grasas Trans (mg)*█
      totalCarbohydrates: '', // █Hidratos de Carbono disponibles g█
      hasFatsAdded: false, // Grasas Añadidas
      hasSugarsAdded: false, // Azucares añadidos
      hasSodiumAdded: false, // Sodio añadido
      hasSweetenersAdded: false, // Edulcorantes añadidos
      hasCaffeineAdded: false // Cafeina añadida
    };

    let nutritionalTable: NutritionalTable = {
      containerEnergy: {
        kcal: 0,
        kj: 0
      },
      portionEnergy: {
        kcal: 0,
        kj: 0
      },
      proteins: 0,
      totalFat: 0,
      saturatedFat: 0,
      transFat: 0,
      carbohydrates: 0,
      sugars: 0,
      sugarsAdded: 0,
      fiber: 0,
      sodium: 0
    };

    let arraySeals: any = [];
    let arrayStrip: any = [];
    let trueSeals: any = [];
    let trueStrip: string[] = [];

    const requirePreparation = requirePreparationValue ? { value: requirePreparationValue } : document.getElementById('require-preparation') as HTMLSelectElement;
    const contentCommonListInput = contentCommonListInputValue ? { value: contentCommonListInputValue } : document.getElementById('content') as HTMLInputElement;
    const content = document.getElementById('total-mix') as HTMLInputElement;
    const productType = productTypeValue ? { value: productTypeValue } : document.getElementById('productType') as HTMLInputElement;
    const fase = faseValue ? { textContent: faseValue } : document.getElementById('Fase');
    const portion = document.getElementById('product-portion') as HTMLInputElement;
    const proteins = document.getElementById('proteins-input') as HTMLInputElement;
    const totalFat = document.getElementById('totalFat-input') as HTMLInputElement;
    const transFat = document.getElementById('transFat-input') as HTMLInputElement;
    const carbohydrates = document.getElementById('carbohydrates-input') as HTMLInputElement;
    const fiber = document.getElementById('fiber-input') as HTMLInputElement;
    const saturatedFat = document.getElementById('saturatedFat-input') as HTMLInputElement;
    const sodium = document.getElementById('sodium-input') as HTMLInputElement;
    const sugars = document.getElementById('sugars-input') as HTMLInputElement;
    const sugarsAdded = document.getElementById('sugarsAdded-input') as HTMLInputElement;
    const currentPortion = currentPortionValue ? { value: currentPortionValue } : document.getElementById('Current portion') as HTMLInputElement;

    const azucaresSelect = azucaresSelectValue ? { value: azucaresSelectValue } : document.getElementById('azucares-select') as HTMLSelectElement;
    const grasasSelect = grasasSelectValue ? { value: grasasSelectValue } : document.getElementById('grasas-select') as HTMLSelectElement;
    const sodioSelect = sodioSelectValue ? { value: sodioSelectValue } : document.getElementById('sodio-select') as HTMLSelectElement;
    const edulcorantesSelect = edulcorantesSelectValue ? { value: edulcorantesSelectValue } : document.getElementById('edulcorantes-select') as HTMLSelectElement;
    const cafeinaSelect = cafeinaSelectValue ? { value: cafeinaSelectValue } : document.getElementById('cafeina-select') as HTMLSelectElement;

    if (currentPortion) {
      currentPortion.value = currentPortion.value.replace(/-/g, '');
    }

    if (content) {
      content.value = content.value.replace(/-/g, '');

      product.content = `${contentValue || content.value}`;
    }

    if (productType) {
      product.productType = productType.value === 'solido g' ? 'solid' : 'liquid';
    }

    if (fase) {
      product.phase = fase.textContent === 'II' ? 'II' : 'III';
    }

    if (portion) {
      portion.value = portion.value.replace(/-/g, '');

      product.portion = portionValue || portion.value;
    }

    if (proteins) {
      proteins.value = proteins.value.replace(/-/g, '');
      product.proteins = proteinsValue || proteins.value;
    }

    if (totalFat) {
      totalFat.value = totalFat.value.replace(/-/g, '');
      product.totalFat = totalFatValue || totalFat.value;
    }

    if (transFat) {
      transFat.value = transFat.value.replace(/-/g, '');
      product.transFat = transFatValue || transFat.value;
    }

    if (carbohydrates) {
      carbohydrates.value = carbohydrates.value.replace(/-/g, '');
      product.totalCarbohydrates = carbohydratesValue || carbohydrates.value;
    }

    if (fiber) {
      fiber.value = fiber.value.replace(/-/g, '');
      product.fiber = fiberValue || fiber.value;
    }

    if (saturatedFat) {
      saturatedFat.value = saturatedFat.value.replace(/-/g, '');
      product.saturatedFat = saturatedFatValue || saturatedFat.value;
    }

    if (sodium) {
      sodium.value = sodium.value.replace(/-/g, '');
      product.sodium = sodiumValue || sodium.value;
    }

    if (sugars) {
      sugars.value = sugars.value.replace(/-/g, '');
      product.sugars = sugarsValue || sugars.value;
    }

    if (sugarsAdded) {
      sugarsAdded.value = sugarsAdded.value.replace(/-/g, '');
      product.sugarsAdded = sugarsAddedValue || sugarsAdded.value;
    }

    if (azucaresSelect) {
      product.hasSugarsAdded = azucaresSelect.value === 'SI';
    }

    if (grasasSelect) {
      product.hasFatsAdded = grasasSelect.value === 'SI';
    }

    if (sodioSelect) {
      product.hasSodiumAdded = sodioSelect.value === 'SI';
    }

    if (edulcorantesSelect) {
      product.hasSweetenersAdded = edulcorantesSelect.value === 'SI';
    }

    if (cafeinaSelect) {
      product.hasCaffeineAdded = cafeinaSelect.value === 'SI';
    }

    let Cantidad_de_porciones: number = 0;
    let Nuevo_Contenido_Neto: number = 0;
    let Porciones_de_100g: number = 0;

    if (requirePreparation) {
      if (requirePreparation.value === 'SI') {
        Cantidad_de_porciones = (convertToNumber(contentCommonListInput.value) / convertToNumber(portionValue || portion.value));
        Nuevo_Contenido_Neto = (Cantidad_de_porciones * convertToNumber(contentValue || content.value));
        Porciones_de_100g = convertToNumber((Nuevo_Contenido_Neto / 100).toFixed(1));
      }
    }

    if (requirePreparation && content && portion) {
      if (requirePreparation.value === 'NO') {
        content.disabled = true;
        content.value = '';

        portion.disabled = true;
        portion.value = '';
      } else if (requirePreparation.value === 'SI') {
        content.disabled = false;
        portion.disabled = false;
      }
    }

    const columnPer100 = () => {
      const proteinsP100 = (convertToNumber(product.proteins) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const totalFatP100 = (convertToNumber(product.totalFat) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const saturatedFatP100 = (convertToNumber(product.saturatedFat) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const transFatP100 = (convertToNumber(product.transFat) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const totalCarbohydratesP100 = (convertToNumber(product.totalCarbohydrates) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const sugarsP100 = (convertToNumber(product.sugars) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const sugarsAddedP100 = (convertToNumber(product.sugarsAdded) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const fiberP100 = (convertToNumber(product.fiber) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const sodiumP100 = (convertToNumber(product.sodium) * 100) / convertToNumber(currentPortion ? currentPortion.value : 0);

      return {
        proteinsP100,
        totalFatP100,
        saturatedFatP100,
        transFatP100,
        totalCarbohydratesP100,
        sugarsP100,
        sugarsAddedP100,
        fiberP100,
        sodiumP100
      };
    };

    const getColumnPerContainer = () => {
      const proteinsPerContainer = (convertToNumber(product.proteins) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const totalFatPerContainer = (convertToNumber(product.totalFat) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const saturatedFatPerContainer = (convertToNumber(product.saturatedFat) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const transFatPerContainer = (convertToNumber(product.transFat) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const totalCarbohydratesPerContainer = (convertToNumber(product.totalCarbohydrates) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const sugarsPerContainer = (convertToNumber(product.sugars) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const sugarsAddedPerContainer = (convertToNumber(product.sugarsAdded) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const fiberPerContainer = (convertToNumber(product.fiber) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);
      const sodiumPerContainer = (convertToNumber(product.sodium) * convertToNumber(contentCommonListInput ? contentCommonListInput.value : 0)) / convertToNumber(currentPortion ? currentPortion.value : 0);

      return {
        proteinsPerContainer,
        totalFatPerContainer,
        saturatedFatPerContainer,
        transFatPerContainer,
        totalCarbohydratesPerContainer,
        sugarsPerContainer,
        sugarsAddedPerContainer,
        fiberPerContainer,
        sodiumPerContainer
      };
    };

    const columnEnergy100kcal = (columnP100: any) => {
      const proteinsE100kcal = columnP100.proteinsP100 * 4;
      const totalFatE100kcal = columnP100.totalFatP100 * 9;
      const totalCarbohydratesE100kcal = columnP100.totalCarbohydratesP100 * 4;

      const totalE100kcal = proteinsE100kcal + totalFatE100kcal + totalCarbohydratesE100kcal;

      return {
        proteinsE100kcal,
        totalFatE100kcal,
        totalCarbohydratesE100kcal,
        totalE100kcal
      };
    };

    const columnEnergy100kj = (columnP100: any) => {
      const proteinsE100kj = columnP100.proteinsP100 * 17;
      const totalFatE100kj = columnP100.totalFatP100 * 37;
      const totalCarbohydratesE100kj = columnP100.totalCarbohydratesP100 * 17;

      const totalE100kj = proteinsE100kj + totalFatE100kj + totalCarbohydratesE100kj;

      return {
        proteinsE100kj,
        totalFatE100kj,
        totalCarbohydratesE100kj,
        totalE100kj
      };
    };

    const columnEnergyContainerKcal = (columnPerContainer: any) => {
      const proteinsEContainerKcal = columnPerContainer.proteinsPerContainer * 4;
      const totalFatEContainerKcal = columnPerContainer.totalFatPerContainer * 9;
      const totalCarbohydratesEContainerKcal = columnPerContainer.totalCarbohydratesPerContainer * 4;

      const totalEContainerKcal = proteinsEContainerKcal + totalFatEContainerKcal + totalCarbohydratesEContainerKcal;

      return {
        proteinsEContainerKcal,
        totalFatEContainerKcal,
        totalCarbohydratesEContainerKcal,
        totalEContainerKcal
      };
    };

    const columnEnergyContainerKj = (columnPerContainer: any) => {
      const proteinsEContainerKj = columnPerContainer.proteinsPerContainer * 17;
      const totalFatEContainerKj = columnPerContainer.totalFatPerContainer * 37;
      const totalCarbohydratesEContainerKj = columnPerContainer.totalCarbohydratesPerContainer * 17;

      const totalEContainerKj = proteinsEContainerKj + totalFatEContainerKj + totalCarbohydratesEContainerKj;

      return {
        proteinsEContainerKj,
        totalFatEContainerKj,
        totalCarbohydratesEContainerKj,
        totalEContainerKj
      };
    };

    const getCaloriesStamp = (columnE100kcal: any, columnP100: any) => {
      if (product.phase === 'II') {
        if ((product.hasSugarsAdded) || (product.hasFatsAdded)) {
          if (product.productType === 'liquid') {
            if (columnE100kcal.totalE100kcal >= 70 || (columnP100.sugarsAddedP100 * 4) >= 8) {
              return true;
            }
          }

          if (product.productType === 'solid') {
            if ((columnE100kcal.totalE100kcal) >= 275) {
              return true;
            }
          }
        }
      }
      if (product.phase === 'III') {
        if (product.hasSugarsAdded || product.hasFatsAdded || product.hasSodiumAdded) {
          if (product.productType === 'liquid') {
            if (columnE100kcal.totalE100kcal >= 70 || (columnP100.sugarsAddedP100 * 4) >= 8) {
              return true;
            }
          }
          if (product.productType === 'solid') {
            if ((columnE100kcal.totalE100kcal) >= 275) {
              return true;
            }
          }
        }
      }

      return false;
    };

    const getSugarsStamp = (columnE100kcal: any, columnP100: any) => {
      if (product.phase === 'II') {
        if (product.hasSugarsAdded) {
          if ((((columnP100.sugarsAddedP100 * 4) / columnE100kcal.totalE100kcal) * 100) >= 10) {
            return true;
          }
        }
      }
      if (product.phase === 'III') {
        if (product.hasSugarsAdded || product.hasFatsAdded || product.hasSodiumAdded) {
          if ((((columnP100.sugarsAddedP100 * 4) / columnE100kcal.totalE100kcal) * 100) >= 10) {
            return true;
          }
        }
      }
      return false;
    };

    const getSaturedStamp = (columnE100kcal: any, columnP100: any) => {
      if (product.phase === 'II') {
        if (product.hasFatsAdded) {
          if ((((columnP100.saturatedFatP100 * 9) / columnE100kcal.totalE100kcal) * 100) >= 10) {
            return true;
          }
        }
      }
      if (product.phase === 'III') {
        if (product.hasSugarsAdded || product.hasFatsAdded || product.hasSodiumAdded) {
          if ((((columnP100.saturatedFatP100 * 9) / columnE100kcal.totalE100kcal) * 100) >= 10) {
            return true;
          }
        }
      }

      return false;
    };

    const getTransStamp = (columnE100kcal: any, columnP100: any) => {
      if (product.phase === 'II') {
        if (product.hasFatsAdded) {
          if (((((columnP100.transFatP100 / 1000) * 9) / columnE100kcal.totalE100kcal) * 100) >= 1) {
            return true;
          }
        }
      }
      if (product.phase === 'III') {
        if (product.hasSugarsAdded || product.hasFatsAdded || product.hasSodiumAdded) {
          if (((((columnP100.transFatP100 / 1000) * 9) / columnE100kcal.totalE100kcal) * 100) >= 1) {
            return true;
          }
        }
      }

      return false;
    };

    const getSodiumStamp = (columnE100kcal: any, columnP100: any) => {
      if (product.phase === 'II') {
        if (product.hasSodiumAdded) {
          if (((convertToNumber(product.sodium) / columnE100kcal.totalE100kcal) >= 1) || convertToNumber(product.sodium) >= 300) {
            return true;
          }
        }
      }
      if (product.phase === 'III') {
        if (product.hasSugarsAdded || product.hasFatsAdded || product.hasSodiumAdded) {
          if (((columnP100.sodiumP100 / columnE100kcal.totalE100kcal) >= 1) || columnP100.sodiumP100 >= 300) {
            return true;
          }
        }
      }

      return false;
    };

    const SPE = SPEValue ? { textContent: SPEValue } : document.getElementById('SPE-value');
    const measures = getMeasures(parseFloat(`${SPE?.textContent}`.replace(/[,]/g, '')));
    const columnP100 = columnPer100();
    const columnPerContainer = getColumnPerContainer();
    const columnE100kcal = columnEnergy100kcal(columnP100);
    const columnE100kj = columnEnergy100kj(columnP100);
    const columnEContainerKcal = columnEnergyContainerKcal(columnPerContainer);
    const columnEContainerKj = columnEnergyContainerKj(columnPerContainer);

    const caloriesStamp = getCaloriesStamp(columnE100kcal, columnP100);
    const sugarsStamp = getSugarsStamp(columnE100kcal, columnP100);
    const saturedStamp = getSaturedStamp(columnE100kcal, columnP100);
    const transStamp = getTransStamp(columnE100kcal, columnP100);
    const sodiumStamp = getSodiumStamp(columnE100kcal, columnP100);
    const sweetenerStamp = product.hasSweetenersAdded;
    const caffeineStamp = product.hasCaffeineAdded;

    const stamps = {
      caloriesStamp,
      sugarsStamp,
      saturedStamp,
      transStamp,
      sodiumStamp,
      sweetenerStamp,
      caffeineStamp
    };

    nutritionalTable = {
      containerEnergy: {
        kcal: columnEContainerKcal.totalEContainerKcal,
        kj: columnEContainerKj.totalEContainerKj
      },
      portionEnergy: {
        kcal: columnE100kcal.totalE100kcal,
        kj: columnE100kj.totalE100kj
      },
      proteins: columnP100.proteinsP100,
      totalFat: columnP100.totalFatP100,
      saturatedFat: columnP100.saturatedFatP100,
      transFat: columnP100.transFatP100,
      carbohydrates: columnP100.totalCarbohydratesP100,
      sugars: columnP100.sugarsP100,
      sugarsAdded: columnP100.sugarsAddedP100,
      fiber: columnP100.fiberP100,
      sodium: columnP100.sodiumP100
    };

    const finalData = {
      measures,
      nutritionalTable,
      spe: parseFloat(`${SPE?.textContent}`.replace(/[,]/g, '')),
      stamps
    };

    arraySeals = Object.entries(finalData.stamps).slice(0, 5);
    arrayStrip = Object.entries(finalData.stamps).slice(5, 7);
    trueSeals = arraySeals.filter((e: any) => { return e[1] === true; });
    const trueStripAux = arrayStrip.filter((e: any) => { return e[1] === true; });
    // eslint-disable-next-line array-callback-return, consistent-return
    trueStrip = trueStripAux.map((item: any) => {
      if (item[1] === true) {
        return item[0];
      }
    });

    const EXCESOCALORIAS = EXCESOCALORIASValue ? { textContent: EXCESOCALORIASValue } : document.getElementById('EXCESO CALORIAS');
    const EXCESOAZUCARES = EXCESOAZUCARESValue ? { textContent: EXCESOAZUCARESValue } : document.getElementById('EXCESO AZUCARES');
    const EXCESOGRASASSATURADAS = EXCESOGRASASSATURADASValue ? { textContent: EXCESOGRASASSATURADASValue } : document.getElementById('EXCESO GRASAS SATURADAS');
    const EXCESOGRASASTRANS = EXCESOGRASASTRANSValue ? { textContent: EXCESOGRASASTRANSValue } : document.getElementById('EXCESO GRASAS TRANS');
    const EXCESOSODIO = EXCESOSODIOValue ? { textContent: EXCESOSODIOValue } : document.getElementById('EXCESO SODIO');
    const EDULCORANTES = EDULCORANTESValue ? { textContent: EDULCORANTESValue } : document.getElementById('EDULCORANTES');
    const CAFEINA = CAFEINAValue ? { textContent: CAFEINAValue } : document.getElementById('CAFEINA');

    const stampsArray: string[] = [];

    for (let index = 0; index < trueSeals.length; index += 1) {
      stampsArray.push(trueSeals[index][0]);
    }

    if (EXCESOCALORIAS) {
      if (stampsArray.includes('caloriesStamp')) {
        EXCESOCALORIAS.textContent = 'SI';
      } else {
        EXCESOCALORIAS.textContent = 'NO';
      }
    }
    if (EXCESOAZUCARES) {
      if (stampsArray.includes('sugarsStamp')) {
        EXCESOAZUCARES.textContent = 'SI';
      } else {
        EXCESOAZUCARES.textContent = 'NO';
      }
    }
    if (EXCESOGRASASSATURADAS) {
      if (stampsArray.includes('saturedStamp')) {
        EXCESOGRASASSATURADAS.textContent = 'SI';
      } else {
        EXCESOGRASASSATURADAS.textContent = 'NO';
      }
    }
    if (EXCESOGRASASTRANS) {
      if (stampsArray.includes('transStamp')) {
        EXCESOGRASASTRANS.textContent = 'SI';
      } else {
        EXCESOGRASASTRANS.textContent = 'NO';
      }
    }
    if (EXCESOSODIO) {
      if (stampsArray.includes('sodiumStamp')) {
        EXCESOSODIO.textContent = 'SI';
      } else {
        EXCESOSODIO.textContent = 'NO';
      }
    }

    if (EDULCORANTES) {
      if (trueStrip.includes('sweetenerStamp')) {
        EDULCORANTES.textContent = 'SI';
      } else {
        EDULCORANTES.textContent = 'NO';
      }
    }
    if (CAFEINA) {
      if (trueStrip.includes('caffeineStamp')) {
        CAFEINA.textContent = 'SI';
      } else {
        CAFEINA.textContent = 'NO';
      }
    }

    const PorLoTanto = document.getElementById('Por lo tanto');
    const resultSellos = arraySeals.concat(arrayStrip);

    if (PorLoTanto) {
      if (resultSellos.some((item: any) => item[1] === true)) {
        PorLoTanto.textContent = 'El producto no debe de ostentar declaraciones de propiedades saludables.';
      }
      if (resultSellos.every((item: any) => item[1] === false)) {
        PorLoTanto.textContent = 'El producto puede ostentar declaraciones de propiedades saludables siempre y cuando sean comprobables.';
      }
    }

    const sellosImagenes = document.getElementById('imagenes-sellos');
    const sellosImagenes2 = document.getElementById('imagenes-sellos-2');
    const sellosImagenes3 = document.getElementById('imagenes-sellos-3');

    if (sellosImagenes || sellosImagenes2 || sellosImagenes3) {
      if (sellosImagenes) {
        sellosImagenes.innerHTML = '';
      }
      if (sellosImagenes2) {
        sellosImagenes2.innerHTML = '';
      }
      if (sellosImagenes3) {
        sellosImagenes3.innerHTML = '';
      }

      if (finalData.spe <= 5
        && trueSeals.length !== 0) {
        const contentHTML = `
      <img
        src="/images/sealFor1.png"
        alt="seal-for-1"
        style="position: absolute;
        width: 30rem;
        top: 0;
        left: 0;"
      />
      <img
        src="/images/stampFor${trueSeals.length}.png"
        alt="stamp-for-${trueSeals.length}"
        style="position: absolute;
          width: 12rem;
          top: 8rem;
          left: 13rem;"
      />
    `;
        if (sellosImagenes) {
          sellosImagenes.innerHTML += contentHTML;
        }
        if (sellosImagenes2) {
          sellosImagenes2.innerHTML += contentHTML;
        }
        if (sellosImagenes3) {
          sellosImagenes3.innerHTML += contentHTML;
        }
      } else if (finalData.spe <= 40
        && trueSeals.length !== 0) {
        const contentHTML = `
      <img
        src="/images/sealFor1.png"
        alt="seal-for-1"
        style="position: absolute;
        width: 30rem;
        top: 0;
        left: 0;"
      />
      <img
        src="/images/stampFor${trueSeals.length}.png"
        alt="stamp-for-${trueSeals.length}"
        style="position: absolute;
          width: 12rem;
          top: 8rem;
          left: 13rem;"
      />
      <span 
        style="
          position: absolute;
          background-color: #ffffff; color: #000000;
          padding: 0 5px;
          left: 1rem;
          top: 16rem;"
      >${finalData.measures.height}cm</span>
      <span
        style="
          position: absolute;
          background-color: #ffffff; color: #000000;
          padding: 0 5px;
          left: 16rem;
          top: 1rem;"
      >${finalData.measures.width}cm</span>
    `;
        if (sellosImagenes) {
          sellosImagenes.innerHTML += contentHTML;
        }
        if (sellosImagenes2) {
          sellosImagenes2.innerHTML += contentHTML;
        }
        if (sellosImagenes3) {
          sellosImagenes3.innerHTML += contentHTML;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (trueSeals.length > 0) {
          const contentHTML = `<img
          src="/images/sealFor${trueSeals.length}.png"
          alt="sealFor-${trueSeals.length}"
          className="result__img-seal"
          style="position: absolute;
          width: 30rem;
          top: 0;
          left: 0;"
        />`;
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
        if (trueSeals.length === 0) {
          const contentHTML = '<h1 className="result__free-seals-message">No Aplica</h1>';
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
        if (trueSeals.length === 1) {
          const contentHTML = `
          <img
            src="/images/${trueSeals[0][0]}.png"
            alt="${trueSeals[0][0]}"
            className="result__img-text-1 result__img-text-1--1"
            style="position: absolute;
            width: 14rem;
            top: 13rem;
            left: 12rem;"
          />
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: 0;
              top: 16rem;
            "
          >${finalData.measures.height}cm</span>
          <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: 16rem;
            top: 1rem;
          ">${finalData.measures.width}cm</span>
        `;
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
        if (trueSeals.length === 2) {
          const contentHTML = `
          <img
            src="/images/${trueSeals[0][0]}.png" alt="${trueSeals[0][0]}"
            style="position: absolute;
            width: 8rem;
            top: 7.5rem;
            left: 7rem;"
          />
          <img
            src="/images/${trueSeals[1][0]}.png" alt="${trueSeals[1][0]}"
            style="position: absolute;
            width: 8rem;
            top: 7.5rem;
            left: 19.5rem;"
          />
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: -2rem;
              top: 9rem;
              transform: rotate(-90deg)"
            >${finalData.measures.height}cm</span>
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: 7rem;
              top: 0rem;"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: 20rem;
              top: 0rem;"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
        `;
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
        if (trueSeals.length === 3) {
          const contentHTML = `
        <img
          src="/images/${trueSeals[0][0]}.png" alt="${trueSeals[0][0]}" 
          style="position: absolute;
          width: 8rem;
          top: 8.5rem;
          left: 6.5rem;"
        />
        <img
          src="/images/${trueSeals[1][0]}.png" alt="${trueSeals[1][0]}"
          style="position: absolute;
          width: 9rem;
          top: 7.5rem;
          left: 19rem;"
        />
        <img
          src="/images/${trueSeals[2][0]}.png" alt="${trueSeals[2][0]}"
          style="position: absolute;
          width: 9rem;
          top: 21rem;
          left: 19rem;"
        />
        <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: 7rem;
            top: 0rem;"
        >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
        <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: -2rem;
            top: 9.5rem;
            transform: rotate(-90deg)"
        >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
        <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: 20rem;
            top: 0rem;"
        >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
        <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: -2rem;
            top: 22rem;
            transform: rotate(-90deg)"
        >${parseFloat((finalData.measures.height).toFixed(2))}cm</span>
      `;
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
        if (trueSeals.length === 4) {
          const contentHTML = `
          <img
            src="/images/${trueSeals[0][0]}.png" alt="${trueSeals[0][0]}"
            style="position: absolute;
            width: 8.5rem;
            top: 8rem;
            left: 7rem;"
          />
          <img
            src="/images/${trueSeals[1][0]}.png" alt="${trueSeals[1][0]}" 
            style="position: absolute;
            width: 8.5rem;
            top: 7rem;
            left: 19.5rem;"
          />
          <img
            src="/images/${trueSeals[2][0]}.png" alt="${trueSeals[2][0]}"
            style="position: absolute;
            width: 8.5rem;
            top: 20rem;
            left: 7rem;"
          />
          <img
            src="/images/${trueSeals[3][0]}.png" alt="${trueSeals[3][0]}"
            style="position: absolute;
            width: 8.5rem;
            top: 20rem;
            left: 19.5rem;"
          />
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: 7rem;
              top: 0rem;"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: -2rem;
              top: 9.5rem;
              transform: rotate(-90deg)"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: 20rem;
              top: 0rem;"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: -2rem;
              top: 22rem;
              transform: rotate(-90deg)"
          >${parseFloat((finalData.measures.height).toFixed(2))}cm</span>
        </>`;
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
        if (trueSeals.length === 5) {
          const contentHTML = `
          <img
            src="/images/${trueSeals[0][0]}.png" alt="${trueSeals[0][0]}"
            style="position: absolute;
            width: 6rem;
            top: 7rem;
            left: 5rem;"
          />
          <img
            src="/images/${trueSeals[1][0]}.png" alt="${trueSeals[1][0]}"
            style="position: absolute;
            width: 6rem;
            top: 6.5rem;
            left: 14rem;"
          />
          <img
            src="/images/${trueSeals[2][0]}.png" alt="${trueSeals[2][0]}" 
            style="position: absolute;
            width: 6rem;
            top: 6.5rem;
            left: 23rem;"
          />
          <img
            src="/images/${trueSeals[3][0]}.png" alt="${trueSeals[3][0]}"
            style="position: absolute;
            width: 6rem;
            top: 15.5rem;
            left: 5rem;"
          />
          <img
            src="/images/${trueSeals[4][0]}.png" alt="${trueSeals[4][0]}"
            style="position: absolute;
            width: 6rem;
            top: 15.5rem;
            left: 14rem;"
          />
          <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: 5rem;
            top: 0rem;
            font-size: 1.7rem"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: -2rem;
            top: 7rem;
            font-size: 1.7rem;
            transform: rotate(-90deg)"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
            style="
              position: absolute;
              background-color: #ffffff; color: #000000;
              padding: 0 5px;
              left: 14rem;
              top: 0rem;
              font-size: 1.7rem"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
          <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: -2rem;
            top: 16rem;
            font-size: 1.7rem;
            transform: rotate(-90deg)"
          >${parseFloat((finalData.measures.height).toFixed(2))}cm</span>
          <span
          style="
            position: absolute;
            background-color: #ffffff; color: #000000;
            padding: 0 5px;
            left: 23rem;
            top: 0rem;
            font-size: 1.7rem"
          >${parseFloat((finalData.measures.width).toFixed(2))}cm</span>
        `;
          if (sellosImagenes) {
            sellosImagenes.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes2) {
            sellosImagenes2.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
          if (sellosImagenes3) {
            sellosImagenes3.insertAdjacentHTML(
              'beforeend',
              contentHTML
            );
          }
        }
      }
    }

    const strips1 = document.getElementById('strips-images-1');
    const strips2 = document.getElementById('strips-images-2');
    const measures1 = document.getElementById('measures-images-1');
    const measures2 = document.getElementById('measures-images-2');
    const leyend216 = document.getElementById('leyend-216');
    const leyend216Select = document.getElementById('article-216-select') as HTMLSelectElement;

    const strips1Question141 = document.getElementById('strips-images-1-question-141');
    const strips2Question142 = document.getElementById('strips-images-2-question-142');
    const measures1Question141 = document.getElementById('measures-images-1-question-141');
    const measures2Question142 = document.getElementById('measures-images-2-question-142');

    if (leyend216 && leyend216Select) {
      if (leyend216Select.value === 'SI') {
        if (product
          && product.transFat
          && product.totalFat
          && convertToNumber(product.transFat)
          && convertToNumber(product.totalFat)) {
          if (((((convertToNumber(product.transFat) / 1000) / convertToNumber(product.totalFat)) * 100) >= 2)) {
            leyend216.innerHTML = 'El producto incumple con el articulo 216 BIS de la Ley General de Salud. Ya que contiene aceites vegetales y más de dos partes de grasas trans por cada cien partes de acidos grasos.';
          } else {
            leyend216.innerHTML = 'El producto cumple con el articulo 216 BIS de la Ley General de Salud';
          }
        }
      } else {
        leyend216.innerHTML = 'El producto cumple con el articulo 216 BIS de la Ley General de Salud';
      }
    }

    if (
      strips1
      || strips2
      || measures1
      || measures2
      || strips1Question141
      || strips2Question142
      || measures1Question141
      || measures2Question142
    ) {
      if (strips1) {
        strips1.innerHTML = '';
      }
      if (strips2) {
        strips2.innerHTML = '';
      }
      if (measures1) {
        measures1.innerHTML = '';
      }
      if (measures2) {
        measures2.innerHTML = '';
      }
      if (strips1Question141) {
        strips1Question141.innerHTML = '';
      }
      if (strips2Question142) {
        strips2Question142.innerHTML = '';
      }
      if (measures1Question141) {
        measures1Question141.innerHTML = '';
      }
      if (measures2Question142) {
        measures2Question142.innerHTML = '';
      }

      if (trueStrip.includes('caffeineStamp')) {
        const contentHTML = `
      <p
        style="
          position: absolute;
          padding: 0 4px;
          left: 10rem;
          top: 5.5rem;
          background-color: #ffffff; color: #000000;
          font-size: 1.5rem;
        "
      >${finalData.spe <= 5 ? '' : `${setMeasuresCafeina(finalData.spe).long}mm`}</p>
      <p
        style="
          position: absolute;
          padding: 0 2px;
          left: 0;
          top: 1rem;
          background-color: #ffffff; color: #000000;
          font-size: 1rem;
        "
      >${finalData.spe <= 5 ? '' : `${setMeasuresCafeina(finalData.spe).broad}mm`}</p>
      <img
        src="/images/caffeineStamp.png"
        alt="caffeineStamp"
        style="
          width: 100%;
        "
      />`;
        if (strips2) {
          strips2.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }
        if (strips2Question142) {
          strips2Question142.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }

        if (finalData.spe <= 5) {
          if (measures2) {
            measures2.innerHTML = 'Al menos 15% de la Superficie Principal de Exhibición';
          }
          if (measures2Question142) {
            measures2Question142.innerHTML = 'Al menos 15% de la Superficie Principal de Exhibición';
          }
        } else {
          if (measures2) {
            measures2.innerHTML = `Medidas de la leyenda: ${finalData.spe <= 5 ? '' : `${setMeasuresCafeina(finalData.spe).long}mm`} de largo por ${finalData.spe <= 5 ? '' : `${setMeasuresCafeina(finalData.spe).broad}mm`} de ancho`;
          }
          if (measures2Question142) {
            measures2Question142.innerHTML = `Medidas de la leyenda: ${finalData.spe <= 5 ? '' : `${setMeasuresCafeina(finalData.spe).long}mm`}de largo por ${finalData.spe <= 5 ? '' : `${setMeasuresCafeina(finalData.spe).broad}mm`} de ancho`;
          }
        }
      } else {
        const contentHTML = '<h5>No Aplica</h5>';
        if (strips2) {
          strips2.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }
        if (strips2Question142) {
          strips2Question142.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }
        if (measures2) {
          measures2.innerHTML = '';
        }
        if (measures2Question142) {
          measures2Question142.innerHTML = '';
        }
      }

      if (trueStrip.includes('sweetenerStamp')) {
        const contentHTML = `
      <p
        style="
          position: absolute;
          padding: 0 4px;
          left: 10rem;
          top: 5.5rem;
          background-color: #ffffff; color: #000000;
          font-size: 1.5rem;
        "
      >${finalData.spe <= 5 ? '' : `${setMeasuresEdulcorante(finalData.spe).long}mm`}</p>
      <p
        style="
          position: absolute;
          padding: 0 2px;
          left: 0;
          top: 1rem;
          background-color: #ffffff; color: #000000;
          font-size: 1rem;
        "
      >${finalData.spe <= 5 ? '' : `${setMeasuresEdulcorante(finalData.spe).broad}mm`}</p>
      <img
        src="/images/sweetenerStamp.png"
        alt="sweetenerStamp"
        style="
          width: 100%;
        "
      />`;
        if (strips1) {
          strips1.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }
        if (strips1Question141) {
          strips1Question141.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }

        if (finalData.spe <= 5) {
          if (measures1) {
            measures1.innerHTML = 'Al menos 15% de la Superficie Principal de Exhibición';
          }
          if (measures1Question141) {
            measures1Question141.innerHTML = 'Al menos 15% de la Superficie Principal de Exhibición';
          }
        } else {
          if (measures1) {
            measures1.innerHTML = `Medidas de la leyenda: ${finalData.spe <= 5 ? '' : `${setMeasuresEdulcorante(finalData.spe).long}mm`} de largo por ${finalData.spe <= 5 ? '' : `${setMeasuresEdulcorante(finalData.spe).broad}mm`} de ancho`;
          }
          if (measures1Question141) {
            measures1Question141.innerHTML = `Medidas de la leyenda: ${finalData.spe <= 5 ? '' : `${setMeasuresEdulcorante(finalData.spe).long}mm`} de largo por ${finalData.spe <= 5 ? '' : `${setMeasuresEdulcorante(finalData.spe).broad}mm`} de ancho`;
          }
        }
      } else {
        const contentHTML = '<h5>No Aplica</h5>';
        if (strips1) {
          strips1.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }
        if (strips1Question141) {
          strips1Question141.insertAdjacentHTML(
            'beforeend',
            contentHTML
          );
        }
        if (measures1) {
          measures1.innerHTML = '';
        }
        if (measures1Question141) {
          measures1Question141.innerHTML = '';
        }
      }
    }

    const sellosMedidas = document.getElementById('ubicación-de-los-sellos');
    const sellosMedidas2 = document.getElementById('ubicación-de-los-sellos-2');
    const sellosMedidas3 = document.getElementById('ubicación-de-los-sellos-3');

    if (sellosMedidas || sellosMedidas2 || sellosMedidas3) {
      if (finalData.spe <= 5) {
        const contentHTML = `${trueSeals.length === 0
          ? `<p>
        El producto esta libre de los sellos referentes a azucares, calorias, grasas saturadas, grasas trans, y sodio.
      </p>`
          : `<p>
          El producto correspondiente a esta evaluacion debe contener${' '}
          ${trueSeals.length > 0
            && trueSeals.length === 1 ? '<span style="color: rgb(50, 81, 182);">sello</span>' : `<span style="color: rgb(50, 81, 182);">${trueSeals.length} sellos</span>`}
          ${getLabelMeasuresBySPE(convertToNumber(SPE?.textContent || ''))}
    </p>`}`;
        if (sellosMedidas) {
          sellosMedidas.innerHTML = contentHTML;
        }
        if (sellosMedidas2) {
          sellosMedidas2.innerHTML = contentHTML;
        }
        if (sellosMedidas3) {
          sellosMedidas3.innerHTML = contentHTML;
        }
      } else {
        const contentHTML = `${trueSeals.length === 0
          ? `<p>
          El producto esta libre de los sellos referentes a azucares, calorias, grasas saturadas, grasas trans, y sodio.
        </p>`
          : `<p>
          El producto correspondiente a esta evaluacion debe contener${' '}
          ${trueSeals.length > 0
            && trueSeals.length === 1 ? '<span style="color: rgb(50, 81, 182);">sello</span>' : `<span style="color: rgb(50, 81, 182);">${trueSeals.length} sellos</span>`}
          ${' '}con las medidas${' '}
    <span style="color: rgb(50, 81, 182);">
      ${finalData.measures.height}cm
    </span>
          ${' '}de alto por${' '}
    <span style="color: rgb(50, 81, 182);">
      ${finalData.measures.width}cm
    </span>
          ${' '}de ancho
          ${getLabelMeasuresBySPE(convertToNumber(SPE?.textContent || ''))}
        </p > `}`;
        if (sellosMedidas) {
          sellosMedidas.innerHTML = contentHTML;
        }
        if (sellosMedidas2) {
          sellosMedidas2.innerHTML = contentHTML;
        }
        if (sellosMedidas3) {
          sellosMedidas3.innerHTML = contentHTML;
        }
      }
    }

    const result2 = document.getElementById('result-2');
    const result3 = document.getElementById('result-3');
    const result4 = document.getElementById('result-4');
    const result5 = document.getElementById('result-5');
    const result6 = document.getElementById('result-6');
    const result7 = document.getElementById('result-7');
    const result8 = document.getElementById('result-8');
    const result9 = document.getElementById('result-9');
    const result10 = document.getElementById('result-10');

    if (result2) {
      result2.textContent = `${(finalData.nutritionalTable.proteins).toFixed(2)}`;
    }

    if (result3) {
      result3.textContent = `${(finalData.nutritionalTable.totalFat).toFixed(2)}`;
    }

    if (result4) {
      result4.textContent = `${(finalData.nutritionalTable.saturatedFat).toFixed(2)}`;
    }

    if (result5) {
      result5.textContent = `${(finalData.nutritionalTable.transFat).toFixed(2)}`;
    }

    if (result6) {
      result6.textContent = `${(finalData.nutritionalTable.carbohydrates).toFixed(2)}`;
    }

    if (result7) {
      result7.textContent = `${(finalData.nutritionalTable.sugars).toFixed(2)}`;
    }

    if (result8) {
      result8.textContent = `${(finalData.nutritionalTable.sugarsAdded).toFixed(2)}`;
    }

    if (result9) {
      result9.textContent = `${(finalData.nutritionalTable.fiber).toFixed(2)}`;
    }

    if (result10) {
      result10.textContent = `${(finalData.nutritionalTable.sodium).toFixed(2)}`;
    }

    const result1Table2 = document.getElementById('result-1-table-2');
    const result2Table2 = document.getElementById('result-2-table-2');
    const result3Table2 = document.getElementById('result-3-table-2');
    const result4Table2 = document.getElementById('result-4-table-2');
    const result5Table2 = document.getElementById('result-5-table-2');
    const result6Table2 = document.getElementById('result-6-table-2');
    const result7Table2 = document.getElementById('result-7-table-2');
    const result8Table2 = document.getElementById('result-8-table-2');
    const result9Table2 = document.getElementById('result-9-table-2');
    const result10Table2 = document.getElementById('result-10-table-2');
    const result11Table2 = document.getElementById('result-11-table-2');

    if (result1Table2) {
      if (requirePreparation.value === 'SI') {
        result1Table2.textContent = `${formatNumbers(parseFloat(
          (columnE100kcal.totalE100kcal * Porciones_de_100g).toFixed(1)
        ))}kcal\n(${formatNumbers(parseFloat((columnE100kj.totalE100kj * Porciones_de_100g).toFixed(1)))}kJ)`;
      } else {
        result1Table2.textContent = `${formatNumbers(parseFloat(
          finalData.nutritionalTable.containerEnergy.kcal.toFixed(1)
        ))}kcal\n(${formatNumbers(parseFloat(finalData.nutritionalTable.containerEnergy.kj.toFixed(1)))}kJ)`;
      }
    }

    if (result2Table2) {
      result2Table2.textContent = `${formatNumbers(parseFloat(
        finalData.nutritionalTable.portionEnergy.kcal.toFixed(1)
      ))}kcal\n(${formatNumbers(parseFloat(finalData.nutritionalTable.portionEnergy.kj.toFixed(1)))}kJ)`;
    }

    if (result3Table2) {
      result3Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.proteins.toFixed(2)))}g`;
    }

    if (result4Table2) {
      result4Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.totalFat.toFixed(2)))}g`;
    }

    if (result5Table2) {
      result5Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.saturatedFat.toFixed(2)))}g`;
    }

    if (result6Table2) {
      result6Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.transFat.toFixed(2)))}mg`;
    }

    if (result7Table2) {
      result7Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.carbohydrates.toFixed(2)))}g`;
    }

    if (result8Table2) {
      result8Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.sugars.toFixed(2)))}g`;
    }

    if (result9Table2) {
      result9Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.sugarsAdded.toFixed(2)))}g`;
    }

    if (result10Table2) {
      result10Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.fiber.toFixed(2)))}g`;
    }

    if (result11Table2) {
      result11Table2.textContent = `${formatNumbers(parseFloat(finalData.nutritionalTable.sodium.toFixed(2)))}mg`;
    }

    const declaration = document.getElementById('declaration');

    const dataDeclarations = {
      data1: '',
      data2: '',
      data3: '',
      data4: '',
      data5: '',
      data6: '',
      data7: '',
      data8: '',
      data9: '',
      data10: '',
      data11: '',
      data12: '',
      data13: '',
      data14: ''
    };

    dataDeclarations.data1 = formatNumbers(parseFloat(nutritionalTable.containerEnergy.kcal.toFixed(2)));
    dataDeclarations.data2 = formatNumbers(parseFloat(nutritionalTable.containerEnergy.kj.toFixed(2)));
    dataDeclarations.data3 = product.productType === 'solid' ? 'g' : 'ml';
    dataDeclarations.data4 = formatNumbers(parseFloat(nutritionalTable.portionEnergy.kcal.toFixed(2)));
    dataDeclarations.data5 = formatNumbers(parseFloat(nutritionalTable.portionEnergy.kj.toFixed(2)));
    dataDeclarations.data6 = formatNumbers(parseFloat(nutritionalTable.proteins.toFixed(2)));
    dataDeclarations.data7 = formatNumbers(parseFloat(nutritionalTable.totalFat.toFixed(2)));
    dataDeclarations.data8 = formatNumbers(parseFloat(nutritionalTable.saturatedFat.toFixed(2)));
    dataDeclarations.data9 = formatNumbers(parseFloat(nutritionalTable.transFat.toFixed(2)));
    dataDeclarations.data10 = formatNumbers(parseFloat(nutritionalTable.carbohydrates.toFixed(2)));
    dataDeclarations.data11 = formatNumbers(parseFloat(nutritionalTable.sugars.toFixed(2)));
    dataDeclarations.data12 = formatNumbers(parseFloat(nutritionalTable.sugarsAdded.toFixed(2)));
    dataDeclarations.data13 = formatNumbers(parseFloat(nutritionalTable.fiber.toFixed(2)));
    dataDeclarations.data14 = formatNumbers(parseFloat(nutritionalTable.sodium.toFixed(2)));

    if (declaration) {
      declaration.textContent = '';
      declaration.insertAdjacentHTML(
        'beforeend',
        `<b>Contenido energetico Por Envase  ${dataDeclarations.data1} kcal(${dataDeclarations.data2} kJ), 
      Por 100${dataDeclarations.data3} Contenido energetico ${dataDeclarations.data4} kcal(${dataDeclarations.data5} kJ)</b>, 
      Proteinas ${dataDeclarations.data6}g, Grasas Totales ${dataDeclarations.data7}g, 
      <b>Grasas Saturadas ${dataDeclarations.data8}g, 
      Grasas Trans ${dataDeclarations.data9}mg</b>, 
      Hidratos de carbono disponibles ${dataDeclarations.data10}g, Azúcares ${dataDeclarations.data11}g, 
      <b>Azúcares Añadidos ${dataDeclarations.data12}g</b>, 
      Fibra Dietetica ${dataDeclarations.data13}g, 
      <b>Sodio ${dataDeclarations.data14}mg</b>`
      );
    }

    // eslint-disable-next-line no-unused-vars
    const question145Select = document.getElementById('question-145-select') as HTMLSelectElement;
    // eslint-disable-next-line no-unused-vars
    const resultQuestion145 = document.getElementById('result-question-145');

    let calorias: boolean = false;
    let azucares: boolean = false;
    let saturadas: boolean = false;
    let trans: boolean = false;
    let sodio: boolean = false;
    const edulcorantes: boolean = edulcorantesSelect ? edulcorantesSelect.value === 'SI' : false;
    const cafeina: boolean = cafeinaSelect ? cafeinaSelect.value === 'SI' : false;

    if (
      product.phase === 'II'
      && azucaresSelect
      && grasasSelect
    ) {
      if (
        azucaresSelect.value === 'SI'
        || grasasSelect.value === 'SI'
      ) {
        if (productType.value === 'solido g') {
          if (convertToNumber(finalData.nutritionalTable.portionEnergy.kcal || '') >= 275) {
            calorias = true;
          }
        } else if (productType.value === 'liquido ml') {
          if (
            (convertToNumber(finalData.nutritionalTable.portionEnergy.kcal || '') >= 70)
            || ((convertToNumber(finalData.nutritionalTable.sugarsAdded || '') * 4) >= 8)
          ) {
            calorias = true;
          }
        }
      }

      if (
        azucaresSelect.value === 'SI'
        && ((convertToNumber((finalData.nutritionalTable.sugarsAdded).toFixed(2)) * 4) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1
      ) {
        azucares = true;
      }

      if (
        grasasSelect.value === 'SI'
        && ((convertToNumber((finalData.nutritionalTable.saturatedFat).toFixed(2)) * 9) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1
      ) {
        saturadas = true;
      }

      if (
        grasasSelect.value === 'SI'
        && (((convertToNumber((finalData.nutritionalTable.transFat).toFixed(2)) / 1000) * 9) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1
      ) {
        trans = true;
      }

      if (
        sodioSelect.value === 'SI'
        && (
          ((convertToNumber((finalData.nutritionalTable.sodium).toFixed(2)) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1)
          || convertToNumber(finalData.nutritionalTable.sodium || '') >= 300
        )
      ) {
        sodio = true;
      }
    }

    if (
      product.phase === 'III'
      && azucaresSelect
      && grasasSelect
      && sodioSelect
    ) {
      if (
        azucaresSelect.value === 'SI'
        || grasasSelect.value === 'SI'
        || sodioSelect.value === 'SI'
      ) {
        if (productType.value === 'solido g') {
          if (convertToNumber(finalData.nutritionalTable.portionEnergy.kcal || '') >= 275) {
            calorias = true;
          }
        } else if (productType.value === 'liquido ml') {
          if (
            (convertToNumber(finalData.nutritionalTable.portionEnergy.kcal || '') >= 70)
            || ((convertToNumber(finalData.nutritionalTable.sugarsAdded || '') * 4) >= 8)
          ) {
            calorias = true;
          }
        }
      }

      if (
        (
          azucaresSelect.value === 'SI'
          || grasasSelect.value === 'SI'
          || sodioSelect.value === 'SI'
        )
        && ((convertToNumber((finalData.nutritionalTable.sugarsAdded).toFixed(2)) * 4) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1
      ) {
        azucares = true;
      }

      if (
        (
          azucaresSelect.value === 'SI'
          || grasasSelect.value === 'SI'
          || sodioSelect.value === 'SI'
        )
        && ((convertToNumber((finalData.nutritionalTable.saturatedFat).toFixed(2)) * 9) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1
      ) {
        saturadas = true;
      }

      if (
        (
          azucaresSelect.value === 'SI'
          || grasasSelect.value === 'SI'
          || sodioSelect.value === 'SI'
        )
        && (((convertToNumber((finalData.nutritionalTable.transFat).toFixed(2)) / 1000) * 9) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1
      ) {
        trans = true;
      }

      if (
        (
          azucaresSelect.value === 'SI'
          || grasasSelect.value === 'SI'
          || sodioSelect.value === 'SI'
        )
        && (
          ((convertToNumber((finalData.nutritionalTable.sodium).toFixed(2)) / finalData.nutritionalTable.portionEnergy.kcal) >= 0.1)
          || convertToNumber(finalData.nutritionalTable.sodium || '') >= 300
        )
      ) {
        sodio = true;
      }
    }

    if (
      question145Select
      && resultQuestion145
    ) {
      if (question145Select.value === 'SI'
        && (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
      ) {
        resultQuestion145.textContent = 'El producto NO cumple, ya que ostenta personajes infantiles, animaciones, dibujos animados que fomentan el consumo, compra o elección en niños (3-12 años), ya que contiene información nutrimental complementaria (sellos)';
      }

      if (question145Select.value === 'NO'
        && (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
      ) {
        resultQuestion145.textContent = 'El producto no ostenta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas';
      }

      if (question145Select.value === 'SI'
        && !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
      ) {
        resultQuestion145.textContent = 'El producto cumple, ya que contiene personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas que pudieran fomentar el consumo, compra o elección en niños sin ostentar información nutrimental complementaria';
      }

      if (question145Select.value === 'NO'
        && !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
      ) {
        resultQuestion145.textContent = 'El producto no ostenta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas';
      }
    }

    const selectLeyendaQ144 = document.getElementById('select-leyenda-question-144') as HTMLSelectElement;
    const selectSuperficieLeyendaQ144 = document.getElementById('select-superficie-leyenda-question-144') as HTMLSelectElement;
    const inputMedidaLeyendaQ144 = document.getElementById('input-medida-leyenda-question-144') as HTMLInputElement;
    const resultQ144 = document.getElementById('result-question-144');
    let minimumSPE: number = 12;
    let minimumContNet: number = 6;

    if (selectLeyendaQ144 && selectSuperficieLeyendaQ144 && inputMedidaLeyendaQ144) {
      if (selectLeyendaQ144.value === 'NO') {
        selectSuperficieLeyendaQ144.disabled = true;
        selectSuperficieLeyendaQ144.value = '';

        inputMedidaLeyendaQ144.disabled = true;
        inputMedidaLeyendaQ144.value = '';
      } else if (selectLeyendaQ144.value === 'SI') {
        selectSuperficieLeyendaQ144.disabled = false;
        inputMedidaLeyendaQ144.disabled = false;
      }
    }

    if (SPE) {
      if (convertToNumber(SPE.textContent || '') <= 32) {
        minimumSPE = 1.5;
      } else if (convertToNumber(SPE.textContent || '') <= 161) {
        minimumSPE = 3;
      } else if (convertToNumber(SPE.textContent || '') <= 645) {
        minimumSPE = 4.5;
      } else if (convertToNumber(SPE.textContent || '') <= 2580) {
        minimumSPE = 6;
      } else if (convertToNumber(SPE.textContent || '') <= 2580) {
        minimumSPE = 6;
      }
    }

    if (contentCommonListInput) {
      if (convertToNumber(contentCommonListInput.value) <= 50) {
        minimumContNet = 1.5;
      } else if (convertToNumber(contentCommonListInput.value) <= 200) {
        minimumContNet = 2;
      } else if (convertToNumber(contentCommonListInput.value) <= 750) {
        minimumContNet = 3;
      } else if (convertToNumber(contentCommonListInput.value) <= 1000) {
        minimumContNet = 4.5;
      } else if (convertToNumber(contentCommonListInput.value) <= 5000) {
        minimumContNet = 5;
      }
    }

    if (resultQ144 && selectLeyendaQ144 && selectSuperficieLeyendaQ144 && inputMedidaLeyendaQ144) {
      if (selectLeyendaQ144.value === 'NO') {
        resultQ144.textContent = 'La etiqueta no ostenta la leyenda "Este producto no contiene sellos ni leyendas"';
      }

      if (
        (
          calorias === false
          && azucares === false
          && saturadas === false
          && trans === false
          && sodio === false
          && edulcorantes === false
          && cafeina === false
        )
      ) {
        resultQ144.textContent = '';
      }

      if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && selectLeyendaQ144.value === 'SI'
      ) {
        resultQ144.textContent = 'El producto no puede ostentar la leyenda "Este producto no contiene sellos ni leyendas" ya que ostenta información nutrimental complementaria';
      }

      if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && selectLeyendaQ144.value === 'SI'
        && selectSuperficieLeyendaQ144.value === 'S.P.E.'
        && (convertToNumber(inputMedidaLeyendaQ144.value) <= Math.min(minimumSPE, minimumContNet))
      ) {
        resultQ144.textContent = 'El producto NO cumple ya que la leyenda "Este producto no contiene sellos ni leyendas" no puede colocarse en la Superficie Principal de Exhibición';
      }

      if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && selectLeyendaQ144.value === 'SI'
        && selectSuperficieLeyendaQ144.value === 'S.P.E.'
        && (convertToNumber(inputMedidaLeyendaQ144.value) > Math.min(minimumSPE, minimumContNet))
      ) {
        resultQ144.textContent = `El producto NO cumple ya que la leyenda 'Este producto no contiene sellos ni leyendas' no puede colocarse en la Superficie Principal de Exhibición, adicional que el tamaño máximo permitido para la leyenda es ${Math.min(minimumSPE, minimumContNet)}mm y la leyenda ostenta ${inputMedidaLeyendaQ144.value}mm de altura`;
      }

      if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && selectLeyendaQ144.value === 'SI'
        && selectSuperficieLeyendaQ144.value === 'S.I.'
        && (convertToNumber(inputMedidaLeyendaQ144.value) <= Math.min(minimumSPE, minimumContNet))
      ) {
        resultQ144.textContent = 'El producto cumple con la declaratoria';
      }

      if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && selectLeyendaQ144.value === 'SI'
        && selectSuperficieLeyendaQ144.value === 'S.I.'
        && (convertToNumber(inputMedidaLeyendaQ144.value) > Math.min(minimumSPE, minimumContNet))
      ) {
        resultQ144.textContent = `El producto NO cumple, ya que la leyenda 'Este producto no contiene sellos ni leyendas' excede el tamaño permitido. Debe medir como máximo ${Math.min(minimumSPE, minimumContNet)}mm y actualmente ostenta ${inputMedidaLeyendaQ144.value}mm de altura`;
      }
    }

    const select1Question135 = document.getElementById('input-1-question-135') as HTMLInputElement;
    const select2Question135 = document.getElementById('select-2-question-135') as HTMLSelectElement;
    const select3Question135 = document.getElementById('select-3-question-135') as HTMLSelectElement;
    const select4Question135 = document.getElementById('input-4-question-135') as HTMLInputElement;

    const select5Question135 = document.getElementById('input-5-question-135') as HTMLInputElement;
    const select6Question135 = document.getElementById('select-6-question-135') as HTMLSelectElement;
    const select7Question135 = document.getElementById('select-7-question-135') as HTMLSelectElement;
    const select8Question135 = document.getElementById('input-8-question-135') as HTMLInputElement;

    const select9Question135 = document.getElementById('input-9-question-135') as HTMLInputElement;
    const select10Question135 = document.getElementById('select-10-question-135') as HTMLSelectElement;
    const select11Question135 = document.getElementById('select-11-question-135') as HTMLSelectElement;
    const select12Question135 = document.getElementById('input-12-question-135') as HTMLInputElement;

    const result1Question135 = document.getElementById('result-1-question-135');
    const result2Question135 = document.getElementById('result-2-question-135');
    const result3Question135 = document.getElementById('result-3-question-135');
    const result4Question135 = document.getElementById('result-4-question-135');
    const result5Question135 = document.getElementById('result-5-question-135');
    const result6Question135 = document.getElementById('result-6-question-135');

    if (result1Question135 && result3Question135 && result5Question135) {
      if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
      ) {
        result1Question135.textContent = 'Sin restricción';
        result3Question135.textContent = 'Sin restricción';
        result5Question135.textContent = 'Sin restricción';
      } else {
        result1Question135.textContent = `${Math.min(minimumSPE, minimumContNet)}`;
        result3Question135.textContent = `${Math.min(minimumSPE, minimumContNet)}`;
        result5Question135.textContent = `${Math.min(minimumSPE, minimumContNet)}`;
      }
    }

    if (result6Question135 && result5Question135 && select12Question135) {
      if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select9Question135.value !== ''
        && select10Question135.value === 'SI'
        && select11Question135.value === 'Superficie de Información'
        && convertToNumber(select12Question135.value || 0) <= convertToNumber(result5Question135.textContent || '')
      ) {
        result6Question135.textContent = `"${select9Question135.value}" CUMPLE YA QUE PUEDE COMPROBARSE Ademas de que se encuentra en la ${select11Question135.value} y ostenta un tamaño de letra de ${select12Question135.value}mm siendo el maximo permitido ${result5Question135.textContent}mm de altura`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">true</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select9Question135.value !== ''
        && select10Question135.value === 'NO'
      ) {
        result6Question135.textContent = `"${select9Question135.value}" NO CUMPLE, PORQUE NO PUEDE COMPROBARSE`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select9Question135.value !== ''
        && select10Question135.value === 'SI'
        && select11Question135.value === 'Superficie Principal de Exhibición'
        && convertToNumber(select12Question135.value || 0) > convertToNumber(result5Question135.textContent || '')
      ) {
        result6Question135.textContent = `"${select9Question135.value}" NO CUMPLE POR QUE LA DECLARACIÓN SE ENCUENTRA EN LA SUPERFICIE PRINCIPAL DE EXHIBICIÓN, ADEMAS DE QUE EL TAMAÑO DE LETRA EXCEDE EL LIMITE PERMITIDO, DEBIENDO SER ${convertToNumber(result5Question135.textContent || '')}mm COMO MAXIMO Y LA DECLARACIÓN OSTENTA ${convertToNumber(select12Question135.value)}mm`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select9Question135.value !== ''
        && select10Question135.value === 'SI'
        && select11Question135.value === 'Superficie Principal de Exhibición'
      ) {
        result6Question135.textContent = `"${select9Question135.value}" NO CUMPLE POR QUE LA DECLARACIÓN SE ENCUENTRA EN LA SUPERFICIE PRINCIPAL DE EXHIBICIÓN`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select9Question135.value !== ''
        && select10Question135.value === 'SI'
        && select11Question135.value === 'Superficie de Información'
        && convertToNumber(select12Question135.value || 0) > convertToNumber(result5Question135.textContent || '')
      ) {
        result6Question135.textContent = `"${select9Question135.value}" NO CUMPLE POR QUE EXCEDE EL TAMAÑO MAXIMO DE LETRA PERMITIDO, DEBIENDO SER ${convertToNumber(result5Question135.textContent || '')}mm COMO MAXIMO Y LA DECLARACIÓN OSTENTA ${convertToNumber(select12Question135.value)}mm`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">false</p>'
        );
      } else if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && select9Question135.value !== ''
        && select10Question135.value === 'SI'
      ) {
        result6Question135.textContent = `"${select9Question135.value}" CUMPLE`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">true</p>'
        );
      } else if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && select9Question135.value !== ''
        && select10Question135.value === 'NO'
      ) {
        result6Question135.textContent = `"${select9Question135.value}" NO CUMPLE, PORQUE NO PUEDE COMPROBARSE`;
        result6Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-1-validation">false</p>'
        );
      } else {
        result6Question135.textContent = '';
        const result1Validation = document.getElementById('result-1-validation');

        if (result1Validation) {
          result1Validation.remove();
        }
      }
    }

    if (result4Question135 && result3Question135 && select8Question135) {
      if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select5Question135.value !== ''
        && select6Question135.value === 'SI'
        && select7Question135.value === 'Superficie de Información'
        && convertToNumber(select8Question135.value || 0) <= convertToNumber(result3Question135.textContent || '')
      ) {
        result4Question135.textContent = `"${select5Question135.value}" CUMPLE YA QUE PUEDE COMPROBARSE Ademas de que se encuentra en la ${select7Question135.value} y ostenta un tamaño de letra de ${select8Question135.value}mm siendo el maximo permitido ${result3Question135.textContent}mm de altura`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-2-validation">true</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select5Question135.value !== ''
        && select6Question135.value === 'NO'
      ) {
        result4Question135.textContent = `"${select5Question135.value}" NO CUMPLE, PORQUE NO PUEDE COMPROBARSE`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0; overflow: hidden;" id="result-2-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select5Question135.value !== ''
        && select6Question135.value === 'SI'
        && select7Question135.value === 'Superficie Principal de Exhibición'
        && convertToNumber(select8Question135.value || 0) > convertToNumber(result3Question135.textContent || '')
      ) {
        result4Question135.textContent = `"${select5Question135.value}" NO CUMPLE POR QUE LA DECLARACIÓN SE ENCUENTRA EN LA SUPERFICIE PRINCIPAL DE EXHIBICIÓN, ADEMAS DE QUE EL TAMAÑO DE LETRA EXCEDE EL LIMITE PERMITIDO, DEBIENDO SER ${convertToNumber(result3Question135.textContent || '')}mm COMO MAXIMO Y LA DECLARACIÓN OSTENTA ${convertToNumber(select8Question135.value)}mm`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-2-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select5Question135.value !== ''
        && select6Question135.value === 'SI'
        && select7Question135.value === 'Superficie Principal de Exhibición'
      ) {
        result4Question135.textContent = `"${select5Question135.value}" NO CUMPLE POR QUE LA DECLARACIÓN SE ENCUENTRA EN LA SUPERFICIE PRINCIPAL DE EXHIBICIÓN`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-2-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select5Question135.value !== ''
        && select6Question135.value === 'SI'
        && select7Question135.value === 'Superficie de Información'
        && convertToNumber(select8Question135.value || 0) > convertToNumber(result3Question135.textContent || '')
      ) {
        result4Question135.textContent = `"${select5Question135.value}" NO CUMPLE POR QUE EXCEDE EL TAMAÑO MAXIMO DE LETRA PERMITIDO, DEBIENDO SER ${convertToNumber(result3Question135.textContent || '')}mm COMO MAXIMO Y LA DECLARACIÓN OSTENTA ${convertToNumber(select8Question135.value)}mm`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-2-validation">false</p>'
        );
      } else if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && select5Question135.value !== ''
        && select6Question135.value === 'SI'
      ) {
        result4Question135.textContent = `"${select5Question135.value}" CUMPLE`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-2-validation">true</p>'
        );
      } else if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && select5Question135.value !== ''
        && select6Question135.value === 'NO'
      ) {
        result4Question135.textContent = `"${select5Question135.value}" NO CUMPLE, PORQUE NO PUEDE COMPROBARSE`;
        result4Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-2-validation">false</p>'
        );
      } else {
        result4Question135.textContent = '';
        const result2Validation = document.getElementById('result-2-validation');

        if (result2Validation) {
          result2Validation.remove();
        }
      }
    }

    if (result2Question135 && result1Question135 && select4Question135) {
      if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select1Question135.value !== ''
        && select2Question135.value === 'SI'
        && select3Question135.value === 'Superficie de Información'
        && convertToNumber(select4Question135.value || 0) <= convertToNumber(result1Question135.textContent || '')
      ) {
        result2Question135.textContent = `"${select1Question135.value}" CUMPLE YA QUE PUEDE COMPROBARSE Ademas de que se encuentra en la ${select3Question135.value} y ostenta un tamaño de letra de ${select4Question135.value}mm siendo el maximo permitido ${result1Question135.textContent}mm de altura`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">true</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select1Question135.value !== ''
        && select2Question135.value === 'NO'
      ) {
        result2Question135.textContent = `"${select1Question135.value}" NO CUMPLE, PORQUE NO PUEDE COMPROBARSE`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select1Question135.value !== ''
        && select2Question135.value === 'SI'
        && select3Question135.value === 'Superficie Principal de Exhibición'
        && convertToNumber(select4Question135.value || 0) > convertToNumber(result1Question135.textContent || '')
      ) {
        result2Question135.textContent = `"${select1Question135.value}" NO CUMPLE POR QUE LA DECLARACIÓN SE ENCUENTRA EN LA SUPERFICIE PRINCIPAL DE EXHIBICIÓN, ADEMAS DE QUE EL TAMAÑO DE LETRA EXCEDE EL LIMITE PERMITIDO, DEBIENDO SER ${convertToNumber(result1Question135.textContent || '')}mm COMO MAXIMO Y LA DECLARACIÓN OSTENTA ${convertToNumber(select4Question135.value)}mm`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select1Question135.value !== ''
        && select2Question135.value === 'SI'
        && select3Question135.value === 'Superficie Principal de Exhibición'
      ) {
        result2Question135.textContent = `"${select1Question135.value}" NO CUMPLE POR QUE LA DECLARACIÓN SE ENCUENTRA EN LA SUPERFICIE PRINCIPAL DE EXHIBICIÓN`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">false</p>'
        );
      } else if (
        (
          calorias
          || azucares
          || saturadas
          || trans
          || sodio
          || edulcorantes
          || cafeina
        )
        && select1Question135.value !== ''
        && select2Question135.value === 'SI'
        && select3Question135.value === 'Superficie de Información'
        && convertToNumber(select4Question135.value || 0) > convertToNumber(result1Question135.textContent || '')
      ) {
        result2Question135.textContent = `"${select1Question135.value}" NO CUMPLE POR QUE EXCEDE EL TAMAÑO MAXIMO DE LETRA PERMITIDO, DEBIENDO SER ${convertToNumber(result1Question135.textContent || '')}mm COMO MAXIMO Y LA DECLARACIÓN OSTENTA ${convertToNumber(select4Question135.value)}mm`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">false</p>'
        );
      } else if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && select1Question135.value !== ''
        && select2Question135.value === 'SI'
      ) {
        result2Question135.textContent = `"${select1Question135.value}" CUMPLE`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">true</p>'
        );
      } else if (
        !calorias
        && !azucares
        && !saturadas
        && !trans
        && !sodio
        && !edulcorantes
        && !cafeina
        && select1Question135.value !== ''
        && select2Question135.value === 'NO'
      ) {
        result2Question135.textContent = `"${select1Question135.value}" NO CUMPLE, PORQUE NO PUEDE COMPROBARSE`;
        result2Question135.insertAdjacentHTML(
          'beforeend',
          '<p style="width: 0; height: 0;  overflow: hidden;" id="result-3-validation">false</p>'
        );
      } else {
        result2Question135.textContent = '';
        const result3Validation = document.getElementById('result-3-validation');

        if (result3Validation) {
          result3Validation.remove();
        }
      }
    }

    const question98Alert1 = document.getElementById('question-98-alert-1');
    const question98Alert2 = document.getElementById('question-98-alert-2');
    const question98Alert3 = document.getElementById('question-98-alert-3');
    const question98Alert4 = document.getElementById('question-98-alert-4');
    const question98Alert5 = document.getElementById('question-98-alert-5');
    const question98Alert6 = document.getElementById('question-98-alert-6');

    // eslint-disable-next-line no-unused-vars
    const buttonAlert = document.getElementById('button-alerts-messages');

    const selectAddedSugars = document.getElementById('azucares-select') as HTMLSelectElement;

    if (question98Alert6 && buttonAlert && leyend216Select) {
      if (grasasSelect.value === 'NO'
        && leyend216Select.value === 'SI'
      ) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert6.textContent = 'EN INGREDIENTES AÑADIDOS INDICASTE QUE NO TIENE GRASAS AÑADIDAS, PERO ESTAS INDICANDO EN EL ARTICULO 216-BIS QUE SI OSTENTA';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert6.textContent = '';
      }
    }

    if (question98Alert1 && buttonAlert) {
      if (selectAddedSugars.value === 'NO'
        && Number(sugarsAddedValue || sugarsAdded.value) > 0) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert1.textContent = 'DECLARASTE AZUCARES AÑADIDOS EN LA DECLARACION NUTRIMENTAL, Y ESTAS INDICANDO QUE NO TIENE AZUCARES AÑADIDOS';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert1.textContent = '';
      }
    }

    if (question98Alert2 && buttonAlert) {
      if (selectAddedSugars.value === 'SI'
        && Number(sugarsAddedValue || sugarsAdded.value) === 0) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert2.textContent = 'DECLARASTE QUE NO HAY AZUCARES AÑADIDOS EN LA TABLA, PERO ESTAS INDICANDO QUE SI TIENE AÑADIDOS';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert2.textContent = '';
      }
    }

    if (question98Alert4 && sugars && buttonAlert) {
      if (Number(sugarsAddedValue || sugarsAdded.value) > Number(sugarsValue || sugars.value)) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert4.textContent = 'AZUCARES AÑADIDOS NO PUEDE SER MAYOR QUE AZUCARES';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert4.textContent = '';
      }
    }

    if (question98Alert5 && sugars && buttonAlert) {
      if (Number(sugarsValue || sugars.value) > Number(carbohydratesValue || carbohydrates.value)) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert5.textContent = 'AZUCARES NO PUEDE SER MAYOR QUE HIDRATOS DE CARBONO DISPONIBLES';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert5.textContent = '';
      }
    }

    if (question98Alert4 && buttonAlert) {
      if (Number(sugarsAddedValue || sugarsAdded.value) > Number(sugarsValue || sugars.value)) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert4.textContent = 'AZUCARES AÑADIDOS NO PUEDE SER MAYOR QUE AZUCARES';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert4.textContent = '';
      }
    }

    if (question98Alert5 && buttonAlert) {
      if (Number(sugarsValue || sugars.value) > Number(carbohydratesValue || carbohydrates.value)) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert5.textContent = 'AZUCARES NO PUEDE SER MAYOR QUE HIDRATOS DE CARBONO DISPONIBLES';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert5.textContent = '';
      }
    }

    if (question98Alert3 && buttonAlert) {
      if (Number(saturatedFatValue || saturatedFat.value) > Number(totalFatValue || totalFat.value)) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert3.textContent = 'GRASAS SATURADAS NO PUEDE SER MAYOR QUE GRASAS TOTALES';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert3.textContent = '';
      }
    }

    if (question98Alert3 && buttonAlert) {
      if (Number(saturatedFatValue || saturatedFat.value) > Number(totalFatValue || totalFat.value)) {
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert3.textContent = 'GRASAS SATURADAS NO PUEDE SER MAYOR QUE GRASAS TOTALES';
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert3.textContent = '';
      }
    }
  },
  sendNotification: (
    title: string,
    body: string,
    onClick?: () => void
  ) => {
    // eslint-disable-next-line no-undef
    if (!('Notification' in window)) {
      // eslint-disable-next-line no-console
      console.log('Este navegador no soporta notificaciones de escritorio');
    } else if (Notification.permission === 'granted') {
      const notification = new Notification(`CECSA Trade${title ? ` - ${title}` : ''}`, {
        body,
        icon: '/favicon.ico',
        badge: '/favicon.ico'
      });

      notification.onclick = () => {
        if (onClick) {
          onClick();
        }
      };
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(`CECSA Trade${title ? ` - ${title}` : ''}`, {
            body,
            icon: '/favicon.ico',
            badge: '/favicon.ico'
          });

          notification.onclick = () => {
            if (onClick) {
              onClick();
            }
          };
        }
      });
    }
  }
};

export default functions;
