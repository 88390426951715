/* eslint-disable no-undef */
/* eslint-disable max-len */
const list = {
  documentCode: 'F7-49',
  documentDescription: 'ista de inspección para la NORMA Oficial Mexicana NOM-020-SCFI-1997: Información comercial, etiquetado de cueros y pieles curtidas naturales y materiales sintéticos o artificiales con esa apariencia, calzado, marroquinería, así como los productos elaborados con dichos materiales.',
  documentIssueDate: '01/04/2024',
  hasPackingTable: false,
  hasSamplingTable: true,
  hasMeasurementEquipment: true,
  questions: [
    {
      pageNumber: 2,
      questionNumber: 0,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          Calzado
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 1,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Todos los materiales y productos terminados objeto de esta Norma, deben marcarse en idioma español en forma
          permanente y lugar visible con la información siguiente:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 2,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social completo o abreviado del fabricante nacional o importador o su marca registrada.
          <table className="table-content__inspector-table" id='table-on-site-1-020-1'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 3,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          <table className="table-content__inspector-table" id='table-on-site-2-020-2'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el país de origen del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-50-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-50-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 4,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          c) Definición genérica o específica de los materiales y opcionalmente su acabado.
          <br />(Esta información puede ser ostentada en una etiqueta).
          <br /><br />
          Cuando exista combinación de dos o más materiales dentro de alguna de estas tres partes, debe especificarse el que predomine.
          <table className="table-content__inspector-table" id='table-on-site-2'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td rowSpan={14}>3.1 Acabado - Se entiende por acabado, el proceso que se le da a una piel curtida para cambiar su aspecto original.</td>
                      <td className="text-left">
                        a) Ante: Es el tacto aterciopelado por el lado de la carne que se le da a una piel de cabra, borrego, becerro, etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Badana: Es el curtido vegetal que se le da a una piel de borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Buck: Es el tacto afelpado por el lado de la flor que se da a cualquier piel.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Cardado: Es la preparación que se le da a las fibras de lana, en la piel de borrego curtida.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        e) Charol: Es el barniz adherente que se le da a cualquier piel, incluso materiales artificiales o regenerados
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        f) Corrugado: Es el aspecto arrugado que se le da a una piel mediante un proceso químico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        g) Gamuza: Es el tacto suave y afelpado por el lado de la carne que se le da a una piel o carnaza de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        h) Glasé: Es el lustre que se le da a cualquier piel de cabra o borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        i) Grabado: Es el aspecto que se le da por el lado de la carne o la flor, por medio de un prensado mecánico a una piel, carnaza, cuero, material artificial o regenerado
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        j) Hunting: Es el tacto afelpado por el lado de la carne que se le da a una piel de animal bovino o vacuno.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        k) Marroquín: Es el graneado químico o manual por el lado de la flor que se le da a una piel de cabra.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        l) Nappa: Es la textura especialmente suave que se le da a una piel de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        m) Romaneado: Es el aspecto arrugado que se le da a una piel mediante un proceso físico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        n) Transfer o folia: Es el proceso de recubrir la carnaza con película sintética para darle apariencia de flor (action leather).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Piel de animal bovino o vacuno</td>
                      <td className="text-left">
                        a) Becerro.- Es la piel elaborada, procedente de animales menores de un año, cuya superficie total es de 55 dm2 a 150 dm2(piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Becerro nonato.- Es la piel entera que proviene del animal en desarrollo, aun en el vientre, cuya superficie total es menor de70 dm2 (piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Res.- Es la piel elaborada procedente de animales mayores de dos años, cuya superficie es de 150 dm2 en adelante (lado).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Ternera.- Es la piel elaborada procedente de animales de un año hasta de dos años, cuya superficie es de 90 dm2 a 150dm2 (lado).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.5 Piel de animal caprino</td>
                      <td className="text-left">
                        a) Cabra.- Es la piel que proviene de animales de esta especie de más de un mes de nacido y cuya superficie total es mayorde 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cabrito.- Es la piel que proviene de esta especie hasta de un mes de nacido, cuya superficie es de hasta 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.6 Piel de animal equino</td>
                      <td className="text-left">
                        La que pertenece al caballo, mulo y asno
                        <br />
                        <br />
                        a) Anca de potro.- Es la piel que corresponde a la región del anca de esta especie. Este término puede utilizarse opcionalmente ya que expresa la zona y calidad del producto y puede sustituir al término "piel de equino".
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.7 Piel de animal ovino</td>
                      <td className="text-left">
                        a) Borrego.- Es la piel del animal de esta especie mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cordero.- Es la piel del animal de esta especie no mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.8 Piel de animal marino</td>
                      <td className="text-left">
                        La que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.10 Piel de reptil</td>
                      <td className="text-left">
                        La que proviene de animales como víbora o serpiente, iguana, lagarto, cocodrilo, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.11 Suela de cuero</td>
                      <td className="text-left">
                        Es el cuero curtido para suela. Este artículo debe denominarse invariablemente suela de cuero, pudiendo además especificarse la especie de la cual proceda.
                      </td>
                    </tr>
                    <tr>
                      <td>3.2 Materiales sintéticos o artificiales</td>
                      <td className="text-left">
                        Para los materiales plásticos, sintéticos o textiles con apariencia de piel o cuero, éstos pueden denominarse como tales; pudiendo además denominarse por su nombre específico: Piroxilina, poliuretano, polietileno, vinilo o vinílico, policloruro de vinilo(PVC) y acrilo-nitrilo-butadieno-estireno (ABS), poliamida (nylon), poliéster, etil-vynil-acetato (EVA), etc. Queda prohibido emplearlos términos piel o cuero para designar estos materiales.
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 5,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          El corte,
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 6,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          El forro
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 7,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          La suela.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 8,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          e) Cuando los artículos a que se refiere esta Norma se comercialicen como pares, la información podrá presentarse en una o ambas piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 9,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          En las prendas de vestir como abrigos, chamarras, sacos, pantalones, faldas, bolsas para dama, guantes, cinturones y artículos para viaje como velices, portafolios, mochilas, etc., la parte externa del producto y el forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 10,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Todos los materiales y productos terminados objeto de esta Norma, deben marcarse en idioma español en forma permanente y lugar visible con la información siguiente:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 11,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social completo o abreviado del fabricante nacional o importador o su marca registrada.
          <table className="table-content__inspector-table" id='table-on-site-1-020-3'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 12,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          <table className="table-content__inspector-table" id='table-on-site-2-020-4'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el país de origen del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-50-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-50-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 13,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          c) Definición genérica o específica de los materiales y opcionalmente su acabado.
          <br />(Esta información puede ser ostentada en una etiqueta).
          <br /><br />
          Parte externa del producto
          <table className="table-content__inspector-table" id='table-on-site-2'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td rowSpan={14}>3.1 Acabado - Se entiende por acabado, el proceso que se le da a una piel curtida para cambiar su aspecto original.</td>
                      <td className="text-left">
                        a) Ante: Es el tacto aterciopelado por el lado de la carne que se le da a una piel de cabra, borrego, becerro, etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Badana: Es el curtido vegetal que se le da a una piel de borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Buck: Es el tacto afelpado por el lado de la flor que se da a cualquier piel.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Cardado: Es la preparación que se le da a las fibras de lana, en la piel de borrego curtida.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        e) Charol: Es el barniz adherente que se le da a cualquier piel, incluso materiales artificiales o regenerados
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        f) Corrugado: Es el aspecto arrugado que se le da a una piel mediante un proceso químico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        g) Gamuza: Es el tacto suave y afelpado por el lado de la carne que se le da a una piel o carnaza de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        h) Glasé: Es el lustre que se le da a cualquier piel de cabra o borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        i) Grabado: Es el aspecto que se le da por el lado de la carne o la flor, por medio de un prensado mecánico a una piel, carnaza, cuero, material artificial o regenerado
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        j) Hunting: Es el tacto afelpado por el lado de la carne que se le da a una piel de animal bovino o vacuno.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        k) Marroquín: Es el graneado químico o manual por el lado de la flor que se le da a una piel de cabra.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        l) Nappa: Es la textura especialmente suave que se le da a una piel de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        m) Romaneado: Es el aspecto arrugado que se le da a una piel mediante un proceso físico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        n) Transfer o folia: Es el proceso de recubrir la carnaza con película sintética para darle apariencia de flor (action leather).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Piel de animal bovino o vacuno</td>
                      <td className="text-left">
                        a) Becerro.- Es la piel elaborada, procedente de animales menores de un año, cuya superficie total es de 55 dm2 a 150 dm2(piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Becerro nonato.- Es la piel entera que proviene del animal en desarrollo, aun en el vientre, cuya superficie total es menor de70 dm2 (piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Res.- Es la piel elaborada procedente de animales mayores de dos años, cuya superficie es de 150 dm2 en adelante (lado).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Ternera.- Es la piel elaborada procedente de animales de un año hasta de dos años, cuya superficie es de 90 dm2 a 150dm2 (lado).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.5 Piel de animal caprino</td>
                      <td className="text-left">
                        a) Cabra.- Es la piel que proviene de animales de esta especie de más de un mes de nacido y cuya superficie total es mayorde 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cabrito.- Es la piel que proviene de esta especie hasta de un mes de nacido, cuya superficie es de hasta 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.6 Piel de animal equino</td>
                      <td className="text-left">
                        La que pertenece al caballo, mulo y asno
                        <br />
                        <br />
                        a) Anca de potro.- Es la piel que corresponde a la región del anca de esta especie. Este término puede utilizarse opcionalmente ya que expresa la zona y calidad del producto y puede sustituir al término "piel de equino".
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.7 Piel de animal ovino</td>
                      <td className="text-left">
                        a) Borrego.- Es la piel del animal de esta especie mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cordero.- Es la piel del animal de esta especie no mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.8 Piel de animal marino</td>
                      <td className="text-left">
                        La que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.10 Piel de reptil</td>
                      <td className="text-left">
                        La que proviene de animales como víbora o serpiente, iguana, lagarto, cocodrilo, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.11 Suela de cuero</td>
                      <td className="text-left">
                        Es el cuero curtido para suela. Este artículo debe denominarse invariablemente suela de cuero, pudiendo además especificarse la especie de la cual proceda.
                      </td>
                    </tr>
                    <tr>
                      <td>3.2 Materiales sintéticos o artificiales</td>
                      <td className="text-left">
                        Para los materiales plásticos, sintéticos o textiles con apariencia de piel o cuero, éstos pueden denominarse como tales; pudiendo además denominarse por su nombre específico: Piroxilina, poliuretano, polietileno, vinilo o vinílico, policloruro de vinilo(PVC) y acrilo-nitrilo-butadieno-estireno (ABS), poliamida (nylon), poliéster, etil-vynil-acetato (EVA), etc. Queda prohibido emplearlos términos piel o cuero para designar estos materiales.
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 14,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          Forro (si aplica)
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 15,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          e) Cuando los artículos a que se refiere esta Norma se comercialicen como pares, la información podrá presentarse en una o ambas piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 16,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          En artículos como monederos, carteras, billeteras, llaveros, estuches, artículos para escritorio, para oficina, deportivos y otros no especificados, se deben indicar el material de la vista, sin considerar armazones, divisiones y otras partes, excepto el forro cuando éste sea piel.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 17,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Todos los materiales y productos terminados objeto de esta Norma, deben marcarse en idioma español en forma permanente y lugar visible con la información siguiente:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 18,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social completo o abreviado del fabricante nacional o importador o su marca registrada.
          <table className="table-content__inspector-table" id='table-on-site-1-020-5'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 19,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          <table className="table-content__inspector-table" id='table-on-site-2-020-6'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el país de origen del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-50-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-50-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 20,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          c) Definición genérica o específica de los materiales y opcionalmente su acabado.
          <br />(Esta información puede ser ostentada en una etiqueta).
          <br /><br />
          Material a la vista
          <table className="table-content__inspector-table" id='table-on-site-2'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td rowSpan={14}>3.1 Acabado - Se entiende por acabado, el proceso que se le da a una piel curtida para cambiar su aspecto original.</td>
                      <td className="text-left">
                        a) Ante: Es el tacto aterciopelado por el lado de la carne que se le da a una piel de cabra, borrego, becerro, etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Badana: Es el curtido vegetal que se le da a una piel de borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Buck: Es el tacto afelpado por el lado de la flor que se da a cualquier piel.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Cardado: Es la preparación que se le da a las fibras de lana, en la piel de borrego curtida.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        e) Charol: Es el barniz adherente que se le da a cualquier piel, incluso materiales artificiales o regenerados
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        f) Corrugado: Es el aspecto arrugado que se le da a una piel mediante un proceso químico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        g) Gamuza: Es el tacto suave y afelpado por el lado de la carne que se le da a una piel o carnaza de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        h) Glasé: Es el lustre que se le da a cualquier piel de cabra o borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        i) Grabado: Es el aspecto que se le da por el lado de la carne o la flor, por medio de un prensado mecánico a una piel, carnaza, cuero, material artificial o regenerado
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        j) Hunting: Es el tacto afelpado por el lado de la carne que se le da a una piel de animal bovino o vacuno.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        k) Marroquín: Es el graneado químico o manual por el lado de la flor que se le da a una piel de cabra.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        l) Nappa: Es la textura especialmente suave que se le da a una piel de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        m) Romaneado: Es el aspecto arrugado que se le da a una piel mediante un proceso físico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        n) Transfer o folia: Es el proceso de recubrir la carnaza con película sintética para darle apariencia de flor (action leather).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Piel de animal bovino o vacuno</td>
                      <td className="text-left">
                        a) Becerro.- Es la piel elaborada, procedente de animales menores de un año, cuya superficie total es de 55 dm2 a 150 dm2(piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Becerro nonato.- Es la piel entera que proviene del animal en desarrollo, aun en el vientre, cuya superficie total es menor de70 dm2 (piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Res.- Es la piel elaborada procedente de animales mayores de dos años, cuya superficie es de 150 dm2 en adelante (lado).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Ternera.- Es la piel elaborada procedente de animales de un año hasta de dos años, cuya superficie es de 90 dm2 a 150dm2 (lado).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.5 Piel de animal caprino</td>
                      <td className="text-left">
                        a) Cabra.- Es la piel que proviene de animales de esta especie de más de un mes de nacido y cuya superficie total es mayorde 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cabrito.- Es la piel que proviene de esta especie hasta de un mes de nacido, cuya superficie es de hasta 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.6 Piel de animal equino</td>
                      <td className="text-left">
                        La que pertenece al caballo, mulo y asno
                        <br />
                        <br />
                        a) Anca de potro.- Es la piel que corresponde a la región del anca de esta especie. Este término puede utilizarse opcionalmente ya que expresa la zona y calidad del producto y puede sustituir al término "piel de equino".
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.7 Piel de animal ovino</td>
                      <td className="text-left">
                        a) Borrego.- Es la piel del animal de esta especie mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cordero.- Es la piel del animal de esta especie no mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.8 Piel de animal marino</td>
                      <td className="text-left">
                        La que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.10 Piel de reptil</td>
                      <td className="text-left">
                        La que proviene de animales como víbora o serpiente, iguana, lagarto, cocodrilo, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.11 Suela de cuero</td>
                      <td className="text-left">
                        Es el cuero curtido para suela. Este artículo debe denominarse invariablemente suela de cuero, pudiendo además especificarse la especie de la cual proceda.
                      </td>
                    </tr>
                    <tr>
                      <td>3.2 Materiales sintéticos o artificiales</td>
                      <td className="text-left">
                        Para los materiales plásticos, sintéticos o textiles con apariencia de piel o cuero, éstos pueden denominarse como tales; pudiendo además denominarse por su nombre específico: Piroxilina, poliuretano, polietileno, vinilo o vinílico, policloruro de vinilo(PVC) y acrilo-nitrilo-butadieno-estireno (ABS), poliamida (nylon), poliéster, etil-vynil-acetato (EVA), etc. Queda prohibido emplearlos términos piel o cuero para designar estos materiales.
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 21,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          Forro (solo si es de piel)
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 22,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          e) Cuando los artículos a que se refiere esta Norma se comercialicen como pares, la información podrá presentarse en una o ambas piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 23,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          En los artículos de cuero para uso industrial, como son los rollos de banda de cuero, artículos tales como refacciones de cuero, empaques de cuero, etc., únicamente debe marcarse en un lugar visible de su embalaje.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 24,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Todos los materiales y productos terminados objeto de esta Norma, deben marcarse en idioma español en forma permanente y lugar visible con la información siguiente:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 25,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social completo o abreviado del fabricante nacional o importador o su marca registrada.
          <table className="table-content__inspector-table" id='table-on-site-1-020-7'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 26,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          <table className="table-content__inspector-table" id='table-on-site-2-020-8'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el país de origen del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-50-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-50-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 27,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          c) Definición genérica o específica de los materiales y opcionalmente su acabado.
          <br />(Esta información puede ser ostentada en una etiqueta).
          <table className="table-content__inspector-table" id='table-on-site-2'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td rowSpan={14}>3.1 Acabado - Se entiende por acabado, el proceso que se le da a una piel curtida para cambiar su aspecto original.</td>
                      <td className="text-left">
                        a) Ante: Es el tacto aterciopelado por el lado de la carne que se le da a una piel de cabra, borrego, becerro, etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Badana: Es el curtido vegetal que se le da a una piel de borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Buck: Es el tacto afelpado por el lado de la flor que se da a cualquier piel.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Cardado: Es la preparación que se le da a las fibras de lana, en la piel de borrego curtida.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        e) Charol: Es el barniz adherente que se le da a cualquier piel, incluso materiales artificiales o regenerados
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        f) Corrugado: Es el aspecto arrugado que se le da a una piel mediante un proceso químico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        g) Gamuza: Es el tacto suave y afelpado por el lado de la carne que se le da a una piel o carnaza de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        h) Glasé: Es el lustre que se le da a cualquier piel de cabra o borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        i) Grabado: Es el aspecto que se le da por el lado de la carne o la flor, por medio de un prensado mecánico a una piel, carnaza, cuero, material artificial o regenerado
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        j) Hunting: Es el tacto afelpado por el lado de la carne que se le da a una piel de animal bovino o vacuno.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        k) Marroquín: Es el graneado químico o manual por el lado de la flor que se le da a una piel de cabra.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        l) Nappa: Es la textura especialmente suave que se le da a una piel de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        m) Romaneado: Es el aspecto arrugado que se le da a una piel mediante un proceso físico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        n) Transfer o folia: Es el proceso de recubrir la carnaza con película sintética para darle apariencia de flor (action leather).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Piel de animal bovino o vacuno</td>
                      <td className="text-left">
                        a) Becerro.- Es la piel elaborada, procedente de animales menores de un año, cuya superficie total es de 55 dm2 a 150 dm2(piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Becerro nonato.- Es la piel entera que proviene del animal en desarrollo, aun en el vientre, cuya superficie total es menor de70 dm2 (piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Res.- Es la piel elaborada procedente de animales mayores de dos años, cuya superficie es de 150 dm2 en adelante (lado).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Ternera.- Es la piel elaborada procedente de animales de un año hasta de dos años, cuya superficie es de 90 dm2 a 150dm2 (lado).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.5 Piel de animal caprino</td>
                      <td className="text-left">
                        a) Cabra.- Es la piel que proviene de animales de esta especie de más de un mes de nacido y cuya superficie total es mayorde 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cabrito.- Es la piel que proviene de esta especie hasta de un mes de nacido, cuya superficie es de hasta 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.6 Piel de animal equino</td>
                      <td className="text-left">
                        La que pertenece al caballo, mulo y asno
                        <br />
                        <br />
                        a) Anca de potro.- Es la piel que corresponde a la región del anca de esta especie. Este término puede utilizarse opcionalmente ya que expresa la zona y calidad del producto y puede sustituir al término "piel de equino".
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.7 Piel de animal ovino</td>
                      <td className="text-left">
                        a) Borrego.- Es la piel del animal de esta especie mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cordero.- Es la piel del animal de esta especie no mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.8 Piel de animal marino</td>
                      <td className="text-left">
                        La que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.10 Piel de reptil</td>
                      <td className="text-left">
                        La que proviene de animales como víbora o serpiente, iguana, lagarto, cocodrilo, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.11 Suela de cuero</td>
                      <td className="text-left">
                        Es el cuero curtido para suela. Este artículo debe denominarse invariablemente suela de cuero, pudiendo además especificarse la especie de la cual proceda.
                      </td>
                    </tr>
                    <tr>
                      <td>3.2 Materiales sintéticos o artificiales</td>
                      <td className="text-left">
                        Para los materiales plásticos, sintéticos o textiles con apariencia de piel o cuero, éstos pueden denominarse como tales; pudiendo además denominarse por su nombre específico: Piroxilina, poliuretano, polietileno, vinilo o vinílico, policloruro de vinilo(PVC) y acrilo-nitrilo-butadieno-estireno (ABS), poliamida (nylon), poliéster, etil-vynil-acetato (EVA), etc. Queda prohibido emplearlos términos piel o cuero para designar estos materiales.
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 28,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          e) Cuando los artículos a que se refiere esta Norma se comercialicen como pares, la información podrá presentarse en una o ambas piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 29,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          Las pieles o cueros curtidos
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 30,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          a) Únicamente deben marcarse en un lugar visible de su embalaje, pudiendo hacerlo mediante etiqueta adherida de acuerdo a las definiciones establecidas en el capítulo 3. A elección del fabricante, puede anteponerse la palabra piel o cuero y/o nombre genérico o específico como se muestra a continuación:
          <br />- Los genéricos: vacuno, equino, caprino u otros.
          <br />- Los específicos: becerro, ternera, cabra, tiburón u otros.
          <br />- Los dos términos, genérico y específico, como se muestra a continuación:
          <br />* vacuno; piel de becerro.
          <br />* Ovino; piel de cordero.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 31,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          b) Si se usan los términos de acabado como ante y gamuza, deben indicarse en el mismo tipo de tamaño de letra, primeramente el nombre específico del animal del que proviene y a continuación la palabra acabado. Ejemplos:
          <br />Ternera acabado ante.
          <br />Ternera acabado gamuza.
          <br />Carnaza acabado gamuza.
          <br />Carnaza acabado ante.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 32,
      isATitle: true,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          Etiquetado y/o marcado de materiales artificiales o sintéticos
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 33,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          Los materiales terminados con apariencia de piel, cuando se encuentren enrollados se pueden marcar colocando una etiqueta dentro de la bobina y cuando estén en forma de plancha u hojas se deben marcar por medio de etiquetas adheridas o colgadas firmemente. Dichos materiales deben estar marcados con impresión o etiqueta en forma legible y en lugar visible, ostentando una leyenda en español con los datos que se indican en el presente capítulo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 34,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Todos los materiales y productos terminados objeto de esta Norma, deben marcarse en idioma español en forma permanente y lugar visible con la información siguiente:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 35,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social completo o abreviado del fabricante nacional o importador o su marca registrada.
          <table className="table-content__inspector-table" id='table-on-site-1-020-9'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 36,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          <table className="table-content__inspector-table" id='table-on-site-2-020-10'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el país de origen del pedimento con el de la etiqueta?</td>
                      <td>
                        <p id='question-50-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-50-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 37,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          c) Definición genérica o específica de los materiales y opcionalmente su acabado.
          <br />(Esta información puede ser ostentada en una etiqueta).
          <table className="table-content__inspector-table" id='table-on-site-2'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td rowSpan={14}>3.1 Acabado - Se entiende por acabado, el proceso que se le da a una piel curtida para cambiar su aspecto original.</td>
                      <td className="text-left">
                        a) Ante: Es el tacto aterciopelado por el lado de la carne que se le da a una piel de cabra, borrego, becerro, etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Badana: Es el curtido vegetal que se le da a una piel de borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Buck: Es el tacto afelpado por el lado de la flor que se da a cualquier piel.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Cardado: Es la preparación que se le da a las fibras de lana, en la piel de borrego curtida.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        e) Charol: Es el barniz adherente que se le da a cualquier piel, incluso materiales artificiales o regenerados
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        f) Corrugado: Es el aspecto arrugado que se le da a una piel mediante un proceso químico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        g) Gamuza: Es el tacto suave y afelpado por el lado de la carne que se le da a una piel o carnaza de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        h) Glasé: Es el lustre que se le da a cualquier piel de cabra o borrego
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        i) Grabado: Es el aspecto que se le da por el lado de la carne o la flor, por medio de un prensado mecánico a una piel, carnaza, cuero, material artificial o regenerado
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        j) Hunting: Es el tacto afelpado por el lado de la carne que se le da a una piel de animal bovino o vacuno.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        k) Marroquín: Es el graneado químico o manual por el lado de la flor que se le da a una piel de cabra.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        l) Nappa: Es la textura especialmente suave que se le da a una piel de cabra, borrego o bovino
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        m) Romaneado: Es el aspecto arrugado que se le da a una piel mediante un proceso físico.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        n) Transfer o folia: Es el proceso de recubrir la carnaza con película sintética para darle apariencia de flor (action leather).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Piel de animal bovino o vacuno</td>
                      <td className="text-left">
                        a) Becerro.- Es la piel elaborada, procedente de animales menores de un año, cuya superficie total es de 55 dm2 a 150 dm2(piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Becerro nonato.- Es la piel entera que proviene del animal en desarrollo, aun en el vientre, cuya superficie total es menor de70 dm2 (piel entera).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        c) Res.- Es la piel elaborada procedente de animales mayores de dos años, cuya superficie es de 150 dm2 en adelante (lado).
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        d) Ternera.- Es la piel elaborada procedente de animales de un año hasta de dos años, cuya superficie es de 90 dm2 a 150dm2 (lado).
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.5 Piel de animal caprino</td>
                      <td className="text-left">
                        a) Cabra.- Es la piel que proviene de animales de esta especie de más de un mes de nacido y cuya superficie total es mayorde 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cabrito.- Es la piel que proviene de esta especie hasta de un mes de nacido, cuya superficie es de hasta 24 dm2.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.6 Piel de animal equino</td>
                      <td className="text-left">
                        La que pertenece al caballo, mulo y asno
                        <br />
                        <br />
                        a) Anca de potro.- Es la piel que corresponde a la región del anca de esta especie. Este término puede utilizarse opcionalmente ya que expresa la zona y calidad del producto y puede sustituir al término "piel de equino".
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>3.5.7 Piel de animal ovino</td>
                      <td className="text-left">
                        a) Borrego.- Es la piel del animal de esta especie mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        b) Cordero.- Es la piel del animal de esta especie no mayor de un año de edad.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.8 Piel de animal marino</td>
                      <td className="text-left">
                        La que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.10 Piel de reptil</td>
                      <td className="text-left">
                        La que proviene de animales como víbora o serpiente, iguana, lagarto, cocodrilo, etc.
                      </td>
                    </tr>
                    <tr>
                      <td>3.5.11 Suela de cuero</td>
                      <td className="text-left">
                        Es el cuero curtido para suela. Este artículo debe denominarse invariablemente suela de cuero, pudiendo además especificarse la especie de la cual proceda.
                      </td>
                    </tr>
                    <tr>
                      <td>3.2 Materiales sintéticos o artificiales</td>
                      <td className="text-left">
                        Para los materiales plásticos, sintéticos o textiles con apariencia de piel o cuero, éstos pueden denominarse como tales; pudiendo además denominarse por su nombre específico: Piroxilina, poliuretano, polietileno, vinilo o vinílico, policloruro de vinilo(PVC) y acrilo-nitrilo-butadieno-estireno (ABS), poliamida (nylon), poliéster, etil-vynil-acetato (EVA), etc. Queda prohibido emplearlos términos piel o cuero para designar estos materiales.
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 38,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          e) Cuando los artículos a que se refiere esta Norma se comercialicen como pares, la información podrá presentarse en una o ambas piezas.
        </p>
      )
    }
  ]
};

export default {
  DC: list,
  CC: {
    ...list,
    hasSamplingTable: false
  },
  REV: {
    ...list,
    hasSamplingTable: false
  }
};
