/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Link, useLocation } from 'react-router-dom';

import {
  File,
  FileInput,
  Icon,
  Input,
  Modal,
  ModalView,
  Select
} from '../../../../components';
import Storage from '../../../../storage';
import roleDictionaryEn from '../../../../dictionaries/roleDictionary-en';
import roleDictionaryEs from '../../../../dictionaries/roleDictionary-es';

import './styles.scss';
import {
  Collaborator,
  FileInputData,
  FileToSign,
  MainMenuOption
} from '../../../../interfaces';
import { useResource } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';
import { utils } from '../../../../helpers';

interface Props {
  menu: MainMenuOption[]
}

export const MainMenu: React.FC<Props> = (props) => {
  const { menu } = props;

  const {
    fetchResource: getCollaborator
  } = useResource<Collaborator>();

  const {
    createResource
  } = useResource<any>();

  const {
    userRole,
    userName,
    apiType,
    themeMode,
    setThemeMode,
    setMenuOpen,
    menuOpen,
    soundEffects,
    setSoundEffects,
    notifications,
    approver,
    setOpenModal,
    manager
  } = useContext(AppContext);

  const version = 'v1.27.41';

  const { i18n, t } = useTranslation();

  // eslint-disable-next-line max-len
  const menuItems = menu.filter((item: MainMenuOption) => {
    return (item.roles.includes(userRole)
      && (item.approver && ['inspector'].includes(userRole) ? approver === item.approver : true)
      && (item.manager !== undefined && ['master', 'inspector'].includes(userRole) ? item.manager === manager : true)
      && (item.childOf === undefined));
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [footerOpen, setFooterOpen] = useState<boolean>(false);
  const [dropDownOpen, setDropDownOpen] = useState<string>('');
  const [optionHeight, setOptionHeight] = useState<number>(0);
  const [clientBusinessName, setClientBusinessName] = useState<string>('');
  const [currentElementIndex, setCurrentElementIndex] = useState<number>(Number(Storage.get('mainMenuSelectedIndex')) || 0);
  const [
    showReportBugModal,
    setShowReportBugModal] = useState<boolean>(false);
  const [bugComment, setBugComment] = useState<string>('');
  const [fileData, setFileData] = useState<FileInputData>({
    data: '',
    fileName: ''
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});
  // eslint-disable-next-line no-undef
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    // eslint-disable-next-line no-undef
    setWindowWidth(window.innerWidth);
  };

  const location = useLocation();

  const getIndexOfMenuItem = (path: string) => {
    let indexItem: number = 0;

    for (let index = 0; index < menuItems.length; index += 1) {
      if (menuItems[index].viewPath.includes(path)) {
        indexItem = index;
      }
    }

    return indexItem;
  };

  useEffect(() => {
    if (location.pathname.includes('/tasks/')) {
      onSelectOption(getIndexOfMenuItem('/tasks/'));
    }

    if (location.pathname.includes('/dictum-request/')) {
      onSelectOption(getIndexOfMenuItem('/dictum-request/'));
    }

    if (location.pathname.includes('/constancy-request/')) {
      onSelectOption(getIndexOfMenuItem('/constancy-request/'));
    }

    if (location.pathname.includes('/se-procedures/')) {
      onSelectOption(getIndexOfMenuItem('/se-procedures/'));
    }

    if (location.pathname.includes('/clients')) {
      onSelectOption(getIndexOfMenuItem('/clients'));
    }

    if (location.pathname.includes('/users')) {
      onSelectOption(getIndexOfMenuItem('/users'));
    }

    if (location.pathname.includes('/reports')) {
      onSelectOption(getIndexOfMenuItem('/reports'));
    }

    if (location.pathname.includes('/notifications')) {
      onSelectOption(getIndexOfMenuItem('/notifications'));
    }

    if (location.pathname.includes('/profile')) {
      onSelectOption(getIndexOfMenuItem('/profile'));
    }
  }, [location]);

  // eslint-disable-next-line no-undef
  let selectedComponent = document.getElementById('selector');

  const onSelectOption = (index: number) => {
    setCurrentElementIndex(index);

    if (selectedComponent) {
      // eslint-disable-next-line no-undef
      const option1 = document.getElementById('option-0');
      // eslint-disable-next-line no-undef
      const option2 = document.getElementById('option-1');

      if (option1 && option2) {
        selectedComponent.style.top = `${optionHeight * index}px`;
      }
    }
  };

  const setRole = (role: string | null) => {
    if (role) {
      if (approver && role === 'inspector') {
        return i18n.language === 'es' ? (roleDictionaryEs as any).inspectorApprover : (roleDictionaryEn as any).inspectorApprover || 'Desconocido';
      }

      return i18n.language === 'es' ? (roleDictionaryEs as any)[role] : (roleDictionaryEn as any)[role] || 'Desconocido';
    }
    return 'Desconocido';
  };

  const handleChangeLanguaje = () => {
    i18n.changeLanguage(i18n.language === 'es' ? 'en' : 'es');
  };

  // eslint-disable-next-line no-unused-vars
  const setNumberNotifications = (route: string): string => {
    let total: number = 0;

    if (route.includes('/notifications')) {
      if (notifications.notifications.length) {
        for (let index = 0; index < notifications.notifications.length; index += 1) {
          if (notifications.notifications[index].alert) {
            total += 1;
          }
        }
      }
    }

    if (total <= 0) {
      return '';
    }

    if (total > 99) {
      return '+99';
    }

    return `${total}`;
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', handleResize);

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line no-undef
  }, [window.innerWidth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    selectedComponent = document.getElementById('selector');

    // eslint-disable-next-line no-undef
    const option1 = document.getElementById('option-0');
    // eslint-disable-next-line no-undef
    const option2 = document.getElementById('option-1');

    if (option1 && option2) {
      setOptionHeight(Math.trunc(
        option2?.getBoundingClientRect().top - option1?.getBoundingClientRect().top
      ));
    }
  }, [windowWidth]);

  useEffect(() => {
    if (userRole === 'collaborator') {
      onSelectOption(3);
    } else {
      onSelectOption(Number(Storage.get('mainMenuSelectedIndex')) || 0);
    }
  }, [apiType, optionHeight]);

  useEffect(() => {
    Storage.set('mainMenuSelectedIndex', currentElementIndex);
  }, [currentElementIndex]);

  useEffect(() => {
    setCurrentElementIndex(Number(Storage.get('mainMenuSelectedIndex')) || 0);
  }, []);

  useEffect(() => {
    if (userRole === 'collaborator' && !clientBusinessName && apiType === 'public') {
      getCollaborator(
        '/public/collaborators/me',
        (data) => {
          setClientBusinessName(data.client.businessName);
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );
    }
  }, [apiType]);

  const uploadBugComment = () => {
    if (bugComment.length > 0) {
      const paths: FileToSign[] = [];

      if (fileData.fileName !== '') {
        paths.push({
          path: fileData.fileName,
          type: fileData.data.type,
          documentName: utils.deleteDotsOnFileName(fileData.fileName)
        });
      }

      createResource(
        '/admin/errors/bug-comment',
        {
          comment: bugComment,
          filePaths: paths,
          routeOnApp: location.pathname
        },
        // eslint-disable-next-line consistent-return
        async (data) => {
          setErrors({});
          setShowReportBugModal(false);

          if (data.length > 0 && Array.isArray(data)) {
            Modal.fireLoading(undefined, 0);
            try {
              const formData = new FormData();

              Object.entries(data[0].signedURL.fields).forEach(([key, value]) => {
                formData.append(key, value as string);
              });

              formData.append('file', fileData.data);

              await axios.post(
                data[0].signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              );

              Modal.close();
              setBugComment('');
              setFileData({
                data: '',
                fileName: ''
              });
              Modal.fireSuccess(
                t('global.correct'),
                t('global.bugCommentSaved'),
                setOpenModal,
                undefined,
                soundEffects
              );
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              Modal.fireError(t('global.errorUploadingFile'), setOpenModal, soundEffects);
            }
          } else {
            setBugComment('');
            setFileData({
              data: '',
              fileName: ''
            });
            Modal.fireSuccess(
              t('global.correct'),
              t('global.bugCommentSaved'),
              setOpenModal,
              undefined,
              soundEffects
            );
          }
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    } else {
      setErrors({
        commentReview: t('inspections.bugMessageError')
      });
    }
  };

  const renderMenuItem = (item: MainMenuOption, index: number) => {
    if (item.target === '_blank') {
      return (<a
        href={item.viewPath}
        key={index}
        onClick={() => {
          setMobileMenuOpen(false);
          onSelectOption(index);
          setDropDownOpen('');
        }}
        className={`main-menu__options-container__option main-menu__options-container__option--${menuOpen ? '' : 'closed'}`}
        id={`option-${index}`}
        target='_blank'
      >
        <Icon type={item.icon} className='main-menu__options-container__option__icon' />
        <p>{item.label}</p>
      </a>);
    }

    const haveChildrens = menu.find(menuItem => menuItem.childOf === item.id) !== undefined;

    if (haveChildrens) {
      return (<div
        key={index}
        onClick={() => {
          onSelectOption(index);

          if (dropDownOpen === '') {
            setDropDownOpen(item.id);
            setMenuOpen(true);
          } else if (dropDownOpen === item.id) {
            setDropDownOpen('');
          } else {
            setDropDownOpen(item.id);
          }
        }}
        className={`main-menu__options-container__option main-menu__options-container__option--with-childrens main-menu__options-container__option--with-childrens-${menu.filter(menuItem => menuItem.childOf === dropDownOpen && menuItem.childOf === item.id && menuItem.roles.includes(userRole)
          && (menuItem.approver !== undefined && ['inspector'].includes(userRole) ? approver === menuItem.approver : true)
          && (menuItem.manager !== undefined && ['master', 'inspector'].includes(userRole) ? menuItem.manager === manager : true)).length} main-menu__options-container__option--${menuOpen ? '' : 'closed'}`}
        id={`option-${index}`}
      >
        <div className='main-menu__options-container__option__container-full'>
          <div className='main-menu__options-container__option__container'>
            <Icon type={item.icon} className='main-menu__options-container__option__icon' />
            <p className={`main-menu__options-container__option__label main-menu__options-container__option__label--${menuOpen ? '' : 'closed'}`}>{item.label}</p>
          </div>
          <Icon type='downArrowWhite' className={`main-menu__options-container__option__arrow-icon main-menu__options-container__option__arrow-icon--${dropDownOpen === item.id ? 'open' : ''}`} />
        </div>
        <div className='main-menu__options-container__option__container-full-children'>
          {
            menu.filter(menuItem => menuItem.childOf === item.id) !== undefined && (
              <>
                {
                  menu.filter(menuItem => menuItem.childOf === item.id && menuItem.roles.includes(userRole)
                    && (menuItem.approver !== undefined && ['inspector'].includes(userRole) ? approver === menuItem.approver : true)
                    && (menuItem.manager !== undefined && ['master', 'inspector'].includes(userRole) ? menuItem.manager === manager : true)).map((menuChildren) => (
                      <Link
                        to={menuChildren.viewPath}
                        key={index}
                        onClick={() => {
                          setMobileMenuOpen(false);
                          onSelectOption(index);
                        }}
                        className={`main-menu__options-container__option main-menu__options-container__option--${menuOpen ? '' : 'closed'}`}
                        id={`option-${index}`}
                      >
                        <div className='main-menu__options-container__option__container'>
                          <Icon type={menuChildren.icon} className='main-menu__options-container__option__icon' />
                          <p className={`main-menu__options-container__option__label main-menu__options-container__option__label--${menuOpen ? '' : 'closed'}`}>{menuChildren.label}</p>
                        </div>
                        {
                          setNumberNotifications(menuChildren.viewPath) !== '' && (
                            <div className='main-menu__options-container__option__alert-dot'>{setNumberNotifications(menuChildren.viewPath)}</div>
                          )
                        }
                      </Link>
                      // eslint-disable-next-line indent
                    ))
                }
              </>
            )
          }
        </div>
        {
          setNumberNotifications(item.viewPath) !== '' && (
            <div className='main-menu__options-container__option__alert-dot'>{setNumberNotifications(item.viewPath)}</div>
          )
        }
      </div >);
    }

    return (<Link
      to={item.viewPath}
      key={index}
      onClick={() => {
        setMobileMenuOpen(false);
        onSelectOption(index);
        setDropDownOpen('');
      }}
      className={`main-menu__options-container__option main-menu__options-container__option--${menuOpen ? '' : 'closed'}`}
      id={`option-${index}`}
    >
      <div className='main-menu__options-container__option__container'>
        <Icon type={item.icon} className='main-menu__options-container__option__icon' />
        <p className={`main-menu__options-container__option__label main-menu__options-container__option__label--${menuOpen ? '' : 'closed'}`}>{item.label}</p>
      </div>
      {
        setNumberNotifications(item.viewPath) !== '' && (
          <div className='main-menu__options-container__option__alert-dot'>{setNumberNotifications(item.viewPath)}</div>
        )
      }
    </Link>);
  };

  return (
    <div className={`main-menu main-menu--${menuOpen ? '' : 'desktop-close'}`} id='main-menu'>
      <ModalView
        ableFuncionalityEnterToConfirm={false}
        visible={showReportBugModal}
        onClose={() => {
          setShowReportBugModal(false);
          setErrors({});
        }}
        fullSzie={false}
        onConfirm={uploadBugComment}
        customComponent={
          <div className='bug-modal'>
            <span>{t('inspections.bugMessage')}</span>
            <Input
              type={'textarea'}
              value={bugComment}
              hasError={!!errors.commentReview}
              helperText={errors.commentReview}
              onChange={(value: string | number) => {
                setBugComment(`${value}`);
              }}
              makeValidations={false}
              maxLength={2000}
            />
            {
              fileData.fileName === ''
                ? (<FileInput
                  onSelectFile={(file: FileInputData[]) => {
                    // eslint-disable-next-line no-console
                    console.log(file);
                    if (file[0] && file[0].fileName) {
                      setFileData(file[0]);
                    }
                  }}
                  acceptedFileFormats={'.jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.svg,.SVG'}
                  isMultiple={false}
                />) : (
                  <File
                    name={fileData.fileName}
                    onDelete={() => setFileData({
                      data: '',
                      fileName: ''
                    })}
                  />
                )
            }
          </div>
        }
      />
      <div className='main-menu__arrow-button' onClick={() => { setMenuOpen(!menuOpen); setFooterOpen(false); setDropDownOpen(''); }}>
        <Icon
          type={menuOpen ? 'leftArrow' : 'rightArrow'}
        />
      </div>
      <Icon
        type='hamburguerButton'
        alt='Hamburguer Button'
        className='main-menu__hamburguer-button'
        onClick={() => { setMobileMenuOpen(true); setMenuOpen(true); }}
      />
      <div className={`main-menu__mobile-background main-menu__mobile-background--${mobileMenuOpen ? '' : 'close'}`} onClick={() => setMobileMenuOpen(false)}></div>
      <div className={`main-menu__menu-container main-menu__menu-container--${mobileMenuOpen ? '' : 'close'}`}>
        <div className='main-menu__close-button-container'>
          <Icon
            type='closeWhite'
            alt='Close Button'
            className='main-menu__close-button-container__close-button'
            onClick={() => setMobileMenuOpen(false)}
          />
        </div>
        {
          menuOpen ? (
            <>
              <Icon type='CECSAlogo' alt='CECSA Logo' className='main-menu__icon' />
              <div className='main-menu__divider'></div>
              <p className='main-menu__user-name'>{userName}</p>
              <p className='main-menu__user-information'>{setRole(userRole)}</p>
              {
                clientBusinessName && (
                  <p className='main-menu__client-short-name'>{clientBusinessName}</p>
                )
              }
            </>
          ) : (
            <div className='main-menu__spacer-top'></div>
          )
        }
        <div className='main-menu__options-container'>
          <div className={`main-menu__options-container__selector main-menu__options-container__selector--${menuOpen ? '' : 'closed'}`} id='selector'></div>
          {
            menuItems.map((item: MainMenuOption, index: number) => (
              renderMenuItem(item, index)
            ))
          }
        </div>
        <div className={`main-menu__footer main-menu__footer${footerOpen ? '--open' : ''}`} id='main-menu-footer'>
          <div className='main-menu__footer__icon-container' onClick={() => { setFooterOpen(!footerOpen); setMenuOpen(true); }}>
            <Icon type='downArrowWhite' className={`main-menu__footer__icon main-menu__footer__icon${footerOpen ? '--open' : ''}`} />
          </div>
          <div className='main-menu__bug-report' onClick={() => setShowReportBugModal(true)}>
            <Icon type='bug' className='main-menu__bug-report__icon' />
            <p>{t('global.repotBug')}</p>
          </div>
          <div className='main-menu__languaje'>
            <Select
              backgroundClear={true}
              onChange={(_value: string) => {
                if (_value === 'es' || _value === 'en') {
                  i18n.changeLanguage(_value);
                }
              }}
              value={i18n.language}
              id='languaje'
              options={[
                {
                  text: 'English',
                  value: 'en'
                },
                {
                  text: 'Español',
                  value: 'es'
                }
              ]}
            />
            <Icon type='languaje' onClick={handleChangeLanguaje} className='main-menu__languaje__icon' />
          </div>
          <div className='main-menu__sound'>
            <p>{t('global.soundEffects')}</p>
            <Icon type={soundEffects ? 'sound' : 'notSound'} onClick={() => setSoundEffects(!soundEffects)} className='main-menu__sound__icon' />
          </div>
          <div className='main-menu__theme-mode-switch' onClick={() => setThemeMode(themeMode === 'light' ? 'dark' : 'light')}>
            <p>{t('global.theme')} {themeMode === 'dark' ? t('global.themeDark') : t('global.themeLight')}</p>
            <div className='main-menu__theme-mode-switch__button'>
              <Icon type={themeMode === 'dark' ? 'darkMode' : 'lightMode'} className={`main-menu__theme-mode-switch__button__icon main-menu__theme-mode-switch__button__icon--${themeMode}`} />
            </div>
          </div>
          <p className='main-menu__version'>{version}</p>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
