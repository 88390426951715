import i18next from 'i18next';
import {
  NormCodes,
  NormFases
} from '../../../../../../../../interfaces';

export interface ProductRow {
  normCode: string
  fase: number
  labelingMode: string
  tariffFraction: string
  umc: number
  umcQuantity: number
  labelsToPut: number
  modelsQuantity: number
  codes: string
  codes2: string
  brand: string
  description: string
}

interface RowValidation { isValid: boolean, errors: { [name: string]: string } }
interface CellValidation { isValid: boolean, errors: { [name: string]: string }, value: string }
type ValitadionsColumns = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I'

const isNormValid = (normCode: string): boolean => {
  if (normCode.length === 0) {
    return false;
  }

  switch (normCode) {
    case '004':
    // case '003':
    // case '03':
    // case '3':
    // eslint-disable-next-line no-fallthrough
    case '050':
    case '051':
    case '4':
    case '04':
    case '50':
    case '51':
    case '141':
    case '142':
    case '189':
    case '015':
    case '024':
    case '15':
    case '24':
    case '020':
    case '20':
    case '173':
    case '187':
    case '235':
      return true;
    default:
      return false;
  }
};

export default {
  validateProductRow: (product: ProductRow, rowNumber: number): RowValidation => {
    let isValid = true;
    const errors: { [name: string]: string } = {};

    if (!isNormValid(product.normCode)) {
      isValid = false;
      errors[`A${rowNumber}`] = `${i18next.t('services.errors.invalidNorm')} '${product.normCode}'`;
    }

    if (['051', '51', '173', '235', '187'].includes(product.normCode) && !product.fase) {
      isValid = false;
      errors[`B${rowNumber}`] = 'Fase vacía, ingresa un número válido';
    }

    if (['051', '51', '173', '235', '187'].includes(product.normCode) && (product.fase < 2 || product.fase > 3)) {
      isValid = false;
      errors[`B${rowNumber}`] = 'Fase no válida, ingresa un número válido';
    }

    if (!isNormValid(product.normCode)) {
      isValid = false;
      errors[`B${rowNumber}`] = 'Revise la Fase y seleccione un valor si la norma es la NOM 051, 187, 173 ó 235';
    }

    if (product.brand.length === 0) {
      isValid = false;
      errors[`C${rowNumber}`] = 'Marca vacía';
    }

    if (product.description.length === 0) {
      isValid = false;
      errors[`D${rowNumber}`] = 'Descripción vacía';
    }

    if (product.tariffFraction.toString().length !== 8) {
      isValid = false;
      errors[`E${rowNumber}`] = 'Fracción arancelaria no es de 8 dígitos';
    }

    if (product.modelsQuantity < 1) {
      isValid = false;
      errors[`F${rowNumber}`] = 'Cantidad de modelos incorrecto, ingresa un número positivo válido';
    }

    if (product.modelsQuantity !== product.codes.split(',').length || product.codes.length === 0) {
      isValid = false;
      errors[`F${rowNumber}`] = 'Cantidad de modelos no es el mismo a la cantidad de códigos';
      errors[`G${rowNumber}`] = `Cantidad de modelos no es el mismo a la cantidad de códigos, hay ${product.codes.split(',').length} códigos`;
    }

    if (product.codes2.length > 0
      && product.codes2.split(',').length > 0
      && product.codes2.split(',').length !== product.codes.split(',').length) {
      isValid = false;
      errors[`H${rowNumber}`] = 'Cantidad de modelo 2 no es el mismo a la cantidad de modelos';
    }

    if (product.umc < 1 || product.umc > 21) {
      isValid = false;
      errors[`I${rowNumber}`] = 'UMC debe ser mayor a 0 y menor a 21';
    }

    if (product.umcQuantity < 0) {
      isValid = false;
      errors[`J${rowNumber}`] = 'Cantidad de UMC debe ser mayor a 0';
    }

    if (product.labelsToPut < 1) {
      isValid = false;
      errors[`K${rowNumber}`] = 'Cantidad de piezas a etiquetar debe ser mayor a 0';
    }

    const auxCodes: string[] = product.codes.split(',');

    for (let index = 0; index < auxCodes.length; index += 1) {
      if (auxCodes[index].trim().length === 0) {
        isValid = false;
        errors[`G${rowNumber}`] = 'Uno o varios códigos separado por comas estan vacíos';
      }
    }

    if (product.codes2.length > 0) {
      const auxCodes2: string[] = product.codes2.split(',');

      for (let index = 0; index < auxCodes2.length; index += 1) {
        if (auxCodes2[index].trim().length === 0) {
          isValid = false;
          errors[`H${rowNumber}`] = 'Uno o varios códigos separado por comas estan vacíos';
        }
      }
    }

    if (product.codes.length === 0) {
      isValid = false;
      errors[`G${rowNumber}`] = 'Código vacío';
    }

    if (product.labelingMode !== 'PB' && product.labelingMode !== 'PA') {
      isValid = false;
      errors[`L${rowNumber}`] = `Modalidad de etiquetado no válida, se ingresó: '${product.labelingMode}'`;
    }

    return {
      isValid,
      errors
    };
  },
  validateProductCell: (id: string): CellValidation => {
    let isValid = true;
    const errors: { [name: string]: string } = {};
    const type: ValitadionsColumns = id.substring(0, 1) as ValitadionsColumns;

    // eslint-disable-next-line no-undef
    const element: HTMLInputElement | null = document.querySelector(`#${id}`);

    let value: string = '';
    let valueNormCode: string = '';

    if (element) {
      value = element.value;
      element.classList.remove('excel-table__input--error');
    }

    if (type === 'B') {
      // eslint-disable-next-line no-undef
      const elementNormCode: HTMLInputElement | null = document.querySelector(`#A${id.substring(1)}`);

      if (elementNormCode) {
        valueNormCode = elementNormCode.value;
      }
    }

    const validations = {
      A: () => {
        if (!isNormValid(value)) {
          isValid = false;
          errors[id] = `${i18next.t('services.errors.invalidNorm')} '${value}'`;
        }
      },
      B: () => {
        if (['051', '51', '173', '235', '187'].includes(valueNormCode) && value === 'N/A') {
          isValid = false;
          errors[id] = 'Fase vacía';
        }
      },
      C: () => {
        if (value.length <= 0) {
          isValid = false;
          errors[id] = 'Marca vacía';
        }
      },
      D: () => {
        if (value.length <= 0) {
          isValid = false;
          errors[id] = 'Descripción vacía';
        }
      },
      E: () => {
        if (value.length !== 8) {
          isValid = false;
          errors[id] = 'Fracción arancelaria no es de 8 dígitos';
        }
      },
      F: () => {
        if (Number(value) < 1 || value.length === 0) {
          isValid = false;
          errors[id] = 'Cantidad de modelos vacío o inválido (sólo se permiten número mayores a 1)';
        }
      },
      G: () => {
        // eslint-disable-next-line no-undef
        const elementQuantityModels: HTMLInputElement | null = document.querySelector(`#F${id.substring(1)}`);

        if (elementQuantityModels) {
          if (Number(elementQuantityModels.value) !== value.split(',').length || value.length === 0) {
            isValid = false;
            errors[`G${id.substring(1)}`] = 'Cantidad de modelos no es el mismo a la cantidad de códigos';
            errors[id] = 'Cantidad de modelos no es el mismo a la cantidad de códigos';
          }
        }

        const auxCodes: string[] = value.split(',');

        for (let index = 0; index < auxCodes.length; index += 1) {
          if (auxCodes[index].trim().length === 0) {
            isValid = false;
            errors[id] = 'Uno o varios códigos separado por comas estan vacíos';
          }
        }

        if (value.length <= 0) {
          isValid = false;
          errors[id] = 'Código vacío';
        }
      },
      H: () => {
        // eslint-disable-next-line no-undef
        const elementQuantityModels: HTMLInputElement | null = document.querySelector(`#G${id.substring(1)}`);
        // eslint-disable-next-line no-undef
        const elementQuantityModels2: HTMLInputElement | null = document.querySelector(`#H${id.substring(1)}`);

        if (elementQuantityModels && elementQuantityModels2) {
          if (elementQuantityModels.value.split(',').length !== elementQuantityModels2.value.split(',').length) {
            isValid = false;
            errors[`H${id.substring(1)}`] = 'Cantidad de modelo 2 no es el mismo a la cantidad de modelos';
          }
        }

        const auxCodes: string[] = value.split(',');

        for (let index = 0; index < auxCodes.length; index += 1) {
          if (auxCodes[index].trim().length === 0) {
            isValid = false;
            errors[id] = 'Uno o varios códigos separado por comas estan vacíos';
          }
        }
      },
      I: () => {
        if (Number(value) < 1 || Number(value) > 21) {
          isValid = false;
          errors[id] = 'UMC debe ser mayor a 0 y menor a 21';
        }
      },
      J: () => {
        if (Number(value) < 0) {
          isValid = false;
          errors[id] = 'Cantidad de UMC debe ser mayor a 0';
        }
      },
      K: () => {
        if (Number(value) < 1) {
          isValid = false;
          errors[id] = 'Cantidad de piezas a etiquetar debe ser mayor a 0';
        }
      },
      L: () => {
        if (value !== 'PB' && value !== 'PA') {
          isValid = false;
          errors[id] = `Modalidad de etiquetado no válida, se ingresó: '${value}'`;
        }
      },
      presentation: () => { }
    };

    validations[type]();

    return {
      isValid,
      errors,
      value
    };
  },
  isAnEmptyRow: (product: ProductRow): boolean => {
    if (
      product.fase <= -1
      && product.umc <= -1
      && product.umcQuantity <= -1
      && product.labelsToPut <= -1
    ) {
      return true;
    }

    return false;
  },
  parseNorm051Fase: (norm: NormCodes, fase: number): NormFases => {
    if (!['051', '51', '173', '235', '187'].includes(norm)) {
      return 'N/A';
    }

    switch (fase) {
      case 2:
        return 'II';
      case 3:
        return 'III';
      default:
        return 'N/A';
    }
  },
  parseNormCode: (normCode: string): NormCodes => {
    switch (normCode) {
      case '3':
      case '03':
      case '003':
        return '003';
      case '4':
      case '04':
      case '004':
        return '004';
      case '50':
      case '050':
        return '050';
      case '51':
      case '051':
        return '051';
      case '141':
        return '141';
      case '142':
        return '142';
      case '189':
        return '189';
      case '15':
      case '015':
        return '015';
      case '24':
      case '024':
        return '024';
      case '20':
      case '020':
        return '020';
      case '173':
        return '173';
      case '187':
        return '187';
      case '235':
        return '235';
      default:
        return '051';
    }
  },
  formatFase: (value: string): number => {
    const auxValue = value.toLocaleUpperCase();

    const traductionsFase: { [name: string]: number } = {
      I: 1,
      II: 2,
      III: 3,
      1: 1,
      2: 2,
      3: 3
    };

    return traductionsFase[auxValue] || -1;
  },
  formatNOM: (value: string): string => {
    let result = value;

    if (value.indexOf('NOM-003') !== -1) {
      result = '003';
    }

    if (value.indexOf('NOM-004') !== -1) {
      result = '004';
    }

    if (value.indexOf('NOM-141') !== -1) {
      result = '141';
    }

    if (value.indexOf('NOM-142') !== -1) {
      result = '142';
    }

    if (value.indexOf('NOM-050') !== -1) {
      result = '050';
    }

    if (value.indexOf('NOM-051') !== -1) {
      result = '051';
    }

    if (value.indexOf('NOM-189') !== -1) {
      result = '189';
    }

    if (value.indexOf('NOM-024') !== -1) {
      result = '024';
    }

    if (value.indexOf('NOM-015') !== -1) {
      result = '015';
    }

    if (value.indexOf('NOM-020') !== -1) {
      result = '020';
    }

    if (value.indexOf('NOM-173') !== -1) {
      result = '173';
    }

    if (value.indexOf('NOM-187') !== -1) {
      result = '187';
    }

    if (value.indexOf('NOM-235') !== -1) {
      result = '235';
    }

    return result;
  }
};
