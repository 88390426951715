import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BubbleElements,
  Button,
  Input,
  Select,
  Separator,
  Title
} from '../..';
import { emptyDictumProduct } from '../../../emptyObjects';
import { validate } from '../../../helpers';
import { useKeyPress } from '../../../hooks';
import {
  DictumProduct,
  DictumProductInput,
  DictumProductWithCodes,
  NormCodes
} from '../../../interfaces';

import './styles.scss';
import { AppContext } from '../../../context/AppContext';

const labelingModeOptions = [
  {
    text: 'PA',
    value: 'PA'
  },
  {
    text: 'PB',
    value: 'PB'
  }
];

const normOptions = [
  // {
  //   text: '003',
  //   value: '003'
  // },
  {
    text: '004',
    value: '004'
  },
  {
    text: '015',
    value: '015'
  },
  {
    text: '020',
    value: '020'
  },
  {
    text: '024',
    value: '024'
  },
  {
    text: '050',
    value: '050'
  },
  {
    text: '051',
    value: '051'
  },
  {
    text: '141',
    value: '141'
  },
  {
    text: '142',
    value: '142'
  },
  {
    text: '173',
    value: '173'
  },
  {
    text: '187',
    value: '187'
  },
  {
    text: '189',
    value: '189'
  },
  {
    text: '235',
    value: '235'
  }
];

const umcOptions = [
  {
    text: '1',
    value: '1'
  },
  {
    text: '2',
    value: '2'
  },
  {
    text: '3',
    value: '3'
  },
  {
    text: '4',
    value: '4'
  },
  {
    text: '5',
    value: '5'
  },
  {
    text: '6',
    value: '6'
  },
  {
    text: '7',
    value: '7'
  },
  {
    text: '8',
    value: '8'
  },
  {
    text: '9',
    value: '9'
  },
  {
    text: '10',
    value: '10'
  },
  {
    text: '11',
    value: '11'
  },
  {
    text: '12',
    value: '12'
  },
  {
    text: '13',
    value: '13'
  },
  {
    text: '14',
    value: '14'
  },
  {
    text: '15',
    value: '15'
  },
  {
    text: '16',
    value: '16'
  },
  {
    text: '17',
    value: '17'
  },
  {
    text: '18',
    value: '18'
  },
  {
    text: '19',
    value: '19'
  },
  {
    text: '20',
    value: '20'
  },
  {
    text: '21',
    value: '21'
  }
];

const getFaseOptions = (normCode: NormCodes) => {
  if (['051', '173', '187', '235'].includes(normCode)) {
    return [
      {
        text: 'II',
        value: 'II'
      },
      {
        text: 'III',
        value: 'III'
      }
    ];
  }

  return [
    {
      text: 'N/A',
      value: 'N/A'
    }
  ];
};

interface Props {
  title?: string
  preloadedProduct?: DictumProduct
  onCreateProduct?: (_product: DictumProductWithCodes) => void
  onUpdateProduct?: (_product: DictumProductWithCodes, _productHasChanged: boolean) => void
  disableInputs?: DictumProductInput[]
  triggerSaveProduct?: boolean
  cleanStates?: boolean
}

export const DictumProductForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    title = t('services.productFormTitle') || '',
    preloadedProduct = emptyDictumProduct,
    onCreateProduct = () => { },
    onUpdateProduct = () => { },
    disableInputs = [],
    triggerSaveProduct = false,
    cleanStates = false
  } = props;

  const {
    openModal
  } = useContext(AppContext);

  const [productHasChanged, setProductHasChanged] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [codes, setCodes] = useState<string[]>([]);
  const [codes2, setCodes2] = useState<string[]>([]);
  const [product, setProduct] = useState<DictumProduct>(preloadedProduct);
  const [currentCode, setCurrentCode] = useState<string>('');
  const [currentCode2, setCurrentCode2] = useState<string>('');
  const [auxTitle, setAuxTitle] = useState<string>(title);

  const isANewProduct = preloadedProduct.id === '';

  const validateProductData = () => {
    if (
      !isANewProduct
      && product.status === 'in-inspection'
      && (product.presentation === 'Por definir' || product.presentation === 'Undefined' || product.presentation === '')
      && !disableInputs.includes('presentation')
    ) {
      setErrors({
        presentation: t('services.errors.presentation')
      });

      return false;
    }

    if (validate.text(product.presentation, 1) !== 'success' && !disableInputs.includes('presentation')) {
      setErrors({
        presentation: t('services.errors.presentation')
      });

      return false;
    }

    if (validate.text(product.normCode, 1) !== 'success' || product.normCode === 'none') {
      setErrors({
        normCode: t('services.errors.norm')
      });

      return false;
    }

    if (validate.text(product.fase, 1) !== 'success' || product.fase === 'none') {
      setErrors({
        fase: t('services.errors.fase')
      });

      return false;
    }

    if (validate.text(product.tariffFraction, 8, 8) !== 'success') {
      setErrors({
        tariffFraction: t('services.errors.tarifFraccion')
      });

      return false;
    }

    if (validate.text(product.umc, 1) !== 'success' || product.umc === 'none') {
      setErrors({
        umc: t('services.errors.umc')
      });

      return false;
    }

    if (validate.number(product.umcQuantity.toString()) !== 'success'
      || Number(product.umcQuantity) < 0) {
      setErrors({
        umcQuantity: t('services.errors.umcQuantity')
      });

      return false;
    }

    if (validate.number(product.labelsToPut.toString()) !== 'success'
      || validate.text(product.labelsToPut.toString(), 1) !== 'success'
      || Number(product.labelsToPut) < 1) {
      setErrors({
        labelsToPut: t('services.errors.labelsToPut')
      });

      return false;
    }

    if (validate.text(product.labelingMode, 1) !== 'success' || product.labelingMode === 'none') {
      setErrors({
        labelingMode: t('services.errors.labelingMode')
      });

      return false;
    }

    if (validate.text(product.brand, 1) !== 'success') {
      setErrors({
        brand: t('services.errors.brand')
      });

      return false;
    }

    if (validate.text(product.description, 1) !== 'success') {
      setErrors({
        description: t('services.errors.description')
      });

      return false;
    }

    if (validate.text(currentCode, 1) !== 'success' && codes.length < 1) {
      setErrors({
        codes: t('services.errors.code')
      });

      return false;
    }

    if (codes2.length > 0 && codes.length !== codes2.length) {
      setErrors({
        codes2: t('services.errors.code2')
      });

      return false;
    }
    setErrors({});

    return true;
  };

  const onChangeInput = (value: string | number, id: string) => {
    setProductHasChanged(true);
    if (id === 'normCode') {
      if (!['051', '173', '187', '235'].includes(`${value}`)) {
        setProduct({
          ...product,
          normCode: value as NormCodes,
          fase: 'N/A'
        });
      } else {
        setProduct({
          ...product,
          normCode: value as NormCodes,
          fase: 'II'
        });
      }
    } else {
      setProduct({
        ...product,
        [id]: value
      });
    }
  };

  const handleSaveProduct = () => {
    if (validateProductData()) {
      setProductHasChanged(false);
      if (isANewProduct) {
        if (currentCode && currentCode.length > 0) {
          codes.push(currentCode);
        }

        onCreateProduct({
          ...product,
          codes: [...new Set(codes)],
          codes2: [...new Set(codes2)],
          modelsQuantity: codes.length,
          umcQuantity: Number(product.umcQuantity),
          labelsToPut: Number(product.labelsToPut)
        });
      } else {
        if (currentCode && currentCode.length > 0) {
          codes.push(currentCode);
        }

        onUpdateProduct(
          {
            ...product,
            codes: [...new Set(codes)],
            codes2: [...new Set(codes2)],
            modelsQuantity: codes.length,
            umcQuantity: Number(product.umcQuantity),
            labelsToPut: Number(product.labelsToPut)
          },
          productHasChanged
        );
      }
    }
  };

  useEffect(() => {
    setProduct(preloadedProduct);
    if (preloadedProduct.code) {
      setCodes(preloadedProduct.code.split(','));
    }
    if (preloadedProduct.code2) {
      setCodes2(preloadedProduct.code2.split(','));
    }
    if (preloadedProduct) {
      setAuxTitle(preloadedProduct.indicators.isGrouped ? t('services.editGroupedProduct') || '' : t('services.editProduct') || '');
    }
  }, [preloadedProduct]);

  useEffect(() => {
    if (cleanStates && !preloadedProduct.id) {
      setProduct(emptyDictumProduct);
      setCurrentCode2('');
      setCurrentCode('');
      setCodes([]);
      setCodes2([]);
    }
  }, [cleanStates, preloadedProduct]);

  useEffect(() => {
    if (triggerSaveProduct) {
      handleSaveProduct();
    }
  }, [triggerSaveProduct]);

  useEffect(() => {
    if (disableInputs.length > 0) {
      setProductHasChanged(true);
    }
  }, [disableInputs]);

  useKeyPress(openModal ? () => { } : () => handleSaveProduct(), [product, openModal]);

  return (
    <div className='manual-form'>
      <Title title={auxTitle} type='secondary' />
      <p>{t('services.required')}</p>
      <br />
      <div className='manual-form__container'>
        <Select
          options={normOptions}
          id='normCode'
          onChange={onChangeInput}
          title={t('global.norm') || ''}
          value={product.normCode}
          disabled={disableInputs.includes('normCode')}
          hasError={!!errors.normCode}
          helperText={errors.normCode}
        />
        <Select
          disabled={!['051', '173', '187', '235'].includes(product.normCode) || disableInputs.includes('fase')}
          options={getFaseOptions(product.normCode)}
          id='fase'
          onChange={onChangeInput}
          title='Fase'
          value={product.fase}
          hasError={!!errors.fase}
          helperText={errors.fase}
        />
        <Input
          type='number'
          value={product.tariffFraction}
          onChange={(value: string | number) => {
            if ((value as string).length <= 8) {
              setProductHasChanged(true);
              setProduct({
                ...product,
                tariffFraction: `${value}`
              });
            }
          }}
          title={t('services.tariffFraction') || ''}
          placeholder={t('services.tariffFractionExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.tariffFractionExplication') || ''}
          disabled={disableInputs.includes('tariffFraction')}
          hasError={!!errors.tariffFraction}
          helperText={errors.tariffFraction ? errors.tariffFraction : t('services.errors.tarifFraccion') || ''}
          cleanInput={true}
        />
        <Select
          options={umcOptions}
          id='umc'
          onChange={onChangeInput}
          title='UMC'
          value={product.umc}
          toolTipPosition='left'
          toolTipText={t('services.umcExplication') || ''}
          disabled={disableInputs.includes('umc')}
          hasError={!!errors.umc}
          helperText={errors.umc}
        />
        <Input
          type='decimal'
          value={product.umcQuantity || ''}
          id='umcQuantity'
          onChange={onChangeInput}
          title={t('services.umcQuantity') || ''}
          placeholder={t('services.umcQuantityExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.umcQuantityExplication') || ''}
          disabled={disableInputs.includes('umcQuantity')}
          hasError={!!errors.umcQuantity}
          helperText={errors.umcQuantity ? errors.umcQuantity : t('services.errors.mustBeGreaterThan0') || ''}
          cleanInput={true}
        />
        <Input
          type='number'
          value={product.labelsToPut || ''}
          id='labelsToPut'
          onChange={onChangeInput}
          title={t('services.labelsToPut') || ''}
          placeholder={t('services.labelsToPutExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.labelsToPutExplication') || ''}
          disabled={disableInputs.includes('labelsToPut')}
          hasError={!!errors.labelsToPut}
          helperText={errors.labelsToPut ? errors.labelsToPut : t('services.errors.mustBeGreaterThan0') || ''}
          cleanInput={true}
        />
        <Select
          options={labelingModeOptions}
          id='labelingMode'
          onChange={onChangeInput}
          value={product.labelingMode}
          title={t('services.labelingMode') || ''}
          toolTipPosition='left'
          toolTipText={t('services.labelingModeExplication') || ''}
          disabled={disableInputs.includes('labelingMode')}
          hasError={!!errors.labelingMode}
          helperText={errors.labelingMode}
        />
        <Input
          type='text'
          value={product.brand}
          id='brand'
          onChange={onChangeInput}
          title={t('services.productBrand') || ''}
          placeholder={t('services.productBrandExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.productBrandExplication') || ''}
          disabled={disableInputs.includes('brand')}
          hasError={!!errors.brand}
          helperText={errors.brand}
          cleanInput={true}
        />
        {
          (['in-inspection'].includes(product.status)) && (
            <Input
              type='text'
              value={product.presentation}
              id='presentation'
              onChange={onChangeInput}
              title={t('services.presentation') || ''}
              placeholder={t('services.presentationExample') || ''}
              ableAutocomplete={false}
              toolTipPosition='left'
              toolTipText={t('services.presentationExplication') || ''}
              disabled={disableInputs.includes('presentation')}
              hasError={!!errors.presentation}
              helperText={errors.presentation}
              upperCase={false}
              cleanInput={true}
            />
          )
        }
      </div>
      <br />
      <div className='manual-form__textarea-container'>
        <Input
          type='text'
          value={product.description}
          id='description'
          onChange={onChangeInput}
          title={t('services.description') || ''}
          placeholder={t('services.descriptionExample') || ''}
          ableAutocomplete={false}
          toolTipPosition='left'
          toolTipText={t('services.descriptionExplication') || ''}
          disabled={disableInputs.includes('description')}
          hasError={!!errors.description}
          helperText={errors.description}
          cleanInput={true}
        />
      </div>
      <br />
      {
        !disableInputs.includes('code') && (
          <>
            <div className='manual-form__textarea-container'>
              <Input
                type='text'
                value={currentCode}
                onChange={(value) => setCurrentCode(value as string)}
                title={t('services.codeInput') || ''}
                placeholder={t('services.codeInputExample') || ''}
                ableAutocomplete={false}
                disabled={disableInputs.includes('code')}
                hasError={!!errors.codes}
                helperText={
                  errors.codes
                  || t('services.multiCodesExplication') || ''
                }
                cleanInput={true}
              />
            </div>
            <div className='manual-form__bottom-buttons'>
              <Button
                onClick={() => {
                  if (currentCode && currentCode.length > 0) {
                    setErrors({ ...errors, codes: '' });
                    setCodes([...codes, currentCode]);
                    setCurrentCode('');
                    setProductHasChanged(true);
                  } else {
                    setErrors({ ...errors, codes: t('services.errors.invalidCode') });
                  }
                }}
                type='secondary-outlined'
                border='secondary-outlined'
                disabled={disableInputs.includes('code')}
                label={t('services.addAnotherCode') || ''}
              />
            </div>
          </>
        )
      }
      {
        (codes.length > 0) && (
          <BubbleElements
            title={t('services.listCodes') || ''}
            elements={codes}
            onDelete={(deletedCode) => {
              setProductHasChanged(true);
              setCodes(codes.filter((code) => code !== deletedCode));
            }}
            emptyStateTitle={t('services.emptyListCodes') || ''}
            ableDeleteElements={!disableInputs.includes('code')}
          />
        )
      }
      <Separator orientation='horizontal' />
      <br />
      {
        !disableInputs.includes('code') && (
          <>
            <div className='manual-form__textarea-container'>
              <Input
                type='text'
                value={currentCode2}
                onChange={(value) => setCurrentCode2(value as string)}
                title={t('services.codeInput2') || ''}
                placeholder={t('services.codeInputExample') || ''}
                ableAutocomplete={false}
                disabled={disableInputs.includes('code')}
                hasError={!!errors.codes2}
                helperText={
                  errors.codes2
                  || t('services.multiCodesExplication2') || ''
                }
                cleanInput={true}
              />
            </div>
            <div className='manual-form__bottom-buttons'>
              <Button
                onClick={() => {
                  if (currentCode2 && currentCode2.length > 0) {
                    setErrors({ ...errors, codes: '' });
                    setCodes2([...codes2, currentCode2]);
                    setCurrentCode2('');
                    setProductHasChanged(true);
                  } else {
                    setErrors({ ...errors, codes: t('services.errors.invalidCode') });
                  }
                }}
                type='secondary-outlined'
                border='secondary-outlined'
                disabled={disableInputs.includes('code')}
                label={t('services.addAnotherCode') || ''}
              />
            </div>
          </>
        )
      }
      {
        (codes.length > 0) && (
          <BubbleElements
            title={t('services.listCodes2') || ''}
            elements={codes2}
            onDelete={(deletedCode) => {
              setProductHasChanged(true);
              setCodes2(codes2.filter((code) => code !== deletedCode));
            }}
            emptyStateTitle={t('services.emptyListCodes') || ''}
            ableDeleteElements={!disableInputs.includes('code')}
          />
        )
      }
      <Separator orientation='horizontal' />
      <div className='manual-form__bottom-buttons'>
        <Button
          onClick={handleSaveProduct}
          type='secondary'
          label={t('global.save') || ''}
          submit={true}
          disabled={!productHasChanged}
        />
        {
          isANewProduct && (
            <Button
              onClick={() => {
                setProduct(emptyDictumProduct);
                setCurrentCode('');
                setCurrentCode2('');
                setCodes([]);
                setCodes2([]);
              }}
              type='secondary-outlined'
              border='secondary-outlined'
              label={t('services.cleanForm') || ''}
              id='form-bottom'
            />
          )
        }
      </div>
    </div>
  );
};

export default DictumProductForm;
