import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Task } from '../../interfaces';

import './styles.scss';
import Icon from '../Icon';

const dayjs = require('dayjs');

export type TaskCardType = 'onsite-inspection' | 'remote-inspection';

interface Props {
  task: Task
  onClick: (_task: Task) => void
}

const TaskCard: React.FC<Props> = (props: Props) => {
  const {
    task,
    onClick
  } = props;

  const { t } = useTranslation();

  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const TaskCardTypeDictionary = {
    'onsite-inspection': t('tasks.form.inSiteInspection'),
    'remote-inspection': t('tasks.form.remoteInspection'),
    'constancy-inspection': t('tasks.form.constancyInspection'),
    'revision-inspection': t('tasks.form.revisionInspection')
  };

  return (
    <div className={`task-card task-card--${task.type}`} onClick={() => onClick(task)}>
      <div className={`task-card__left-bar task-card__left-bar--${task.type}`} />
      <div className="task-card__content">
        <div className="task-card__header">
          <span className="task-card__header__client">{task.client.businessName}</span>
          <span className={`task-card__header__type task-card__header__type--${task.type}`}>{TaskCardTypeDictionary[task.type]}</span>
        </div>
        <div className="task-card__list">
          <span className="task-card__list__line">
            {
              task.indicators.editInspection && (
                <>
                  <Icon type={'alertRed'} />{' '}
                </>
              )
            }
            {`${task.indicators.inspectionsQuantity} ${t('global.inspections')}`}
            {
              task.indicators.urgent === true && (
                <span className="task-card__list__alert"> {t('global.urgent').toLocaleUpperCase()}</span>
              )
            }
          </span>
          <span className="task-card__list__line">{task.number}</span>
        </div>
        <span className="task-card__footer">
          <div className="task-card__footer__person">
            {
              imageLoaded && (
                <img
                  src={task.responsible.picture}
                  className="task-card__footer__person__photo"
                  onError={handleImageError}
                />
              )
            }
            <p className="task-card__footer__person__name">{task.responsible.name}</p>
          </div>
          <div className="task-card__footer__date">
            {dayjs(String(task.datesRegistry.scheduledTo).substring(0, 10)).format('DD MMM YYYY')}
          </div>
        </span>
      </div>
    </div>
  );
};

export default TaskCard;
