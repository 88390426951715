/* eslint-disable max-len */
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  Accordeon,
  ActionButtons,
  Button,
  Icon,
  LateralMenu,
  Modal,
  ModalView,
  TaskDetail,
  Input
} from '..';
import {
  ActionButton,
  Inspection,
  User,
  DictumProduct,
  ConstancyProduct,
  Task,
  Round,
  InspectionSections,
  DictumServiceByID,
  ConstancyServiceByID,
  DictumProductByID,
  ConstancyProductByID,
  RevisionProductByID,
  RevisionServiceByID,
  RevisionProduct,
  ServiceCodes,
  ConstancyProductIndicators,
  RevisionProductIndicators,
  Client,
  LateralMenuOption
} from '../../interfaces';
import {
  GeneralInfo,
  Rounds,
  History,
  ReviewComments,
  InspectionList,
  ProductDocuments
} from './components';
import { GeneralInfo as ConstancyProductGeneralInfo } from '../../views/ConstancyProductDetail/components';
import { GeneralInfo as RevisionProductGeneralInfo } from '../../views/RevisionProductDetail/components';
import { GeneralInfo as DictumProductGeneralInfo } from '../../views/DictumProductDetail/components';
import DictaminateInspectionModal from '../../views/Inspection/components/DictaminateInspectionModal';

import './styles.scss';
import {
  emptyClient,
  emptyConstancyServiceByID,
  emptyDictumServiceByID,
  emptyRevisionServiceByID,
  emptyTask,
  emptyUser
} from '../../emptyObjects';
import { useNavigate, useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';
import configuration from '../../configuration';
import storage from '../../storage';

type InspectionDetailContentType = InspectionSections;

interface SuccessReviewModal {
  visible: boolean
  showAlertLimitRoundReached: boolean
  complies: boolean
}

interface RoundSteps {
  haveProductUpdated: string | undefined,
  haveListCompleted: string | undefined,
  haveDictaminate: string | undefined,
  haveUploadEvidence: string | undefined,
  haveUploadedInspectionFormat: string | undefined
}

interface Props {
  lookInTheTrash: boolean
  setShowProductInformation: (_value: boolean) => void
  dictumProduct: DictumProduct
  constancyProduct: ConstancyProduct
  revisionProduct: RevisionProduct
  onFetchInspection?: () => void
  getInspections?: () => void
  selectedSection?: InspectionSections
}

const InspectionDetail: React.FC<Props> = (props) => {
  const {
    lookInTheTrash,
    setShowProductInformation,
    dictumProduct,
    constancyProduct,
    revisionProduct,
    getInspections,
    onFetchInspection,
    selectedSection
  } = props;

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const {
    fetchResource
  } = useResource<DictumServiceByID>();

  const {
    fetchResource: getClient
  } = useResource<Client>();

  const {
    fetchResource: getConstancy
  } = useResource<ConstancyServiceByID>();

  const {
    fetchResource: getRevision
  } = useResource<RevisionServiceByID>();

  const {
    fetchResource: getDictumProduct
  } = useResource<DictumProductByID>();

  const {
    fetchResource: getConstancyProduct
  } = useResource<ConstancyProductByID>();

  const {
    fetchResource: getRevisionProduct
  } = useResource<RevisionProductByID>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const {
    fetchResource: getTask
  } = useResource<Task>();

  const {
    fetchResources,
    updateResource,
    createResource
  } = useResource<Inspection>();

  const {
    apiType,
    userRole,
    setOpenModal,
    inspection,
    setInspection,
    soundEffects,
    setVisibleInspectionDetailModal,
    visibleTaskDetailModal,
    setVisibleTaskDetailModal,
    setTaskIDModalAfterActions,
    taskIDModalAfterActions
  } = useContext(AppContext);

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    true: 'true',
    false: 'false'
  };

  const nowFormat = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`;

  const [user, setUser] = useState<User>(emptyUser);
  const [task, setTask] = useState<Task>(emptyTask);
  const [dictumService, setDictumService] = useState<DictumServiceByID>(emptyDictumServiceByID);
  const [constancyService, setConstancyService] = useState<ConstancyServiceByID>(emptyConstancyServiceByID);
  const [revisionService, setRevisionService] = useState<RevisionServiceByID>(emptyRevisionServiceByID);
  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [inspectionLateralMenuValue, setInspectionLateralMenuValue] = useState<InspectionDetailContentType>('info');
  const [refreshTask, setRefreshTask] = useState<boolean>(true);
  const [productLookInTheTrash, setProductLookInTheTrash] = useState<boolean>(true);
  const [client, setClient] = useState<Client>(emptyClient);
  const [showSuccessReviewRound, setShowSuccessReviewRound] = useState<SuccessReviewModal>({
    visible: false,
    showAlertLimitRoundReached: false,
    complies: false
  });
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [evidenceUploaded, setEvidenceUploaded] = useState<boolean>(false);
  const [commentReview, setCommentReview] = useState<string>('');
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});
  const [
    showMessagePostEditInspectionModal,
    setShowMessagePostEditInspectionModal] = useState<boolean>(false);
  const [
    showReviewInspectionModal,
    setShowReviewInspectionModal
  ] = useState<boolean>(false);
  const [
    showDictaminateInspectionModal,
    setShowDictaminateInspectionModal
  ] = useState<boolean>(false);
  const [
    dictaminateType,
    setDictaminateType
  ] = useState<boolean>(false);
  const [
    showSuccessReviewInspectionModal,
    setShowSuccessReviewInspectionModal
  ] = useState<boolean>(false);
  const [preselectedRoundID, setPreselectedRoundID] = useState<number>(-1);
  const [
    haveQuestionInspectionListAsNotComplies,
    setHaveQuestionInspectionListAsNotComplies
  ] = useState<boolean>(false);
  const [
    roundSteps,
    setRoundSteps
  ] = useState<RoundSteps>({
    haveProductUpdated: undefined,
    haveListCompleted: undefined,
    haveDictaminate: undefined,
    haveUploadEvidence: undefined,
    haveUploadedInspectionFormat: undefined
  });

  const getClientData = (clientID: string) => {
    getClient(
      `/admin/clients/${clientID}`,
      (data) => {
        setClient(data);
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const getLastRound = useMemo((): Round => {
    return utils.getCurrentRound(inspection.rounds);
  }, [inspection]);

  const renderProductInformation = (): ReactElement => {
    if (constancyProduct.id === '' && dictumProduct.id === '' && revisionProduct.id === '') {
      return (<div className='full-table__empty-state'>
        <p>{t('errorCodes.inspectionProductNotFound')}</p>
        <Icon type='emptyState' className='full-table__empty-state__icon' />
      </div>
      );
    }

    if (['CC', 'CN'].includes(inspection.service.code)) {
      return (<ConstancyProductGeneralInfo
        product={constancyProduct}
        constancyServiceLookInTheTrash={constancyService.lookInTheTrash}
        inspectionLookInTheTrash={lookInTheTrash}
      />);
    }

    if (['REV'].includes(inspection.service.code)) {
      return (<RevisionProductGeneralInfo
        product={revisionProduct}
        revisionServiceLookInTheTrash={revisionService.lookInTheTrash}
        inspectionLookInTheTrash={lookInTheTrash}
      />);
    }

    return (<DictumProductGeneralInfo
      product={dictumProduct}
      dictumServiceLookInTheTrash={dictumService.lookInTheTrash}
      inspectionLookInTheTrash={lookInTheTrash}
    />);
  };

  const setlateralmenuOptions = useCallback(() => {
    const lateralMenuOptions: LateralMenuOption[] = [
      {
        label: t('services.generalInformation'),
        value: 'info'
      },
      {
        label: t('services.product'),
        value: 'product'
      }
    ];

    if (['CC', 'CN', 'REV'].includes(inspection.service.code) && apiType === 'admin') {
      lateralMenuOptions.push({
        label: t('services.productDocuments'),
        value: 'product-documents'
      });
    }

    if (apiType === 'admin') {
      lateralMenuOptions.push({
        label: t('services.list'),
        value: 'inspection-list'
      });

      lateralMenuOptions.push({
        label: t('services.reviews'),
        value: 'review',
        alert: task.status === 'scheduled' && inspection.reviewComments.length > 0 && apiType === 'admin'
      });

      lateralMenuOptions.push({
        label: t('services.history'),
        value: 'history'
      });
    }

    lateralMenuOptions.push({
      label: t('services.rounds'),
      value: 'rounds'
    });

    return lateralMenuOptions;
  }, [inspection, task]);

  const handleSelectLateralMenu = (value: string) => {
    setInspectionLateralMenuValue((value as InspectionDetailContentType));
  };

  const sendToReview = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('inspections.sendToReview'),
      () => {
        updateResource(
          `/admin/inspections/${inspection.id}/status/in-review`,
          {},
          (data) => {
            Modal.fireSuccess(
              t('global.correct'),
              t('inspections.sentToReview'),
              setOpenModal,
              () => {
                setRefreshTask(!refreshTask);
                setInspection(data);
                if (getInspections) {
                  getInspections();
                }
                Modal.close(setOpenModal);
              },
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    if (['DC', 'DN'].includes(inspection.service.code) && utils.getCurrentRound(inspection.rounds).task && apiType === 'admin') {
      getTask(
        `/admin/tasks/${utils.getCurrentRound(inspection.rounds).task?.id}?b_lookInTheTrash=${lookInTheTrash ? 'true' : ''}`,
        (data) => {
          setTask(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }

    if (['CC', 'CN', 'REV'].includes(inspection.service.code) && inspection.task && apiType === 'admin') {
      getTask(
        `/admin/tasks/${inspection.task?.id}?b_lookInTheTrash=${lookInTheTrash ? 'true' : ''}`,
        (data) => {
          setTask(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [inspection, refreshTask]);

  const handleReviewInspection = (approved: boolean) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      `${t('inspections.markInspection')} "${approved ? t('inspections.approved') : t('inspections.deprecated')}"?, ${approved ? '' : t('inspections.postMarkInspectionMessage')}`,
      () => {
        if (approved) {
          if (inspection.complies === false) {
            setShowReviewInspectionModal(false);

            if (((['DC', 'DN'].includes(inspection.service.code)
              && inspection.rounds.length + 1 === 3)
              || (['CC', 'CN', 'REV'].includes(inspection.service.code)
                && (inspection.rounds.length + 1 === 4)))) {
              createResource(
                `/admin/inspections/${inspection.id}/review`,
                {
                  approved,
                  negation: true,
                  roundID: utils.getCurrentRound(inspection.rounds).id
                },
                (data) => {
                  setRefreshTask(!refreshTask);

                  setInspection(data);
                  if (getInspections) getInspections();
                  if (onFetchInspection) onFetchInspection();

                  setShowSuccessReviewRound({
                    visible: true,
                    showAlertLimitRoundReached: true,
                    complies: true
                  });
                },
                (error: string) => {
                  Modal.fireError(error, setOpenModal, soundEffects);
                }
              );
            } else {
              createResource(
                `/admin/inspections/${inspection.id}/review`,
                {
                  approved,
                  negation: false,
                  roundID: utils.getCurrentRound(inspection.rounds).id
                },
                (data) => {
                  setInspection(data);
                  if (getInspections) getInspections();
                  if (onFetchInspection) onFetchInspection();

                  setShowSuccessReviewRound({
                    visible: true,
                    showAlertLimitRoundReached: false,
                    complies: true
                  });
                },
                (error: string) => {
                  Modal.fireError(error, setOpenModal, soundEffects);
                }
              );
            }
          } else {
            createResource(
              `/admin/inspections/${inspection.id}/review`,
              {
                approved,
                negation: false,
                roundID: utils.getCurrentRound(inspection.rounds).id
              },
              (data) => {
                setInspection(data);
                if (getInspections) getInspections();
                if (onFetchInspection) onFetchInspection();

                setShowReviewInspectionModal(false);
                setShowSuccessReviewRound({
                  visible: true,
                  showAlertLimitRoundReached: false,
                  complies: true
                });
              },
              (error: string) => {
                Modal.fireError(error, setOpenModal, soundEffects);
              }
            );
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (inspection.indicators.waitingToExpired) {
            Modal.fireError(t('services.errors.expiredNotCompliesInspection'), setOpenModal, soundEffects);
          } else {
            Modal.close(setOpenModal);
            setShowReviewInspectionModal(false);
            createResource(
              `/admin/inspections/${inspection.id}/review`,
              {
                approved: false,
                negation: false,
                roundID: utils.getCurrentRound(inspection.rounds).id
              },
              (data) => {
                setRefreshTask(!refreshTask);

                setInspection(data);
                if (getInspections) getInspections();
                if (onFetchInspection) onFetchInspection();

                setShowMessagePostEditInspectionModal(true);
              },
              (error: string) => {
                Modal.fireError(error, setOpenModal, soundEffects);
              }
            );
          }
        }
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const uploadReviewComment = () => {
    if (commentReview.length > 0) {
      createResource(
        `/admin/inspections/${inspection.id}/review-comment`,
        {
          comment: commentReview
        },
        (data) => {
          setInspection(data);
          setErrors({});
          setShowMessagePostEditInspectionModal(false);
          setShowSuccessReviewInspectionModal(true);
          setCommentReview('');
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    } else {
      setErrors({
        commentReview: t('inspections.reviewMessageError')
      });
    }
  };

  const productNotPresented = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.notPresentedProductAsk'),
      () => {
        updateResource(
          `/admin/inspections/${inspection.id}/product-not-presented`,
          {},
          (data) => {
            Modal.fireSuccess(
              t('global.correct'),
              t('inspections.productNotPresented'),
              setOpenModal,
              () => {
                setInspection(data);
                Modal.close(setOpenModal);
              },
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    if (inspection
      && inspection.rounds
      && utils.getCurrentRound(inspection.rounds) !== undefined
      && utils.getCurrentRound(inspection.rounds).steps !== undefined) {
      setEvidenceUploaded(!(utils.getCurrentRound(inspection.rounds).steps.find(element => element === 'uploaded-evidence') === undefined));
    }
  }, [inspection]);

  const checkRoundsStatus = (): boolean => {
    const { complies } = utils.getCurrentRound(inspection.rounds);

    if (['CC', 'CN', 'REV'].includes(inspection.service.code)
      && inspection.rounds.length === 3
      && complies !== null
      && roundSteps.haveDictaminate !== undefined
    ) {
      return false;
    }

    if (['DC', 'DN'].includes(inspection.service.code)
      && inspection.rounds.length === 2
      && complies !== null
      && roundSteps.haveDictaminate !== undefined
    ) {
      return false;
    }

    return true;
  };

  const sendDocumentsArray = (): string[] => {
    if (inspection.service.code.startsWith('REV')) {
      return revisionProduct.documents;
    }

    return constancyProduct.documents;
  };

  const setproductIndicators = (): ConstancyProductIndicators | RevisionProductIndicators | undefined => {
    if (['CC', 'CN', 'REV'].includes(inspection.service.code)) {
      return inspection.service.code.startsWith('REV') ? revisionProduct.indicators : constancyProduct.indicators;
    }

    return undefined;
  };

  const getContent = useCallback(() => {
    let lookInTheTrashAux: boolean = false;

    if (['DC', 'DN'].includes(inspection.service.code)) {
      lookInTheTrashAux = dictumService.lookInTheTrash;
    } else if (['CC', 'CN'].includes(inspection.service.code)) {
      lookInTheTrashAux = constancyService.lookInTheTrash;
    } else if (['REV'].includes(inspection.service.code)) {
      lookInTheTrashAux = revisionService.lookInTheTrash;
    }

    const contents = {
      info: (<GeneralInfo
        inspection={inspection}
        serviceLookInTheTrash={lookInTheTrashAux}
        productLookInTheTrash={productLookInTheTrash}
      />),
      product: renderProductInformation(),
      'inspection-list': (<InspectionList
        updateInspectionState={updateInspectionState}
        dictumProduct={dictumProduct}
        productIndicators={setproductIndicators()}
        user={user}
        client={client}
      />),
      rounds: (
        <Rounds
          user={user}
          setInspection={updateInspectionState}
          preselectedRoundID={preselectedRoundID}
          setVisibleTaskDetailModal={setVisibleTaskDetailModal}
          evidenceUploaded={evidenceUploaded}
          setEvidenceUploaded={setEvidenceUploaded}
          design={constancyProduct.indicators.design === true || revisionProduct.indicators.design === true}
          inspectionIsTheUser={user.id === getLastRound.inspector?.id}
        />
      ),
      history: (<History history={inspection.tracking} />),
      review: (<ReviewComments
        setInspection={updateInspectionState}
        user={user}
      />),
      'product-documents': (<ProductDocuments documents={sendDocumentsArray()} />)
    };

    return inspectionLateralMenuValue !== 'none' ? contents[inspectionLateralMenuValue] : (<></>);
  }, [
    inspectionLateralMenuValue,
    inspection,
    lookInTheTrash,
    user,
    evidenceUploaded,
    dictumProduct,
    constancyProduct,
    revisionProduct,
    preselectedRoundID,
    dictumService,
    constancyService,
    revisionService,
    haveQuestionInspectionListAsNotComplies
  ]);

  useEffect(() => {
    setShowProductInformation(false);
    if (apiType === 'admin' && userRole !== 'collaborator') {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [apiType, userRole]);

  const checkIfHaveStartedAtDate = (): boolean => {
    if ((
      task.datesRegistry.startedAt === null
      && task.type === 'onsite-inspection'
    ) || (task.visit ? task.visit?.planificationSignature.signature === '' : false && task.type === 'onsite-inspection')) {
      return false;
    }

    return true;
  };

  const updateInspectionState = (value: Inspection) => {
    setRefreshTask(!refreshTask);
    setInspection(value);
  };

  const checkAbleUploadEvidence = (): boolean => {
    const lastRound = utils.getCurrentRound(inspection.rounds);

    if (lastRound.complies) {
      if (lastRound.files.length === 10) {
        return false;
      }

      return true;
    }

    if (utils.getCurrentRound(inspection.rounds).steps.find(element => element === 'uploaded-evidence') === undefined) {
      return true;
    }

    return !(lastRound.files.length === 11);
  };

  const checkAbleSentToReview = (): boolean => {
    if (utils.getCurrentRound(inspection.rounds).complies || inspection.complies) {
      if (roundSteps.haveUploadEvidence === undefined) {
        return false;
      }

      return true;
    }

    if (['DC', 'DN'].includes(inspection.service.code)) {
      return roundSteps.haveUploadEvidence !== undefined;
    }

    return true;
  };

  const ifAllInspectionsAreSendToNegation = (): boolean => {
    let sendToNegationInspections = 0;

    for (let index = 0; index < inspections.length; index += 1) {
      if (inspections[index].indicators.sendToNegation) {
        sendToNegationInspections += 1;
      }
    }

    if (task.status === 'in-progress'
      && sendToNegationInspections === inspections.length) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (inspection.status === 'in-progress' && task.id !== '') {
      fetchResources(
        {
          resourcePath: `/${apiType}/inspections`,
          filters: {
            s_taskID: ['remote-inspection', 'revision-inspection', 'constancy-inspection'].includes(task.type) ? task.id : '',
            s_roundTaskID: task.type === 'onsite-inspection' ? task.id : '',
            b_lookInTheTrash: lookInTheTrash ? 'true' : ''
          }
        },
        (data) => {
          setInspections(data.items);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [task.id, task, lookInTheTrash, inspection, apiType]);

  useEffect(() => {
    const lastTaskID = taskIDModalAfterActions || storage.get('lastTaskDetailID');
    const taskID = ['DC', 'DN'].includes(inspection.service.code) ? getLastRound.task?.id : inspection.task?.id;

    if (lastTaskID !== undefined && lastTaskID !== '' && lastTaskID !== null) {
      setVisibleTaskDetailModal({
        visible: true,
        taskID: lastTaskID
      });
    } else {
      setVisibleTaskDetailModal({ visible: false, taskID: taskID || '' });
    }
  }, [inspection, taskIDModalAfterActions]);

  useEffect(() => {
    if (selectedSection && selectedSection !== 'none') {
      handleSelectLateralMenu(selectedSection);
    }
  }, [selectedSection]);

  // eslint-disable-next-line no-unused-vars
  const getLastRoundWithoutQuestions = (): number => {
    const auxRoundIDs: number[] = [];

    for (let index = 0; index < inspection.rounds.length; index += 1) {
      if ((inspection.rounds[index].list?.questions.some((question) => question.answer === 0)
        || inspection.rounds[index].list === null)
        && inspection.rounds[index].productNotPresented === false) {
        auxRoundIDs.push(inspection.rounds[index].id);
      }
    }

    if (inspection.status === 'in-progress') {
      return Math.max(...auxRoundIDs);
    }

    return Math.min(...auxRoundIDs);
  };

  useEffect(() => {
    if (inspection.client.id && inspection.client.id !== 'none') {
      getClientData(inspection.client.id);
    }
  }, [inspection]);

  useEffect(() => {
    const round = utils.getCurrentRound(inspection.rounds);

    if (inspection
      && inspection.rounds
      && round !== undefined
      && round.steps !== undefined) {
      setRoundSteps({
        haveProductUpdated: round.steps.find(element => element === 'product-updated'),
        haveListCompleted: round.steps.find(element => element === 'list-completed'),
        haveDictaminate: round.steps.find(element => element === 'dictaminate'),
        haveUploadEvidence: round.steps.find(element => element === 'uploaded-evidence'),
        haveUploadedInspectionFormat: round.steps.find(element => element === 'uploaded-inspection-format')
      });
    }
  }, [inspection]);

  useEffect(() => {
    if (['DC', 'DN'].includes(inspection.service.code)
      && inspection.service.id) {
      fetchResource(
        `/${apiType}/dictum-services/${inspection.service.id}`,
        (data) => {
          setDictumService(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );

      if (inspection.product.id) {
        getDictumProduct(
          `/${apiType}/dictum-products/${inspection.product.id}`,
          (data) => {
            setProductLookInTheTrash(data.lookInTheTrash);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }

    if (['CC', 'CN'].includes(inspection.service.code)
      && inspection.service.id) {
      getConstancy(
        `/${apiType}/constancy-services/${inspection.service.id}`,
        (data) => {
          setConstancyService(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );

      if (inspection.product.id) {
        getConstancyProduct(
          `/${apiType}/constancy-products/${inspection.product.id}`,
          (data) => {
            setProductLookInTheTrash(data.lookInTheTrash);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }

    if (['REV'].includes(inspection.service.code)
      && inspection.service.id) {
      getRevision(
        `/${apiType}/revision-services/${inspection.service.id}`,
        (data) => {
          setRevisionService(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );

      if (inspection.product.id) {
        getRevisionProduct(
          `/${apiType}/revision-products/${inspection.product.id}`,
          (data) => {
            setProductLookInTheTrash(data.lookInTheTrash);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  }, [inspection]);

  useEffect(() => {
    let ableButtonsIfRemoteInspectionWaitForNewDocuments = true;
    let inspectionIsTheUser = false;
    let inspector = null;
    let ifOnSiteInspectionHaveClientSign = false;
    let validateUploadFiles = false;
    let serviceCode: ServiceCodes = 'DC';

    if (['CC', 'CN'].includes(inspection.service.code)) {
      serviceCode = 'CC';
    } else if (['REV'].includes(inspection.service.code)) {
      serviceCode = 'REV';
    }

    if (
      ['CC', 'CN', 'REV'].includes(inspection.service.code)
      && inspection.indicators.waitingForFilesForRemoteRound
      && !inspection.indicators.changedFilesForRemoteRound
    ) {
      ableButtonsIfRemoteInspectionWaitForNewDocuments = false;
    }

    if (['DC', 'DN'].includes(inspection.service.code)) {
      inspectionIsTheUser = user.id === getLastRound.inspector?.id;
    } else {
      inspectionIsTheUser = user.id === inspection.inspector?.id;
    }

    if (['DC', 'DN'].includes(inspection.service.code)) {
      inspector = getLastRound.inspector;
    } else {
      inspector = inspection.inspector;
    }

    const inspectionlistQuestions = inspection.rounds.at(-1)?.list?.questions || [];

    for (let index = 0; index < inspectionlistQuestions.length; index += 1) {
      if (inspectionlistQuestions[index].answer === false
        && inspection.status === 'in-progress'
        && roundSteps.haveDictaminate === undefined
        && inspectionIsTheUser) {
        setHaveQuestionInspectionListAsNotComplies(true);
      }
    }

    if (task.type === 'onsite-inspection') {
      if (task.visit !== null) {
        if (task.visit?.planificationSignature.signature.length > 0) {
          ifOnSiteInspectionHaveClientSign = true;
        }
      }
    }

    validateUploadFiles = (!!roundSteps.haveListCompleted
      && !!roundSteps.haveProductUpdated);

    setButtons([
      {
        button: (
          <Button
            onClick={() => productNotPresented()}
            type='primary-outlined'
            label={t('services.notPresentedProduct') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='close'
            alignContent='left'
          />
        ),
        available: inspectionIsTheUser
          && inspection.status === 'in-progress'
          && checkIfHaveStartedAtDate()
          && roundSteps.haveProductUpdated === undefined
          && getLastRound.productNotPresented === false
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && inspection.indicators.waitingToExpired === false,
        specialValidation: apiType === 'admin' && ['DC', 'DN'].includes(inspection.service.code) && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/inspection/update-product/${inspection.id}`)}
            type='primary-outlined'
            label={t('services.updateProduct') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='updateProduct'
            alignContent='left'
          />
        ),
        available: inspectionIsTheUser
          && inspection.status === 'in-progress'
          && checkIfHaveStartedAtDate()
          && roundSteps.haveProductUpdated === undefined
          && getLastRound.productNotPresented === false
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && inspection.indicators.waitingToExpired === false,
        specialValidation: apiType === 'admin' && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => {
              createResource(
                `/admin/inspections/${inspection.id}/start-list`,
                {},
                () => {
                  setRefreshTask(!refreshTask);
                  navigate(`/inspection/inspection-list/${inspection.id}/${inspection.rounds.length}`);
                },
                (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            }}
            type='primary-outlined'
            label={t('services.makeList', { extraLabel: `${inspection.rounds.length > 1 ? `${t('inspections.round')} #${inspection.rounds.length}` : ''}` }) || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspectionList'
            alignContent='left'
          />
        ),
        available: ((user.role === 'trainee' || inspectionIsTheUser)
          && checkIfHaveStartedAtDate()
          && ['in-progress'].includes(inspection.status)
          && !!roundSteps.haveProductUpdated
          && roundSteps.haveListCompleted === undefined
          && getLastRound.productNotPresented === false
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && inspection.status !== 'deleted'
          && inspection.listCompleted === false
          && inspection.indicators.waitingToExpired === false)
          || (['in-review', 'concluded'].includes(inspection.status)
            && inspectionIsTheUser
            && checkIfHaveStartedAtDate()
            && inspection.rounds.at(-1)?.productNotPresented === false
            && inspection.listCompleted === false
            && inspection.indicators.waitingToExpired === false),
        specialValidation: apiType === 'admin'
          && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => {
              setPreselectedRoundID(-1);
              setTimeout(() => { setPreselectedRoundID(utils.getCurrentRound(inspection.rounds).id); }, 100);

              setInspectionLateralMenuValue('rounds');
            }}
            type='primary-outlined'
            label={t('services.uploadEvidence') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='upload'
            alignContent='left'
          />
        ),
        available: inspectionIsTheUser
          && inspection.status === 'in-progress'
          && checkIfHaveStartedAtDate()
          && !!roundSteps.haveProductUpdated
          && !!roundSteps.haveListCompleted
          && validateUploadFiles
          && roundSteps.haveUploadEvidence === undefined
          && checkAbleUploadEvidence()
          && getLastRound.productNotPresented === false
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && evidenceUploaded === false
          && inspection.indicators.waitingToExpired === false,
        specialValidation: apiType === 'admin' && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => {
              setDictaminateType(true);
              setShowDictaminateInspectionModal(true);
            }}
            type='primary'
            label={t('services.markComplies') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='like'
            alignContent='left'
          />
        ),
        available: inspectionIsTheUser
          && inspection.status === 'in-progress'
          && inspector !== null
          && checkRoundsStatus()
          && checkIfHaveStartedAtDate()
          && !!roundSteps.haveProductUpdated
          && !!roundSteps.haveListCompleted
          && !!roundSteps.haveUploadEvidence
          && roundSteps.haveDictaminate === undefined
          && getLastRound.productNotPresented === false
          && ableButtonsIfRemoteInspectionWaitForNewDocuments,
        specialValidation: apiType === 'admin' && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => {
              setDictaminateType(false);
              setShowDictaminateInspectionModal(true);
            }}
            type='red'
            label={t('services.markNotComplies') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='dislike'
            alignContent='left'
          />
        ),
        available: inspectionIsTheUser
          && inspection.status === 'in-progress'
          && inspector !== null
          && checkRoundsStatus()
          && checkIfHaveStartedAtDate()
          && !!roundSteps.haveProductUpdated
          && !!roundSteps.haveListCompleted
          && !!roundSteps.haveUploadEvidence
          && roundSteps.haveDictaminate === undefined
          && getLastRound.productNotPresented === false
          && ableButtonsIfRemoteInspectionWaitForNewDocuments,
        specialValidation: apiType === 'admin' && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/planification-signature/${task.id}`)}
            type='primary-outlined'
            label={t('tasks.planificationSing') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='signature'
            alignContent='left'
          />
        ),
        available: (user.id === task.responsible.id
          && task.type === 'onsite-inspection'
          && ['in-progress'].includes(task.status)
          && !ifAllInspectionsAreSendToNegation()
          && (task.visit ? task.visit?.planificationSignature.signature === '' : false)
          && checkIfHaveStartedAtDate()
          && inspection.status === 'in-progress'
          && roundSteps.haveUploadEvidence !== undefined
          && getLastRound.productNotPresented === false)
          || (
            getLastRound.productNotPresented
            && inspectionIsTheUser
            && checkIfHaveStartedAtDate()
            && inspection.status === 'in-progress'
            && ableButtonsIfRemoteInspectionWaitForNewDocuments
            && (task.type === 'onsite-inspection' ? task.visit !== null : true)
            && (task.visit ? task.visit?.planificationSignature.signature === '' : false)
          ),
        specialValidation: task.type === 'onsite-inspection' && !['quality', 'collaborator', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={sendToReview}
            type='primary-outlined'
            label={t('services.sendToReview') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inReview'
            alignContent='left'
          />
        ),
        available: (inspection.status === 'in-progress'
          && inspectionIsTheUser
          && checkIfHaveStartedAtDate()
          && !!roundSteps.haveProductUpdated
          && !!roundSteps.haveListCompleted
          && !!roundSteps.haveDictaminate
          && roundSteps.haveUploadEvidence !== undefined
          && checkAbleSentToReview()
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && (task.type === 'onsite-inspection' ? task.visit !== null : true)
          && (task.type === 'onsite-inspection' ? ifOnSiteInspectionHaveClientSign : true)
          // && evidenceUploaded
        )
          || (
            getLastRound.productNotPresented
            && inspectionIsTheUser
            && checkIfHaveStartedAtDate()
            && inspection.status === 'in-progress'
            && ableButtonsIfRemoteInspectionWaitForNewDocuments
            && (task.type === 'onsite-inspection' ? task.visit !== null : true)
            && (task.type === 'onsite-inspection' ? ifOnSiteInspectionHaveClientSign : true)
            && inspection.indicators.waitingToExpired === false
          ),
        specialValidation: apiType === 'admin' && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => setShowReviewInspectionModal(true)}
            type='primary-outlined'
            label={t('services.approvedInspection') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='needAproval'
            alignContent='left'
          />
        ),
        available: (inspection.status === 'in-review'
          && user.id !== inspector?.id
          && user.approver
          && user.norms.some(item => item.norm === inspection.norm)
          && checkIfHaveStartedAtDate()
          && (inspection.listCompleted === false ? true : roundSteps.haveListCompleted !== undefined)
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && (task.type === 'onsite-inspection' ? task.visit !== null : true)
          && (task.type === 'onsite-inspection' ? ifOnSiteInspectionHaveClientSign : true)
        )
          || (
            getLastRound.productNotPresented
            && user.approver
            && user.norms.some(item => item.norm === inspection.norm)
            && user.id !== inspector?.id
            && checkIfHaveStartedAtDate()
            && inspection.status === 'in-review'
            && ableButtonsIfRemoteInspectionWaitForNewDocuments
            && (task.type === 'onsite-inspection' ? task.visit !== null : true)
            && (task.type === 'onsite-inspection' ? ifOnSiteInspectionHaveClientSign : true)
          )
          || (
            inspection.status === 'in-review'
            && user.id !== inspector?.id
            && user.approver
            && user.norms.some(item => item.norm === inspection.norm)
            && (inspection.indicators.sendToNegation || inspection.indicators.waitingToExpired)
          ),
        specialValidation: apiType === 'admin' && user.approver && user.id !== inspector?.id && !['quality', 'master'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/none/${inspection.service.id}/${serviceCode}`)}
            type='primary-outlined'
            label={t('services.assignInspection') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspector'
            alignContent='left'
          />
        ),
        available: inspection.scheduled === false
          && utils.getCurrentRound(inspection.rounds).steps.length === 0
          && ableButtonsIfRemoteInspectionWaitForNewDocuments
          && inspection.status === 'in-progress'
          && (dictumService.id ? dictumService.indicators.isReadyForVisit === true : true)
          && (constancyService.id ? constancyService.indicators.requestIsCompleted === true : true)
          && (revisionService.id ? revisionService.indicators.requestIsCompleted === true : true)
          && inspection.indicators.waitingToExpired === false,
        specialValidation: apiType === 'admin' && ['master', 'operator'].includes(userRole)
      }
    ]);
  }, [
    inspection,
    user,
    i18n.language,
    task,
    apiType,
    inspections,
    evidenceUploaded,
    userRole,
    dictumService,
    revisionService,
    constancyService
  ]);

  const ifTaskHaveWitnessSigns = (): boolean => {
    if (task.visit) {
      if (task.visit?.witnesses.length === 0) {
        return false;
      }
    }

    return true;
  };

  const ifTaskHaveVisitorStatements = (): boolean => {
    if (task.visit) {
      if (task.visit.visitorStatements === null) {
        return false;
      }
    }

    return true;
  };

  const ableClosetask = (): boolean => {
    let inReviewInspections = 0;
    let concludedInspections = 0;

    for (let index = 0; index < inspections.length; index += 1) {
      if (inspections[index].status === 'in-review') {
        inReviewInspections += 1;
      }
      if (inspections[index].status === 'concluded') {
        concludedInspections += 1;
      }
    }

    if (['in-progress'].includes(task.status)
      && (inReviewInspections === inspections.length
        || concludedInspections === inspections.length
        || (inReviewInspections + concludedInspections) === inspections.length)
      && (task.visit !== null ? task.visit!.visitorStatements !== null : false)
      && (task.visit !== null ? task.visit!.witnesses.length > 0 : false)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <TaskDetail
        onClose={() => {
          setVisibleTaskDetailModal({
            taskID: getLastRound.task?.id || '',
            visible: false
          });

          storage.set('lastTaskDetailID', '');
          setTaskIDModalAfterActions('');

          if (onFetchInspection) onFetchInspection();
          setRefreshTask(!refreshTask);
        }}
        setVisibleTaskDetailModal={setVisibleTaskDetailModal}
        visibleTaskDetailModal={visibleTaskDetailModal}
        lookInTheTrash={lookInTheTrash}
        refreshTask={refreshTask}
      />
      <DictaminateInspectionModal
        inspection={inspection}
        setInspection={setInspection}
        visible={showDictaminateInspectionModal}
        setVisible={setShowDictaminateInspectionModal}
        dictaminate={dictaminateType}
        roundNumber={inspection.rounds.length}
        haveQuestionInspectionListAsNotComplies={haveQuestionInspectionListAsNotComplies}
        setHaveQuestionInspectionListAsNotComplies={setHaveQuestionInspectionListAsNotComplies}
      />
      <ModalView
        visible={showSuccessReviewRound.visible}
        onClose={() => setShowSuccessReviewRound({
          visible: false,
          showAlertLimitRoundReached: false,
          complies: false
        })}
        confirmationButonLabel={t('global.close') || ''}
        fullSzie={false}
        customComponent={
          <div className='dictaminate-inspection-modal'>
            <p>
              <Icon type='success' className='dictaminate-inspection-modal__icon' />
              <p>{t('global.correct')}</p>
              <br />
              {t('inspections.inspectionMessage')} {showSuccessReviewRound.complies ? t('inspections.approved') : t('inspections.deprecated')} {t('global.correctly')}
              <br />
              {
                showSuccessReviewRound.showAlertLimitRoundReached === false
                && inspection.indicators.waitingToExpired === false
                && inspection.status === 'in-progress' && (
                  ['DC', 'DN'].includes(inspection.service.code) && (
                    t('services.newRoundOnSite')
                  )
                )
              }
              {
                ['CC', 'CN', 'REV'].includes(inspection.service.code)
                && inspection.indicators.waitingForFilesForRemoteRound && (
                  t('services.newRoundRemote')
                )
              }
              {
                // eslint-disable-next-line no-nested-ternary
                showSuccessReviewRound.showAlertLimitRoundReached ? (
                  <span className='dictaminate-inspection-modal__alert'>{t('global.roundLimitReached')}</span>
                ) : (
                  ['CC', 'CN', 'REV'].includes(inspection.service.code)
                  && ['master', 'operator'].includes(userRole)
                  && inspection.indicators.waitingToExpired === false
                  && inspection.indicators.waitingForFilesForRemoteRound && (
                    <div className='dictaminate-inspection-modal__buttons-container'>
                      <div className='dictaminate-inspection-modal__buttons-container__option'>
                        <Button
                          onClick={() => navigate(`/constancy-products/detail/${inspection.product.id}/none/true`)}
                          label={t('services.uploadFiles') || ''}
                          type='primary'
                          size="big"
                          fullWidth={true}
                        />
                      </div>
                    </div>
                  )
                )
              }
            </p>
          </div>
        }
      />
      <ModalView
        ableFuncionalityEnterToConfirm={false}
        visible={showMessagePostEditInspectionModal}
        onClose={() => {
          if (commentReview.length === 0) {
            setErrors({
              commentReview: t('inspections.reviewMessageError')
            });
          } else {
            uploadReviewComment();
            setErrors({});
            setCommentReview('');
            setShowMessagePostEditInspectionModal(false);
            setInspectionLateralMenuValue('review');
          }
        }}
        fullSzie={false}
        onConfirm={uploadReviewComment}
        customComponent={
          <div className='dictaminate-inspection-modal'>
            <span>{t('inspections.reviewMessage')}</span>
            <Input
              type={'textarea'}
              value={commentReview}
              placeholder={t('global.comments') || ''}
              hasError={!!errors.commentReview}
              helperText={errors.commentReview}
              onChange={(value: string | number) => {
                setCommentReview(`${value}`);
              }}
              makeValidations={false}
              maxLength={1000}
              autofocus={true}
              allowLineBreaks={true}
            />
          </div>
        }
      />
      <ModalView
        visible={showReviewInspectionModal}
        onClose={() => setShowReviewInspectionModal(false)}
        confirmationButonLabel={t('signinView.resetPasswordButton.resetTitle') || ''}
        fullSzie={false}
        customComponent={
          <div className='dictaminate-inspection-modal'>
            <p className='dictaminate-inspection-modal__title'>{t('services.approvedInspectionTitle')}</p>
            {
              inspection.indicators.sendToNegation ? (
                <p>
                  {t('services.approvedExpiredInspectionDescription')}
                </p>
              ) : (
                <p>
                  {t('services.approvedInspectionDescription')}
                </p>
              )
            }
            <div className='dictaminate-inspection-modal__buttons-container'>
              {/* {
                inspection.indicators.sendToNegation === false && ( */}
              <div className='dictaminate-inspection-modal__buttons-container__option'>
                <Button
                  onClick={() => handleReviewInspection(false)}
                  label={t('inspections.deprecated') || ''}
                  type='red'
                  icon="dislike"
                  size="giant"
                  fullWidth={true}
                />
              </div>
              {/* )
               } */}
              <div className='dictaminate-inspection-modal__buttons-container__option'>
                <Button
                  onClick={() => handleReviewInspection(true)}
                  label={t('inspections.approved') || ''}
                  type='primary'
                  icon="like"
                  size="giant"
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
        }
      />
      <ModalView
        visible={showSuccessReviewInspectionModal}
        onClose={() => {
          setShowReviewInspectionModal(false);
          setShowSuccessReviewInspectionModal(false);
        }}
        confirmationButonLabel={t('global.accept') || ''}
        onConfirm={() => {
          setShowReviewInspectionModal(false);
          setShowSuccessReviewInspectionModal(false);
        }}
        fullSzie={false}
        customComponent={
          <div className='success-dictaminate-inspection-modal'>
            <p className='success-dictaminate-inspection-modal__title'>{t('global.correct')}</p>
            <Icon type="success" className="success-dictaminate-inspection-modal__img" />
            <p>
              {t('inspections.inspectionMessage')} {inspection.approved === true ? t('inspections.approved') : t('inspections.deprecated')} {t('global.correctly')}
            </p>
          </div>
        }
      />
      {
        visibleTaskDetailModal.taskID && userRole !== 'collaborator' ? (
          <div className="inspection-detail__navigation-bar">
            <Button
              onClick={() => {
                setVisibleTaskDetailModal({ ...visibleTaskDetailModal, visible: true });
                setVisibleInspectionDetailModal({
                  visible: false,
                  inspectionID: inspection.id
                });
              }}
              label={t('inspections.seeTask') || ''}
              type='secondary-outlined'
            />
          </div>
        ) : (
          <br />
        )
      }
      {
        ['CC', 'CN', 'REV'].includes(inspection.service.code)
        && inspection.indicators.waitingForFilesForRemoteRound
        && !inspection.indicators.changedFilesForRemoteRound
        && inspection.indicators.waitingToExpired === false
        && ['master', 'operator', 'collaborator'].includes(userRole) && (
          <div className='inspection-detail__alert'>
            <p>{t('global.uploadConstancyFilesToNewRoundAlert')}</p>
          </div>
        )
      }
      {
        (task.type === 'onsite-inspection'
          && user.id === task.responsible.id
          && dayjs(nowFormat).diff(String(task.datesRegistry.scheduledTo).substring(0, 10), 'day') === 0
          && inspection.indicators.waitingToExpired === false
          && (task.datesRegistry.startedAt === null
            || (task.visit ? task.visit?.planificationSignature.signature === '' : false))
        ) && (
          <div className='inspection-detail__alert'>
            <p>{t('tasks.startTaskAlert')}</p>
          </div>
        )
      }
      {
        (task.type === 'onsite-inspection'
          && user.id === task.responsible.id
          && ['concluded', 'in-review'].includes(inspection.status)
          && ['in-progress'].includes(task.status)
          && ((task.visit ? task.visit?.planificationSignature.signature !== '' : false)
            || inspection.indicators.waitingToExpired === true)
          && (ifTaskHaveWitnessSigns() === false
            || ifTaskHaveVisitorStatements() === false)) && (
          <div className='inspection-detail__alert'>
            <p>{t('tasks.uploadWitnessSignsAndStatements')}</p>
          </div>
        )
      }
      {
        (user.id === task.responsible.id
          && task.datesRegistry.endedAt === null
          && ableClosetask()) && (
          <div className='inspection-detail__alert'>
            <p>{t('tasks.shouldBeCloseTask')}</p>
          </div>
        )
      }
      <div className='inspection-detail__main'>
        <div className="inspection-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                element: (
                  <div >
                    <LateralMenu
                      value={inspectionLateralMenuValue}
                      onSelect={handleSelectLateralMenu}
                      options={setlateralmenuOptions()}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="inspection-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/inspection.detail.${inspection.id}.${lookInTheTrashValues[lookInTheTrash ? 'true' : 'false'] || 'false'}.none.none`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/inspection.detail.${inspection.id}.${lookInTheTrashValues[lookInTheTrash ? 'true' : 'false'] || 'false'}.none.none`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
          />
          {getContent()}
        </div>
      </div>
    </>
  );
};

export default InspectionDetail;
