import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Pagination,
  Spinner
} from '../../../../components';

import { utils } from '../../../../helpers';
import {
  BinnacleTableHeader,
  ElementTypes,
  FilterTypes,
  TableOrderDirection
} from '../../../../interfaces';
import FilterModal from './components/FilterModal';
import MobileFilter from './components/MobileFilter';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';

const itemsWithLimitLength = ['description', 'inspectionAddress', 'visit.address', 'brand'];

interface Props {
  headers: BinnacleTableHeader[]
  items: any[]
  currentPage?: number
  perPage?: number
  totalItems?: number
  onChangeCurrentPage?: (_currentPage: number) => void
  onClickRow?: (_item: any) => void
  onChangeOrder: (_order: string, _direction: TableOrderDirection | 'none') => void
  filters: { [name: string]: string }
  setFilters: (_value: { [name: string]: string }) => void
  orderBy: string
  orderDirection: TableOrderDirection | 'none'
  elementsType: 'binnacle' | 'trashCan'
  isPending: boolean
  handleSearch: (_whitoutFilters?: boolean) => void
  element: ElementTypes
  startTour: () => void
}

const Table: React.FC<Props> = (props) => {
  const {
    headers = [],
    items = [],
    currentPage = 0,
    perPage = 0,
    totalItems = 0,
    onChangeCurrentPage = (_currentPage: number) => { },
    onClickRow,
    filters,
    setFilters,
    onChangeOrder,
    orderBy,
    orderDirection,
    elementsType,
    isPending,
    handleSearch,
    element,
    startTour
  } = props;

  const {
    userRole,
    apiType
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [_isPending, _startTransition] = useTransition();
  const [headerFilter, setHeaderFilter] = useState<FilterTypes>('none');
  const [activeModal, setActiveModal] = useState<string>('');
  const [localHeaders, setLocalHeaders] = useState<BinnacleTableHeader[]>([]);

  const handleCloseModalFilters = () => {
    setActiveModal('');
  };

  const handleClearFilter = () => {
    const keys = Object.keys(filters);

    for (let index = 0; index < keys.length; index += 1) {
      filters[keys[index]] = '';
    }

    setFilters({ ...filters });
    onChangeOrder('s_createdAt', 'DESC');
  };

  const setFilterIcon = (header: BinnacleTableHeader) => {
    if (elementsType === 'trashCan' && header.value === 'status') {
      return (
        <div
          className='header-container header-container--no-click-events'
        >
          <span>{header.label}</span>
        </div>
      );
    }

    return (
      <>
        <div
          className='header-container'
          onClick={() => onClickHeader(header.filterType, header.filterName)}
        >
          <span>{header.label}</span>
          <Icon
            type={filters[header.filterName] || orderBy === header.filterName ? 'filterActive' : 'filter'}
            alt='Filter'
            className='binnacle-table__header__filter-icon'
          />
        </div>
        <FilterModal
          filter={headerFilter}
          onChangeOrder={onChangeOrder}
          headerValue={header.filterName}
          showModal={header.filterName === activeModal}
          onCloseModalFilters={handleCloseModalFilters}
          filterValue={filters[header.filterName]}
          setFilterValue={(value: string) => {
            setActiveModal(header.filterName);
            setFilters({
              ...filters,
              [header.filterName]: value
            });
          }}
          onClearFilter={() => {
            setFilters({
              ...filters,
              [header.filterName]: ''
            });
            onChangeOrder('none', 'ASC');
          }}
          orderBy={orderBy}
          orderDirection={orderDirection}
          handleSearch={handleSearch}
        />
      </>
    );
  };

  const onClickHeader = useCallback((filterType: FilterTypes, value: string) => {
    setHeaderFilter(filterType);

    if (value !== activeModal) {
      setActiveModal(value);
    }
  }, [activeModal]);

  useEffect(() => {
    const allowedIDs = [
      'binnacle-table-modal',
      'header-element',
      'input-filter',
      'filter-modal-icon',
      'container-filter-modal',
      'filter-modal-text'
    ];
    // eslint-disable-next-line no-undef
    document.addEventListener('mouseup', (event: any) => {
      if (!allowedIDs.includes(event.target.id)) {
        setActiveModal('');
      }
    });
  }, [activeModal]);

  useEffect(() => {
    if (['finance', 'master'].includes(userRole)
      && element === 'services') {
      headers.shift();
      headers.unshift({
        label: 'UUID',
        value: 'indicators.invoicedInfo.UUID',
        format: 'none',
        filterType: 'text',
        filterName: 's_UUID'
      });
      headers.unshift({
        label: t('global.dateBilling'),
        value: 'indicators.invoicedInfo.date',
        format: 'date',
        filterType: 'date',
        filterName: 's_invoicedInfoDate'
      });
      headers.unshift({
        label: t('global.invoiceNumber'),
        value: 'indicators.invoicedInfo.invoiceNumber',
        format: 'none',
        filterType: 'text',
        filterName: 's_invoiceNumber'
      });
      headers.unshift({
        label: t('global.invoiced'),
        value: 'indicators.invoiced',
        format: 'boolean',
        filterType: 'boolean',
        filterName: 'b_invoiced'
      });
      headers.unshift({
        label: t('global.createdAt'),
        value: 'createdAt',
        format: 'date-time',
        filterType: 'date',
        filterName: 's_createdAt'
      });
    }

    if (elementsType === 'trashCan') {
      setLocalHeaders(headers.filter(obj => obj.filterName !== 's_actionsEnabled' && (obj.onlyAdmin !== undefined ? obj.onlyAdmin === true && apiType === 'admin' : true)));
    } else {
      setLocalHeaders(headers.filter(obj => (obj.onlyAdmin !== undefined ? obj.onlyAdmin === true && apiType === 'admin' : true)));
    }
  }, [headers, userRole, element, elementsType, apiType]);

  return (
    <div className='binnacle-table-container' >
      <div className='binnacle-table-container__reset-filter-container'>
        <Button
          type={'secondary'}
          onClick={startTour}
          label={t('tours.showTour') || ''}
          icon='tour'
          rounded={true}
          fullWidth={false}
        />
        <Button
          type='secondary-outlined'
          border='secondary-outlined'
          onClick={() => {
            handleClearFilter();
            setActiveModal('');
            handleSearch(true);
          }}
          transparent={true}
          label={t('services.resetAllfilters') || ''}
          icon='resetFilter'
          iconPosition='left'
          id='binnacle-filter-clear'
        />
      </div>
      <MobileFilter
        handleClearFilter={handleClearFilter}
        headers={localHeaders}
        setFilters={setFilters}
        onChangeOrder={onChangeOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
      />
      <table className='binnacle-table' id="binnacle-table">
        <thead id='binnacle-filter'>
          <tr>
            {
              localHeaders.map((header: BinnacleTableHeader, index: number) => (
                <th key={index} id='header-element' >
                  <div className='header-container-all'>
                    {setFilterIcon(header)}
                  </div>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            isPending && (
              <tr className='spinner-container'>
                <td className='spinner-container__row' colSpan={localHeaders.length}>
                  <div className='spinner-container__container'><Spinner /></div>
                </td>
              </tr>
            )}
          {
            items.length <= 0 ? (
              <tr className='empty-state'>
                <td className='empty-state__row' colSpan={localHeaders.length}>
                  <div className='empty-state__container'>
                    <p className='empty-state__row__empty-message'>{t('global.itemsNotFound')}</p>
                    <Icon type='emptyState' className='empty-state__icon' />
                  </div>
                </td>
              </tr>
            ) : (
              items.map((item: any, index: number) => (
                <tr
                  className={onClickRow ? 'binnacle-table__row' : 'binnacle-table__row binnacle-table__row--disabled'}
                  key={index}
                  onClick={onClickRow ? () => onClickRow(item) : () => { }}
                >
                  {
                    localHeaders.map((header: BinnacleTableHeader, _index: number) => (
                      <td key={_index}>
                        <div className='binnacle-table__cell'>
                          <div className='binnacle-table__cell__label'>
                            {header.label}
                          </div>
                          <div className={`binnacle-table__cell__value binnacle-table__cell__value${header.value === 'description' ? '--cell-description' : ''}`}>
                            {
                              itemsWithLimitLength.includes(header.value) ? (
                                utils.limitValueLength(
                                  utils.formatValue(
                                    header.format,
                                    utils.getNestedValue(item, header.value),
                                    '',
                                    _index + 1
                                  ),
                                  25
                                )
                              ) : (
                                utils.formatValue(
                                  header.format,
                                  utils.getNestedValue(item, header.value),
                                  '',
                                  _index + 1
                                )
                              )
                            }
                          </div>
                        </div>
                      </td>
                    ))
                  }
                </tr>
              ))
            )
          }
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        perPage={perPage}
        totalItems={totalItems}
        onChangeCurrentPage={onChangeCurrentPage}
      />
    </div>
  );
};

export default Table;
