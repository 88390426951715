import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
  Button,
  ExcelTable,
  File,
  FileInput,
  Icon,
  Modal,
  Title
} from '../../../../../../../../components';
import { FileInputData, ExcelTableHeader } from '../../../../../../../../interfaces';
import './styles.scss';
import helper, { ProductRow } from './helper';
import { AppContext } from '../../../../../../../../context/AppContext';
import { utils } from '../../../../../../../../helpers';

const Papa = require('papaparse');

interface ProductPayload {
  normCode: string
  fase: string
  brand: string
  presentation: string
  description: string
  code: string
  design: string
  package: string
  long: string
  high: string
  radius: string
  urgent: string
}

interface Props {
  onCreateProducts: (_products: ProductPayload[]) => void
}

export const CSVForm: React.FC<Props> = (props) => {
  const { onCreateProducts } = props;

  const {
    soundEffects
  } = useContext(AppContext);

  const [operatingSystem, setOperatingSystem] = useState<string>('windows');
  const [fileErrors, setFileErrors] = useState<{ [name: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorsIDs, setErrorsIDs] = useState<string[]>([]);
  const [productsToBeCreated, setProductsToBeCreated] = useState<ProductPayload[]>([]);

  const { t } = useTranslation();

  const headerExcelTable: ExcelTableHeader[] = [
    {
      label: t('global.norm'),
      type: 'norm'
    },
    {
      label: t('global.fase051'),
      type: 'fase'
    },
    {
      label: t('global.brand'),
      type: 'text'
    },
    {
      label: t('global.presentation'),
      type: 'text'
    },
    {
      label: t('global.description'),
      type: 'text'
    },
    {
      label: t('global.codes'),
      type: 'text'
    },
    {
      label: t('global.design'),
      type: 'text'
    },
    {
      label: t('global.package'),
      type: 'text'
    },
    {
      label: t('global.long'),
      type: 'text'
    },
    {
      label: t('global.high'),
      type: 'text'
    },
    {
      label: t('global.radius'),
      type: 'text'
    },
    {
      label: t('global.urgent'),
      type: 'text'
    }
  ];

  const hanldeDownloadTemplate = async () => {
    // eslint-disable-next-line no-undef
    const link = document.createElement('a');
    // eslint-disable-next-line no-undef
    link.href = operatingSystem === 'windows'
      ? `https://s3.amazonaws.com/images.cecsa.com/documents/plantilla-para-partidas-constancia-windows.csv?version=${new Date().getTime()}`
      : `https://s3.amazonaws.com/images.cecsa.com/documents/plantilla-para-partidas-constancia.csv?version=${new Date().getTime()}`;
    link.download = t('services.csv.document');
    link.click();
  };

  const createProducts = () => {
    const auxErrorsIDs: string[] = [];
    const errors: { [name: string]: string } = {};
    const auxProducts: ProductPayload[] = productsToBeCreated;

    const keyValueTradduction = {
      A: 'normCode',
      B: 'fase',
      C: 'brand',
      D: 'presentation',
      E: 'description',
      F: 'code',
      G: 'design',
      H: 'package',
      I: 'long',
      J: 'high',
      K: 'radius',
      L: 'urgent'
    };

    for (let index = 0; index < errorsIDs.length; index += 1) {
      const rowResult = helper.validateProductCell(errorsIDs[index]);

      if (rowResult.isValid) {
        const propertyName = (keyValueTradduction as any)[errorsIDs[index].substring(0, 1)];

        if (propertyName === 'package') {
          const value = rowResult.value.toLocaleUpperCase();

          if (value === 'CILINDRO') {
            // eslint-disable-next-line max-len
            (auxProducts[Number(errorsIDs[index].substring(1)) - 2] as any)[propertyName] = 'cylindrical';
          } else {
            // eslint-disable-next-line max-len
            (auxProducts[Number(errorsIDs[index].substring(1)) - 2] as any)[propertyName] = rowResult.value.toLocaleLowerCase();
          }
        } else {
          // eslint-disable-next-line max-len
          (auxProducts[Number(errorsIDs[index].substring(1)) - 2] as any)[propertyName] = rowResult.value;
        }

        setProductsToBeCreated(auxProducts);
      } else {
        Object.entries(rowResult.errors).forEach(([key, value]) => {
          errors[key] = value;
          auxErrorsIDs.push(key);
        });
      }
    }

    setErrorsIDs(auxErrorsIDs);
    setFileErrors(errors);
    setTimeout(() => setError(errors), 1500);

    if (Object.keys(errors).length === 0) {
      onCreateProducts(auxProducts);
      setSuccessMessage('');
      setProductsToBeCreated([]);
      setFileErrors({});
    }
  };

  const setValues = (id: string, value: string) => {
    // eslint-disable-next-line no-undef
    const inputElement: any = document.getElementById(id);

    if (inputElement) {
      inputElement.value = utils.removeAccents(`${value}`);
    }
  };

  const showProductsToCreate = async (auxProductsToBeCreated: ProductPayload[]) => {
    // eslint-disable-next-line no-undef
    const exceltable = document.getElementById('excel-table-container');

    const keyValueTradduction: { [name: string]: string } = {
      normCode: 'A',
      fase: 'B',
      brand: 'C',
      presentation: 'D',
      description: 'E',
      code: 'F',
      design: 'G',
      package: 'H',
      long: 'I',
      high: 'J',
      radius: 'K',
      urgent: 'L'
    };

    setTimeout(() => {
      for (let index = 0; index < auxProductsToBeCreated.length; index += 1) {
        const product = auxProductsToBeCreated[index];
        const keys = Object.keys(product);

        keys.forEach(key => {
          const value = (product as any)[key];

          if (keyValueTradduction[key]) {
            setValues(`${keyValueTradduction[key]}${index + 2}`, value);
          }
        });
      }

      if (exceltable) {
        exceltable.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }

      Modal.close();
    }, 1500);
  };

  const setError = (errors: { [name: string]: string }) => {
    const idsArr: { [name: string]: string } = {};

    Object.entries(errors).forEach(([key, _value]) => {
      // eslint-disable-next-line no-undef
      const element: HTMLInputElement | null = document.querySelector(`#${key}`);

      idsArr[key.substring(0, 1)] = key.substring(1);

      if (element) {
        element.classList.add('excel-table__input--error');
      }
    });

    Modal.close();
  };

  const parseFile = (file: any) => {
    Modal.fireLoading();

    setFileErrors({});

    const parsedProducts: ProductPayload[] = [];
    const encoding = operatingSystem === 'windows' ? 'ascii' : 'utf8';
    const errors: { [name: string]: string } = {};
    const auxErrorsIDs: string[] = [];
    let fileParsedHaveErrors: boolean = false;
    let rowNumber: number = 2;

    Papa.parse(file, {
      header: true,
      encoding,
      step: (row: any = {}, parser: any) => {
        const { data = {} } = row;

        const parsedRow: ProductRow = {
          normCode: utils.removeAccents(data.Norma || ''),
          fase: helper.formatFase(String(data['Fase (Solo NOM 051)'])),
          brand: utils.removeAccents(data.Marca || ''),
          presentation: utils.removeAccents(data['Presentación'] || ''),
          description: utils.removeAccents(data['Descripción'] || ''),
          code: utils.removeAccents(data['Código(s)'] || ''),
          design: utils.removeAccents(data['Diseño'] || ''),
          package: utils.removeAccents(data['TIPO DE ENVASE'] || ''),
          long: utils.removeAccents(data['Largo (cm)'] || ''),
          high: utils.removeAccents(data['Alto (cm)'] || ''),
          radius: utils.removeAccents(data['Radio (cm)'] || ''),
          urgent: utils.removeAccents(data.Urgente || '')
        };

        if (helper.isAnEmptyRow(parsedRow)) {
          rowNumber -= 1;
          parser.abort();
          return;
        }

        const rowResult = helper.validateProductRow(parsedRow, rowNumber);

        if (!rowResult.isValid) {
          fileParsedHaveErrors = true;

          Object.entries(rowResult.errors).forEach(([key, value]) => {
            errors[key] = value;
            auxErrorsIDs.push(key);

            if (key.startsWith('H')
              && value === i18next.t('services.errors.enumsPackage')) {
              const number: string = key.substring(1);
              auxErrorsIDs.push(`I${number}`);
              auxErrorsIDs.push(`J${number}`);
              auxErrorsIDs.push(`K${number}`);
            }
          });
        }

        parsedProducts.push({
          normCode: helper.parseNormCode(parsedRow.normCode),
          fase: helper.parseNorm051Fase(helper.parseNormCode(parsedRow.normCode), parsedRow.fase),
          brand: parsedRow.brand.toString(),
          presentation: parsedRow.presentation.toString(),
          description: parsedRow.description.toString(),
          code: parsedRow.code.toString(),
          design: helper.parseDesign(parsedRow.design),
          package: parsedRow.package.toString().toLocaleUpperCase(),
          long: parsedRow.long.toString().replace(/[a-zA-Z ^\s@$%^&*()\-/´+{},:¨*_|°"#?¡¿!='ÑñáéíóúÁÉÍÓÚ´]/g, ''),
          high: parsedRow.high.toString().replace(/[a-zA-Z ^\s@$%^&*()\-/´+{},:¨*_|°"#?¡¿!='ÑñáéíóúÁÉÍÓÚ´]/g, ''),
          radius: parsedRow.radius.toString().replace(/[a-zA-Z ^\s@$%^&*()\-/´+{},:¨*_|°"#?¡¿!='ÑñáéíóúÁÉÍÓÚ´]/g, ''),
          urgent: helper.parseDesign(parsedRow.urgent)
        });

        rowNumber += 1;
      },
      complete: () => {
        if (!parsedProducts.length && Object.keys(errors).length === 0) {
          Modal.fireError(t('services.productsNotFoundCSV'), undefined, soundEffects);
          return;
        }

        if (fileParsedHaveErrors) {
          setProductsToBeCreated(parsedProducts);
          setFileErrors(errors);
          setErrorsIDs(auxErrorsIDs);
          showProductsToCreate(parsedProducts);
          setSuccessMessage('');
          setTimeout(() => setError(errors), 1500);
        } else {
          setSuccessMessage(t('services.productsFoundCSV') || '');
          setProductsToBeCreated(parsedProducts);
          showProductsToCreate(parsedProducts);
        }
      },
      error: () => {
        Modal.fireError(t('services.errors.csv'), undefined, soundEffects);
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const OS: string = String(navigator.userAgent || '').toLowerCase();

    if (OS.includes('windows')) {
      setOperatingSystem('windows');
    } else {
      setOperatingSystem('other');
    }
  }, []);

  return (
    <div className='csv-form'>
      <Title title={t('services.instructions')} type='secondary' />
      <p>{t('services.csv.steps')}</p>
      <p>{t('services.csv.1')}</p>
      <File name={t('services.csv.document')} onDownload={hanldeDownloadTemplate} />
      <p>{t('services.csv.2')}</p>
      <p>{t('services.csv.3')}</p>
      <p>{t('services.csv.4')}</p>
      <br />
      <FileInput
        fileName={t('services.csv.title') || ''}
        isMultiple={false}
        acceptedFileFormats='.csv'
        onSelectFile={(payload: FileInputData[]) => {
          parseFile(payload[0].data);
        }}
      />
      <div className='csv-form__excel-table-container' id='excel-table-container'>
        {
          (successMessage || Object.keys(fileErrors).length !== 0) && (
            <>
              <Title title={successMessage || t('services.csv.error')} type={successMessage ? 'secondary' : 'error'} />
              <ExcelTable
                headers={headerExcelTable}
                disable={successMessage !== ''}
                inputsErrors={fileErrors}
                rowsQuantity={productsToBeCreated.length}
              />
              <div className='csv-form__excel-table-container__footer-container'>
                <div className='csv-form__excel-table-container__footer-container__title'>
                  <Icon type='alertBullet' className='csv-form__excel-table-container__footer-container__title__icon' />
                  <Title title={t('services.csv.confirmation')} type='secondary' />
                </div>
                <div className='csv-form__excel-table-container__footer-container__buttons-container'>
                  <Button
                    onClick={() => {
                      setSuccessMessage('');
                      setProductsToBeCreated([]);
                      setFileErrors({});
                    }}
                    type='secondary-outlined'
                    transparent={true}
                    border='secondary-outlined'
                    label={t('services.cleanForm') || ''}
                  />
                  <Button
                    onClick={createProducts}
                    type='secondary-outlined'
                    label={t('global.accept') || ''}
                  />
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default CSVForm;
