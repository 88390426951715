import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';

import { Modal, Spinner, TaskDetail } from '../../components';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';
import { useNavigate, useResource } from '../../hooks';

import {
  BinnacleTableHeader,
  Collaborator,
  ConstancyProduct,
  ConstancyService,
  DictumProduct,
  DictumService,
  ElementTypes,
  Pagination,
  TableOrderDirection,
  User
} from '../../interfaces';
import storage from '../../storage';
import {
  Footer,
  Header,
  Table
} from './components';

import './styles.scss';

const Binnacle = () => {
  const {
    fetchResources
  } = useResource<any>();

  const {
    updateResource
  } = useResource<User>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const {
    apiType,
    soundEffects,
    visibleTaskDetailModal,
    setVisibleTaskDetailModal,
    userToursDone,
    setUserToursDone,
    userID
  } = useContext(AppContext);

  const navigate = useNavigate();

  const getElementsFromLocalStorage = (property: string) => {
    if (storage.get('binnacleFilters')) {
      return JSON.parse(storage.get('binnacleFilters') || '')[property];
    }

    navigate('/signin/no-route');
    return null;
  };

  const [isPending, startTransition] = useTransition();
  const [elementsType, setElementsType] = useState<'binnacle' | 'trashCan'>(getElementsFromLocalStorage('elementsType') || 'binnacle');
  const [element, setElement] = useState<ElementTypes>(getElementsFromLocalStorage('element') || 'services');
  const [type, setType] = useState<string>(getElementsFromLocalStorage('type') || 'dictum');
  const [emptyList, setEmptyList] = useState<boolean>(true);
  const [pagination, setPagination] = useState<Pagination>(getElementsFromLocalStorage('pagination') || {
    currentPage: 1,
    perPage: 20,
    totalItems: 0
  });
  const [orderBy, setOrderBy] = useState<string>(getElementsFromLocalStorage('orderBy') || 's_createdAt');
  const [orderDirection, setOrderDirection] = useState<TableOrderDirection | 'none'>(getElementsFromLocalStorage('orderDirection') || 'DESC');
  // eslint-disable-next-line max-len
  const [items, setItems] = useState<DictumService[] | ConstancyService[] | DictumProduct[] | ConstancyProduct[]>([]);
  const [filters, setFilters] = useState<{ [name: string]: string }>(getElementsFromLocalStorage('filters') || {});

  useEffect(() => {
    setTimeout(() => {
      setElementsType(getElementsFromLocalStorage('elementsType') || 'binnacle');
      setElement(getElementsFromLocalStorage('element') || 'services');
      setType(getElementsFromLocalStorage('type') || 'dictum');
      setPagination(getElementsFromLocalStorage('pagination') || {
        currentPage: 1,
        perPage: 20,
        totalItems: 0
      });
      setOrderBy(getElementsFromLocalStorage('orderBy') || 's_createdAt');
      setOrderDirection(getElementsFromLocalStorage('orderDirection') || 'DESC');
      setFilters(getElementsFromLocalStorage('filters') || {});
    }, 100);
  }, []);

  const handleChangePage = (page: number) => {
    startTransition(() => {
      setPagination({
        ...pagination,
        currentPage: page
      });
    });
  };

  const handelChangeOrder = (order: string, direction: TableOrderDirection | 'none') => {
    startTransition(() => {
      setOrderBy(order);
      setOrderDirection(direction);
    });
  };

  const formatFilters = (whitoutFilters = false): { [name: string]: string } => {
    if (whitoutFilters === false) {
      const auxFilters: { [name: string]: string } = filters;
      const keys = Object.keys(auxFilters);

      for (let index = 0; index < keys.length; index += 1) {
        if (auxFilters[keys[index]] === 'none') {
          auxFilters[keys[index]] = '';
        }
      }

      return auxFilters;
    }

    return {};
  };

  const renderEntityDetail = (item: any) => {
    driverObj.destroy();

    const paths = {
      services: {
        dictum: () => navigate(`/dictum-services/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}`),
        constancy: () => navigate(`/constancy-services/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}`),
        revision: () => navigate(`/revision-services/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}`)
      },
      products: {
        dictum: () => navigate(`/dictum-products/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'false'}`),
        constancy: () => navigate(`/constancy-products/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/false`),
        revision: () => navigate(`/revision-products/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/false`)
      },
      inspections: {
        DC: () => navigate(`/inspection/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/none/none`),
        CC: () => navigate(`/inspection/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/none/none`),
        DN: () => navigate(`/inspection/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/none/none`),
        CN: () => navigate(`/inspection/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/none/none`),
        REV: () => navigate(`/inspection/detail/${item.id}/${elementsType === 'trashCan' ? 'true' : 'none'}/none/none`)
      },
      tasks: {
        'constancy-inspection': () => {
          setVisibleTaskDetailModal({
            taskID: item.id,
            visible: true
          });
        },
        'remote-inspection': () => {
          setVisibleTaskDetailModal({
            taskID: item.id,
            visible: true
          });
        },
        'onsite-inspection': () => {
          setVisibleTaskDetailModal({
            taskID: item.id,
            visible: true
          });
        },
        'revision-inspection': () => {
          setVisibleTaskDetailModal({
            taskID: item.id,
            visible: true
          });
        }
      }
    };

    (paths[element] as any)[type]();
  };

  const handleSearch = (whitoutFilters = false) => {
    const querys = {
      services: {
        dictum: 'dictum-services',
        constancy: 'constancy-services',
        revision: 'revision-services'
      },
      products: {
        dictum: 'dictum-products',
        constancy: 'constancy-products',
        revision: 'revision-products'
      },
      inspections: {
        DC: 'inspections',
        CC: 'inspections',
        DN: 'inspections',
        CN: 'inspections',
        REV: 'inspections'
      },
      tasks: {
        'remote-inspection': 'tasks',
        'constancy-inspection': 'tasks',
        'onsite-inspection': 'tasks',
        'revision-inspection': 'tasks'
      }
    };

    fetchResources(
      {
        resourcePath: `/${apiType}/${(querys[element] as any)[type]}`,
        filters: {
          ...formatFilters(whitoutFilters),
          s_serviceCode: element === 'inspections' ? type : '',
          s_type: element === 'tasks' ? type : '',
          b_lookInTheTrash: elementsType === 'trashCan' ? 'true' : ''
        },
        pagination: {
          n_perPage: pagination.perPage,
          n_currentPage: pagination.currentPage,
          n_totalItems: pagination.totalItems
        },
        ordering: {
          s_orderBy: orderBy,
          s_orderDirection: orderDirection === 'none' ? '' : orderDirection
        }
      },
      (data) => {
        Modal.fireLoading();

        startTransition(() => {
          setItems(data.items);

          if (data.items.length >= 0) {
            setEmptyList(false);
          }

          if (utils.paginationHasChange(pagination, data.pagination)) {
            setPagination({
              ...data.pagination,
              currentPage: 1
            });
          }
        });
        Modal.close();
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const throttledHandleRequest = utils.throttle(handleSearch, 1000);

  useEffect(() => {
    throttledHandleRequest();
  }, [
    orderBy,
    orderDirection,
    pagination.currentPage,
    element,
    type,
    elementsType,
    apiType
  ]);

  useEffect(() => {
    const binnacleFilters = {
      elementsType,
      element,
      type,
      pagination,
      orderBy,
      orderDirection,
      filters
    };

    storage.set('binnacleFilters', JSON.stringify(binnacleFilters));
  }, [
    elementsType,
    element,
    type,
    pagination,
    orderBy,
    orderDirection,
    filters
  ]);

  const { t } = useTranslation();

  const headers: { [name: string]: { [key: string]: BinnacleTableHeader[] } } = {
    services: {
      dictum: [
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.petitionNumber'),
          value: 'petitionNumber',
          format: 'none',
          filterType: 'petitionNumber',
          filterName: 's_petitionNumber'
        },
        {
          label: t('services.modelsQuantity'),
          value: 'modelsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_modelsQuantity'
        },
        {
          label: t('global.nextAction'),
          value: 'actionsEnabled',
          format: 'actionsEnabledDictumService',
          filterType: 'dictumServiceActionsEnabled',
          filterName: 's_actionsEnabled'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'dictumServiceStatus',
          filterName: 's_status'
        },
        {
          label: t('services.customsClearanceDate'),
          value: 'outOfCustomsDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_outOfCustomsDate'
        },
        {
          label: t('services.closedDte'),
          value: 'closedDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_closedDate'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('services.requestCompleted'),
          value: 'indicators.requestIsCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_requestIsCompleted'
        },
        {
          label: t('services.visitReady'),
          value: 'indicators.isReadyForVisit',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_isReadyForVisit'
        },
        {
          label: t('global.status.daysToClose'),
          value: 'daysToClose',
          format: 'none',
          filterType: 'number',
          filterName: 's_daysToClose'
        }
      ],
      constancy: [
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.urgent'),
          value: 'indicators.urgent',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_indicatorsUrgent'
        },
        {
          label: t('tasks.filters.number'),
          value: 'requestNumber',
          format: 'none',
          filterType: 'text',
          filterName: 's_requestNumber'
        },
        {
          label: t('global.nextAction'),
          value: 'actionsEnabled',
          format: 'actionsEnabledConstancyService',
          filterType: 'constancyServiceActionsEnabled',
          filterName: 's_actionsEnabled'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'constancyServiceStatus',
          filterName: 's_status'
        },
        {
          label: t('services.modelsQuantity'),
          value: 'modelsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_modelsQuantity'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('services.requestCompleted'),
          value: 'indicators.requestIsCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_requestIsCompleted'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      revision: [
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.urgent'),
          value: 'indicators.urgent',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_indicatorsUrgent'
        },
        {
          label: t('tasks.filters.number'),
          value: 'requestNumber',
          format: 'none',
          filterType: 'text',
          filterName: 's_requestNumber'
        },
        {
          label: t('global.nextAction'),
          value: 'actionsEnabled',
          format: 'actionsEnabledRevisionService',
          filterType: 'revisionServiceActionsEnabled',
          filterName: 's_actionsEnabled'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'constancyServiceStatus',
          filterName: 's_status'
        },
        {
          label: t('services.modelsQuantity'),
          value: 'modelsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_modelsQuantity'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('services.requestCompleted'),
          value: 'indicators.requestIsCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_requestIsCompleted'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ]
    },
    products: {
      dictum: [
        {
          label: t('services.requestDate'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('global.norm'),
          value: 'normCode',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_normCode'
        },
        {
          label: t('services.roundTitle'),
          value: 'round',
          format: 'none',
          filterType: 'number',
          filterName: 's_round'
        },
        {
          label: t('global.nextAction'),
          value: 'actionsEnabled',
          format: 'actionsEnabledDictumProduct',
          filterType: 'dictumProductActionsEnabled',
          filterName: 's_actionsEnabled'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'dictumProductStatus',
          filterName: 's_status'
        },
        {
          label: t('global.status.daysToClose'),
          value: 'daysToClose',
          format: 'none',
          filterType: 'number',
          filterName: 's_daysToClose'
        },
        {
          label: t('global.invoice'),
          value: 'invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_invoice'
        },
        {
          label: t('global.subInvoice'),
          value: 'subInvoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_subInvoice'
        },
        {
          label: t('services.dictumNumberProduct'),
          value: 'service.number',
          format: 'none',
          filterType: 'petitionNumber',
          filterName: 's_serviceNumber'
        },
        {
          label: t('global.brand'),
          value: 'brand',
          format: 'none',
          filterType: 'text',
          filterName: 's_brand'
        },
        {
          label: t('global.description'),
          value: 'description',
          format: 'none',
          filterType: 'text',
          filterName: 's_description'
        },
        {
          label: t('services.code'),
          value: 'code',
          format: 'none',
          filterType: 'text',
          filterName: 's_code'
        },
        {
          label: t('global.model2'),
          value: 'code2',
          format: 'none',
          filterType: 'text',
          filterName: 's_code2'
        },
        {
          label: t('services.modelsQuantity'),
          value: 'modelsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 's_modelsQuantity'
        },
        {
          label: t('global.tariffFraction'),
          value: 'tariffFraction',
          format: 'none',
          filterType: 'text',
          filterName: 's_tariffFraction'
        },
        {
          label: t('services.sendInlayout1'),
          value: 'indicators.sentInLayout1Date',
          format: 'date',
          filterType: 'date',
          filterName: 's_sentInLayout1Date'
        },
        {
          label: t('global.rejectReasons.12'),
          value: 'applicationValidity',
          format: 'date',
          filterType: 'date',
          filterName: 's_applicationValidity'
        },
        {
          label: t('services.labelingMode'),
          value: 'labelingMode',
          format: 'none',
          filterType: 'labelingMode',
          filterName: 's_labelingMode'
        },
        {
          label: 'UMC',
          value: 'umc',
          format: 'none',
          filterType: 'number',
          filterName: 's_umc'
        },
        {
          label: t('services.umcQuantity'),
          value: 'umcQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_umcQuantity'
        },
        {
          label: t('services.labelsToPut'),
          value: 'labelsToPut',
          format: 'none',
          filterType: 'number',
          filterName: 'n_labelsToPut'
        },
        {
          label: t('services.requested'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_collaboratorName'
        },
        {
          label: t('global.validationDate'),
          value: 'indicators.dateOfValidation',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_dateOfValidation'
        },
        {
          label: t('services.customsClearanceDate'),
          value: 'outOfCustomsDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_outOfCustomsDate'
        },
        {
          label: t('services.closedDte'),
          value: 'closedDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_closedDate'
        },
        {
          label: t('services.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approver',
          onlyAdmin: true
        },
        {
          label: t('global.sentInLayout2Date'),
          value: 'indicators.sentInLayout2Date',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_indicatorsSentInLayout2Date'
        },
        {
          label: t('global.sentInLayout1Date'),
          value: 'indicators.sentInLayout1Date',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_indicatorsSentInLayout1Date'
        },
        {
          label: t('services.attachedToService'),
          value: 'indicators.attachedToTheService',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_attachedToTheService'
        }
      ],
      constancy: [
        {
          label: t('services.requestDate'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('global.norm'),
          value: 'normCode',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_normCode'
        },
        {
          label: t('services.roundTitle'),
          value: 'round',
          format: 'none',
          filterType: 'number',
          filterName: 's_round'
        },
        {
          label: t('global.nextAction'),
          value: 'actionsEnabled',
          format: 'actionsEnabledConstancyProduct',
          filterType: 'constancyProductActionsEnabled',
          filterName: 's_actionsEnabled'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'constancyProductStatus',
          filterName: 's_status'
        },
        {
          label: t('global.status.daysToClose'),
          value: 'daysToClose',
          format: 'none',
          filterType: 'number',
          filterName: 's_daysToClose'
        },
        {
          label: t('global.invoice'),
          value: 'invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_invoice'
        },
        {
          label: t('services.constancyNumberProduct'),
          value: 'service.number',
          format: 'none',
          filterType: 'petitionNumber',
          filterName: 's_serviceNumber'
        },
        {
          label: t('global.design'),
          value: 'indicators.design',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_indicatorsDesign'
        },
        {
          label: t('global.urgent'),
          value: 'indicators.urgent',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_indicatorsUrgent'
        },
        {
          label: t('global.brand'),
          value: 'brand',
          format: 'none',
          filterType: 'text',
          filterName: 's_brand'
        },
        {
          label: t('global.description'),
          value: 'description',
          format: 'none',
          filterType: 'text',
          filterName: 's_description'
        },
        {
          label: t('services.code'),
          value: 'code',
          format: 'none',
          filterType: 'text',
          filterName: 's_code'
        },
        {
          label: t('services.requested'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_collaboratorName'
        },
        {
          label: t('services.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approver',
          onlyAdmin: true
        }
      ],
      revision: [
        {
          label: t('services.requestDate'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('global.norm'),
          value: 'normCode',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_normCode'
        },
        {
          label: t('services.roundTitle'),
          value: 'round',
          format: 'none',
          filterType: 'number',
          filterName: 's_round'
        },
        {
          label: t('global.nextAction'),
          value: 'actionsEnabled',
          format: 'actionsEnabledRevisionProduct',
          filterType: 'revisionProductActionsEnabled',
          filterName: 's_actionsEnabled'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'constancyProductStatus',
          filterName: 's_status'
        },
        {
          label: t('global.status.daysToClose'),
          value: 'daysToClose',
          format: 'none',
          filterType: 'number',
          filterName: 's_daysToClose'
        },
        {
          label: t('global.invoice'),
          value: 'invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_invoice'
        },
        {
          label: t('services.constancyNumberProduct'),
          value: 'service.number',
          format: 'none',
          filterType: 'petitionNumber',
          filterName: 's_serviceNumber'
        },
        {
          label: t('global.design'),
          value: 'indicators.design',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_indicatorsDesign'
        },
        {
          label: t('global.urgent'),
          value: 'indicators.urgent',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_indicatorsUrgent'
        },
        {
          label: t('global.brand'),
          value: 'brand',
          format: 'none',
          filterType: 'text',
          filterName: 's_brand'
        },
        {
          label: t('global.description'),
          value: 'description',
          format: 'none',
          filterType: 'text',
          filterName: 's_description'
        },
        {
          label: t('services.code'),
          value: 'code',
          format: 'none',
          filterType: 'text',
          filterName: 's_code'
        },
        {
          label: t('services.requested'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_collaboratorName'
        },
        {
          label: t('services.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approver',
          onlyAdmin: true
        }
      ]
    },
    inspections: {
      DN: [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'inspectionStatus',
          filterName: 's_status'
        },
        {
          label: t('services.petitionNumber'),
          value: 'service.number',
          format: 'none',
          filterType: 'petitionNumber',
          filterName: 's_serviceNumber'
        },
        {
          label: t('services.assignedTask'),
          value: 'rounds',
          format: 'onSiteTask',
          filterType: 'text',
          filterName: 's_roundTaskNumber'
        },
        {
          label: t('global.complies'),
          value: 'complies',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_complies'
        },
        {
          label: t('global.product'),
          value: 'product.invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_productInvoice'
        },
        {
          label: t('global.listDone'),
          value: 'listCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_listCompleted'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approverName'
        },
        {
          label: t('global.approved'),
          value: 'approved',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_approved'
        },
        {
          label: t('global.norm'),
          value: 'norm',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_norm'
        },
        {
          label: t('global.code'),
          value: 'product.code',
          format: 'none',
          filterType: 'text',
          filterName: 's_productCode'
        },
        {
          label: 'Fase',
          value: 'fase',
          format: 'none',
          filterType: 'fase',
          filterName: 's_fase'
        },
        {
          label: t('global.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      CN: [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'inspectionStatus',
          filterName: 's_status'
        },
        {
          label: t('services.constancy'),
          value: 'service.number',
          format: 'none',
          filterType: 'text',
          filterName: 's_serviceNumber'
        },
        {
          label: t('services.assignedTask'),
          value: 'task.number',
          format: 'none',
          filterType: 'text',
          filterName: 's_taskNumber'
        },
        {
          label: t('global.complies'),
          value: 'complies',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_complies'
        },
        {
          label: t('global.product'),
          value: 'product.invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_productInvoice'
        },
        {
          label: t('global.listDone'),
          value: 'listCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_listCompleted'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'none',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approverName'
        },
        {
          label: t('global.approved'),
          value: 'approved',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_approved'
        },
        {
          label: t('global.norm'),
          value: 'norm',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_norm'
        },
        {
          label: 'Fase',
          value: 'fase',
          format: 'none',
          filterType: 'fase',
          filterName: 's_fase'
        },
        {
          label: t('global.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      DC: [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'inspectionStatus',
          filterName: 's_status'
        },
        {
          label: t('services.petitionNumber'),
          value: 'service.number',
          format: 'none',
          filterType: 'petitionNumber',
          filterName: 's_serviceNumber'
        },
        {
          label: t('services.assignedTask'),
          value: 'rounds',
          format: 'onSiteTask',
          filterType: 'text',
          filterName: 's_roundTaskNumber'
        },
        {
          label: t('global.complies'),
          value: 'complies',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_complies'
        },
        {
          label: t('global.product'),
          value: 'product.invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_productInvoice'
        },
        {
          label: t('global.listDone'),
          value: 'listCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_listCompleted'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approverName'
        },
        {
          label: t('global.approved'),
          value: 'approved',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_approved'
        },
        {
          label: t('global.norm'),
          value: 'norm',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_norm'
        },
        {
          label: t('global.code'),
          value: 'product.code',
          format: 'none',
          filterType: 'text',
          filterName: 's_productCode'
        },
        {
          label: 'Fase',
          value: 'fase',
          format: 'none',
          filterType: 'fase',
          filterName: 's_fase'
        },
        {
          label: t('global.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      CC: [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'inspectionStatus',
          filterName: 's_status'
        },
        {
          label: t('services.constancy'),
          value: 'service.number',
          format: 'none',
          filterType: 'text',
          filterName: 's_serviceNumber'
        },
        {
          label: t('services.assignedTask'),
          value: 'task.number',
          format: 'none',
          filterType: 'text',
          filterName: 's_taskNumber'
        },
        {
          label: t('global.complies'),
          value: 'complies',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_complies'
        },
        {
          label: t('global.product'),
          value: 'product.invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_productInvoice'
        },
        {
          label: t('global.listDone'),
          value: 'listCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_listCompleted'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approverName'
        },
        {
          label: t('global.approved'),
          value: 'approved',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_approved'
        },
        {
          label: t('global.norm'),
          value: 'norm',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_norm'
        },
        {
          label: 'Fase',
          value: 'fase',
          format: 'none',
          filterType: 'fase',
          filterName: 's_fase'
        },
        {
          label: t('global.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      REV: [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'inspectionStatus',
          filterName: 's_status'
        },
        {
          label: t('services.service'),
          value: 'service.number',
          format: 'none',
          filterType: 'text',
          filterName: 's_serviceNumber'
        },
        {
          label: t('services.assignedTask'),
          value: 'task.number',
          format: 'none',
          filterType: 'text',
          filterName: 's_taskNumber'
        },
        {
          label: t('global.complies'),
          value: 'complies',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_complies'
        },
        {
          label: t('global.product'),
          value: 'product.invoice',
          format: 'none',
          filterType: 'text',
          filterName: 's_productInvoice'
        },
        {
          label: t('global.listDone'),
          value: 'listCompleted',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_listCompleted'
        },
        {
          label: t('services.inspectorName'),
          value: 'inspector.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_inspectorName',
          onlyAdmin: true
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('services.collaborator'),
          value: 'collaborator.name',
          format: 'person-name',
          filterType: 'collaboratorName',
          filterName: 's_collaboratorName'
        },
        {
          label: t('global.approver'),
          value: 'approver.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_approverName'
        },
        {
          label: t('global.approved'),
          value: 'approved',
          format: 'boolean',
          filterType: 'boolean',
          filterName: 'b_approved'
        },
        {
          label: t('global.norm'),
          value: 'norm',
          format: 'none',
          filterType: 'normCode',
          filterName: 's_norm'
        },
        {
          label: 'Fase',
          value: 'fase',
          format: 'none',
          filterType: 'fase',
          filterName: 's_fase'
        },
        {
          label: t('global.releaseDate'),
          value: 'releaseDate',
          format: 'date',
          filterType: 'date',
          filterName: 's_releaseDate'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ]
    },
    tasks: {
      'onsite-inspection': [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'taskStatus',
          filterName: 's_status'
        },
        {
          label: t('tasks.filters.taskResponsable'),
          value: 'responsible.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_responsibleName'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('tasks.listCompleted'),
          value: 'indicators.completedInspectionListsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_indicatorsCompletedInspectionListsQuantity'
        },
        {
          label: t('tasks.inspectionsQuantity'),
          value: 'indicators.inspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_indicatorsInspectionsQuantity'
        },
        {
          label: t('tasks.inspectionsCompleted'),
          value: 'indicators.completedInspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_indicatorsCompletedInspectionsQuantity'
        },
        {
          label: t('tasks.inspectionAddress'),
          value: 'visit.address',
          format: 'none',
          filterType: 'text',
          filterName: 's_visitAddress'
        },
        {
          label: t('tasks.scheduledTo'),
          value: 'datesRegistry.scheduledTo',
          format: 'date',
          filterType: 'date',
          filterName: 's_scheduledTo'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('tasks.startedAt'),
          value: 'datesRegistry.startedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_startedAt'
        },
        {
          label: t('tasks.endedAt'),
          value: 'datesRegistry.endedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_endedAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      'remote-inspection': [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'taskStatus',
          filterName: 's_status'
        },
        {
          label: t('tasks.filters.taskResponsable'),
          value: 'responsible.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_responsibleName'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('tasks.listCompleted'),
          value: 'indicators.completedInspectionListsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_completedInspectionListsQuantity'
        },
        {
          label: t('tasks.inspectionsQuantity'),
          value: 'indicators.inspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_inspectionsQuantity'
        },
        {
          label: t('tasks.inspectionsCompleted'),
          value: 'indicators.completedInspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_completedInspectionsQuantity'
        },
        {
          label: t('tasks.scheduledTo'),
          value: 'datesRegistry.scheduledTo',
          format: 'date',
          filterType: 'date',
          filterName: 's_scheduledTo'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('tasks.startedAt'),
          value: 'datesRegistry.startedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_startedAt'
        },
        {
          label: t('tasks.endedAt'),
          value: 'datesRegistry.endedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_endedAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      'constancy-inspection': [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'taskStatus',
          filterName: 's_status'
        },
        {
          label: t('tasks.filters.taskResponsable'),
          value: 'responsible.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_responsibleName'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('tasks.listCompleted'),
          value: 'indicators.completedInspectionListsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_completedInspectionListsQuantity'
        },
        {
          label: t('tasks.inspectionsQuantity'),
          value: 'indicators.inspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_inspectionsQuantity'
        },
        {
          label: t('tasks.inspectionsCompleted'),
          value: 'indicators.completedInspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_completedInspectionsQuantity'
        },
        {
          label: t('tasks.scheduledTo'),
          value: 'datesRegistry.scheduledTo',
          format: 'date',
          filterType: 'date',
          filterName: 's_scheduledTo'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('tasks.startedAt'),
          value: 'datesRegistry.startedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_startedAt'
        },
        {
          label: t('tasks.endedAt'),
          value: 'datesRegistry.endedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_endedAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ],
      'revision-inspection': [
        {
          label: t('tasks.filters.number'),
          value: 'number',
          format: 'none',
          filterType: 'text',
          filterName: 's_number'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status',
          filterType: 'taskStatus',
          filterName: 's_status'
        },
        {
          label: t('tasks.filters.taskResponsable'),
          value: 'responsible.name',
          format: 'person-name',
          filterType: 'text',
          filterName: 's_responsibleName'
        },
        {
          label: t('clients.titleS'),
          value: 'client.shortName',
          format: 'none',
          filterType: 'clientBusinessName',
          filterName: 's_clientShortName'
        },
        {
          label: t('tasks.listCompleted'),
          value: 'indicators.completedInspectionListsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_completedInspectionListsQuantity'
        },
        {
          label: t('tasks.inspectionsQuantity'),
          value: 'indicators.inspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_inspectionsQuantity'
        },
        {
          label: t('tasks.inspectionsCompleted'),
          value: 'indicators.completedInspectionsQuantity',
          format: 'none',
          filterType: 'number',
          filterName: 'n_completedInspectionsQuantity'
        },
        {
          label: t('tasks.scheduledTo'),
          value: 'datesRegistry.scheduledTo',
          format: 'date',
          filterType: 'date',
          filterName: 's_scheduledTo'
        },
        {
          label: t('global.createdAt'),
          value: 'createdAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_createdAt'
        },
        {
          label: t('tasks.startedAt'),
          value: 'datesRegistry.startedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_startedAt'
        },
        {
          label: t('tasks.endedAt'),
          value: 'datesRegistry.endedAt',
          format: 'date',
          filterType: 'date',
          filterName: 's_endedAt'
        },
        {
          label: t('global.updatedAt'),
          value: 'updatedAt',
          format: 'date-time',
          filterType: 'date',
          filterName: 's_updatedAt'
        }
      ]
    }
  };

  const driverObj = driver({
    showProgress: true,
    allowClose: false,
    smoothScroll: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#binnacle-table', popover: { title: t('tours.dataTable') || '', description: t('tours.dataTableDescription') || '' } },
      { element: '#binnacle-filter', popover: { title: t('tours.filters') || '', description: t('tours.filtersDescription') || '' } },
      { element: '#binnacle-filter-clear', popover: { title: t('tours.cleanfilters') || '', description: t('tours.cleanFiltersDescripion') || '' } },
      { element: '#binnacle-footer', popover: { title: t('tours.types') || '', description: t('tours.typesDescription') || '' } },
      { element: '#csv-report', popover: { title: t('tours.CSVData') || '', description: t('tours.CSVDataDescription') || '' } },
      { element: '#home-button', popover: { title: t('tours.principalMenu') || '', description: t('tours.principalMenuDescription') || '' } }
    ],
    onDestroyed: () => {
      if (apiType === 'admin') {
        updateResource(
          `admin/users/${userID}/tour-done`,
          {
            tourDone: 'binnacle'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }

      if (apiType === 'public') {
        updateCollaborator(
          'public/collaborators/tour-done',
          {
            tourDone: 'binnacle'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }
    }
  });

  useEffect(() => {
    if (userToursDone !== false) {
      if (userToursDone === undefined || !userToursDone.includes('binnacle') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <div className='binnacle'>
      <TaskDetail
        onClose={() => setVisibleTaskDetailModal({ ...visibleTaskDetailModal, visible: false })}
        setVisibleTaskDetailModal={setVisibleTaskDetailModal}
        visibleTaskDetailModal={visibleTaskDetailModal}
        lookInTheTrash={elementsType === 'trashCan'}
      />
      <Suspense fallback={<Spinner />}>
        <Header
          elementsType={elementsType}
          setElementsType={setElementsType}
          setFilters={setFilters}
        />
      </Suspense>
      <Table
        headers={headers[element][type]}
        items={items}
        currentPage={pagination.currentPage}
        perPage={pagination.perPage}
        totalItems={pagination.totalItems}
        onChangeCurrentPage={handleChangePage}
        onClickRow={(item: any) => renderEntityDetail(item)}
        onChangeOrder={handelChangeOrder}
        filters={filters}
        setFilters={setFilters}
        orderBy={orderBy}
        orderDirection={orderDirection}
        elementsType={elementsType}
        isPending={isPending || emptyList === true}
        handleSearch={handleSearch}
        element={element}
        startTour={() => driverObj.drive()}
      />
      <Suspense fallback={<Spinner />}>
        <Footer
          element={element}
          setElement={setElement}
          type={type}
          setType={setType}
          setFilters={setFilters}
          headers={headers[element][type]}
          elementsType={elementsType}
          orderBy={orderBy}
          orderDirection={orderDirection}
          filters={filters}
          driverObj={driverObj}
          isPending={isPending || emptyList === true}
        />
      </Suspense>
    </div>
  );
};

export default Binnacle;
