import React from 'react';
import { useTranslation } from 'react-i18next';

import DataDisplay from '../../../../components/DataDisplay';
import { DataDisplayItem, DictumProduct } from '../../../../interfaces';

interface Props {
  product: DictumProduct
  dictumServiceLookInTheTrash: boolean
  inspectionLookInTheTrash: boolean
}

const DictumProductDetailGeneralInfo: React.FC<Props> = (props) => {
  const {
    product,
    dictumServiceLookInTheTrash,
    inspectionLookInTheTrash
  } = props;

  const { t } = useTranslation();

  const itemsDisplay: DataDisplayItem[] = [
    {
      label: t('global.status.title'),
      value: product.status,
      format: 'status'
    },
    {
      label: t('global.client'),
      value: product.client.businessName,
      format: 'none',
      navigateRoute: `/client/detail/${product.client.id}`,
      onlyAdmin: true
    },
    {
      label: t('global.invoice'),
      value: product.invoice,
      navigateRoute: `/dictum-products/detail/${product.id}/none`,
      format: 'none'
    },
    {
      label: t('global.collaborator'),
      value: product.collaborator.name,
      format: 'none'
    },
    {
      label: t('services.petitionNumber'),
      value: product.service.number,
      format: 'none',
      navigateRoute: `/dictum-services/detail/${product.service.id}/${dictumServiceLookInTheTrash ? 'true' : 'none'}`
    },
    {
      label: 'Inspector',
      value: product.inspector?.name || '',
      format: 'none',
      navigateRoute: product.inspector ? `/user/detail/${product.inspector?.id}` : '',
      onlyAdmin: true
    },
    {
      label: t('inspections.round'),
      value: product.round || '',
      format: 'none'
    },
    {
      label: t('tasks.inspection'),
      value: product.inspection?.number || '',
      format: 'none',
      navigateRoute: product.inspection ? `/inspection/detail/${product.inspection?.id}/${inspectionLookInTheTrash ? 'true' : 'none'}/none/none` : ''
    },
    {
      label: t('services.tariffFraction'),
      value: product.tariffFraction,
      format: 'none'
    },
    {
      label: t('global.norm'),
      value: product.normCode,
      format: 'none'
    },
    {
      label: 'Fase',
      value: product.fase,
      format: 'none'
    },
    {
      label: t('services.labelingMode'),
      value: product.labelingMode,
      format: 'none'
    },
    {
      label: 'UMC',
      value: product.umc,
      format: 'none'
    },
    {
      label: t('services.umcQuantity'),
      value: product.umcQuantity,
      format: 'none'
    },
    {
      label: t('services.labelsToPut'),
      value: product.labelsToPut,
      format: 'none'
    },
    {
      label: t('global.models'),
      value: product.code,
      format: 'none'
    },
    {
      label: t('global.model2'),
      value: product.code2,
      format: 'none'
    },
    {
      label: t('global.brand'),
      value: product.brand,
      format: 'none'
    },
    {
      label: t('global.presentation'),
      value: product.presentation,
      format: 'none'
    },
    {
      label: t('global.description'),
      value: product.description,
      format: 'none'
    },
    {
      label: t('global.createdAtTitle'),
      value: product.createdAt,
      format: 'date-time'
    },
    {
      label: t('global.grouped'),
      value: product.indicators.isGrouped,
      format: 'boolean'
    },
    {
      label: t('global.attachedToTheService'),
      value: product.indicators.attachedToTheService,
      format: 'boolean'
    }
  ];

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={product.deletedAt ? itemsDisplay.concat([
        {
          label: t('global.deletedAt'),
          value: product.deletedAt,
          format: 'date-time'
        }])
        : itemsDisplay}
    />
  );
};

export default DictumProductDetailGeneralInfo;
