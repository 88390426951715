/* eslint-disable no-unused-vars */
import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ExcelTable,
  File,
  FileInput,
  Icon,
  Modal,
  Title
} from '../../../../../../../../components';
import {
  FileInputData,
  ExcelTableHeader,
  DictumProductWithCodes,
  LabelingMode
} from '../../../../../../../../interfaces';
import helper, { ProductRow } from './helper';
import { emptyDictumProduct } from '../../../../../../../../emptyObjects';

import './styles.scss';
import { AppContext } from '../../../../../../../../context/AppContext';
import { utils } from '../../../../../../../../helpers';

const Papa = require('papaparse');

interface Props {
  onCreateProducts: (_products: DictumProductWithCodes[]) => void
}

export const CSVForm: React.FC<Props> = (props) => {
  const { onCreateProducts } = props;

  const {
    soundEffects
  } = useContext(AppContext);

  const [operatingSystem, setOperatingSystem] = useState<string>('windows');
  const [fileErrors, setFileErrors] = useState<{ [name: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorsIDs, setErrorsIDs] = useState<string[]>([]);
  const [productsToBeCreated, setProductsToBeCreated] = useState<DictumProductWithCodes[]>([]);

  const { t } = useTranslation();

  const headerExcelTable: ExcelTableHeader[] = [
    {
      label: t('global.norm'),
      type: 'norm'
    },
    {
      label: t('global.fase051'),
      type: 'fase'
    },
    {
      label: t('global.brand'),
      type: 'text'
    },
    {
      label: t('global.description'),
      type: 'text'
    },
    {
      label: t('services.tariffFraction'),
      type: 'text'
    },
    {
      label: t('services.modelsQuantity'),
      type: 'number'
    },
    {
      label: t('global.codes'),
      type: 'text'
    },
    {
      label: t('global.model2'),
      type: 'text'
    },
    {
      label: 'UMC',
      type: 'number'
    },
    {
      label: t('services.umcQuantity'),
      type: 'number'
    },
    {
      label: t('services.labelsToPut'),
      type: 'number'
    },
    {
      label: t('services.labelingMode'),
      type: 'labelingMode'
    }
  ];

  const hanldeDownloadTemplate = async () => {
    // eslint-disable-next-line no-undef
    const link = document.createElement('a');
    // eslint-disable-next-line no-undef
    link.href = operatingSystem === 'windows' ? `https://s3.amazonaws.com/images.cecsa.com/documents/plantilla-para-partidas-dictamen-windows.csv?version=${new Date().getTime()}` : `ttps://s3.amazonaws.com/images.cecsa.com/documents/plantilla-para-partidas-dictamen.csv?version=${new Date().getTime()}`;
    link.download = t('services.csv.document');
    link.click();
  };

  const createProducts = () => {
    const auxErrorsIDs: string[] = [];
    const errors: { [name: string]: string } = {};
    const auxProducts: DictumProductWithCodes[] = productsToBeCreated;

    const keyValueTradduction = {
      A: 'normCode',
      B: 'fase',
      C: 'brand',
      D: 'description',
      E: 'tariffFraction',
      F: 'modelsQuantity',
      G: 'codes',
      H: 'codes2',
      I: 'umc',
      J: 'umcQuantity',
      K: 'labelsToPut',
      L: 'labelingMode'
    };

    for (let index = 0; index < errorsIDs.length; index += 1) {
      const rowResult = helper.validateProductCell(errorsIDs[index]);

      if (rowResult.isValid) {
        const propertyName = (keyValueTradduction as any)[errorsIDs[index].substring(0, 1)];

        if (propertyName === 'labelsToPut') {
          // eslint-disable-next-line max-len
          (auxProducts[Number(errorsIDs[index].substring(1)) - 2] as any)[propertyName] = Number(rowResult.value);
        } else {
          // eslint-disable-next-line max-len
          (auxProducts[Number(errorsIDs[index].substring(1)) - 2] as any)[propertyName] = propertyName === 'codes' ? rowResult.value.split(',') : rowResult.value;
        }

        setProductsToBeCreated(auxProducts);
      } else {
        Object.entries(rowResult.errors).forEach(([key, value]) => {
          errors[key] = value;
          auxErrorsIDs.push(key);
        });
      }
    }

    setErrorsIDs(auxErrorsIDs);
    setFileErrors(errors);
    setError(errors);

    if (Object.keys(errors).length === 0) {
      onCreateProducts(auxProducts);
      setSuccessMessage('');
      setProductsToBeCreated([]);
      setFileErrors({});
    }
  };

  const setValues = (id: string, value: string) => {
    // eslint-disable-next-line no-undef
    const inputElement: any = document.getElementById(id);

    if (inputElement) {
      inputElement.value = utils.removeAccents(`${value}`);
    }
  };

  const showProductsToCreate = async (auxProductsToBeCreated: DictumProductWithCodes[]) => {
    Modal.fireLoading();

    // eslint-disable-next-line no-undef
    const exceltable = document.getElementById('excel-table-container');

    const keyValueTradduction: { [name: string]: string } = {
      normCode: 'A',
      fase: 'B',
      brand: 'C',
      description: 'D',
      tariffFraction: 'E',
      modelsQuantity: 'F',
      codes: 'G',
      codes2: 'H',
      umc: 'I',
      umcQuantity: 'J',
      labelsToPut: 'K',
      labelingMode: 'L'
    };

    setTimeout(() => {
      for (let index = 0; index < auxProductsToBeCreated.length; index += 1) {
        const product = auxProductsToBeCreated[index];
        const keys = Object.keys(product);

        keys.forEach(key => {
          const value = (product as any)[key];

          if (keyValueTradduction[key]) {
            setValues(`${keyValueTradduction[key]}${index + 2}`, value);
          }
        });
      }

      if (exceltable) {
        exceltable.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }

      Modal.close();
    }, 1500);
  };

  const setError = (errors: { [name: string]: string }) => {
    const idsArr: { [name: string]: string } = {};

    Object.entries(errors).forEach(([key, _value]) => {
      // eslint-disable-next-line no-undef
      const element: HTMLInputElement | null = document.querySelector(`#${key}`);

      idsArr[key.substring(0, 1)] = key.substring(1);

      if (element) {
        element.classList.add('excel-table__input--error');
      }
    });

    Modal.close();
  };

  const parseFile = (file: any) => {
    Modal.fireLoading();

    setFileErrors({});

    const parsedProducts: DictumProductWithCodes[] = [];
    const encoding = operatingSystem === 'windows' ? 'ascii' : 'utf8';
    const errors: { [name: string]: string } = {};
    const auxErrorsIDs: string[] = [];
    let fileParsedHaveErrors: boolean = false;
    let rowNumber: number = 2;

    Papa.parse(file, {
      header: true,
      encoding,
      step: (row: any = {}, parser: any) => {
        const { data = {} } = row;

        const parsedRow: ProductRow = {
          normCode: utils.removeAccents(helper.formatNOM(data.Norma) || ''),
          fase: helper.formatFase(String(data['Fase (Solo NOM 051)'])),
          brand: utils.removeAccents(data.Marca || ''),
          description: utils.removeAccents(data['Descripción'] || ''),
          tariffFraction: utils.removeAccents(data['Fraccion arancelaria'] || ''),
          modelsQuantity: Number(data['Cantidad de modelos']) || -1,
          codes: utils.removeAccents(data['Modelo(s)'] || ''),
          codes2: utils.removeAccents(data['Modelo(s) 2'] || ''),
          umc: Number(data.UMC) || -1,
          umcQuantity: Number(data['Cantidad de UMC']) || -1,
          labelsToPut: Number(data['Cantidad de piezas a etiquetar']) || -1,
          labelingMode: utils.removeAccents(data['Modalidad de etiquetado'] || '')
        };

        if (helper.isAnEmptyRow(parsedRow)) {
          rowNumber -= 1;
          parser.abort();
          return;
        }

        parsedRow.labelingMode = parsedRow.labelingMode.toUpperCase();

        const rowResult = helper.validateProductRow(parsedRow, rowNumber);

        if (!rowResult.isValid) {
          fileParsedHaveErrors = true;

          Object.entries(rowResult.errors).forEach(([key, value]) => {
            errors[key] = value;
            auxErrorsIDs.push(key);
          });
        }

        parsedProducts.push({
          ...emptyDictumProduct,
          tariffFraction: parsedRow.tariffFraction,
          normCode: helper.parseNormCode(parsedRow.normCode),
          fase: helper.parseNorm051Fase(helper.parseNormCode(parsedRow.normCode), parsedRow.fase),
          labelingMode: parsedRow.labelingMode as LabelingMode,
          umc: parsedRow.umc.toString(),
          umcQuantity: parsedRow.umcQuantity,
          labelsToPut: parsedRow.labelsToPut,
          modelsQuantity: parsedRow.modelsQuantity,
          codes: parsedRow.codes.split(','),
          codes2: parsedRow.codes2.split(','),
          brand: parsedRow.brand,
          presentation: t('global.undefined'),
          description: parsedRow.description
        });

        rowNumber += 1;
      },
      complete: () => {
        if (!parsedProducts.length && Object.keys(errors).length === 0) {
          Modal.fireError(t('services.productsNotFoundCSV'), undefined, soundEffects);
          return;
        }

        if (fileParsedHaveErrors) {
          setProductsToBeCreated(parsedProducts);
          setFileErrors(errors);
          setErrorsIDs(auxErrorsIDs);
          showProductsToCreate(parsedProducts);
          setSuccessMessage('');
          setTimeout(() => setError(errors), 1500);
        } else {
          setSuccessMessage(t('services.productsFoundCSV') || '');
          setProductsToBeCreated(parsedProducts);
          showProductsToCreate(parsedProducts);
        }
      },
      error: () => {
        Modal.fireError(t('services.errors.csv'), undefined, soundEffects);
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const OS: string = String(navigator.userAgent || '').toLowerCase();

    if (OS.includes('windows')) {
      setOperatingSystem('windows');
    } else {
      setOperatingSystem('other');
    }
  }, []);

  return (
    <div className='csv-form'>
      <div className='csv-form__form'>
        <Title title={t('services.instructions')} type='secondary' />
        <p>{t('services.csv.steps')}</p>
        <p>{t('services.csv.1')}</p>
        <File name={t('services.csv.dictumDocument')} onDownload={hanldeDownloadTemplate} />
        <p>{t('services.csv.2')}</p>
        <p>{t('services.csv.3')}</p>
        <p>{t('services.csv.4')}</p>
        <br />
        <FileInput
          fileName={t('services.csv.title') || ''}
          isMultiple={false}
          acceptedFileFormats='.csv'
          onSelectFile={(payload: FileInputData[]) => {
            parseFile(payload[0].data);
          }}
        />
      </div>
      <div className='csv-form__excel-table-container' id='excel-table-container'>
        {
          (successMessage || Object.keys(fileErrors).length !== 0) && (
            <>
              <Title title={successMessage || t('services.csv.error')} type={successMessage ? 'secondary' : 'error'} />
              <ExcelTable
                headers={headerExcelTable}
                disable={successMessage !== ''}
                inputsErrors={fileErrors}
                rowsQuantity={productsToBeCreated.length}
              />
              <div className='csv-form__excel-table-container__footer-container'>
                <div className='csv-form__excel-table-container__footer-container__title'>
                  <Icon type='alertBullet' className='csv-form__excel-table-container__footer-container__title__icon' />
                  <Title title={t('services.csv.confirmation')} type='secondary' />
                </div>
                <div className='csv-form__excel-table-container__footer-container__buttons-container'>
                  <Button
                    onClick={() => {
                      setSuccessMessage('');
                      setProductsToBeCreated([]);
                      setFileErrors({});
                    }}
                    type='secondary-outlined'
                    transparent={true}
                    border='secondary-outlined'
                    label={t('services.cleanForm') || ''}
                  />
                  <Button
                    onClick={createProducts}
                    type='secondary-outlined'
                    label={t('global.accept') || ''}
                  />
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default CSVForm;
