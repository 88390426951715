import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Header,
  Input,
  Modal,
  Separator,
  SubTitle
} from '../../../components';

import './styles.scss';
import { useResource } from '../../../hooks';
import { AppContext } from '../../../context/AppContext';

const UsersList = () => {
  const { t } = useTranslation();

  dayjs.extend(weekOfYear);

  const {
    createResource
  } = useResource<any>();

  const {
    setOpenModal,
    soundEffects,
    userRole,
    approver
  } = useContext(AppContext);

  const [range, setRange] = useState({ start: '', end: '' });
  const [tasksRange, setTasksRange] = useState({ start: '', end: '' });
  const [commentsRange, setCommentsRange] = useState({ start: '', end: '' });
  const [certificateRange, setCertificateRange] = useState({ start: '', end: '' });
  const [incomeServices, setIncomeServices] = useState({ start: '', end: '' });

  const getCertificateReport = () => {
    createResource(
      '/admin/notifications/certificate-report',
      {
        startDate: certificateRange.start,
        endDate: certificateRange.end
      },
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        link.download = `Reporte trimestral de la EMA del ${dayjs(certificateRange.start).format('DD-MM-YYYY')} al ${dayjs(certificateRange.end).format('DD-MM-YYYY')}.csv`;
        link.click();
        Modal.close();
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const getIncomeServicesReport = () => {
    createResource(
      '/admin/notifications/income-services-report',
      {
        startDate: incomeServices.start,
        endDate: incomeServices.end
      },
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        link.download = `Reporte de ingreso de servicios del ${dayjs(incomeServices.start).format('DD-MM-YYYY')} al ${dayjs(incomeServices.end).format('DD-MM-YYYY')}.csv`;
        link.click();
        Modal.close();
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const getInspectionsReport = () => {
    createResource(
      '/admin/notifications/inspections-report',
      {
        startDate: range.start,
        endDate: range.end
      },
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        link.download = `Reporte inspecciones del ${dayjs(range.start).format('DD-MM-YYYY')} al ${dayjs(range.end).format('DD-MM-YYYY')}.csv`;
        link.click();
        Modal.close();
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const getTasksReport = () => {
    createResource(
      '/admin/notifications/tasks-report',
      {
        startDate: tasksRange.start,
        endDate: tasksRange.end
      },
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        link.download = `Reporte tareas del ${dayjs(tasksRange.start).format('DD-MM-YYYY')} al ${dayjs(tasksRange.end).format('DD-MM-YYYY')}.csv`;
        link.click();
        Modal.close();
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const getCommentsReport = () => {
    createResource(
      '/admin/notifications/comments-report',
      {
        startDate: commentsRange.start,
        endDate: commentsRange.end
      },
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        link.download = `Reporte comentarios del ${dayjs(commentsRange.start).format('DD-MM-YYYY')} al ${dayjs(commentsRange.end).format('DD-MM-YYYY')}.csv`;
        link.click();
        Modal.close();
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  return (
    <div className="reports">
      <Header
        title={t('menu.reports') || ''}
        showBackbutton={true}
      />
      <div className="reports__main">
        <div className="reports__main__container">
          {
            (['master', 'sys-admin'].includes(userRole) || (userRole === 'inspector' && approver === true)) && (
              <>
                <div className="reports__main__container__inspections-report">
                  <SubTitle subTitle='Selecciona rango de fecha para el reporte de ingreso de servicios' />
                  <div className="reports__main__container__dates-container">
                    <Input
                      title='Fecha inicio'
                      type={'date'}
                      value={incomeServices.start}
                      onChange={(_value: string | number, _id: string) => {
                        setIncomeServices({
                          ...incomeServices,
                          start: `${_value}`
                        });
                      }}
                    />
                    <Input
                      title='Fecha fin'
                      type={'date'}
                      value={incomeServices.end}
                      onChange={(_value: string | number, _id: string) => {
                        setIncomeServices({
                          ...incomeServices,
                          end: `${_value}`
                        });
                      }}
                    />
                  </div>
                  <Button
                    label={t('global.servicesReport', { startDate: incomeServices.start ? dayjs(incomeServices.start).format('DD-MMM-YYYY') : '------', endDate: incomeServices.end ? dayjs(incomeServices.end).format('DD-MMM-YYYY') : '------' }) || ''}
                    type={'primary'}
                    size='big'
                    icon='downloadWhite'
                    onClick={getIncomeServicesReport}
                    disabled={incomeServices.start === '' || incomeServices.end === ''}
                  />
                </div>
                <Separator orientation='horizontal' />
                <div className="reports__main__container__inspections-report">
                  <SubTitle subTitle='Selecciona rango de fecha para el reporte Trimestral de la EMA' />
                  <div className="reports__main__container__dates-container">
                    <Input
                      title='Fecha inicio'
                      type={'date'}
                      value={certificateRange.start}
                      onChange={(_value: string | number, _id: string) => {
                        setCertificateRange({
                          ...certificateRange,
                          start: `${_value}`
                        });
                      }}
                    />
                    <Input
                      title='Fecha fin'
                      type={'date'}
                      value={certificateRange.end}
                      onChange={(_value: string | number, _id: string) => {
                        setCertificateRange({
                          ...certificateRange,
                          end: `${_value}`
                        });
                      }}
                    />
                  </div>
                  <Button
                    label={t('global.certificateReport', { startDate: certificateRange.start ? dayjs(certificateRange.start).format('DD-MMM-YYYY') : '------', endDate: certificateRange.end ? dayjs(certificateRange.end).format('DD-MMM-YYYY') : '------' }) || ''}
                    type={'primary'}
                    size='big'
                    icon='downloadWhite'
                    onClick={getCertificateReport}
                    disabled={certificateRange.start === '' || certificateRange.end === ''}
                  />
                </div>
                <Separator orientation='horizontal' />
                <div className="reports__main__container__inspections-report">
                  <SubTitle subTitle='Selecciona rango de fecha para el reporte de inspecciones' />
                  <div className="reports__main__container__dates-container">
                    <Input
                      title='Fecha inicio'
                      type={'date'}
                      value={range.start}
                      onChange={(_value: string | number, _id: string) => {
                        setRange({
                          ...range,
                          start: `${_value}`
                        });
                      }}
                    />
                    <Input
                      title='Fecha fin'
                      type={'date'}
                      value={range.end}
                      onChange={(_value: string | number, _id: string) => {
                        setRange({
                          ...range,
                          end: `${_value}`
                        });
                      }}
                    />
                  </div>
                  <Button
                    label={t('global.weeklyReport', { startDate: range.start ? dayjs(range.start).format('DD-MMM-YYYY') : '------', endDate: range.end ? dayjs(range.end).format('DD-MMM-YYYY') : '------' }) || ''}
                    type={'primary'}
                    size='big'
                    icon='downloadWhite'
                    onClick={getInspectionsReport}
                    disabled={range.start === '' || range.end === ''}
                  />
                </div>
                <Separator orientation='horizontal' />
                <div className="reports__main__container__inspections-report">
                  <SubTitle subTitle='Selecciona rango de fecha para el reporte de tareas' />
                  <div className="reports__main__container__dates-container">
                    <Input
                      title='Fecha inicio'
                      type={'date'}
                      value={tasksRange.start}
                      onChange={(_value: string | number, _id: string) => {
                        setTasksRange({
                          ...tasksRange,
                          start: `${_value}`
                        });
                      }}
                    />
                    <Input
                      title='Fecha fin'
                      type={'date'}
                      value={tasksRange.end}
                      onChange={(_value: string | number, _id: string) => {
                        setTasksRange({
                          ...tasksRange,
                          end: `${_value}`
                        });
                      }}
                    />
                  </div>
                  <Button
                    label={t('global.weeklyTasksReport', { startDate: tasksRange.start ? dayjs(tasksRange.start).format('DD-MMM-YYYY') : '------', endDate: tasksRange.end ? dayjs(tasksRange.end).format('DD-MMM-YYYY') : '------' }) || ''}
                    type={'primary'}
                    size='big'
                    icon='downloadWhite'
                    onClick={getTasksReport}
                    disabled={tasksRange.start === '' || tasksRange.end === ''}
                  />
                </div>
                <Separator orientation='horizontal' />
                <div className="reports__main__container__inspections-report">
                  <SubTitle subTitle='Selecciona rango de fecha para el reporte de comentarios de inspección' />
                  <div className="reports__main__container__dates-container">
                    <Input
                      title='Fecha inicio'
                      type={'date'}
                      value={commentsRange.start}
                      onChange={(_value: string | number, _id: string) => {
                        setCommentsRange({
                          ...commentsRange,
                          start: `${_value}`
                        });
                      }}
                    />
                    <Input
                      title='Fecha fin'
                      type={'date'}
                      value={commentsRange.end}
                      onChange={(_value: string | number, _id: string) => {
                        setCommentsRange({
                          ...commentsRange,
                          end: `${_value}`
                        });
                      }}
                    />
                  </div>
                  <Button
                    label={t('global.weeklyCommentsReport', { startDate: commentsRange.start ? dayjs(commentsRange.start).format('DD-MMM-YYYY') : '------', endDate: commentsRange.end ? dayjs(commentsRange.end).format('DD-MMM-YYYY') : '------' }) || ''}
                    type={'primary'}
                    size='big'
                    icon='downloadWhite'
                    onClick={getCommentsReport}
                    disabled={commentsRange.start === '' || commentsRange.end === ''}
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default UsersList;
