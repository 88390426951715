import React, {
  createContext,
  useState,
  ReactElement,
  useEffect
} from 'react';
import axios from 'axios';

import Cookies from 'universal-cookie';

import {
  ApiTypes,
  DataContext,
  Inspection,
  ThemeModes,
  UserRoles,
  InspectionList,
  NotificationResponse,
  SetInspectionData,
  TaskDetailModal,
  DataWebSocket,
  ToursDone
} from '../interfaces';
import storage from '../storage';
import { useNotification, useResource } from '../hooks';
import Modal from '../components/Modal';
import { emptyInspection, emptyInspectionList } from '../emptyObjects';
import configuration from '../configuration';

export const AppContext = createContext<DataContext>({
  apiType: 'admin',
  setApiType: () => { },
  userRole: 'collaborator',
  setUserRole: () => { },
  userName: '',
  setUserName: () => { },
  userToursDone: false,
  setUserToursDone: () => { },
  themeMode: 'light',
  setThemeMode: () => { },
  openModal: false,
  setOpenModal: () => { },
  menuOpen: true,
  setMenuOpen: () => { },
  mandatoryInspectionList: true,
  setMandatoryInspectionList: () => { },
  inspection: emptyInspection,
  setInspection: () => { },
  notifications: {
    notifications: [],
    id: ''
  },
  setNotifications: () => { },
  getNotifications: false,
  setGetNotifications: () => { },
  soundEffects: true,
  setSoundEffects: () => { },
  commonFormat: emptyInspectionList,
  setCommonFormat: () => { },
  approver: false,
  setApprover: () => { },
  visibleInspectionDetailModal: {
    inspectionID: '',
    visible: false
  },
  setVisibleInspectionDetailModal: () => { },
  visibleTaskDetailModal: {
    taskID: '',
    visible: false
  },
  setVisibleTaskDetailModal: () => { },
  taskIDModalAfterActions: '',
  setTaskIDModalAfterActions: () => { },
  informationReceivedFromWebsocket: '',
  userID: '',
  setUserID: () => { },
  closeWebSocketConection: () => { },
  manager: false,
  setManager: () => { },
  notReFetchInspection: false,
  setNotReFetchInspection: () => { },
  showDictumRequestTour: false,
  setShowDictumRequestTour: () => { }
});

interface Props {
  children: ReactElement
}

export const AppProvider: React.FC<Props> = (props) => {
  const cookies = new Cookies();

  const {
    fetchResource: getMandatoryInspectionList
  } = useResource<boolean>();

  const [apiType, setApiType] = useState<ApiTypes>('public');
  const [userRole, setUserRole] = useState<UserRoles>('collaborator');
  const [approver, setApprover] = useState<boolean>(false);
  const [manager, setManager] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [userToursDone, setUserToursDone] = useState<ToursDone[] | false>(false);
  const [userID, setUserID] = useState<string>('');
  const [themeMode, setThemeMode] = useState<ThemeModes>('light');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [mandatoryInspectionList, setMandatoryInspectionList] = useState<boolean>(true);
  const [inspection, setInspection] = useState<Inspection>(emptyInspection);
  const [notReFetchInspection, setNotReFetchInspection] = useState<boolean>(false);
  const [showDictumRequestTour, setShowDictumRequestTour] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationResponse>({
    notifications: [],
    id: ''
  });
  const [getNotifications, setGetNotifications] = useState<boolean>(false);
  const [soundEffects, setSoundEffectsState] = useState<boolean>(true);
  const [informationReceivedFromWebsocket, setInformationReceivedFromWebsocket] = useState<any>('');
  const [commonFormat, setCommonFormat] = useState<InspectionList>(emptyInspectionList);
  const [
    visibleInspectionDetailModal,
    setVisibleInspectionDetailModal
  ] = useState<SetInspectionData>({
    visible: false,
    inspectionID: ''
  });
  const [visibleTaskDetailModal, setVisibleTaskDetailModal] = useState<TaskDetailModal>({
    taskID: '',
    visible: false
  });
  const [taskIDModalAfterActions, setTaskIDModalAfterActions] = useState<string>('');

  const setSoundEffects = (value: boolean) => {
    cookies.set(
      'soundEffects',
      value,
      {
        path: '/',
        sameSite: 'strict'
      }
    );
    setSoundEffectsState(value);
  };

  useEffect(() => {
    const apiTypeAux: ApiTypes = storage.get('apiType') as ApiTypes || 'public';
    const userRoleAux: UserRoles = storage.get('userRole') as UserRoles || 'collaborator';
    const userNameAux: string = storage.get('userName') || '';
    const userIDAux: string = storage.get('userID') || '';
    const themeModeAux: ThemeModes = cookies.get('theme-mode') as ThemeModes || 'light';
    const soundEffectsAux: boolean = cookies.get('soundEffects');
    const auxApprover: string = storage.get('approver') || 'false';
    const auxManager: string = storage.get('manager') || 'false';
    const auxUserToursDone: any = storage.get('userToursDone') || '[]';

    if (auxUserToursDone && auxUserToursDone !== 'undefined') {
      setUserToursDone(JSON.parse(auxUserToursDone) === null ? [] : JSON.parse(auxUserToursDone));
    }

    if (themeModeAux) {
      setThemeMode(themeModeAux);
    } else {
      cookies.set(
        'theme-mode',
        themeMode,
        {
          path: '/',
          sameSite: 'strict'
        }
      );
    }

    if (apiTypeAux) {
      setApiType(apiTypeAux);
    }

    if (userRoleAux) {
      setUserRole(userRoleAux);
    }

    if (userNameAux) {
      setUserName(userNameAux);
    }

    if (userIDAux) {
      setUserID(userIDAux);
    }

    if (auxApprover) {
      setApprover(auxApprover === 'true');
    }

    if (auxManager) {
      setManager(auxManager === 'true');
    }

    if (soundEffectsAux === false || soundEffectsAux === true) {
      setSoundEffectsState(soundEffectsAux);
    }
  }, []);

  useEffect(() => {
    if (apiType === 'admin') {
      const fetchMandatoryInspectionList = () => {
        getMandatoryInspectionList(
          '/admin/inspections/mandatory-inspection-list/mandatory',
          (data: boolean) => {
            setMandatoryInspectionList(data);
          },
          (error: string) => Modal.fireError(error, undefined, soundEffects)
        );
      };

      if (storage.get('sessionToken') && typeof storage.get('sessionToken') === 'string') {
        fetchMandatoryInspectionList();
      }
    }
  }, [apiType]);

  useEffect(() => {
    cookies.set(
      'theme-mode',
      themeMode,
      {
        path: '/',
        sameSite: 'strict'
      }
    );
  }, [themeMode]);

  useEffect(() => {
    if (storage.get('sessionToken')
      && userName.length > 0
      && ['master', 'operator', 'inspector', 'collaborator', 'sys-admin', 'finance'].includes(userRole)) {
      axios.get(
        `${configuration.apiBaseUrl}/${apiType}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${storage.get('sessionToken')}`
          }
        }
      ).then((response) => {
        setNotifications(response.data);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setNotifications({
          notifications: [],
          id: ''
        });
      });
    }
  }, [getNotifications, userName, apiType, userRole]);

  let timeoutId: any = null;
  let ws: WebSocket;

  useEffect(() => {
    const sessionToken = storage.get('sessionToken');

    if (sessionToken) {
      ws = new WebSocket(configuration.webSocketBaseUrl, ['token', sessionToken]);

      ws.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('Conexión WebSocket abierta');
      };

      ws.onclose = (error) => {
        // eslint-disable-next-line no-console
        console.log('Conexión WebSocket cerrada:', error);
      };

      ws.onerror = (error) => {
        // eslint-disable-next-line no-console
        console.error('Error en la conexión WebSocket:', error);
      };

      ws.onmessage = (event) => {
        const data: DataWebSocket = JSON.parse(event.data);

        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }

        if (data.usersIDs.length === 0) {
          if (data.userWhoCreateMessage !== userID) {
            timeoutId = setTimeout(() => {
              setInformationReceivedFromWebsocket(data.data);

              if (data.notification) {
                useNotification(
                  data.data.title,
                  undefined,
                  () => {
                    if (data.route) {
                      // eslint-disable-next-line no-undef
                      const enlace = document.createElement('a');
                      enlace.href = data.route;
                      enlace.target = '_blank';

                      enlace.click();
                    }
                  }
                );
              }
              timeoutId = null;
            }, 1000);
          }
        } else if (data.usersIDs.includes(userID)) {
          timeoutId = setTimeout(() => {
            setInformationReceivedFromWebsocket(data.data);

            if (data.notification) {
              useNotification(
                data.data.title,
                undefined,
                () => {
                  if (data.route) {
                    // eslint-disable-next-line no-undef
                    const enlace = document.createElement('a');
                    enlace.href = data.route;
                    enlace.target = '_blank';

                    enlace.click();
                  }
                }
              );
            }
            timeoutId = null;
          }, 1000);
        }
      };

      return () => {
        ws.close();
      };
    }

    return () => { };
  }, [userID]);

  const closeWebSocketConection = () => {
    if (ws && ws.close) {
      ws.close();
    }
  };

  return (
    <AppContext.Provider value={
      {
        apiType,
        setApiType,
        userRole,
        setUserRole,
        userName,
        setUserName,
        themeMode,
        setThemeMode,
        openModal,
        setOpenModal,
        menuOpen,
        setMenuOpen,
        mandatoryInspectionList,
        setMandatoryInspectionList,
        inspection,
        setInspection,
        notifications,
        setNotifications,
        getNotifications,
        setGetNotifications,
        soundEffects,
        setSoundEffects,
        commonFormat,
        setCommonFormat,
        approver,
        setApprover,
        visibleInspectionDetailModal,
        setVisibleInspectionDetailModal,
        visibleTaskDetailModal,
        setVisibleTaskDetailModal,
        taskIDModalAfterActions,
        setTaskIDModalAfterActions,
        informationReceivedFromWebsocket,
        setUserID,
        closeWebSocketConection,
        manager,
        setManager,
        notReFetchInspection,
        setNotReFetchInspection,
        userToursDone,
        setUserToursDone,
        userID,
        showDictumRequestTour,
        setShowDictumRequestTour
      }
    }>
      {props.children}
    </AppContext.Provider>
  );
};
