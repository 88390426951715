import React, {
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useKeyPress,
  useNavigate,
  useResource
} from '../../hooks';
import storage from '../../storage';
import {
  Button,
  Icon,
  Input,
  SubTitle,
  Title,
  Modal,
  Select
} from '../../components';
import './styles.scss';
import { validate } from '../../helpers';
import { ClientsReferences, Session, User } from '../../interfaces';
import { AppContext } from '../../context/AppContext';

const elementsToSaveOnLocalStorage: string[] = [
  'mainMenuSelectedIndex',
  'lateralMenuInspectionsValue',
  'statusInspectionsValue',
  'lastTaskDetailID',
  'lastInspectionDetailID',
  'filterValueOnTasksView',
  'lateralMenuSEProceduresValue'
];

interface InitialState {
  showResetPasswordForm: boolean,
  email: string,
  password: string,
  redirectRoute: string,
  clients: ClientsReferences[],
  selectedClient: string
}

const initialState: InitialState = {
  showResetPasswordForm: false,
  email: '',
  password: '',
  redirectRoute: '',
  clients: [],
  selectedClient: ''
};

type ActionType =
  { type: 'TOGGLE_RESET_FORM'; payload: boolean }
  | { type: 'SET_EMAIL'; payload: string }
  | { type: 'SET_PASSWORD'; payload: string }
  | { type: 'SET_REDIRECT_ROUTE'; payload: string }
  | { type: 'SET_CLIENTS'; payload: ClientsReferences[] }
  | { type: 'SET_SELECTED_CLIENT'; payload: string }

const reducer = (state: InitialState, action: ActionType): InitialState => {
  switch (action.type) {
    case 'TOGGLE_RESET_FORM':
      return { ...state, showResetPasswordForm: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_REDIRECT_ROUTE':
      return { ...state, redirectRoute: action.payload };
    case 'SET_CLIENTS':
      return { ...state, clients: action.payload };
    case 'SET_SELECTED_CLIENT':
      return { ...state, selectedClient: action.payload };
    default:
      throw new Error(`Unknown action type: ${(action as ActionType).type}`);
  }
};

const SignIn = () => {
  const params = useParams();
  const cookies = new Cookies();

  const { route } = params;

  const {
    createResource,
    updateResource
  } = useResource<any>();

  const {
    fetchResource: mandatoryInspectionList
  } = useResource<boolean>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    setApiType,
    setUserRole,
    setUserName,
    setOpenModal,
    openModal,
    setMandatoryInspectionList,
    soundEffects,
    setApprover,
    setManager,
    setVisibleTaskDetailModal,
    setVisibleInspectionDetailModal,
    setUserID,
    closeWebSocketConection,
    setUserToursDone
  } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const resetPassword = () => {
    updateResource(
      `/sessions/${state.email}/password`,
      {
        role: '',
        name: '',
        token: ''
      },
      () => {
        setErrors({});
        Modal.fireSuccess(
          t('signinView.modal.restorePasswordSuccessTitle'),
          t('signinView.modal.restorePasswordSuccessSubtitle'),
          setOpenModal,
          undefined,
          soundEffects
        );

        dispatch({ type: 'TOGGLE_RESET_FORM', payload: false });
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const validData = (): boolean => {
    if (validate.email(state.email) !== 'success') {
      setErrors({
        email: validate.email(state.email)
      });

      return false;
    }

    if (validate.text(state.password, 8, 16) !== 'success') {
      setErrors({
        password: validate.text(state.password, 8, 16)
      });

      return false;
    }

    return true;
  };

  const acceptForm = () => {
    if (state.showResetPasswordForm) {
      resetPassword();
    } else {
      onSignIn();
    }
  };

  const getMandatoryInspectionList = () => {
    mandatoryInspectionList(
      '/admin/inspections/mandatory-inspection-list/mandatory',
      (data: boolean) => {
        setMandatoryInspectionList(data);
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const signInProcess = (data: Session) => {
    setErrors({});
    setUserID(data.userID);
    setApiType(data.role === 'collaborator' ? 'public' : 'admin');
    setUserRole(data.role);
    setApprover(data.approver);
    setUserName(data.name);
    storage.set('sessionToken', data.token);
    storage.set('apiType', data.role === 'collaborator' ? 'public' : 'admin');
    storage.set('selectedListOnTasksView', 'tasks');
    storage.set('selectedListOnApproverView', 'inspections');
    storage.set('selectedOptionOnTasksView', 'none');
    storage.set('userRole', data.role);
    storage.set('userName', data.name);
    storage.set('userID', data.userID);
    storage.set('approver', data.approver);
    storage.set('userToursDone', JSON.stringify(data.toursDone));
    setUserToursDone(data.toursDone || []);
    cookies.set(
      'soundEffects',
      true,
      {
        path: '/',
        sameSite: 'strict'
      }
    );

    if (data.role !== 'collaborator') {
      getUser(
        '/admin/users/me/data',
        (user: User) => {
          let manager: boolean = false;

          manager = (user.technicalManager === true || user.substituteTechnicalManager === true);

          setManager(manager);
          storage.set('manager', manager);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }

    for (let index = 0; index < elementsToSaveOnLocalStorage.length; index += 1) {
      storage.set(elementsToSaveOnLocalStorage[index], '');
    }

    if (data.role === 'trainee') {
      const binnacleFilters = {
        elementsType: 'binnacle',
        element: 'inspections',
        type: 'DC',
        pagination: {
          currentPage: 1,
          perPage: 10,
          totalItems: 0
        },
        orderBy: 's_createdAt',
        orderDirection: 'DESC',
        filters: {}
      };

      storage.set('binnacleFilters', JSON.stringify(binnacleFilters));
    } else {
      const binnacleFilters = {
        elementsType: 'binnacle',
        element: 'services',
        type: 'dictum',
        pagination: {
          currentPage: 1,
          perPage: 20,
          totalItems: 0
        },
        orderBy: 's_createdAt',
        orderDirection: 'DESC',
        filters: {}
      };

      storage.set('binnacleFilters', JSON.stringify(binnacleFilters));
    }

    if (data.role !== 'collaborator') {
      getMandatoryInspectionList();
    }

    if (data.role === 'finance') {
      navigate(state.redirectRoute ? `/${state.redirectRoute}` : '/binnacle');
    } else if (data.role === 'collaborator') {
      navigate(state.redirectRoute ? `/${state.redirectRoute}` : '/binnacle');
    } else if (data.role === 'trainee') {
      navigate('/binnacle');
    } else if (data.role === 'quality') {
      navigate(state.redirectRoute ? `/${state.redirectRoute}` : '/tasks/none');
    } else if (data.approver && data.role !== 'master') {
      navigate(state.redirectRoute ? `/${state.redirectRoute}` : '/approver-view');
    } else {
      navigate(state.redirectRoute ? `/${state.redirectRoute}` : '/tasks/none');
    }
  };

  const onSignIn = () => {
    if (validData()) {
      createResource(
        '/sessions',
        {
          email: state.email,
          password: state.password,
          clientID: state.selectedClient
        },
        (data) => {
          if (Array.isArray(data)) {
            dispatch({ type: 'SET_CLIENTS', payload: data });
          } else if ('role' in data && 'approver' in data && 'token' in data && 'name' in data) {
            signInProcess(data);
          }
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  useEffect(() => {
    if (route === 'no-route') {
      storage.clear();
      setUserToursDone([]);
      setVisibleTaskDetailModal({
        taskID: '',
        visible: false
      });
      setVisibleInspectionDetailModal({
        inspectionID: '',
        visible: false
      });
    } else if (route) {
      dispatch({ type: 'SET_REDIRECT_ROUTE', payload: route.split('.').join('/') });

      if (storage.get('sessionToken') !== null) {
        navigate(`/${route.split('.').join('/')}`);
      }
    }
  }, [route]);

  useEffect(() => {
    setApiType('admin');
    setUserRole('collaborator');
    setUserName('');
    setUserID('');
    setUserToursDone([]);

    closeWebSocketConection();
  }, [route]);

  useKeyPress(
    openModal ? () => { } : () => acceptForm(),
    [openModal, state.email, state.password, state.showResetPasswordForm]
  );

  useEffect(() => {
    dispatch({ type: 'SET_CLIENTS', payload: [] });
    dispatch({ type: 'SET_SELECTED_CLIENT', payload: '' });
  }, [state.email, state.password]);

  return (
    <div className='signin'>
      <div className='signin__header'>
        <Icon type='CECSAlogo' alt='CECSA Logo' className='signin__header__icon' />
      </div>
      <div className='signin__content'>
        <div className='signin__content__title'>
          <Icon type='servicesIcon' alt='Services Logo' className='signin__content__icon' />
          <br />
          <br />
          <Title title={t('signinView.title')} />
          <SubTitle subTitle={state.showResetPasswordForm ? t('signinView.resetPasswordSubTitle') : t('signinView.subTitle')} />
        </div>
        <div className='signin__content__inputs-container'>
          <div className={`signin__content__inputs-container__inputs signin__content__inputs-container__inputs${state.clients.length > 0 ? '--with-select' : ''}`}>
            <Input
              type='email'
              value={state.email}
              onChange={(value: string | number) => dispatch({ type: 'SET_EMAIL', payload: `${value}` })}
              title={t('signinView.emailInput.title') || 'Correo Electrónico'}
              toolTipText={state.showResetPasswordForm ? t('signinView.emailInput.tooltip.resetPasswordTitle') || '' : t('signinView.emailInput.tooltip.title') || ''}
              toolTipPosition='left'
              placeholder={t('signinView.emailInput.placeholder') || 'Ejemplo: example@email.com'}
              icon='arroba'
              ableAutocomplete={true}
              hasError={!!errors.email}
              helperText={errors.email}
              autofocus={true}
            />
            {
              !state.showResetPasswordForm && (
                <>
                  <Input
                    type='password'
                    value={state.password}
                    onChange={(value: string | number) => dispatch({ type: 'SET_PASSWORD', payload: `${value}` })}
                    title={t('signinView.passwordInput.title') || 'Contraseña'}
                    placeholder='**********'
                    icon='padlock'
                    ableAutocomplete={true}
                    hasError={!!errors.password}
                    helperText={errors.password}
                  />
                  {
                    state.clients.length > 0 && (
                      <>
                        <br />
                        <div className='signin__content__inputs-container__inputs__alert'>
                          <Icon type={'alertRed'} className='signin__content__inputs-container__inputs__alert-icon' />
                          <SubTitle subTitle={t('signinView.selectClient')} red={true} />
                        </div>
                        <Select
                          options={
                            state.clients.map((item) => {
                              return {
                                text: `${item.shortName}${state.clients.length > 2 ? `: ${item.collaboratorName}` : ''}`,
                                value: item.id
                              };
                            })
                          }
                          onChange={(_value: string) => {
                            dispatch({ type: 'SET_SELECTED_CLIENT', payload: `${_value}` });
                          }}
                        />
                        <br />
                      </>
                    )
                  }
                </>
              )
            }
          </div>
          <div className='signin__content__inputs-container__options'>
            <div></div>
            <Button
              onClick={state.showResetPasswordForm
                ? () => dispatch({ type: 'TOGGLE_RESET_FORM', payload: false })
                : () => dispatch({ type: 'TOGGLE_RESET_FORM', payload: true })
              }
              type='primary'
              border='none'
              label={state.showResetPasswordForm ? t('signinView.resetPasswordButton.resetTitle') || '' : t('signinView.resetPasswordButton.title') || ''}
              transparent={true}
              size='small'
            />
          </div>
          <Button
            onClick={acceptForm}
            type='primary'
            label={state.showResetPasswordForm ? t('signinView.signinButton.resetPasswordTitle') || '' : t('signinView.signinButton.title') || ''}
            icon='rightArrow'
            iconPosition='right'
            fullWidth={true}
            submit={true}
          />
        </div>
        <div className='signin__content__footer'>
          <Icon
            type='bionYkLogo'
            alt='bionYk Logo'
            className='signin__content__footer__icon'
          />
          <p>Desarrollado por bionYk</p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
