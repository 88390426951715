import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataDisplay } from '../../../../components';
import { Client } from '../../../../interfaces';

interface Props {
  client: Client
}

export const Contact: React.FC<Props> = (props) => {
  const {
    client
  } = props;

  const { t } = useTranslation();

  return (
    <DataDisplay
      dataTitle={t('clients.contact')}
      items={[
        {
          label: 'E-mail',
          value: client.contact.email,
          format: 'none'
        },
        {
          label: t('global.name'),
          value: client.contact.name,
          format: 'none'
        },
        {
          label: t('clients.phone'),
          value: client.contact.phone,
          format: 'none'
        }
      ]}
    />
  );
};

export default Contact;
