import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { useResource, useNavigate } from '../../hooks';

import {
  Accordeon,
  Button,
  Header,
  LateralMenu,
  Modal,
  ActionButtons,
  ModalViewConstancyProductForm
} from '../../components';

import {
  ActionButton,
  Collaborator,
  ConstancyProduct,
  ConstancyService,
  Inspection,
  LateralMenuOption,
  TableOrderDirection,
  User
} from '../../interfaces';

import './styles.scss';

import {
  GeneralInfo,
  Tracing,
  History,
  Products,
  Documents,
  ModalBillingInfo
} from './components';
import { emptyConstancyService } from '../../emptyObjects';
import { AppContext } from '../../context/AppContext';
import configuration from '../../configuration';
import { UploadFiles } from '../ConstancyProductDetail/components';
import { utils } from '../../helpers';
import storage from '../../storage';

type ConstancyServiceDetailContentType = 'info' | 'history' | 'tracing' | 'products';

interface ProductFiles {
  visible: boolean
  productId: string
  roundID: number
}

const ConstancyServiceDetail = () => {
  const params = useParams();

  const {
    constancyServiceID,
    lookInTheTrash
  } = params;

  const {
    fetchResource,
    eraseResource
  } = useResource<ConstancyService>();

  const {
    fetchResources
  } = useResource<ConstancyProduct>();

  const {
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getConstancyProductDocument
  } = useResource<any>();

  const {
    updateResource: updateUser
  } = useResource<User>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects,
    userID,
    setUserToursDone,
    userToursDone
  } = useContext(AppContext);

  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [products, setProducts] = useState<ConstancyProduct[]>([]);
  const [productsTotalItems, setProductsTotalItems] = useState<number>(0);
  const [productsOrderBy, setProductsOrderBy] = useState<string>('invoice');
  const [productsOrderDirection, setProductsOrderDirection] = useState<TableOrderDirection>('ASC');
  const [productsCurrentPage, setProductsCurrentPage] = useState<number>(1);
  const [constancy, setConstancy] = useState<ConstancyService>(emptyConstancyService);
  const [lateralMenuValue, setLateralMenuValue] = useState<ConstancyServiceDetailContentType>('info');
  const [showModalBillingInfo, setShowModalBillingInfo] = useState<boolean>(false);
  const [visibleProductFiles, setVisibleProductFiles] = useState<ProductFiles>({
    visible: false,
    productId: '',
    roundID: 1
  });
  const [
    showModalViewConstancyProductForm,
    setShowModalViewConstancyProductForm
  ] = useState<boolean>(false);
  const [rerenderContent, setRerenderContent] = useState<boolean>(false);

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    true: 'true',
    false: 'false'
  };

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    },
    {
      label: t('services.documents'),
      value: 'documents'
    },
    {
      label: t('services.history'),
      value: 'history'
    },
    {
      label: t('services.tracing'),
      value: 'tracing'
    },
    {
      label: t('global.products'),
      value: 'products'
    }
  ];

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo constancy={constancy} />),
      documents: (<Documents
        constancy={constancy}
        constancyServiceID={constancy.id}
        serviceNumber={constancy.requestNumber}
        clientShortName={constancy.client.shortName}
      />),
      history: (<History history={constancy.tracking} />),
      tracing: (<Tracing status={constancy.status} />),
      products: (<Products
        lookInTheTrash={lookInTheTrash === 'true'}
        products={products}
        totalItems={productsTotalItems}
        orderBy={productsOrderBy}
        orderDirection={productsOrderDirection}
        setOrderBy={setProductsOrderBy}
        setOrderDirection={setProductsOrderDirection}
        currentPage={productsCurrentPage}
        setCurrentPage={setProductsCurrentPage}
      />)
    };

    return contents[lateralMenuValue];
  }, [
    lateralMenuValue,
    constancy,
    rerenderContent,
    lookInTheTrash,
    products,
    productsCurrentPage,
    productsTotalItems,
    productsOrderBy,
    productsOrderDirection
  ]);

  const throttledHandleRequest = utils.throttle(
    () => {
      fetchResource(
        `/${apiType}/constancy-services/${constancyServiceID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash || ''] || ''}`,
        (data) => {
          setConstancy(data);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledHandleRequest();
  }, [constancyServiceID, apiType, rerenderContent]);

  useEffect(() => {
    if (constancy.id) {
      fetchResources(
        {
          resourcePath: `/${apiType}/constancy-products`,
          filters: {
            s_serviceID: constancy.id,
            b_lookInTheTrash: lookInTheTrashValues[lookInTheTrash || ''],
            b_attachedToTheService: true
          },
          pagination: {
            n_currentPage: productsCurrentPage,
            n_perPage: 10,
            n_totalItems: productsTotalItems
          },
          ordering: {
            s_orderBy: productsOrderBy,
            s_orderDirection: productsOrderDirection
          }
        },
        (data) => {
          setProducts(data.items);
          setProductsCurrentPage(data.pagination.currentPage);
          setProductsTotalItems(data.pagination.totalItems);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [constancy, productsOrderBy, productsOrderDirection, productsCurrentPage]);

  const handleSelectLateralMenu = (value: string) => {
    setLateralMenuValue((value as ConstancyServiceDetailContentType));
  };

  const handleDeleteService = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.deleteService'),
      () => {
        eraseResource(
          `/${apiType}/constancy-services/${constancyServiceID}`,
          {},
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('services.serviceDeleted'),
              setOpenModal,
              () => navigate('/binnacle'),
              soundEffects
            );
          },
          (error) => Modal.fireError(error as string, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const ableButtonsIfDoesntComplete = () => {
    if (userRole === 'collaborator'
      && constancy.indicators.requestIsCompleted) {
      return false;
    }

    return true;
  };

  const downloadAllCertificates = () => {
    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters: {
          s_status: 'concluded',
          s_serviceID: constancy.id
        }
      },
      async (data) => {
        let documentsDownloaded: number = 0;

        Modal.fireLoading();

        // eslint-disable-next-line no-restricted-syntax
        for (const product of data.items) {
          // eslint-disable-next-line no-await-in-loop, no-loop-func
          await new Promise<void>((resolve, reject) => {
            getConstancyProductDocument(
              `/${apiType}/constancy-products/${product.id}/constancy-of-inspection`,
              (data2) => {
                Modal.fireLoading();

                const bytes = new Uint8Array(data2.file.data);

                const blob = new Blob([bytes], { type: 'application/pdf' });

                // eslint-disable-next-line no-undef
                const link = document.createElement('a');
                // eslint-disable-next-line no-undef
                link.href = window.URL.createObjectURL(blob);
                link.download = `${product.invoice}-Constancia.pdf`;
                link.click();
                documentsDownloaded += 1;

                // Actualizar el porcentaje y continuar con el siguiente documento
                const porcentaje = (documentsDownloaded / data.items.length) * 100;
                Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));

                resolve();
              },
              (error) => {
                Modal.fireError(error as string, undefined, soundEffects);
                reject(error);
              }
            );
          });
        }

        Modal.close();
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );
  };

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              if (apiType === 'admin') {
                navigate(`/constancy-request/step_1/${constancy.id}`);
              } else {
                navigate(`/constancy-request/step_2/${constancy.id}/${constancy.requestNumber}/${constancy.client.id}/${constancy.collaborator.id}`);
              }
            }}
            type='primary-outlined'
            label={constancy.indicators.requestIsCompleted === true ? t('services.editService') || '' : t('services.completeService') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='editService'
            alignContent='left'
          />
        ),
        available: constancy.actionsEnabled.includes('edit-service'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && ableButtonsIfDoesntComplete()
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              setShowModalViewConstancyProductForm(true);
            }}
            type='primary-outlined'
            label={t('services.addProduct') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='add'
            alignContent='left'
          />
        ),
        available: constancy.actionsEnabled.includes('add-product'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && ableButtonsIfDoesntComplete()
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              navigate(`/constancy-request/step_2/${constancy.id}/${constancy.requestNumber}/${constancy.client.id}/${constancy.collaborator.id}`);
            }}
            type='primary-outlined'
            label={t('services.editProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='edit'
            alignContent='left'
          />
        ),
        available: constancy.actionsEnabled.includes('edit-products'),
        specialValidation: userRole !== 'inspector'
          && userRole !== 'quality'
          && userRole !== 'finance'
          && ableButtonsIfDoesntComplete()
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={handleDeleteService}
            type='primary-outlined'
            label={t('services.deleteServiceButton') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='paperShredder'
            alignContent='left'
          />
        ),
        available: constancy.actionsEnabled.includes('delete-service'),
        specialValidation: ['master', 'operator', 'collaborator'].includes(userRole) && ableButtonsIfDoesntComplete() && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/none/${constancy.id}/CC`)}
            type='primary-outlined'
            label={t('services.assignProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspector'
            alignContent='left'
          />
        ),
        available: constancy.actionsEnabled.includes('assign-products'),
        specialValidation: apiType === 'admin' && ['master', 'operator'].includes(userRole) && ableButtonsIfDoesntComplete() && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => setShowModalBillingInfo(true)}
            type='primary-outlined'
            label={t('global.registerInvoiced') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon={'creditCard'}
            alignContent='left'
          />
        ),
        available: constancy.actionsEnabled.includes('register-invoiced'),
        specialValidation: constancy.actionsEnabled.includes('register-invoiced') && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => downloadAllCertificates()}
            type='primary-outlined'
            label={'Descargar todos los certificados 1-1'}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon={'download'}
            alignContent='left'
            disabled={userRole !== 'sys-admin'}
          />
        ),
        available: userRole === 'sys-admin',
        specialValidation: userRole === 'sys-admin' && constancy.status === 'concluded'
      }
    ]);
  }, [constancy, apiType, i18n.language, products, lookInTheTrash]);

  const driverObj = driver({
    showProgress: true,
    allowClose: false,
    smoothScroll: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#header', popover: { title: t('tours.serviceTitle') || '', description: t('tours.serviceTitleDescription') || '' } },
      { element: '#share-button', popover: { title: t('tours.share') || '', description: t('tours.shareDescription') || '' } },
      { element: '#menu-section', popover: { title: t('tours.sections') || '', description: t('tours.sectionsDescription') || '' } },
      { element: '#data-section', popover: { title: t('tours.data') || '', description: t('tours.dataDescription') || '' } }
    ],
    onDestroyed: () => {
      if (apiType === 'admin') {
        updateUser(
          `admin/users/${userID}/tour-done`,
          {
            tourDone: 'service-detail'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }

      if (apiType === 'public') {
        updateCollaborator(
          'public/collaborators/tour-done',
          {
            tourDone: 'service-detail'
          },
          (data) => {
            setUserToursDone(data.toursDone || []);
            storage.set('userToursDone', JSON.stringify(data.toursDone));
          },
          // eslint-disable-next-line no-console
          (error: string) => console.log(error)
        );
      }
    }
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('service-detail') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <div className="constancy-service-detail">
      <Header
        title={t('services.constancyRequest')}
        subTitle={[
          {
            label: constancy?.requestNumber,
            title: t('services.serviceNumber') || ''
          }
        ]}
        showBackbutton={true}
      />
      <div className="constancy-service-detail__main">
        <div className="constancy-service-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                id: 'menu-section',
                element: (
                  <div >
                    <LateralMenu
                      value="info"
                      onSelect={handleSelectLateralMenu}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="constancy-service-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/constancy-services.detail.${constancy.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/constancy-services.detail.${constancy.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
            id='share-button'
          />
          {getContent()}
        </div>
      </div>
      <ModalViewConstancyProductForm
        visible={showModalViewConstancyProductForm}
        title={t('services.addProduct') || ''}
        clientID={constancy.client.id}
        collaboratorID={constancy.collaborator.id}
        serviceID={constancy.id}
        onCreateProduct={(data) => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.createdProduct'),
            setOpenModal,
            () => {
              setRerenderContent(!rerenderContent);

              getInspection(
                `/admin/inspections/${data.inspection?.id}`,
                (inspection) => {
                  setVisibleProductFiles({
                    visible: true,
                    productId: data.id,
                    roundID: inspection.rounds.length
                  });
                },
                (error: string) => Modal.fireError(error, undefined, soundEffects)
              );
            },
            soundEffects
          );
        }}
        onClose={() => {
          setShowModalViewConstancyProductForm(false);
        }}
        onError={(error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }}
      />
      <ModalBillingInfo
        visible={showModalBillingInfo}
        onClose={() => {
          setShowModalBillingInfo(false);
        }}
        serviceID={constancy.id}
        setConstancy={setConstancy}
      />
      <UploadFiles
        visible={visibleProductFiles.visible}
        require={true}
        onClose={() => setVisibleProductFiles({ ...visibleProductFiles, visible: false })}
        onChangeProduct={(_product: ConstancyProduct) => {
          setVisibleProductFiles({ ...visibleProductFiles, visible: false });

          Modal.fireSuccess(
            t('global.correct'),
            t('services.uploadedFiles'),
            setOpenModal,
            () => { },
            soundEffects
          );
        }}
        productID={visibleProductFiles.productId}
        roundID={visibleProductFiles.roundID}
        productFiles={[]}
      />
    </div>
  );
};

export default ConstancyServiceDetail;
