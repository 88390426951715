import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon
} from '../../../../../../components';
import { TableOrderDirection } from '../../../../../../interfaces';
import '../../styles.scss';
import Filters from '../Filters';

interface Props {
  filter: string
  showModal: boolean
  onCloseModalFilters: () => void
  setFilterValue: (_value: string) => void
  filterValue: string
  onClearFilter: () => void
  onChangeOrder: (_order: string, _direction: TableOrderDirection) => void
  headerValue: string
  orderBy: string
  orderDirection: TableOrderDirection | 'none'
  handleSearch: (_whitoutFilters?: boolean) => void
}

const Table: React.FC<Props> = (props) => {
  const {
    filter,
    showModal,
    onCloseModalFilters,
    filterValue,
    setFilterValue,
    onClearFilter,
    onChangeOrder,
    headerValue,
    orderBy,
    orderDirection,
    handleSearch
  } = props;

  const { t } = useTranslation();

  const onChangeInput = (value: string | number) => {
    setFilterValue(`${value}`);

    if (`${value}`.length === 0) {
      handleSearch();
    }
  };

  return (
    <div
      className={`binnacle-table-modal header-element binnacle-table-modal--${showModal ? '' : 'hidden'}`}
      id='binnacle-table-modal'
    >
      <div className='binnacle-table-modal__close-container' id='container-filter-modal'>
        <Icon
          type='close'
          className='binnacle-table-modal__close-container__icon'
          onClick={onCloseModalFilters}
          id='filter-modal-icon'
        />
      </div>
      <div className='binnacle-table-modal__title' id='container-filter-modal'>
        <Icon type='order' className='binnacle-table-modal__title__icon' id='filter-modal-icon' />
        <p id='filter-modal-text'>{t('global.orderBy')}</p>
      </div>
      <div id='container-filter-modal' className={`binnacle-table-modal__cliceable-option binnacle-table-modal__cliceable-option${orderBy === headerValue && orderDirection === 'DESC' ? '--selected' : ''}`} onClick={() => onChangeOrder(headerValue, 'DESC')}>
        <Icon type='DESC' className='binnacle-table-modal__cliceable-option__icon' id='filter-modal-icon' />
        <p id='filter-modal-text'>{t('global.falling')}</p>
      </div>
      <div id='container-filter-modal' className={`binnacle-table-modal__cliceable-option binnacle-table-modal__cliceable-option${orderBy === headerValue && orderDirection === 'ASC' ? '--selected' : ''}`} onClick={() => onChangeOrder(headerValue, 'ASC')}>
        <Icon type='ASC' className='binnacle-table-modal__cliceable-option__icon' id='filter-modal-icon' />
        <p id='filter-modal-text'>{t('global.upward')}</p>
      </div>
      <div id='container-filter-modal' className='binnacle-table-modal__title' >
        <Icon type='magnifyingGlass' className='binnacle-table-modal__title__icon' id='filter-modal-icon' />
        <p id='filter-modal-text'>{t('global.filer')}</p>
      </div>
      <div id='container-filter-modal' className='binnacle-table-modal__filter-container' >
        <Filters
          filterValue={filterValue}
          onChangeInput={onChangeInput}
          selectedFilter={filter}
        />
      </div>
      <div id='container-filter-modal' className='binnacle-table-modal__filter-container' >
        <Button
          type={'secondary-outlined'}
          onClick={() => handleSearch()}
          label={t('global.search') || ''}
          fullWidth={true}
        />
      </div>
      <div
        className={`binnacle-table-modal__cliceable-option binnacle-table-modal__cliceable-option${!filterValue && orderBy === 'none' ? '--disabled' : ''}`}
        onClick={() => {
          onClearFilter();
          handleSearch(true);
          onCloseModalFilters();
        }}
        id='container-filter-modal'
      >
        <Icon type='resetFilter' className='binnacle-table-modal__cliceable-option__icon' id='filter-modal-icon' />
        <p id='filter-modal-text'>{t('global.deleteFilter')}</p>
      </div>
    </div>
  );
};

export default Table;
