/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom';
import MainMenu from './components';
import { MainMenuOption } from '../../interfaces';

import './styles.scss';
import { AppContext } from '../../context/AppContext';
import { useNavigate } from '../../hooks';

let menu: MainMenuOption[] = [
  {
    icon: 'circleError',
    label: 'Errores',
    viewPath: '/errors/none',
    roles: ['sys-admin'],
    id: 'errors'
  },
  {
    icon: 'checkList',
    label: 'Tareas',
    viewPath: '/tasks/none',
    roles: ['master', 'inspector', 'operator', 'quality', 'sys-admin'],
    id: 'tasks'
  },
  {
    icon: 'fileWhite',
    label: 'Bitácora',
    viewPath: '/binnacle',
    roles: ['trainee', 'collaborator', 'inspector', 'master', 'operator', 'quality', 'finance', 'sys-admin'],
    id: 'binnacle'
  },
  {
    icon: 'DCIcon',
    label: 'Solicitar UVA',
    viewPath: '/dictum-request/step_1/none',
    roles: ['master', 'operator', 'sys-admin', 'collaborator'],
    id: 'uva'
  },
  {
    icon: 'CCIcon',
    label: 'Solicitar Constancia',
    viewPath: '/constancy-request/step_1/none',
    roles: ['master', 'operator', 'sys-admin'],
    id: 'constancy'
  },
  {
    icon: 'revision',
    label: 'Solicitar Revisión',
    viewPath: '/revision-request/step_1/none',
    roles: ['master', 'operator', 'sys-admin'],
    id: 'revision'
  },
  {
    icon: 'SELogo',
    label: 'Trámites ante la S.E.',
    viewPath: '/se-procedures/none/none/none',
    roles: ['master', 'operator', 'quality', 'sys-admin'],
    id: 'se'
  },
  {
    icon: 'clientsLogo',
    label: 'Clientes',
    viewPath: '/clients',
    roles: ['master', 'operator', 'quality', 'finance', 'sys-admin'],
    id: 'clients'
  },
  {
    icon: 'usersLogo',
    label: 'Usuarios',
    viewPath: '/users',
    roles: ['master', 'operator', 'quality', 'sys-admin'],
    id: 'users'
  },
  {
    icon: 'chart',
    label: 'KPI´s',
    viewPath: '/reports',
    roles: ['master', 'operator', 'finance', 'sys-admin', 'inspector', 'quality'],
    id: 'kpi'
  },
  {
    icon: 'work',
    label: 'Gerencia',
    viewPath: '/reports/operations-report',
    roles: ['master', 'operator', 'finance', 'sys-admin', 'inspector', 'quality'],
    id: 'operations-reports',
    childOf: 'kpi'
  },
  {
    icon: 'clientsLogo',
    label: 'Servicio al cliente',
    viewPath: '/reports/client-service',
    roles: ['master', 'operator', 'finance', 'sys-admin', 'inspector', 'quality'],
    id: 'client-service-reports',
    childOf: 'kpi'
  },
  {
    icon: 'inspectorWhite',
    label: 'Inspectores',
    viewPath: '/reports/inspectors-ranking',
    roles: ['master', 'operator', 'finance', 'sys-admin', 'inspector', 'quality'],
    id: 'operations-reports',
    childOf: 'kpi'
  },
  {
    icon: 'chart',
    label: 'Reportes',
    viewPath: '/reports/csv-reports',
    roles: ['master', 'operator', 'finance', 'sys-admin', 'inspector'],
    approver: true,
    id: 'reports'
  },
  {
    icon: 'quality',
    label: 'Calidad',
    viewPath: '/quality',
    roles: ['trainee', 'inspector', 'master', 'operator', 'quality', 'finance', 'sys-admin'],
    manager: false,
    id: 'quality'
  },
  {
    icon: 'monthlyClosings',
    label: 'Cierres mensuales',
    viewPath: '/monthly-closings',
    roles: ['master', 'quality', 'inspector', 'sys-admin'],
    manager: true,
    id: 'monthly-closings',
    childOf: 'quality'
  },
  {
    icon: 'correctiveActions',
    label: 'AC/AP',
    viewPath: '/corrective-actions',
    roles: ['master', 'quality', 'inspector', 'sys-admin'],
    manager: true,
    id: 'corrective-actions',
    childOf: 'quality'
  },
  {
    icon: 'internalAudit',
    label: 'Auditoría interna',
    viewPath: '/internal-audit',
    roles: ['master', 'quality', 'inspector', 'sys-admin'],
    manager: true,
    id: 'internal-audit',
    childOf: 'quality'
  },
  {
    icon: 'trainingRecord',
    label: 'Registros de capacitaciones',
    viewPath: '/training-records',
    roles: ['trainee', 'inspector', 'master', 'operator', 'quality', 'finance', 'sys-admin'],
    manager: false,
    id: 'training-records',
    childOf: 'quality'
  },
  {
    icon: 'notification',
    label: 'Notificaciones',
    viewPath: '/notifications',
    roles: ['master', 'operator', 'inspector', 'sys-admin', 'collaborator'],
    id: 'notifications'
  },
  {
    icon: 'userWhite',
    label: 'Perfil',
    viewPath: '/profile',
    roles: ['trainee', 'collaborator', 'inspector', 'master', 'operator', 'quality', 'finance', 'sys-admin'],
    id: 'profile'
  },
  {
    icon: 'help',
    label: 'Ayuda',
    viewPath: '/help',
    roles: ['master', 'collaborator', 'inspector', 'operator', 'sys-admin'],
    id: 'help'
  },
  {
    icon: 'doorWhite',
    label: 'Cerrar sesión',
    viewPath: '/signin/no-route',
    roles: ['trainee', 'collaborator', 'inspector', 'master', 'operator', 'quality', 'finance', 'sys-admin'],
    id: 'logout'
  }
];

export const MainLayout: React.FC = () => {
  const { t } = useTranslation();

  const {
    apiType,
    approver,
    userRole
  } = useContext(AppContext);

  const navigate = useNavigate();

  menu = [
    {
      ...menu[0],
      label: t('global.errors')
    },
    {
      ...menu[1],
      label: t('menu.tasks')
    },
    {
      ...menu[2],
      label: t('menu.binnacle')
    },
    {
      ...menu[3],
      label: t('menu.requestUVA')
    },
    {
      ...menu[4],
      viewPath: apiType === 'public' ? '/constancy-request/step_2/none/none/none/none' : '/constancy-request/step_1/none',
      label: t('menu.requestConstancy')
    },
    {
      ...menu[5],
      viewPath: apiType === 'public' ? '/revision-request/step_2/none/none/none/none' : '/revision-request/step_1/none',
      label: t('menu.requestRevision')
    },
    {
      ...menu[6],
      label: t('menu.proceduresSE')
    },
    {
      ...menu[7],
      label: t('menu.clients')
    },
    {
      ...menu[8],
      label: t('menu.users')
    },
    {
      ...menu[9],
      label: 'KPI´s'
    },
    {
      ...menu[10],
      label: t('menu.management')
    },
    {
      ...menu[11],
      label: t('menu.customerService')
    },
    {
      ...menu[12],
      label: t('menu.inspectors')
    },
    {
      ...menu[13],
      label: t('menu.reports')
    },
    {
      ...menu[14],
      label: t('global.rolesNames.quality')
    },
    {
      ...menu[15],
      label: t('menu.monthlyClosings')
    },
    {
      ...menu[16],
      label: 'AC/AP'
    },
    {
      ...menu[17],
      label: t('menu.internalAudit')
    },
    {
      ...menu[18],
      label: t('menu.trainingRecords')
    },
    {
      ...menu[19],
      label: t('menu.notifications')
    },
    {
      ...menu[20],
      label: t('menu.profile')
    },
    {
      ...menu[21],
      label: t('menu.help')
    },
    {
      ...menu[22],
      label: t('menu.signOut')
    }
  ];

  const setMenuItems = (): MainMenuOption[] => {
    if ((apiType === 'admin' && approver) || userRole === 'master') {
      return [
        {
          icon: 'inspectorWhite',
          label: t('global.approverTitle'),
          viewPath: '/approver-view',
          roles: ['master', 'inspector'],
          id: 'approver-view'
        },
        ...menu
      ];
    }

    return menu;
  };

  useEffect(() => {
    const channel = new BroadcastChannel('cecsa_trade_channel');

    channel.postMessage({ type: 'new_tab' });

    channel.onmessage = (event) => {
      if (event.data.type === 'tab_opened') {
        // eslint-disable-next-line no-alert
        alert(t('global.appAlreadyOpen'));
        navigate('/app-already-open');
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  if (Notification.permission !== 'granted') {
    Notification.requestPermission();
  }

  return (
    <div className='main-layout'>
      <MainMenu menu={setMenuItems()} />
      <div className='main-layout__outlet' id='outlet-element'>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
