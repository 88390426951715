import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';

import {
  File,
  Modal,
  Separator,
  SubTitle,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { useResource } from '../../../../hooks';
import {
  Client,
  DictumProduct,
  InspectionByID,
  InspectionStatus
} from '../../../../interfaces';
import { emptyInspectionByID } from '../../../../emptyObjects';

interface Props {
  product: DictumProduct
  serviceNumber: string
  hasOutOfCustomsDate: boolean
  inspectionStatus: InspectionStatus
  client: Client
}

const DictumProductProductDetailHistory: React.FC<Props> = (props) => {
  const {
    product,
    serviceNumber,
    hasOutOfCustomsDate,
    inspectionStatus,
    client
  } = props;

  const { t } = useTranslation();

  const {
    fetchResource: getDictumProductDocument
  } = useResource<any>();

  const {
    fetchResource: getInspectionFormatDocument
  } = useResource<any>();

  const {
    fetchResource: getInspection
  } = useResource<InspectionByID>();

  const {
    fetchResource
  } = useResource<string>();

  const {
    fetchResources
  } = useResource<any>();

  const {
    apiType,
    soundEffects
  } = useContext(AppContext);

  const [
    allGroupedProductsAreConcluded,
    setAllGroupedProductsAreConcluded
  ] = useState<boolean>(false);
  const [inspection, setInspection] = useState<InspectionByID>(emptyInspectionByID);

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `${apiType}/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const handleGenerateDictumProductDocument = async () => {
    getDictumProductDocument(
      `/${apiType}/dictum-products/${product.id}/dictum-of-inspection`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `${product.invoice}-${serviceNumber}-Dictamen.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  useEffect(() => {
    fetchResources(
      {
        resourcePath: `/${apiType}/dictum-products`,
        filters: {
          s_invoice: product.invoice
        }
      },
      (data) => {
        setAllGroupedProductsAreConcluded(data.items.every((item) => item.status === 'concluded'));
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, [product]);

  const disableDictum = useCallback((): boolean => {
    let result: boolean = false;

    if (inspectionStatus !== 'concluded') {
      return true;
    }

    if (product.status !== 'concluded') {
      result = true;
    }

    if (product.status === 'concluded'
      && hasOutOfCustomsDate === false) {
      if (product.indicators.waitingToExpired === true) {
        result = false;
      } else {
        result = true;
      }
    }

    if (allGroupedProductsAreConcluded === false) {
      result = true;
    }

    if (client.signedContract !== true) {
      return true;
    }

    return result;
  }, [
    inspection,
    allGroupedProductsAreConcluded,
    inspectionStatus,
    product,
    hasOutOfCustomsDate,
    client
  ]);

  const handleDownloadInspectionFormat = (roundID: number) => {
    getInspectionFormatDocument(
      `/${apiType}/inspections/${inspection.id}/${roundID}/inspection-format`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `Formato de Inspección-${inspection.product.invoice}-ronda-${roundID}.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  useEffect(() => {
    if (product.inspection?.id) {
      getInspection(
        `/${apiType}/inspections/${product.inspection?.id}`,
        (_inspection) => {
          setInspection(_inspection);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [product]);

  return (
    <div className="dictum-product-detail__documents">
      <Title title={t('services.documents')} type="primary" />
      <br />
      <div className="dictum-product-detail__documents__container">
        <File
          name={`${product.invoice}-${serviceNumber}-Dictamen.pdf`}
          onDownload={handleGenerateDictumProductDocument}
          disabled={disableDictum()}
          orientation='horizontal'
          showToolTip={false}
        />
        {
          inspection.rounds.length >= 1
          && (inspection.rounds[0].files.some((file) => file.includes('__round-feedback'))
            || inspection.rounds[0].steps.some(element => element === 'list-completed')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 1`} />
              {
                inspection.rounds[0].files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                  <>
                    {
                      inspection.rounds[0].files.filter((file) => file.includes('__round-feedback')).map((item, index) => (
                        <>
                          <span className='constancy-product-detail__documents__container__title'> {t('services.inspectionFormat')}</span>
                          <File
                            name={item.split('/').at(-1) || item}
                            onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                            key={index}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      inspection.rounds[0].steps.find(element => element === 'list-completed') !== undefined && (
                        <>
                          <File
                            name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-1.pdf`}
                            disabled={false}
                            onDownload={inspection.rounds[0].steps.find(element => element === 'list-completed') !== undefined
                              ? () => handleDownloadInspectionFormat(1)
                              : undefined}
                            orientation='horizontal'
                          />
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
        {
          inspection.rounds.length >= 2
          && (inspection.rounds[1].files.some((file) => file.includes('__round-feedback'))
            || inspection.rounds[1].steps.some(element => element === 'list-completed')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 2`} />
              {
                inspection.rounds[1].files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                  <>
                    {
                      inspection.rounds[1].files.filter((file) => file.includes('__round-feedback')).map((item, index) => (
                        <>
                          <span className='constancy-product-detail__documents__container__title'> {t('services.inspectionFormat')}</span>
                          <File
                            name={item.split('/').at(-1) || item}
                            onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                            key={index}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      inspection.rounds[1].steps.find(element => element === 'list-completed') !== undefined && (
                        <>
                          <File
                            name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-2.pdf`}
                            disabled={false}
                            onDownload={inspection.rounds[1].steps.find(element => element === 'list-completed') !== undefined
                              ? () => handleDownloadInspectionFormat(2)
                              : undefined}
                            orientation='horizontal'
                          />
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
      </div>
    </div>
  );
};

export default DictumProductProductDetailHistory;
