import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import dayjs from 'dayjs';
import localeEs from 'dayjs/locale/es';

import { App } from './App';
import { AppProvider } from './context/AppContext';

import './i18n';
import 'driver.js/dist/driver.css';

// eslint-disable-next-line no-undef
const container = document.getElementById('root');

const root = createRoot(container!);

dayjs.locale(localeEs);

root.render(
  <StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </StrictMode>
);
