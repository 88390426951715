/* eslint-disable max-len */
import React, {
  useContext,
  useEffect,
  useState
} from 'react';

import { CommonList } from '..';
import {
  InspectionList,
  Inspection,
  CommonInspectionFormatQuestion,
  ConstancyProductIndicators,
  RevisionProductIndicators,
  Round,
  SectionsNOM004,
  SectionsNOM003,
  User,
  Question,
  Client
} from '../../../../../interfaces';

import versions from '../versions';
import { AppContext } from '../../../../../context/AppContext';
import Spinner from '../../../../Spinner';
import { emptyRound } from '../../../../../emptyObjects';
import configuration from '../../../../../configuration';
import { useResource } from '../../../../../hooks';
import Modal from '../../../../Modal';

interface Props {
  disabled: boolean
  onFetchInspection?: (_value: Inspection) => void
  labelsToPut: number
  roundID?: number
  isGrouped: boolean
  productIndicators?: ConstancyProductIndicators | RevisionProductIndicators
  user: User
  client: Client
  showNavigateButtons?: boolean
}

const ListForm: React.FC<Props> = (props) => {
  const {
    disabled,
    onFetchInspection,
    labelsToPut,
    roundID,
    isGrouped,
    productIndicators,
    user,
    client,
    showNavigateButtons = true
  } = props;

  const {
    eraseResource
  } = useResource<Inspection>();

  const {
    inspection,
    commonFormat,
    setCommonFormat,
    soundEffects
  } = useContext(AppContext);

  const [normSectionChanged, setNormSectionChanged] = useState<boolean>(false);
  const [norm004Section, setNorm004Section] = useState<SectionsNOM004>('none');
  const [norm020Section, setNorm020Section] = useState<SectionsNOM004>('none');
  const [norm003Section, setNorm003Section] = useState<SectionsNOM003>('none');
  const [norm173Section, setNorm173Section] = useState<SectionsNOM003>('none');
  const [questions, setQuestions] = useState<CommonInspectionFormatQuestion[]>([]);
  const [lastRoundQuestions, setlastRoundQuestions] = useState<Question[]>([]);
  const [round, setRound] = useState<Round>(emptyRound);
  const [lastRound, setLastRound] = useState<Round>(emptyRound);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    setRound(inspection.rounds.find((roundItem) => roundItem.id === roundID) || inspection.rounds[inspection.rounds.length - 1]);

    if (inspection.rounds.length >= 2) {
      setLastRound(inspection.rounds[inspection.rounds.length - 2]);
    }
  }, [
    roundID,
    inspection
  ]);

  useEffect(() => {
    if (round) {
      if (round.id !== -1) {
        setCommonFormat({
          inspectorName: '',
          measurementEquipment: 'none',
          clientBusinessName: '',
          productDescription: '',
          complies: false,
          generalObservations: '',
          questions: [],
          packing: {
            type: 'none',
            height: null,
            width: null,
            radius: null,
            content: 0,
            productType: 'none'
          },
          sampling: null,
          model: '',
          section: '',
          answers: {},
          sign: null
        });

        let auxFormat: InspectionList = {
          inspectorName: '',
          measurementEquipment: 'none',
          clientBusinessName: '',
          productDescription: '',
          complies: false,
          generalObservations: '',
          questions: [],
          packing: {
            type: 'none',
            height: null,
            width: null,
            radius: null,
            content: 0,
            productType: 'none'
          },
          sampling: null,
          model: '',
          section: '',
          answers: {},
          sign: null
        };

        if (round.list) {
          auxFormat = round.list as InspectionList;

          if (auxFormat.questions.some((item) => !('evidence' in item))
            || auxFormat.questions.some((item) => item.answer === false)) {
            auxFormat.questions = auxFormat.questions.map((item) => {
              return {
                answer: item.answer,
                observations: item.observations,
                haveEvidence: item.answer === false,
                evidence: item.evidence ? item.evidence : ''
              };
            });
          }
        }

        if (lastRound
          && lastRound.list
          && lastRound.inspectionListVersion === round.inspectionListVersion
        ) {
          setlastRoundQuestions(lastRound.list.questions);
        }

        // eslint-disable-next-line max-len
        const auxQuestions: CommonInspectionFormatQuestion[] = versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].questions || [];

        if (auxFormat.questions !== null
          && !inspection.listCompleted
          && (auxFormat.questions.length === 0
            || auxFormat.questions.length > auxQuestions.length)
        ) {
          auxFormat.questions = auxQuestions.map((item) => {
            if (item.isATitle) {
              return {
                answer: 'title',
                observations: '',
                haveEvidence: false,
                evidence: '',
                section: item.section
              };
            }

            return {
              answer: 0,
              observations: '',
              haveEvidence: false,
              evidence: '',
              section: item.section
            };
          });
        }

        setCommonFormat(auxFormat);
        setQuestions(auxQuestions);
        setFirstRender(false);
      }
    }
  }, [
    round,
    inspection,
    lastRound
  ]);

  const deleteEvidence = async (evidencePath: string) => {
    await eraseResource(
      `/admin/inspections/${inspection.id}/list/${inspection.rounds.at(-1)?.id}/delete-list-evidence`,
      {
        s_documentPath: evidencePath
      },
      () => {
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  useEffect(() => {
    if (round && firstRender === false && disabled === false) {
      if (round.id !== -1) {
        const auxFormat = commonFormat;

        if (!round.list) {
          auxFormat.questions = auxFormat.questions.map((item) => {
            if (item.section) {
              if (norm004Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm004Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              if (norm020Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm020Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              if (norm003Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm003Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              if (norm173Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm173Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              return {
                answer: 0,
                observations: '',
                haveEvidence: false,
                evidence: '',
                section: item.section
              };
            }

            return {
              answer: item.answer,
              observations: item.observations,
              haveEvidence: item.haveEvidence,
              evidence: item.evidence,
              section: item.section
            };
          });
        }

        if (normSectionChanged) {
          auxFormat.questions = auxFormat.questions.map((item) => {
            if (item.section) {
              if (norm004Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm004Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              if (norm020Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm020Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              if (norm003Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm003Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              if (norm173Section !== 'none') {
                if (item.evidence !== '') {
                  deleteEvidence(item.evidence);
                }

                if (item.answer === 'title') {
                  return {
                    answer: 'title',
                    observations: '',
                    haveEvidence: false,
                    evidence: '',
                    section: item.section
                  };
                }

                return {
                  answer: norm173Section === item.section ? 0 : null,
                  observations: '',
                  haveEvidence: false,
                  evidence: '',
                  section: item.section
                };
              }

              return {
                answer: 0,
                observations: '',
                haveEvidence: false,
                evidence: '',
                section: item.section
              };
            }

            return {
              answer: item.answer,
              observations: item.observations,
              haveEvidence: item.haveEvidence,
              evidence: item.evidence,
              section: item.section
            };
          });
        }

        setCommonFormat({ ...auxFormat });
      }
    }
  }, [
    norm004Section,
    norm020Section,
    norm003Section,
    norm173Section,
    disabled,
    normSectionChanged
  ]);

  if (commonFormat.questions.length > 0 && questions.length > 0 && inspection.id !== '' && round && round.id !== -1) {
    return (
      <CommonList
        disabled={disabled}
        questions={questions}
        lastRoundQuestions={lastRoundQuestions}
        hasPackingTable={versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].hasPackingTable}
        hasSamplingTable={versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].hasSamplingTable}
        hasMeasurementEquipment={versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].hasMeasurementEquipment}
        documentCode={versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].documentCode}
        documentDescription={versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].documentDescription}
        documentIssueDate={versions[round.inspectionListVersion || configuration.inspectionsListsVersion][inspection.norm][inspection.service.code].documentIssueDate}
        onFetchInspection={onFetchInspection}
        labelsToPut={labelsToPut}
        isGrouped={isGrouped}
        roundID={roundID || inspection.rounds.at(-1)?.id || 1}
        normCode={inspection.norm}
        productIndicators={productIndicators}
        norm004Section={norm004Section}
        setNorm004Section={setNorm004Section}
        norm020Section={norm020Section}
        setNorm020Section={setNorm020Section}
        norm003Section={norm003Section}
        setNorm003Section={setNorm003Section}
        norm173Section={norm173Section}
        setNorm173Section={setNorm173Section}
        user={user}
        setNormSectionChanged={setNormSectionChanged}
        client={client}
        showNavigateButtons={showNavigateButtons}
      />
    );
  }

  return (<div className='loading-page'><Spinner /></div>);
};

export default ListForm;
